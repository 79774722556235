import { createApp } from 'vue';
//import App from './App.vue';
import router from './routes.js'; 
import Vuex from 'vuex';
import axios from 'axios';

import { store } from './store/store.js'; 
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import VueLazyLoad from 'vue-lazyload';

import { VueEditor, Quill } from 'vue3-editor';

import '@mdi/font/css/materialdesignicons.css';
import '@mdi/font/fonts/materialdesignicons-webfont.ttf'
import '@mdi/font/fonts/materialdesignicons-webfont.woff'
import '@mdi/font/fonts/materialdesignicons-webfont.eot'
import '@mdi/font/fonts/materialdesignicons-webfont.woff2'






import './public-path' //IMPORTUJEM JAVNE VARIJABLE IZ POMOĆNOG FAJLA
import mitt from 'mitt';//library koji u vue3 mitt odradjuje bus






const app = createApp();

const eventBus=mitt();
app.provide('eventBus',eventBus);//vue3 mitt library za bus







app.config.globalProperties.axios = axios
app.use(router);

//app.use(store);
/************************************  kreni store ***********************************/
//import Vuex from 'vuex';

//app.use( createStore );
app.use(store);

/************************************  kraj store /************************************ */
library.add(faPlusSquare, faTrash);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('v-select', vSelect);
app.component('vue-editor', VueEditor);

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';



const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // Set Material Design Icons as default
    iconfont: 'mdi', // 'mdi', 'fa', 'fa4', 'faSvg'
  },
  sets: {
    mdi: {
      component: 'MaterialDesignIcons',
    },
    fa: {
      component: 'FontAwesomeIcons',
    },
  },
  defaultAssets: {
    //font: false, // Isključuje Roboto font, uključi u app.css body {font-family: 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;}
  },
  theme: {
    themes: {
      light: {
        lightgrey_tbl: "#F2F3F4",
      }
    }
  }
});

app.use(vuetify); 

app.use(VueLazyLoad);


// Export the app instance as a bus
export const bus = app;

// Add a global property $bus to the app instance
app.config.globalProperties.$bus = app;

//app.component('apphomepage', AppHomepage);
//app.component('apphomepage', TestHomepage);
app.component('apphomepage',require('./vue/WebShop/AppHomePage.vue').default);
app.component('homewshop', require('./vue/WebShop/HomeWShop.vue').default);
app.component('webshopwshop', require('./vue/WebShop/WebShopWShop.vue').default);

app.component('editproduktwshop', require('./vue/WebShop/EditProduktWShop.vue').default);
/*
ovo je specifična komponenta jer se mora doći do nje preko adresbara pa je otvorena ovde ali i u
web.php routama i naravno napravljena i editprodukwhsop.blade.php jer laravel neće htjeti otvoriti ovaj link
obzirom da je on zadužen za otvaranje preko adressbara. Vue route ovo regularno otvara preko push.
1.u web.php napravi route ovako
Route::get('/wsprodukteditfront/{editProduktSlug?}', function ($editProduktSlug = null) {
    return view('start', compact('editProduktSlug'));
})->where('editProduktSlug', '.*');
*/

/*app.component('webshopkreiranjetabela', require('./vue/WebShopKreiranje/WebShopKreiranjeTabela').default);
app.component('webshopnarudzbetabela', require('./vue/WebShopNarudzbe/WebShopNarudzbeTabela').default);
app.component('webshopfakturetabela', require('./vue/WebShopFakturisanje/WebShopFaktureTabela').default);
app.component('webshopkreiranjekontejner', require('./vue/WebShopKreiranje/WebShopKreiranjeKontejner').default);
*/

app.component('roba-tabela', require('./vue/Registarroba/RobaTabela.vue').default);
app.component('infofirma',require('./vue/InfoFirma/InfoFirma.vue').default);


app.component('webshopkreiranjetabela', require('./vue/WebShopKreiranje/WebShopKreiranjeTabela').default);
app.component('webshopkreiranjetabelaedit', require('./vue/WebShopKreiranje/WebShopKreiranjeTabelaEdit').default);
app.component('webshopnarudzbetabela', require('./vue/WebShopNarudzbe/WebShopNarudzbeTabela').default);
//app.component('webshopfakturetabela', require('./vue/WebShopFakturisanje/WebShopFaktureTabela').default);
app.component('webshopkreiranjekontejner', require('./vue/WebShopKreiranje/WebShopKreiranjeKontejner').default);



import  { ImageResize } from 'quill-image-resize-module';
import { ImageDrop }  from 'quill-image-drop-module';


Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)



app.mount('#app');


axios.defaults.baseURL = process.env.VUE_APP_API_URL; //ovo je u .env fajlu 

//axios.defaults.baseURL = 'https://botanicaplantshop.ba/api'; //ovo je u .env fajlu

/*store.dispatch('getProdukte')
  .then(() => {})
  .catch((error) => console.error(error));*/
