<template>
    <v-dialog 
        v-model="cartdialog"     
        class="dialogcart"
        >
        
        

        <v-toolbar
        class="sivi-toolbar"        
        >
        <v-toolbar-title> Brzi pregled korpe </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn 
                class="ma-2"
                outlined
                color="white"                        
                @click="$router.push('apiwscheckout')" >
                Zaključi kupovinu
                
            </v-btn>                    
            
            <v-btn
                icon
                dark
                @click="closeDialog()"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            
            <v-toolbar-items>
            </v-toolbar-items>
        </v-toolbar>

        <v-card
            elevation="0"
            min-width="100%"
            class="dialogcartcard no-outline mx-auto pa-4 ">

        

            <v-card-title class="headline">
                Vaša narudžba
            </v-card-title>

            <v-card >

                <v-table 
                    class="custom-v-table" 
                    fixed-header 
                    dense 
                    style="max-height: 400px; 
                    overflow-y: auto;">

                    <thead >
                    <tr class="">
                        <!--th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Proizvod</th-->
                        <th style="width: 50%; text-align: left;">Proizvod</th>
                        <th style=" text-align: right;">Kol</th>
                        <th style=" text-align: right;">Cijena</th>
                        <th style=" text-align: right;">Iznos</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, index) in cart" :key="item.Slug">
                            <td style="width: 50%; text-align: left;" v-text="item.NazivRoba"></td>
                            <td style=" text-align: right;" v-text="formatBroj(item.quantity)"></td>
                            <td style=" text-align: right;" v-text="formatBroj(item.Cijena)"></td>
                            <td style=" text-align: right;" v-text="formatBroj(item.Iznos)"></td>
                            <!--td class="p-4" v-text="cartLineTotal(item)"></td-->
                            <td class="">
                                <v-icon
                                    
                                    class="mr-2"
                                    @click="$store.commit('obrisiIzCarta', index)"
                                    >
                                    mdi-delete
                                </v-icon>

                            </td>
                        </tr>
                        <tr class="custom-total-row">
                            <td class="p-4" style="width: 50%; text-align: left;">TOTAL :</td>
                            <td class="p-4 "></td>
                            <td class="w-10"></td>
                            <td class="p-4 " style=" text-align: right;" v-text="formatBroj(cartTotal)"></td>
                            <td class="w-10 "></td>
                        </tr>
                    </tbody>        
                </v-table>
            </v-card>
        </v-card> 

    </v-dialog>
</template>                         

<script>
    
    var numeral = require('numeral');
    //import { bus } from '../../app.js'//--- busproba
    export default {
        inject:["eventBus"],//bus mitt vue3
        data() {
            return {
                cartdialog:false,
                show1:false,

                chkenabled2:false,
                chkenabled1:false,
                chkenabled:false,
                tabs: null,

                aCart:[],
                trnsaltcust:[],
                altcustomer:{
                    first_name: '',
                    last_name: '',
                    email: '',
                    telefon:'',
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',                    
                },
                customer: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password:'',
                    telefon:'',
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    payment_method_id:'',
                    amount:0,
                    cart:[],
                    stripe_id:'',
                    komentar:'',
                    izmjenipodatkepostojecegusera:false,//ako je postojeci korisnik, a trebalo bi mu izmijeniti ime, adresu osim email i passworda
                    novikorisnikchkenabled:false, //ako je true onda je novi korisnik i treba mu kreriati novi acc  
                    altcustomerchkenabled:false, //ako je true onda se narudzba salje na drugu adresu
                    altcustomer:[],//ukoliko nije isto mjesto placanja i dostave
                    nacinplacanja:'' //opisno: virman, gotovina, kartica

                },
                paymentProcessing: true,
                paymentProcessing1:false,
                paymentProcessing2: false,

                alert:false,
                alerttext:'',
                alertsnackbar:false,
                snackbartekst:'',
                multiLine: true,

                narZag:[],                        
                narStav:[],

                nazivrules: [
                    v => !!v || 'Naziv je obavezan!',
                    v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
                ],
                imerules: [
                    v => !!v || 'Ime je obavezno!',
                    v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
                ],   
                textrules: [
                    v => !!v || 'Vrijednost je obavezna!',
                    v => (v && v.length <= 200) || 'Vrijednost mora biti kraći od 200 karaktera.',
                ],                              
            }
        },

        props:{
            
            prikazidialog:false
        },

        methods: {
            
            formatBroj(val){
                var numBr = numeral(val);
                var strBr = numBr.format('0.00');
                return strBr;

            },   

            cartLineTotal(item) {
                let amount = item.price * item.quantity;
                //amount = (amount / 100);
                return amount.toLocaleString('en-US', { style: 'currency', currency: 'BAM' });
            },

            closeDialog(){
                /*bus.$emit('kontejnerinstancadialozi',{komparacijadialogbool:false, 
                            cartdialog:false}); */
                this.eventBus.emit('kontejnerinstancadialozi',{komparacijadialogbool:false, 
                            cartdialog:false}); 

            }
        },

        mounted() {

            
        },

        computed: {


            cart() {
                this.aCart=this.$store.state.cart;
                return this.$store.state.cart;
            },
            cartQuantity() {
                return this.$store.state.cart.reduce((acc, item) => acc + item.quantity, 0);
            },
            cartTotal() {
                let amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)), 0);
                //let amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)*100), 0);
                //amount = (amount / 100);
                
                return amount.toLocaleString('en-US', { style: 'currency', currency: 'BAM' });
            }
        },

        watch: {
            prikazidialog: function(newVal, oldVal){
                this.cartdialog=newVal;
            }
        },        
    }
</script>

<style>
.table_td_sivo{
  background-color:#f2f3f4;
  font-weight: bold;
  width: 50%;
}
.table_td_sivo_body{
  background-color:#ffffff;
  font-weight: bold;
  width: 50%;
}
.kartice_1{
    background-color:"green lighten-4" 
}
.card_transparent{
    background-color:transparent!important;
    border: rgb(9, 243, 60)!important;
    /*opacity: 0.65;*/
    font-weight: bold;
  /*width: 30%;*/
}
</style>