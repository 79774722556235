    <template>

    <v-dialog
        v-model="localDialog"
        persistent
        max-width="50%"
        
    >

                

                <v-card 
                    outlined
                    >

                        <v-toolbar
                        color="blue darken-2"
                        light
                        extended
                        >
                        <!--v-app-bar-nav-icon></v-app-bar-nav-icon-->
                        <v-toolbar-title class="white--text">
                        Pregled i štampa dokumenata
                        </v-toolbar-title>
                        <v-spacer></v-spacer>

                        <template v-slot:extension>

                            <v-speed-dial

                                v-model="fab"
                                :top="top"
                                :bottom="bottom"
                                :right="right"
                                :left="left"
                                :direction="direction"
                                :open-on-hover="hover"
                                :transition="transition"
                                                        
                            
                            
                                >
                                <template v-slot:activator>
                                    <v-btn
                                    v-model="fab"
                                    color="blue darken-2"
                                    dark
                                    fab
                                    

                                    >
                                    <v-icon v-if="fab"  size="15">
                                        fa-close
                                    </v-icon>
                                    <v-icon v-else size="15">
                                        fa-list
                                    </v-icon>
                                    </v-btn>

                                    
                                </template>

                                <!-- v-btn
                                    fab
                                    dark
                                    small
                                    color="green"

                                    
                                >
                                    <v-icon size="15">fa-refresh</v-icon>
                                </v-btn-->

                                <v-btn
                                    fab
                                    dark
                                    small
                                    color="green"
                                    @click="create_PDFmake()"
                                    
                                >
                                    <v-icon size="15">fa-print</v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    dark
                                    small
                                    color="red"
                                    @click="zatvoriFormu(false,'')"
                                >
                                    <v-icon size="15">fa-times-circle</v-icon>
                                </v-btn>

                            </v-speed-dial>

                        </template>
                        </v-toolbar>  

                        <v-card-actions>
                            <v-snackbar
                            v-model="alertsnackbar"
                            :multi-line="multiLine"
                            absolute
                            left
                            shaped
                            middle
                            >
                                {{ snackbartekst }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn
                                    color="red"
                                    text
                                    v-bind="attrs"
                                    @click="alertsnackbar = false"
                                    >
                                    Zatvori
                                    </v-btn>
                                </template>

                            </v-snackbar>


                        </v-card-actions>

                        <v-row >

                            <v-col         
                            cols="12"
                            sm="8">
                                <v-card-text class="font-weight-bold px-15">
                                    Sačekajte da se kreira izvještaj. Ukoliko se automatski ne otvori prozor to može značiti da su vam pop-up blokirani pa je potrebno
                                    da pritisnete dugme "Štampa izvještaja".
                                </v-card-text>
                            </v-col>
                            
                            <v-col         
                            cols="12"
                            sm="4">   
                                <v-card-text>                      
                                    <v-progress-circular
                                        :rotate="-90"
                                        :size="100"
                                        :width="15"
                                        :value="value"
                                        color="primary"
                                        >
                                        {{ value }}
                                    </v-progress-circular>
                                </v-card-text>
                            </v-col>                            
                        </v-row>
                        <v-card elevation="0" >
                            <!--v-row>
                                <v-col         
                                
                                cols="12"
                                sm="10"> 
                                    <v-card elevation="0">

                                        <v-card-title

                                        class="sekcija_naslov">
                                        Podaci o kupcu
                                        <v-spacer></v-spacer>
                                        
                                        </v-card-title>

                                        <v-simple-table >
                                            <template v-slot:default>
                                                <tbody >
                                                
                                                        <tr >
                                                            <td  class="elevation-1 table_td_left">Naziv/Ime : </td>
                                                            <td class="table_td_osnovna" v-text="kupacinfo.Ime"></td>                                                                

                                                            <td class="table_td_nastavak">Prezime : </td>
                                                            <td class="table_td_osnovna" v-text="kupacinfo.Prezime"></td>
                                                            <td></td><td></td>
                                                        </tr>
                                                        <tr >
                                                            <td class="elevation-1 table_td_left">Adresa : </td>
                                                            <td class="table_td_osnovna" v-text="kupacinfo.Adresa"></td>

                                                            <td class="table_td_nastavak" >Grad : </td>
                                                            <td class="table_td_osnovna" v-text="kupacinfo.Grad"></td>

                                                            <td class="table_td_nastavak" >Država : </td>
                                                            <td class="table_td_osnovna" v-text="kupacinfo.Drzava"></td>
                                                        </tr>    
                                                        <tr >
                                                            <td  class="elevation-1 table_td_left">Email : </td>
                                                            <td  class="table_td_osnovna" v-text="kupacinfo.Email"></td>

                                                            <td  class="table_td_nastavak">Telefon : </td>
                                                            <td  class="table_td_osnovna" v-text="kupacinfo.Telefon"></td>
                                                            <td></td><td></td>
                                                        </tr>     
                                                                                                                                                                                                                
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>

                                        
                                </v-col>

                            </v-row>
                            <v-row>

                                <v-col         
                                
                                cols="12"
                                sm="10"> 
                                    <v-card elevation="0" v-if="altkupacinfo || altkupacinfo.length != 0">

                                        <v-card-title

                                        class="sekcija_naslov">
                                        Dostava na drugu adresu
                                        <v-spacer></v-spacer>
                                        
                                        </v-card-title>

                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                
                                                        <tr >
                                                            <td  class="elevation-1 table_td_left">Naziv/Ime : </td>
                                                            <td class="table_td_osnovna" v-text="altkupacinfo.Ime"></td>                                                                

                                                            <td class="table_td_nastavak">Prezime : </td>
                                                            <td class="table_td_osnovna" v-text="altkupacinfo.Prezime"></td>
                                                            <td></td><td></td>
                                                        </tr>
                                                        <tr >
                                                            <td class="elevation-1 table_td_left">Adresa : </td>
                                                            <td class="table_td_osnovna"  v-text="altkupacinfo.Adresa"></td>

                                                            <td class="table_td_nastavak" >Grad : </td>
                                                            <td class="table_td_osnovna"  v-text="altkupacinfo.Grad"></td>
                                                            <td class="table_td_nastavak" >Država : </td>
                                                            <td class="table_td_osnovna"  v-text="altkupacinfo.Drzava"></td>
                                                        </tr>    
                                                        <tr >
                                                            <td  class="elevation-1 table_td_left">Email : </td>
                                                            <td class="table_td_osnovna"  v-text="altkupacinfo.Email"></td>

                                                            <td  class="table_td_nastavak">Telefon : </td>
                                                            <td class="table_td_osnovna"  v-text="altkupacinfo.Telefon"></td>
                                                            <td></td><td></td>
                                                        </tr>                                                                                                                                                             
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>

                                        
                                </v-col>                                    
                            </v-row>

                            <v-row>
                                <v-col         
                                
                                cols="12"
                                sm="10"> 
                                    <v-card elevation="0">

                                        <v-card-title

                                        class="sekcija_naslov">
                                        Podaci o narudžbi
                                        <v-spacer></v-spacer>
                                        
                                        </v-card-title>

                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                
                                                        <tr >
                                                            <td  class="elevation-1 table_td_left">Narudžba : </td>
                                                            <td class="table_td_osnovna"  v-text="narudzba.Narudzba"></td>                                                                


                                                            
                                                        </tr>
                                                        <tr >
                                                        

                                                            <td  class="elevation-1 table_td_left">Datum : </td>
                                                            <td class="table_td_osnovna"  v-text="narudzba.Datum"></td>
                                                            
                                                        </tr>
                                                        <tr >
                                                            <td class="elevation-1 table_td_left">Način plaćanja : </td>
                                                            <td class="table_td_osnovna"  v-text="narudzba.Nacinplacanja"></td>


                                                        </tr>    
                                                                                                                                                                                                                
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>

                                        
                                </v-col>
                            
                            </v-row>


                            <v-row>
                                <v-col         
                                
                                cols="12"
                                sm="10">                                         
                                    <v-card elevation="0">
                                        <v-card-title
                                        class="sekcija_naslov">
                                            Tabela artikala narudžbe
                                            <v-spacer></v-spacer>

                                        </v-card-title>

                                        
                                            <v-card-text>

                                                    <v-simple-table
                                                    ref="myTable"
                                                    fixed-header
                                                    min-width="50%"
                                                    height="300px"
                                                    class="table_td_visina"
                                                    >
                                                        <template v-slot:default>
                                                            <thead >
                                                            <tr >
                                                                
                                                                <td class="table_td_sivo">Proizvod</td>
                                                                <td class="table_td_sivo">Količina</td>
                                                                <td class="table_td_sivo">Cijena</td>
                                                                <td class="table_td_sivo">Iznos</td>
                                                                <td class="table_td_sivo"></td>
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr v-for="(item, index) in narudzbastav" :key="item.Slug" >
                                                                    <td  class="table_td_osnovna"  v-text="item.NazivRoba"></td>
                                                                    <td  class="table_td_osnovna"  v-text="item.Kolicina"></td>
                                                                    <td  class="table_td_osnovna"  v-text="item.Cijena"></td>
                                                                    <td  class="table_td_osnovna"  v-text="item.Iznos"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="p-4 table_td_sivo">TOTAL :</td>
                                                                    <td class="p-4 table_td_sivo" ></td>
                                                                    <td class="p-4 table_td_sivo"></td>                                            
                                                                    <td class="p-4 table_td_sivo" v-text="narudzbastavSuma"></td>
                                                                    <td class="w-10 table_td_sivo"></td>
                                                                </tr>
                                                            </tbody>        
                                                        </template>
                                                    </v-simple-table>
                                            
                                            </v-card-text>

                                        
                                    </v-card>
                            </v-col>
                            </v-row-->
  

                            <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn
                                color="green darken-1"
                                text
                                @click="create_PDFmake()"
                                :disabled="!loadovano"
                                >
                                Štampa izvještaja
                                </v-btn>


                                <v-btn
                                color="red darken-1"
                                text
                                @click="zatvoriFormu(false,'')"
                                
                                >
                                Zatvori formu
                                </v-btn>
                                </v-card-actions>

                        </v-card>







                </v-card>

    </v-dialog>
</template>
<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

//import VueHtml2pdf from 'vue-html2pdf'

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

var numeral = require('numeral');

import font from '../../fontovi/verdana-normal.js';

export default {

        data() {
            return {

                localDialog: this.adialog,

                //loader
                interval: {},
                value: 0,
                loadovano:false,

                //arBorder:[],
                //ziroracuniloko:[],
                //firmaloko:[],
                lokobrojnarudzbe:'',
                
                narudzbaDefault:{
                    Id: 0,
                    UserID:'', 
                    StripeID:'', 
                    Narudzba:'',
                    Iznos:'', 
                    Datum:'', 
                    Utime:'', 
                    WebPay:'',
                    Nacinplacanja:'',
                    Poslano:0,
                    Isporuceno:0,
                    Fakturisano:0,
                    FakZag:0,
                    Komentar:''           
                }, 
                
                narudzbastav: {
                    Id:0,
                    Sifra: '',
                    Slug:'', 
                    NazivRoba:'', 
                    Kolicina:0, 
                    Cijena:0, 
                    Iznos:0,
                    narudzbastav:0,

                },
                
                narudzbastavSuma:0,

                narudzbastavDefault: {
                    Id:0,
                    Sifra: '',
                    Slug:'', 
                    NazivRoba:'', 
                    Kolicina:0, 
                    Cijena:0, 
                    Iznos:0,
                    narudzbastav:0,

                },            
               
                kupacinfo: {
                        Sifra:0,
                        Naziv:'',
                        Ime:'',
                        Prezime:'',
                        Email:'',
                        Telefon:'',
                        Adresa:'',
                        Grad:'',
                        Drzava:'',
                        ZipCode:'',
                        UserID:'',
                        Pravno:0,
                        IDbroj:'',
                        PDVbroj:'',
                        Komentar:''
                },

                kupacinfodefault: {
                        Sifra:0,
                        Naziv:'',
                        Ime:'',
                        Prezime:'',
                        Email:'',
                        Telefon:'',
                        Adresa:'',
                        Grad:'',
                        Drzava:'',
                        ZipCode:'',
                        UserID:'',
                        Pravno:0,
                        IDbroj:'',
                        PDVbroj:'',
                        Komentar:''
                },

                altkupacinfodefault: {
                    Id:0,
                    Narudzba:'',
                    Naziv:'',
                    Ime:'',
                    Prezime:'',
                    Email:'',
                    Telefon:'',
                    Adresa:'',
                    Grad:'',
                    Drzava:'',
                    ZipCode:'',
                    UserID:'',
                    Pravno:0,
                    IDbroj:'',
                    PDVbroj:'',
                    Komentar:''
                },

                altkupacinfo: {
                    Id:0,
                    Narudzba:'',
                    Naziv:'',
                    Ime:'',
                    Prezime:'',
                    Email:'',
                    Telefon:'',
                    Adresa:'',
                    Grad:'',
                    Drzava:'',
                    ZipCode:'',
                    UserID:'',
                    Pravno:0,
                    IDbroj:'',
                    PDVbroj:'',
                    Komentar:''
                },
                
                ziroracuni: {
                    Id:0,
                    Banka:'',
                    Ziroracun:'',                        
                    Komentar:''
                },
                
                telefoni: {
                    Id:0,
                    Tip:'',
                    Broj:'',                        
                    Komentar:''
                },
                
                email: {
                    Id:0,
                    Tip:'',
                    Email:'',                        
                    Komentar:''
                },
                
                firma: {
                    Id:0,
                    Naziv:'',
                    Nazivopis:'',                        
                    Adresa:'',
                    Grad:'',
                    Drzava:'',
                    IDbroj:'',
                    PDVbroj:'',
                    Sudbroj:''
                },    
                                
                alert:false,
                alerttext:'',
                alertsnackbar:false,
                snackbartekst:'',
                multiLine: true,     
                
                /* toolbar */
                /*dugme*/
                direction: 'right',
                fab: false,
                fling: false,
                hover: false,
                tabs: false,
                top: true,
                right: false,
                bottom: false,
                left: true,
                transition: 'slide-y-reverse-transition',                

            }
        },

        props:{
            adialog:false,
            brojnarudzba:'',
            narudzba:{
                Id: 0,
                UserID:'', 
                StripeID:'', 
                Narudzba:'',
                Iznos:'', 
                Datum:'', 
                Utime:'', 
                WebPay:'',
                Nacinplacanja:'',
                Poslano:0,
                Isporuceno:0,
                Fakturisano:0,
                FakZag:0,
                Komentar:''           
            },



        },
        


        components: {
          //VueHtml2pdf
        },

        beforeDestroy () {
            clearInterval(this.interval)
        },

        mounted() {

            this.lokobrojnarudzbe=this.brojnarudzba; //ovde je popunjavam jer prvi put je ne vidi watch: jer nije newVal 
            this.popunipodatke();  
            
                 
            },

        watch:{
            adialog(newVal) {
                this.localDialog = newVal;
                },

            localDialog(newVal) {
                this.$emit('update:adialog', newVal);
                },
            
            narudzba:  function(newVal) {

                //var poruka ="javio sa Printa broj narudžbe je " + newVal;
                //this.$emit('w_javiosaPrinta', poruka );
                //console.log("brojnaružbe  " + newVal.Narudzba)
                this.lokobrojnarudzbe=newVal.Narudzba;
                this.popunipodatke();
                
            },

        },


        computed: {

        },

        methods: {

            popunipodatke(){
                this.pokrenitimeripopup();
                this.popunikupca(this.narudzba.UserID);
                this.popunistavkenarudzbe(this.narudzba.Narudzba);
                this.popuniziroracune();
                this.popunitelefone();
                this.popuniemail();
                this.popunifirma();

            },

            pokrenitimeripopup(){

                if (this.adialog==true){ 
                    this.loadovano=false;
                    this.interval = setInterval(() => {

                        if (this.value === 100) {
                            
                                return (
                                    this.value = 0,
                                    this.loadovano=true,
                                    clearInterval(this.interval),
                                    this.create_PDFmake()
                                    
                                
                                )
                                

                            }
                            this.value += 10
                        }, 1000) 
                            
            }

            },

            popunikupca(UserID){


                if(UserID==""){
                    //isprazni matrice kupaca i altkupaca jer je add mode
                    this.kupacinfo = Object.assign({}, this.kupacinfodefault);
                    this.altkupacinfo = Object.assign({}, this.altkupacinfodefault)
                    return;
                }

                axios.get('/api/apikupac/'+ UserID, {
                    params:{                            
                    }
                })
                .then((response) => {
        
                    
                    this.kupacinfo = response.data.data;
                    
                    try {
                            this.popunialternativnogkupca();
                            
                    } catch (error) 
                    {
                        this.obavijestHeading("Došlo je do greške popunjavanja altkupca! " + error.toString())
                        this.altkupacinfo.UserID=this.narudzba.UserID;
                        this.altkupacinfo.Narudzba=this.narudzba.Narudzba;
                    }

                    //console.log("vratio vrijednost " + this.kupacinfo.UserID + " " + this.kupacinfo.Email);
                    
                    
                })
                .catch(function (error) {
                    console.log("greska popunikupca" + error);
                });
            },            

            popunialternativnogkupca(){
 
                axios.get('/api/apialtkupac/'+this.narudzba.Narudzba, {

                })
                .then((response) => {
                    
                        if(response.data.greska==true){
                            
                            
                            this.altkupacinfo.UserID=this.narudzba.UserID;
                            this.altkupacinfo.Narudzba=this.narudzba.Narudzba;
                            return;
                        }
                        this.imaaltkupac=true;
                        this.altkupacinfo = response.data.data;
                            //console.log("vratio vrijednost " + this.altkupacinfo.UserID + " " + this.altkupacinfo.Narudzba);
                    
                })
                .catch(function (error) {
                    
                            this.altkupacinfo.UserID=this.narudzba.UserID;
                            this.altkupacinfo.Narudzba=this.narudzba.Narudzba;
                            this.obavijestHeading("Došlo je do greške popunjavanja altkupca! " + error.toString())
                    
                });
             },

            popunistavkenarudzbe(narudzbabr){

                axios.get('/api/apinarudzbestavke/'+narudzbabr, {

                })
                .then((response) => {
                    
                        /*if(response.greska==true){
                                                       
                            this.obavijestHeading("Došlo je do greške popunjavanja stavki narudžbe! Originalna greška : " + response.data);
                            return;
                        }*/

                        
                        this.narudzbastav = response.data.data;

                        //suma narudzbastav

                        try {
                            let page_url = '/api/apifunkcijeRetSUMAFieldValue/wshop_narudzbestavke/Iznos/Narudzba/'+narudzbabr+'/'+narudzbabr;
                            fetch(page_url)
                                .then(res => res.json())
                                .then(res => {
                                    
                                    if(res.greska==true){
                                        this.obavijestHeading("Greška kod dohvatanja sume stavki narudžbe! Originalna greška : " + res.data )
                                        return;
                                    }
                                    this.narudzbastavSuma =res.data;

                            })
                            .catch(err => console.log(err));                            
                        }
                        catch (error) 
                        {
                            this.obavijestHeading("Došlo je do greške popunjavanja vrijednosti sume stavki! " + error.toString())

                        }
                            //console.log("vratio vrijednost " + this.altkupacinfo.UserID + " " + this.altkupacinfo.Narudzba);
                    
                })
                .catch(function (error) {
                    

                            this.obavijestHeading("Došlo je do greške popunjavanja narudžbe! " + error.toString())
                    
                });

            },


            popunifirma(){
                axios.get('/api/apiwsfirma/', {

                })
                .then((response) => {
                    
                        /*if(response.greska==true){                                                       
                            this.obavijestHeading("Došlo je do greške popunjavanja stavki narudžbe! Originalna greška : " + response.data);
                            return;
                        }*/                       
                    this.firma = response.data.data;

                    
                    

                })
                .catch(function (error) {
                    this.obavijestHeading("Došlo je do greške popunjavanja firme!  " + error.toString())
                    
                });
            },


            popuniemail(){
                axios.get('/api/apiwsemail/', {

                })
                .then((response) => {
                    
                        /*if(response.greska==true){                                                       
                            this.obavijestHeading("Došlo je do greške popunjavanja stavki narudžbe! Originalna greška : " + response.data);
                            return;
                        }*/                       
                    this.email = response.data.data;
                    

                })
                .catch(function (error) {
                    this.obavijestHeading("Došlo je do greške popunjavanja email-a!  " + error.toString())
                    
                });
            },

            popunitelefone(){
                axios.get('/api/apiwstelefoni/', {

                })
                .then((response) => {
                    
                        /*if(response.greska==true){                                                       
                            this.obavijestHeading("Došlo je do greške popunjavanja stavki narudžbe! Originalna greška : " + response.data);
                            return;
                        }*/                       
                    this.telefoni = response.data.data;
                    

                })
                .catch(function (error) {
                    this.obavijestHeading("Došlo je do greške popunjavanja telefona!  " + error.toString())
                    
                });
            },

            popuniziroracune(){
                axios.get('/api/apiwsziroracuni/', {

                })
                .then((response) => {
                    
                        /*if(response.greska==true){                                                       
                            this.obavijestHeading("Došlo je do greške popunjavanja stavki narudžbe! Originalna greška : " + response.data);
                            return;
                        }*/                       
                    this.ziroracuni = response.data.data;
                    

                })
                .catch(function (error) {
                    this.obavijestHeading("Došlo je do greške popunjavanja žiroračuna!  " + error.toString())
                    
                });
            },

            printaj(){
                //this.$refs.html2Pdf.generatePdf()
            },
            
            zatvoriFormu (formadialog, poruka) {
                //this.narudzba = Object.assign({}, this.narudzbaDefault);
                this.kupacinfo =  Object.assign({}, this.kupacinfodefault);
                this.altkupacinfo = Object.assign({}, this.altkupacinfodefault);

                this.$emit('w_javiosaPrinta',poruka, formadialog );

            },   
            
            async obavijestHeading(strTekst){
                this.snackbartekst=strTekst;
                this.alertsnackbar=true;
            },    
            
            create_PDFmake(){


                    // ====== popunjavanje firma
                    
                    let firmaloko=[];
                    let firmaloko2=[];
                    try{ 
                        var bPrvikrugFirma=true;
                        var cBordersTblFirma={
                            borders:{
                                lijevo:true,
                                gore:false, 
                                desno:false,
                                dole:false
                            }

                        };

                        var cBordersTblFirma2={

                            borders:{
                                lijevo:false,
                                gore:false, 
                                desno:false,
                                dole:false
                            },

                        };                        
                        for ( var key in this.firma) 
                        {
                            if (this.firma.hasOwnProperty(key))
                            {
                                var data = this.firma[key];
                                
                                var row = new Array();
                                row.push(this.sloziredtabelePDFmake(data.Naziv,'center','','ime_firme','',cBordersTblFirma));
                                firmaloko.push(row);
                                 
                                var row1 = new Array();
                                row1.push(this.sloziredtabelePDFmake(data.Nazivopis,'center','','ime_firme_podnaslov','',cBordersTblFirma));
                                firmaloko.push(row1);
                                
                                var row2 = new Array();
                                row2.push(this.sloziredtabelePDFmake(data.Adresa + ' - ' + data.Grad + ' - ' + data.Drzava,'center','','ime_firme_podnaslov','',cBordersTblFirma));
                                firmaloko.push(row2);
                               
                                var row3 = new Array();
                                row3.push(this.sloziredtabelePDFmake('ID broj: ' + data.IDbroj,'left','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                                row3.push(this.sloziredtabelePDFmake('PDV broj: ' + data.PDVbroj,'center','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                                row3.push(this.sloziredtabelePDFmake('Sud br.: ' + data.Sudbroj,'right','','ime_firme_podnaslov_generalije','',cBordersTblFirma));

                                firmaloko2.push(row3);
                            }
                           
                        } 
                        /*
                        var row = new Array();
                        row.push(this.sloziredtabelePDFmake( this.firma[0].Naziv,'center','','ime_firme','',cBordersTblFirma));
                        firmaloko.push(row);
                        
                        var row1 = new Array();
                        row1.push(this.sloziredtabelePDFmake( this.firma[0].Nazivopis,'center','','ime_firme_podnaslov','',cBordersTblFirma));
                        firmaloko.push(row1);
                        
                        var row2 = new Array();
                        row2.push(this.sloziredtabelePDFmake( this.firma[0].Adresa,'center','','ime_firme_podnaslov','',cBordersTblFirma));
                        firmaloko.push(row2);

                        var row3 = new Array();
                        row3.push(this.sloziredtabelePDFmake( this.firma[0].IDbroj,'center','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                        row3.push(this.sloziredtabelePDFmake( this.firma[0].PDVbroj,'center','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                        row3.push(this.sloziredtabelePDFmake( this.firma[0].Sudbroj,'center','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                        firmaloko.push(row3);                        
                        */
                        
                    }catch (error) 
                    {
                        this.obavijestHeading("Došlo je do greške popunjavanja firme! Originalna greška : " + error.toString());

                    }                                       
                    // ============= popunjavanje firma kraj




                    // ====== popunjavanje email
                    
                    let emailloko=[];
                    try{ 
                        var bPrvikrugEmail=true;
                        var cBordersTblEmail={
                            borders:{
                                lijevo:true,
                                gore:false, 
                                desno:false,
                                dole:false
                            }

                        };

                        var cBordersTblEmail2={

                            borders:{
                                lijevo:false,
                                gore:false, 
                                desno:false,
                                dole:false
                            },

                        };                        
                        for ( var key in this.email) 
                        {
                            if (this.email.hasOwnProperty(key))
                            {
                                var data = this.email[key];
                                var row = new Array();
                                if (bPrvikrugEmail==true){
                                    row.push(this.sloziredtabelePDFmake('Email','left','','','#eeeeee',cBordersTblEmail2));
                                    bPrvikrugEmail=false;
                                }else
                                {row.push(this.sloziredtabelePDFmake('','left','','','#eeeeee',cBordersTblEmail2));}

                                //row.push(this.sloziredtabelePDFmake(data.Tip,'left','','','',cBordersTblTel));
                                row.push(this.sloziredtabelePDFmake(data.Email,'center','','','',cBordersTblEmail));

                                emailloko.push(row);
                            }
                           
                        }   
                        
                    }catch (error) 
                    {
                        this.obavijestHeading("Došlo je do greške popunjavanja email! Originalna greška : " + error.toString());

                    }                                       
                    // ============= popunjavanje email kraj


                    // ====== popunjavanje telefoni
                    
                    let telefoniloko=[];
                    try{ 
                        var bPrvikrugTel=true;
                        var cBordersTblTel={
                            borders:{
                                lijevo:true,
                                gore:false, 
                                desno:false,
                                dole:false
                            }

                        };

                        var cBordersTblTel2={

                            borders:{
                                lijevo:false,
                                gore:false, 
                                desno:false,
                                dole:false
                            },

                        };                        
                        for ( var key in this.telefoni) 
                        {
                            if (this.telefoni.hasOwnProperty(key))
                            {
                                var data = this.telefoni[key];
                                var row = new Array();
                                if (bPrvikrugTel==true){
                                    row.push(this.sloziredtabelePDFmake('Telefoni','left','','','#eeeeee',cBordersTblTel2));
                                    bPrvikrugTel=false;
                                }else
                                {row.push(this.sloziredtabelePDFmake('','left','','','#eeeeee',cBordersTblTel2));}

                                row.push(this.sloziredtabelePDFmake(data.Tip,'left','','','',cBordersTblTel));
                                row.push(this.sloziredtabelePDFmake(data.Broj,'center','','','',cBordersTblTel));

                                telefoniloko.push(row);
                            }
                           
                        }   
                        
                    }catch (error) 
                    {
                        this.obavijestHeading("Došlo je do greške popunjavanja telefona! Originalna greška : " + error.toString());

                    }                                       
                    // ============= popunjavanje telefoni kraj




                    // ====== popunjavanje ziroracuna
                    
                    let ziroracuniloko=[];
                    try{ 
                        var bPrvikrug=true;
                        var cBordersTblZiro={
                            borders:{
                                lijevo:true,
                                gore:false, 
                                desno:false,
                                dole:false
                            }

                        };

                        var cBordersTblZiro2={

                            borders:{
                                lijevo:false,
                                gore:false, 
                                desno:false,
                                dole:false
                            },

                        };                        
                        for ( var key in this.ziroracuni) 
                        {
                            if (this.ziroracuni.hasOwnProperty(key))
                            {
                                var data = this.ziroracuni[key];
                                var row = new Array();
                                if (bPrvikrug==true){
                                    row.push(this.sloziredtabelePDFmake('Žiroračuni','left','','','#eeeeee',cBordersTblZiro2));
                                    bPrvikrug=false;
                                }else
                                {row.push(this.sloziredtabelePDFmake('','left','','','#eeeeee',cBordersTblZiro2));}

                                row.push(this.sloziredtabelePDFmake(data.Banka,'left','','','',cBordersTblZiro));
                                row.push(this.sloziredtabelePDFmake(data.Ziroracun,'center','','','',cBordersTblZiro));

                                ziroracuniloko.push(row);
                            }
                           
                        }   
                        
                    }catch (error) 
                    {
                        this.obavijestHeading("Došlo je do greške popunjavanja žiroračuna! Originalna greška : " + error.toString());

                    }                                       
                    // ============= popunjavanje ziroracuna kraj

                    let tabelastavke=[];


                    var headers = {
                        zaglavljetbl:{
                            col_1:{ text: 'Roba', style: 'tableHeader', alignment: 'left',margin: [0, 8, 0, 0],fillColor:'#eeeeee'},
                            col_2:{ text: 'Količina', style: 'tableHeader', alignment: 'center',margin: [0, 8, 0, 0],fillColor:'#eeeeee' }, 
                            col_3:{ text: 'Cijena', style: 'tableHeader', alignment: 'center',margin: [0, 8, 0, 0],fillColor:'#eeeeee' },
                            col_4:{ text: 'Iznos', style: 'tableHeader', alignment: 'center',margin: [0, 8, 0, 0] , fillColor:'#eeeeee'}
                        }
                    }


                    try{ 

                        for (var key in headers){
                            if (headers.hasOwnProperty(key)){
                                var header = headers[key];
                                var row = new Array();
                                row.push( header.col_1 );
                                row.push( header.col_2 );
                                row.push( header.col_3 );
                                row.push( header.col_4 );

                                tabelastavke.push(row);
                            }
                        }

                        /* ========  TABELA SA STAVKAMA START ========  */                  
                        var cBordersTblStavke={
                            borders:{
                                lijevo:true,
                                gore:true, 
                                desno:true,
                                dole:true
                            }
                        };


                        var cSumaIznos=0;
                        var cFormatSuma=0;
                        for (var key in this.narudzbastav) 
                        {
                            if (this.narudzbastav.hasOwnProperty(key))
                            {
                                var data = this.narudzbastav[key];
                                var row = new Array();

                                var strKolicina=this.formatBroj(data.Kolicina);
                                var strCijena=this.formatBroj(data.Cijena);
                                var strIznos=this.formatBroj(data.Iznos);
                                
                                row.push(this.sloziredtabelePDFmake(data.NazivRoba.toString(),'left','','','',cBordersTblStavke));
                                row.push(this.sloziredtabelePDFmake(strKolicina,'right','','','',cBordersTblStavke));
                                row.push(this.sloziredtabelePDFmake(strCijena,'right','','','',cBordersTblStavke));
                                row.push(this.sloziredtabelePDFmake(strIznos, 'right','','','',cBordersTblStavke));

                                cSumaIznos=cSumaIznos + data.Iznos;

                                tabelastavke.push(row);
                            }

                        }
                        cFormatSuma=this.formatBroj(cSumaIznos);
                        

                        var tablefooters = {
                            footertbl:{
                                col_1:{ text: 'Ukupno ', style: 'tableFooter', alignment: 'left',fillColor:'#eeeeee'},
                                col_2:{ text: '', style: 'tableFooter', alignment: 'center',fillColor:'#eeeeee' }, 
                                col_3:{ text: '', style: 'tableFooter', alignment: 'center',fillColor:'#eeeeee' },
                                col_4:{ text: cFormatSuma, style: 'tableFooter', alignment: 'right', fillColor:'#eeeeee'}
                            }
                        }




                        for (var key in tablefooters){
                            if (tablefooters.hasOwnProperty(key)){
                                var footer = tablefooters[key];
                                var row = new Array();
                                row.push( footer.col_1 );
                                row.push( footer.col_2 );
                                row.push( footer.col_3 );
                                row.push( footer.col_4 );

                                tabelastavke.push(row);
                            }
                        }                        
                        
                        //console.log("1. tabelastavke (this.tabelastavke) : " + tabelastavke + " , 2. tabelastavke (this.tabelastavke[0]) :" + tabelastavke[0] +  " , 3. citanje pojedinacne vrijednosti (this.tabelastavke[0][0]), npr. 0 gdje je naziv  : " + tabelastavke[0][0]);
                        //odgovor: 1. tabelastavke (this.tabelastavke) : VIKING MA 339,1,459.2,459.2 , 2. tabelastavke (this.tabelastavke[0]) :VIKING MA 339,1,459.2,459.2 , 3. citanje pojedinacne vrijednosti (this.tabelastavke[0][0]), npr. 0 gdje je naziv  : VIKING MA 339
                        
                        /* ======== TABELA SA STAVKAMA KRAJ ======== */

                    }catch (error) 
                    {
                        this.obavijestHeading("Došlo je do greške popunjavanja vrijednosti stavki u tabeli štampe! " + error.toString())

                    }      

                    var docDefinition = {
                        pageMargins: [40,100,40,70],
                        //pageOrientation: 'landscape',
                        pageOrientation: 'portrait',

                        header: function(currentPage) {
                            return {

                                margin:[0,5,0,0],

                               
                                table:{ // firma
                                     body:[
                                           
                                            [
                                                 {columns: 
                                                    [
                                                        {
                                                            
                                                        },
                                                        { 
                                                            width: 'auto',
                                                            //margin: [40,0,40,0],
                                                            //widths: ['auto','*','auto'],
                                                            table: {

                                                                    body:firmaloko,
                                                                },
                                                                layout: 'noBorders'
                                                            
                                                        },
                                                        {
                                                            
                                                        }

                                                         
                                                    ]
                                                }                                          
                                            ],
                                            [
                                                {columns: 
                                                    [
                                                        {
                                                            
                                                        },                                                
                                                        { // firma id brojevi
                                                         width: 'auto',
                                                        //margin: [40,0,0,0],
                                                        //widths: [ '*', '*', '*'],
                                                        //style: 'ultrasmall',
                                                        table: {

                                                                body:firmaloko2,
                                                            },
                                                            layout: 'noBorders'
                                                            
                                                        },
                                                        {
                                                            
                                                        }

                                                         
                                                    ]
                                                }                                                             
                                                
                                                

                                            ],
                                            [ 
                                                {canvas: [   // linija headera                         
                                                        {
                                                            type: 'line',
                                                            //x1: 0, y1: 10, // x1 horizontalni start od page margine, y1 vertikalna visina na str.
                                                            //x2: 515, y2:10 , // x2 duzina linije, y2 nagib linije (- prema gore, + prema dole, y1==y2 je horizontalan polozaj)
                                                            x1: 10, y1: 0, 
                                                            x2: 595-10, y2: 0,
                                                            lineWidth: 0.3,
                                                            lineColor: 'gray'
                                                            //lineCap: 'round' // krajevi linije vide se kad je debljina linije veca
                                                            //lineCap: 'round'
                                                        },    
                                                            
                                                        
                                                ] },                                  
                                            ]                                              
                                            

                                     ]
                                    },
                                    layout: 'noBorders'
                            }
                        },
                        
                        footer: function(currentPage, pageCount) { 
                            
                            return { 

                              table:{ 
                                body:[       
                                        [ 
                                             {canvas: [   // crtanje geometrijskih oblika                         
                                                    /*
                                                    {
                                                        type: 'polyline',
                                                        lineWidth: 1,
                                                        closePath: true,
                                                        points: [{ x: 10, y: 10 }, { x: 35, y: 40 }, { x: 100, y: 40 }, { x: 125, y: 10 }]
                                                    },   
                                                    {
                                                        type: 'ellipse',
                                                        x: 150, y: 140,
                                                        color: 'red',
                                                        fillOpacity: 0.5,
                                                        r1: 80, r2: 60
                                                    },  */  
                                                    {
                                                        type: 'line',
                                                        //x1: 0, y1: 10, // x1 horizontalni start od page margine, y1 vertikalna visina na str.
                                                        //x2: 515, y2:10 , // x2 duzina linije, y2 nagib linije (- prema gore, + prema dole, y1==y2 je horizontalan polozaj)
                                                        x1: 10, y1: 5, 
                                                        x2: 595-10, y2: 5,
                                                        lineWidth: 0.3,
                                                        lineColor: 'gray'
                                                        //lineCap: 'round' // krajevi linije vide se kad je debljina linije veca
                                                        //lineCap: 'round'
                                                    },    
                                                        
                                                    
                                            ] },                                  
                                        ],
                                        [   
                                            {columns: [

                                                                    
                                                                    { // žiroračuni
                                                                    width: '*',
                                                                    margin: [40,0,0,0],
                                                                    style: 'ultrasmall',
                                                                    table: {
                                                                            //headerRows: 1,
                                                                            widths: [ 'auto', 'auto','auto' ],
                                                                            body:ziroracuniloko,
                                                                            /*body: [
                                                                            [ { text: 'Žiroračuni ', bold: true }, 'Ime firme', '', ''],
                                                                                [ '', '', '***', '**'],
                                                                                [ 'Value 1', 'Value 2', 'Value 3', 'Value 4' ],
                                                                                [ 'Value 1', 'Value 2', 'Value 3', 'Value 4' ],
                                                                                [ 'Value 1', 'Value 2', 'Value 3', 'Value 4' ]
                                                                        
                                                                            ]*/
                                                                        },
                                                                        layout: {
                                                                            hLineWidth: function (i, node) {
                                                                                //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                                                                return (i === 0 || i === node.table.body.length) ? 0.3 : 0.3;
                                                                            },
                                                                            vLineWidth: function (i, node) {
                                                                                //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                                                                return (i === 0 || i === node.table.widths.length) ? 0.3  : 0.3 ;
                                                                            },
                                                                            hLineColor: function (i, node) {
                                                                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                                                            },
                                                                            vLineColor: function (i, node) {
                                                                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                                                            },
                                                                            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                            // paddingLeft: function(i, node) { return 4; },
                                                                            // paddingRight: function(i, node) { return 4; },
                                                                            // paddingTop: function(i, node) { return 2; },
                                                                            // paddingBottom: function(i, node) { return 2; },
                                                                            // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                                                        }
                                                                        
                                                                    },

                                                                { //telefoni
                                                                    width: '*',
                                                                    margin: [20,0,0,0],
                                                                    style: 'ultrasmall',
                                                                    table: {
                                                                            //headerRows: 1,
                                                                            widths: [ 'auto', 'auto','auto' ],
                                                                            body:telefoniloko,

                                                                        },
                                                                        layout: {
                                                                            hLineWidth: function (i, node) {
                                                                                //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                                                                return (i === 0 || i === node.table.body.length) ? 0.3 : 0.3;
                                                                            },
                                                                            vLineWidth: function (i, node) {
                                                                                //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                                                                return (i === 0 || i === node.table.widths.length) ? 0.3 : 0.3;
                                                                            },
                                                                            hLineColor: function (i, node) {
                                                                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                                                            },
                                                                            vLineColor: function (i, node) {
                                                                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                                                            },
                                                                            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                            // paddingLeft: function(i, node) { return 4; },
                                                                            // paddingRight: function(i, node) { return 4; },
                                                                            // paddingTop: function(i, node) { return 2; },
                                                                            // paddingBottom: function(i, node) { return 2; },
                                                                            // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                                                        }
                                                                        
                                                                    },   
                                                                    
                                                                { //email
                                                                    width: 'auto',
                                                                    margin: [20,0,0,0],
                                                                    style: 'ultrasmall',
                                                                    table: {
                                                                            //headerRows: 1,
                                                                            widths: [ 'auto', 'auto'],
                                                                            body:emailloko,

                                                                        },
                                                                        layout: {
                                                                            hLineWidth: function (i, node) {
                                                                                //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                                                                return (i === 0 || i === node.table.body.length) ? 0.3 : 0.3;
                                                                            },
                                                                            vLineWidth: function (i, node) {
                                                                                //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                                                                return (i === 0 || i === node.table.widths.length) ? 0.3 : 0.3;
                                                                            },
                                                                            hLineColor: function (i, node) {
                                                                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                                                            },
                                                                            vLineColor: function (i, node) {
                                                                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                                                            },
                                                                            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                            // paddingLeft: function(i, node) { return 4; },
                                                                            // paddingRight: function(i, node) { return 4; },
                                                                            // paddingTop: function(i, node) { return 2; },
                                                                            // paddingBottom: function(i, node) { return 2; },
                                                                            // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                                                        }
                                                                        
                                                                    },
                                                                    { // broj stranice
                                                                    width: 'auto',
                                                                    margin: [20,0,40,0],
                                                                    style: 'ultrasmall',
                                                                    table: {
                                                                            //headerRows: 1,
                                                                            widths: [ 'auto', 'auto'],
                                                                            body:[                                                           
                                                                                    [
                                                                                        {text:'Stranica ',alignment:'center',fillColor:'#eeeeee',border: [false, false, false, false]},
                                                                                        {text:currentPage.toString() + ' / ' + pageCount,alignment:'center',border: [true, false, false, false]},
                                                                                    ]
                                                                                ]
                                                                                /*
                                                                            body: [
                                                                                [ 
                                                                                
                                                                                'Stranica ', currentPage.toString() + ' / ' + pageCount
                                                                                ],

                                                                            ]*/
                                                                        
                                                                        },
                                                                        layout: {
                                                                            hLineWidth: function (i, node) {
                                                                                //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                                                                return (i === 0 || i === node.table.body.length) ? 0.3 : 0.3;
                                                                            },
                                                                            vLineWidth: function (i, node) {
                                                                                //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                                                                return (i === 0 || i === node.table.widths.length) ? 0.3 : 0.3;
                                                                            },
                                                                            hLineColor: function (i, node) {
                                                                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                                                            },
                                                                            vLineColor: function (i, node) {
                                                                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                                                            },
                                                                            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                            // paddingLeft: function(i, node) { return 4; },
                                                                            // paddingRight: function(i, node) { return 4; },
                                                                            // paddingTop: function(i, node) { return 2; },
                                                                            // paddingBottom: function(i, node) { return 2; },
                                                                            // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                                                        }
                                                                        
                                                                    },

                                                /*
                                                            { text:'Žiro računi', alignment: 'center', style:'brojevistranica' },
                                                            { text:'NLB Banka : 123546857987654' , alignment: 'center', style:'brojevistranica' },
                                                            { text:' '+ currentPage.toString() + ' / ' + pageCount, alignment: 'right', style:'brojevistranica' },*/
                                                
                                                        //{image: 'sampleImage.jpg', alignment: 'right', width: 50}
                                                ]}
                                        ]
                                    ]
                                },
                                layout: 'noBorders'
                            }
                                	

                        },

                        /* originalni footer
                        footer: function(currentPage, pageCount) {
                            return { text:' '+ currentPage.toString() + ' / ' + pageCount, alignment: 'center',margin:[0,30,0,0], style:'brojevistranica' };
                        },*/



                        content: [
                            
                            
                            
                            {text: 'Obrazac zaprimljene narudžbe', style: 'naslov', margin: [0, 10]},
                            
                            {canvas: [   // crtanje geometrijskih oblika                         
                                /*
                                {
                                    type: 'polyline',
                                    lineWidth: 1,
                                    closePath: true,
                                    points: [{ x: 10, y: 10 }, { x: 35, y: 40 }, { x: 100, y: 40 }, { x: 125, y: 10 }]
                                },   
                                {
                                    type: 'ellipse',
                                    x: 150, y: 140,
                                    color: 'red',
                                    fillOpacity: 0.5,
                                    r1: 80, r2: 60
                                },  
                                {
                                    type: 'line',
                                    x1: 0, y1: 60, // x1 horizontalni start od page margine, y1 vertikalna visina na str.
                                    x2: 515, y2: 560, // x2 duzina linije, y2 nagib linije (- prema gore, + prema dole, y1==y2 je horizontalan polozaj)
                                    lineWidth: 0.3,
                                    lineColor: 'gray'
                                    //lineCap: 'round' // krajevi linije vide se kad je debljina linije veca
                                    //lineCap: 'round'
                                },    
                                    
                                */     
                            ]},
      

                            {text: 'Podaci o kupcu', style: 'podnaslov', margin: [0, 5]},

                            {

                                table: {
                                    style: 'tabelastavke',
                                    headerRows: 1,
                                    //widths: [ 80, 200, 80, 200],
                                    widths: [ 'auto', '*', 'auto', '*'],

                                    body:[
                                            [
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, true, false, false],
                                                    fillColor: '#eeeeee',
                                                    text: 'Naziv/Ime : '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [false, false, false, false],
                                                    //fillColor: '#dddddd',
                                                    text: this.kupacinfo.Ime
                                                },
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [true, true, true, true],
                                                    fillColor: '#eeeeee',
                                                    text: 'Prezime: '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [true, true, true, true],
                                                    //fillColor: '#eeeeee',
                                                    text: this.kupacinfo.Prezime
                                                }                                            
                                            ], 
                                            [
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, true, false, false],
                                                    fillColor: '#eeeeee',
                                                    text: 'Adresa : '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [false, false, false, false],
                                                    //fillColor: '#dddddd',
                                                    text: this.kupacinfo.Adresa
                                                },
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [true, true, true, true],
                                                    fillColor: '#eeeeee',
                                                    text: 'Država/Grad: '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [true, true, true, true],
                                                    //fillColor: '#eeeeee',
                                                    text: this.kupacinfo.Drzava + '/' + this.kupacinfo.Grad
                                                }                                            
                                            ],  
                                            [
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, true, false, false],
                                                    fillColor: '#eeeeee',
                                                    text: 'Email : '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [false, false, false, false],
                                                    //fillColor: '#dddddd',
                                                    text: this.kupacinfo.Email
                                                },
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [true, true, true, true],
                                                    fillColor: '#eeeeee',
                                                    text: 'Telefon: '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [true, true, true, true],
                                                    //fillColor: '#eeeeee',
                                                    text: this.kupacinfo.Telefon
                                                }                                            
                                            ],                                                                                                                                                        
                                    ]
                                    
                                },  
                                layout: {
                                    hLineWidth: function (i, node) {
                                        //return (i === 0 || i === node.table.body.length) ? 2 : 1; --- deblji vanjski border
                                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                                    },
                                    vLineWidth: function (i, node) {
                                        //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                                    },
                                    hLineColor: function (i, node) {
                                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                    },
                                    vLineColor: function (i, node) {
                                        return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                    },
                                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // paddingLeft: function(i, node) { return 4; },
                                    // paddingRight: function(i, node) { return 4; },
                                    // paddingTop: function(i, node) { return 2; },
                                    // paddingBottom: function(i, node) { return 2; },
                                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                }                          

                            },



                            {text: '',   margin: [0, 10]},
                            {text: 'Dostava na drugu adresu', style: 'podnaslov', margin: [0, 5]},

                            {

                                table: {
                                    style: 'tabelastavke',
                                    headerRows: 1,
                                    //widths: [ 80, 200, 80, 200],
                                    widths: [ 'auto', '*', 'auto', '*'],

                                    body:[
                                            [
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, true, false, false],
                                                    fillColor: '#eeeeee',
                                                    text: 'Naziv/Ime : '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [false, false, false, false],
                                                    //fillColor: '#dddddd',
                                                    text: this.altkupacinfo.Ime
                                                },
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [true, true, true, true],
                                                    fillColor: '#eeeeee',
                                                    text: 'Prezime: '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [true, true, true, true],
                                                    //fillColor: '#eeeeee',
                                                    text: this.altkupacinfo.Prezime
                                                }                                            
                                            ], 
                                            [
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, true, false, false],
                                                    fillColor: '#eeeeee',
                                                    text: 'Adresa : '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [false, false, false, false],
                                                    //fillColor: '#dddddd',
                                                    text: this.altkupacinfo.Adresa
                                                },
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [true, true, true, true],
                                                    fillColor: '#eeeeee',
                                                    text: 'Država/Grad: '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [true, true, true, true],
                                                    //fillColor: '#eeeeee',
                                                    text: this.altkupacinfo.Drzava + '/' + this.altkupacinfo.Grad
                                                }                                            
                                            ],  
                                            [
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, true, false, false],
                                                    fillColor: '#eeeeee',
                                                    text: 'Email : '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [false, false, false, false],
                                                    //fillColor: '#dddddd',
                                                    text: this.altkupacinfo.Email
                                                },
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [true, true, true, true],
                                                    fillColor: '#eeeeee',
                                                    text: 'Telefon: '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [true, true, true, true],
                                                    //fillColor: '#eeeeee',
                                                    text: this.altkupacinfo.Telefon
                                                }                                            
                                            ],                                                                                                                                                        
                                    ]
                                    
                                },  
                                layout: {
                                    hLineWidth: function (i, node) {
                                        //return (i === 0 || i === node.table.body.length) ? 2 : 1; --- deblji vanjski border
                                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                                    },
                                    vLineWidth: function (i, node) {
                                        //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                                    },
                                    hLineColor: function (i, node) {
                                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                    },
                                    vLineColor: function (i, node) {
                                        return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                    },
                                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // paddingLeft: function(i, node) { return 4; },
                                    // paddingRight: function(i, node) { return 4; },
                                    // paddingTop: function(i, node) { return 2; },
                                    // paddingBottom: function(i, node) { return 2; },
                                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                }                          

                            },




                            {text: '',   margin: [0, 10]},
                            {text: 'Podaci o narudžbi', style: 'podnaslov', margin: [0, 5]},

                            {

                                table: {
                                    style: 'tabelastavke',
                                    headerRows: 1,
                                    //widths: [ 80, 200, 80, 200],
                                    widths: [ 'auto', '*'],

                                    body:[
                                            [
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, true, false, false],
                                                    fillColor: '#eeeeee',
                                                    text: 'Narudžba : '
                                                },
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, false, false, false],
                                                    //fillColor: '#dddddd',
                                                    text: this.narudzba.Narudzba
                                                },
                                            ],
                                            [ 

                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [true, true, true, true],
                                                    fillColor: '#eeeeee',
                                                    text: 'Datum: '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [true, true, true, true],
                                                    //fillColor: '#eeeeee',
                                                    text: this.narudzba.Datum
                                                }                                            
                                            ], 
                                            [
                                                {
                                                    style: 'tabelastavke_bold',
                                                    //border: [false, true, false, false],
                                                    fillColor: '#eeeeee',
                                                    text: 'Način plaćanja : '
                                                },
                                                {
                                                    style: 'tabelastavke',
                                                    //border: [false, false, false, false],
                                                    //fillColor: '#dddddd',
                                                    text: this.narudzba.Nacinplacanja
                                                },
                                            
                                            ],  
                                                                                                                                                        
                                    ]
                                    
                                },  
                                layout: {
                                    hLineWidth: function (i, node) {
                                        //return (i === 0 || i === node.table.body.length) ? 2 : 1; --- deblji vanjski border
                                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                                    },
                                    vLineWidth: function (i, node) {
                                        //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                                    },
                                    hLineColor: function (i, node) {
                                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                    },
                                    vLineColor: function (i, node) {
                                        return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                    },
                                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // paddingLeft: function(i, node) { return 4; },
                                    // paddingRight: function(i, node) { return 4; },
                                    // paddingTop: function(i, node) { return 2; },
                                    // paddingBottom: function(i, node) { return 2; },
                                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                }                          

                            },


                            {text: '',   margin: [0, 10]},
                            {text: 'Tabela stavki narudžbe', style: 'podnaslov',  margin: [0, 5]},
                                                
                            {

                                //layout: 'lightHorizontalLines', // optional
                                style: 'tabelastavke',
                                table: {

                                    headerRows: 1,
                                    widths: [ '*', 80, 80, 80 ],

                                    body:tabelastavke,
                                    
                                    
                                },  
                                                        
                                layout: {
                                    hLineWidth: function (i, node) {
                                        //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                                    },
                                    vLineWidth: function (i, node) {
                                        //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                                    },
                                    hLineColor: function (i, node) {
                                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                    },
                                    vLineColor: function (i, node) {
                                        return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                    },
                                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                    // paddingLeft: function(i, node) { return 4; },
                                    // paddingRight: function(i, node) { return 4; },
                                    // paddingTop: function(i, node) { return 2; },
                                    // paddingBottom: function(i, node) { return 2; },
                                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                }



                            }
                        ],



                        styles: {

                            

                            ime_firme: {
                                fontSize: 18,
                                bold: true,
                                alignment: 'center',
                                margin: [0, 5, 0, 0]                            
                            },

                            ime_firme_podnaslov: {
                                fontSize: 7,
                                bold: true,
                                alignment: 'center',
                                //margin: [30, 190, 0, 80]                            
                            },             
                            ime_firme_podnaslov_generalije: {
                                fontSize: 7,
                                bold: true,
                                alignment: 'center',
                                //margin: [30, 190, 0, 80]                            
                            },                                                        
                            naslov: {
                                fontSize: 15,
                                bold: true,
                                alignment: 'center',
                                //margin: [0, 0, 0, 0]                            
                            },                        
                            podnaslov: {
                                fontSize: 12,
                                bold: true
                            },
                            quote: {
                                italics: true
                            },
                            small: {
                                fontSize: 8
                            },
                            ultrasmall:{
                                fontSize: 6
                            },
                            sta: {
                                fontSize: 11,
                                bold: false,
                                alignment: 'justify'
                            },
                            tableHeader: {
                                fontSize: 10,
                                bold: true
                            },
                            tableFooter: {
                                fontSize: 10,
                                bold: true
                            },                            
                            tabelastavke:{
                                fontSize: 10,
                            }, 
                            tabelastavke_bold:{
                                fontSize: 10,
                                bold: true
                            },                             
                            brojevistranica:{
                                fontSize:6
                            }                       
                        }
                    };
                    pdfMake.createPdf(docDefinition).open();
            },

            sloziredtabelePDFmake(cRov, chAlign, cvAlign, cStyle, cFillColor, cBorder )
            {

                    var arBorder = [];
                    
                    for (var key in cBorder) 
                    {
                        if (cBorder.hasOwnProperty(key))
                        {
                            var data = cBorder[key];
                            var row = new Array();
                            row.push(data.lijevo);
                            row.push(data.gore);
                            row.push(data.desno);
                            row.push(data.dole);
                            
                            arBorder.push(row);
                        }

                    }
                    

                    //console.log("arborder je " + arBorder[0]);


                    var red={

                        text:cRov,
                        alignment:chAlign,
                        style:cStyle,
                        fillColor:cFillColor,
                        border:arBorder[0] //border:[false, false, false, false]
                        
                    };
                    return red;

            }, 


            create_jsPDF () {

                    let polozajY=30;
                    let columnHeader = [ 'Roba', 'Količina', 'Cijena', 'Iznos'];
                    let tabelastavke=[];

                    
                    try{ 



                        try{ 

                            /* ========  TABELA SA STAVKAMA START ========  */
                            
                            var i=0;
                            for (var i in this.narudzbastav){
                                    /*var obj=[]];
                                    obj[0] =this.narudzbastav[i].NazivRoba,
                                    obj[1]= this.narudzbastav[i].Kolicina,
                                    obj[2]= this.narudzbastav[i].Cijena,
                                    obj[3]= this.narudzbastav[i].Iznos*/
                                    var obj={
                                        0:this.narudzbastav[i].NazivRoba,
                                        1:this.narudzbastav[i].Kolicina,
                                        2:this.narudzbastav[i].Cijena,
                                        3:this.narudzbastav[i].Iznos
                                    };
                                tabelastavke.push(obj);
                                i++;
                            }
                            
                            /* ======== TABELA SA STAVKAMA KRAJ ======== */

                        }catch (error) 
                        {
                            this.obavijestHeading("Došlo je do greške popunjavanja vrijednosti stavki u tabeli štampe! " + error.toString())

                        }                       
                        
                        let pdfName = 'proba';
                        var doc = new jsPDF();
                        
                        try{ 

                            /*==== dodavanje fontova ====*/
                        
                        /* 1.  === solidan način ali ne čita sve fontove i kad se drugi put uloada .
                                izgubi i one fontove što je čito ========
                        =============================================================
                            const myFont ="../../fontovi/verdana.ttf";                                                  
                            var callAddFont = function () {
                                this.addFileToVFS('ArialUnicodeMSFont.ttf', myFont);
                                this.addFont('ArialUnicodeMSFont.ttf', 'ArialUnicodeMSFont', 'normal');
                            };
                            jsPDF.API.events.push(['addFonts', callAddFont]);                        
                            doc.setFont("ArialUnicodeMSFont");
                            doc.setFontSize(12);
                            doc.text("Obrazac zaprimljene narudžbešđčć", 70, polozajY);
                            this.obavijestHeading(doc.getFontList());    
                            =============================================================*/


                            /* 2.  === registracija ttf fonta .. ne pali baš najbolje ====
                            =============================================================
                            import font from '../../fontovi/verdana-normal.js';
                            doc.addFileToVFS("verdana.ttf", font);
                            doc.addFont("verdana.ttf", "verdana", "normal");
                            doc.setFont("verdana");
                            doc.setFontSize(12);
                            doc.text("Obrazac zaprimljene narudžbešđčć", 70, polozajY);
                            this.obavijestHeading(doc.getFontList());   
                            =============================================================*/
                            
                            /* 3. === slicno ko i iostali samo ovde pusha matricu specifivno, dva puta, ne kontam 
                                    
                            =============================================================*/
                            //var font = 'ovde mozes zalijepiti i onaj dugi niz iz slova, kada 
                            // iz konvertera https://rawgit.com/MrRio/jsPDF/master/fontconverter/fontconverter.html dobijes
                            // verdana-normal.js fajl .. samo kopiraj dio sa const font="balblaa"'; 

                            //import font from '../../fontovi/verdana-normal.js';  zalijepi na pocetku scripta ako neces
                            //kopirati onaj ogromni niz iz nekinazivfonta.js

                            var callAddFont = function () {
                                doc.addFileToVFS('Verdana-normal.ttf', font);
                                doc.addFont('Verdana-normal.ttf', 'Verdana-normal', 'normal');
                            };
                            jsPDF.jsPDF.API.events.push(['addFonts', callAddFont]);

                        }
                        catch (error) 
                        {
                            this.obavijestHeading("Došlo je do greške dodavanja fontova! Originalna greška : " + error.toString())

                        }   

                        //doc.setFont("verdana");
                        
    
                        doc.setFontSize(12);
                        doc.text("Obrazac zaprimljene narudžbešđčć", 70, polozajY);



                        //doc.rect(5,20,100,10,'F');
                        //doc.rect(15,20,100,10);
                        //doc.text(17,27,"Ime: " + this.kupacinfo.Ime + " " + this.kupacinfo.Prezime)

                        //doc.setFont("Helvetica");
                        doc.setFontSize(12);
                        doc.text("Podaci o kupcu", 15, polozajY+18);

                        doc.autoTable({


                            startY: polozajY+20,
                            theme: 'grid',
                            styles: {
                                    overflow: 'linebreak',
                                    fontSize: 8,
                                    valign: 'middle',
                                    fillColor: [255, 255, 255],
    
                                },  
                                                        
                                showHead: 'firstPage',
                                columnStyles: { 
                                    0: {columnWidth: 25, /*font:'Helvetica',*/ fontStyle: 'bold', halign: 'left', fillColor: [236, 234, 232] }, 
                                    1: { halign: 'left', /*font:'Times-Roman',*/ fillColor: [255, 255, 255]}, 
                                    2: {columnWidth: 25, /*font:'Helvetica',*/ fontStyle: 'bold',  halign: 'left', fillColor: [236, 234, 232]}, 
                                    3: { halign: 'left', /*font:'Times-Roman',*/ fillColor: [255, 255, 255]} 
                                
                                
                                }, // Cells in first column centered and green
                                margin: { top: 50 },

                                body: [
                                    ['Naziv/Ime : ', this.kupacinfo.Ime, 'Prezime: ', this.kupacinfo.Prezime],
                                    ['Adresa : ', this.kupacinfo.Adresa, 'Država/Grad : ', this.kupacinfo.Drzava + " / " + this.kupacinfo.Grad],
                                    [ 'Telefon : ', this.kupacinfo.Telefon, 'Email : ', this.kupacinfo.Email],

                                ],
                        });    



                        //doc.setFont("verdana");
                        doc.setFontSize(12);
                        doc.text("Dostava na drugu adresu", 15, polozajY+48);

                        doc.autoTable({


                            startY: polozajY + 50,
                            theme: 'grid',
                            styles: {
                                    overflow: 'linebreak',
                                    fontSize: 8,
                                    valign: 'middle',
                                    fillColor: [255, 255, 255] 
                                },                            
                                showHead: 'firstPage',
                                columnStyles: { 
                                    0: {columnWidth: 25, fontStyle: 'bold', halign: 'left', fillColor: [236, 234, 232] }, 
                                    1: { halign: 'left', fillColor: [255, 255, 255]}, 
                                    2: {columnWidth: 25, fontStyle: 'bold',  halign: 'left', fillColor: [236, 234, 232]}, 
                                    3: { halign: 'left', fillColor: [255, 255, 255]} 
                                
                                
                                }, // Cells in first column centered and green
                                margin: { top: 50 },

                                body: [
                                    ['Naziv/Ime : ', this.altkupacinfo.Ime, 'Prezime: ', this.altkupacinfo.Prezime],
                                    ['Adresa : ', this.altkupacinfo.Adresa, 'Država/Grad : ', this.altkupacinfo.Drzava + " / " + this.altkupacinfo.Grad],
                                    [ 'Telefon : ', this.altkupacinfo.Telefon, 'Email : ', this.altkupacinfo.Email],

                                ],
                        });





                        //doc.setFont("Verdana");
                        doc.setFontSize(12);
                        doc.text("Podaci o narudžbi", 15, polozajY+78);

                        doc.autoTable({


                            startY: polozajY + 80,
                            theme: 'grid',
                            styles: {
                                    overflow: 'linebreak',
                                    fontSize: 8,
                                    valign: 'middle',
                                    fillColor: [255, 255, 255] 
                                },                            
                                showHead: 'firstPage',
                                columnStyles: { 
                                    0: {columnWidth: 40, fontStyle: 'bold', halign: 'left', fillColor: [236, 234, 232] }, 
                                    1: { halign: 'left', fillColor: [255, 255, 255]}, 
    
                                
                                
                                }, // Cells in first column centered and green
                                margin: { top: 50 },

                                body: [
                                    ['Narudžba : ', this.narudzba.Narudzba],
                                    ['Datum : ', this.narudzba.Datum],
                                    ['Način plaćanja : ', this.narudzba.Nacinplacanja],

                                ],
                        });


                        //doc.setFont("verdana");
                        doc.setFontSize(12);
                        doc.text("Tabela artikala narudžbe", 15, polozajY+115);

                        doc.autoTable(
                            columnHeader, 
                            tabelastavke, 
                            { 
                                startY: polozajY + 117,
                                pageBreak: 'avoid',
                                theme: 'grid',
                                styles: {
                                    overflow: 'linebreak',
                                    fontSize: 8,
                                    valign: 'middle'
                                },
                                headStyles :{fillColor : [236, 234, 232], textColor: [0, 0, 0], fontStyle: 'bold'}, 
                                columnStyles: {
                                    0: {valign: "top",
                                        fontStyle: 'bold',
                                        align: 'left', 
                                    }, 
                                    1: {
                                        columnWidth: 20,
                                        
                                        halign: 'center', 
                                    },
                                    2: {
                                        columnWidth: 20,
                                    
                                        halign: 'center', 
                                    },
                                    3: {
                                        columnWidth: 20,
                                    
                                        halign: 'center', 
                                    }}
                                });
                        
                        doc.save(pdfName + '.pdf');
                    
                    }catch (error) 
                    {
                        this.obavijestHeading("Došlo je do greške popunjavanja vrijednosti  stavki! " + error.toString())

                    }                     
            },

            formatBroj(val){
                var numBr = numeral(val);
                var strBr = numBr.format('0.00');
                return strBr;

            }             
        }        
	}
</script>

<style >


.glavni_naslov{
  font-size: 12px !important;  
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.sekcija_naslov{
  font-size: 12px !important;  
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.table_td_visina{
     line-height: 8px !important; 
}

.table_td_osnovna{
  font-size: 10px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.table_td_left{
  background-color:#F2F3F4;
  font-weight: bold;
  font-size: 10px !important;
  width: 30%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;  
}
.table_td_nastavak{
  font-weight: bold;
  font-size: 10px !important;
  width: 30%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.table_td_sivo{

  background-color:#F2F3F4;
  font-weight: bold;
  font-size: 10px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;  
}

.table_stavke{
  
    min-width: 200px !important;
}
.table_td_right{
  /*background-color:#F2F3F4;
  font-weight: bold;*/
}

.v-progress-circular {
  margin: 1rem;
}
</style>