<template>

  <v-app>

    
    
    

    <v-main>
        
        <v-card
          outlined
          color="#757575"
          class="white--text"
        >
        
          <v-card-title     
            class="headline">
            Kreiranje webshop-a
            <v-spacer></v-spacer>

          </v-card-title>
          <v-card>
              <v-row>
              
                <v-col  cols="12" md="2"  >

                    <div  class="text-grey text-h6 mt-4 px-9">Filteri</div>
                    <!--
                    <v-row>
                      <v-treeview
                          selected-color="#344955 60% "
                          v-model="selected.branditems"
                          :items="branditems"
                          
                          selectable                                
                          open-all                                                                
                          open-on-click
                          transition 
                      ></v-treeview>
                    </v-row>
                    <v-row class="mt-4">
                      <v-treeview
                          selected-color="#344955 60% "
                          v-model="selected.kategorijeitems"
                          :items="kategorijeitems"
                          :selection-type="selectionType"
                          selectable                                
                          open-all   
                          open-on-click
                          transition                                                              
                      ></v-treeview>
                    </v-row>
                    <v-row class="mt-4">
                      <v-treeview
                          selected-color="#344955 60% "
                          v-model="selected.subkategorijeitems"
                          :items="subkategorijeitems"
                          :selection-type="selectionType"
                          selectable                                
                          open-all     
                          open-on-click
                          transition                                                             
                      ></v-treeview>
                    </v-row>                    
                    -->
                    <v-row class="px-9 mt-4">
                      <v-checkbox
                            v-model="chkenabled"
                            label="Aktivni"
                            @change="handleChangeAktivni"
                            color="#344955 60%"
                        >
                        </v-checkbox>
                    </v-row>
                    <v-row class="px-9 ">
                      <v-checkbox
                            v-model="chkenabled1"
                            label="Artikli akcija"
                            @change="handleChangeAkcija"
                            color="#344955 60%"
                        >
                        </v-checkbox>
                    </v-row>                      
                    <v-row class="px-9 ">
                      <v-checkbox
                            v-model="chkenabled2"
                            label="Top artikli"
                            @change="handleChangeTop"
                            color="#344955 60%"
                        >
                        </v-checkbox>
                    </v-row>   
                    <v-row class="px-9 ">
                      <v-checkbox
                            v-model="chkenabled3"
                            label="Novi artikli"
                            @change="handleChangeNovi"
                            color="#344955 60%"
                        >
                        </v-checkbox>
                    </v-row>       
                    <v-row class="px-9 ">
                      <v-checkbox
                            v-model="chkenabled4"
                            label="Svi artikli"
                            @change="handleChangeSvi"
                            
                            color="#344955 60%"
                        >
                        </v-checkbox>
                    </v-row> 
                    <v-row class="px-9 ">
                      <v-checkbox
                            v-model="chkenabled5"
                            label="Artikli na stanju"
                            @change="handleChangeNastanju"
                            
                            color="#344955 60%"
                        >
                        </v-checkbox>
                    </v-row>                                                            
                </v-col>
                
                <v-col cols="12" md="9" >
                  <!--v-card
                    outlined
                    color="blue darken-2"
                    class="white--text"
                  -->

                      <v-data-table
                      fixed-header
                      :headers="headers"
                      :items="sviprodukti"
                      :search="search"
                      class="elevation-1"
                      :item-class="() => 'hover-row'"
                    >

                        <template v-slot:top>
                          <v-card>
                            <v-card-text>

                                <v-row >
                                  <!-- polja -->
                                  <v-btn
                                    color="blue-darken-2"
                                    rounded="0"
                                    variant="outlined"
                                    prepend-icon="mdi-plus"
                                    @click="additem()"
                                    
                                  >
                                  DODAVANJE

                                  </v-btn>

                                  <v-spacer></v-spacer>
                                  
                                  <v-text-field
                                    v-model="search"
                                    append-icon="fa-search"
                                    label="Pretraga po nazivu, šifri ..."
                                    single-line
                                    hide-details
                                    variant="outlined"
                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                    append-inner-icon="mdi-magnify"
                                  ></v-text-field>

                                  <v-snackbar
                                  v-model="alertsnackbar2"
                                  :multi-line="multiLine"
                                  absolute
                                  top
                                  shaped
                                  middle
                                  >
                                  {{ snackbartekst2 }}

                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        color="red"
                                        text
                                        v-bind="attrs"
                                        @click="alertsnackbar2 = false"
                                        >
                                        Zatvori
                                        </v-btn>
                                    </template>
                                  </v-snackbar>
                                </v-row >
                                

                                <v-row>
                                  <v-dialog v-model="dialogDelete" max-width="500px">
                                    <v-card class="delete-dialog"> 
                                      <v-card-title class="headline">Potvrda brisanja</v-card-title>
                                      <v-card-text class="text-center">
                                          <p>
                                          <strong>Jeste li sigurni u brisanje stavke, Slug:{{deleteproduktslug}}, Šifra: {{deleteproduktsifra}}, Naziv {{ deleteproduktnaziv }} ?</strong> <br />
                                          </p>
                        
                                      </v-card-text>
                                      <v-card-actions class="justify-center">
                                        <v-spacer></v-spacer>
                                        <v-btn class="same-width-btn text-none" color="red-darken-2" variant="outlined" @click="closeDelete">Cancel</v-btn>
                                        <v-btn class="same-width-btn text-none" color="green-darken-2" variant="outlined" @click="deleteItemConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-row>




                            </v-card-text>
                          </v-card>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item)"
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon
                                small
                                @click="deleteItem(item)"
                              >
                                mdi-delete
                              </v-icon>
                        </template>
                      </v-data-table>




                  <!--/v-card-->
                </v-col>
              

              </v-row>
          </v-card>
        </v-card>



  </v-main>
  </v-app>
</template>





<script>  
//import LookupRoba from '../Registarroba/RobaLookUp.vue';
import WebShopKreiranjeTab from '../WebShopKreiranje/WebShopKreiranjeTab.vue'
//import KreiranjeTabelaEdit from '../WebShopKreiranje/WebShopKreiranjeTabelaEdit.vue'
//import KreiranjeDetaljiEdit from '../WebShopKreiranje/WebShopKreiranjeDetalji.vue'
//import KreiranjeMedijaEdit from '../WebShopKreiranje/WebShopKreiranjeMedija.vue'
import axios from 'axios';
import { mapState } from 'vuex';
//import { bus } from '../../app.js'//--- busproba

import { ref } from 'vue'


var numeral = require('numeral');



export default {

  inject:["eventBus"],//bus mitt vue3


  data() {
    return {
      
      

      modules: {
          imageDrop: true,
          imageResize: {}
          },

      tabs: null,
      selected: {
          cijene: [],
          kategorije: [],
          subkategorije: [],
          subsubkategorije: [],
          brandovi: [],
          branditems:[],
          kategorijeitems: [],
          subkategorijeitems: [],
          subsubkategorijeitems: []
      }, 
      kategorijeitems: [],
      subkategorijeitems: [],
      subsubkategorijeitems: [],
      kategorijechild: [],
      subkategorijechild: [],
      subsubkategorijechild: [],
      branditems:[],
 
      selectionType: "leaf",/* v-tree vrsta selekcije */    
      submitted: false,
      svevrsterobe: [],
      sviprodukti: [],
      produkt: {
           
            Sifra:'',
            Slug:'',
            NazivRoba:'',
            Datum:'',
            Stanje:0,
            Staracijena:0, 
            Cijena:0,
            Valuta:'',
            Akcija:0, 
            Akcijadatumstart:'', 
            Akcijadatumkraj:'', 
            Popust:'', 
            Akcijatekst:'',
            Freeshiping:0, 
            Ribbon:0, 
            Ribbontext:'', 
            Prioritet:0,
            Topprodukt:0,
            Cijenanaupit:0, 
            Menukategorija:0,
            Kategorija:0,
            Subkategorija:0,
            SubSubkategorija:0,
            SlikaString:0,
            OpisRoba:0,
            KratkiOpisRoba:0,
            KategorijaNaziv:0,            
            Brand:'',
            Status:0,
            Frontpagepic:'',
            Frontpagepicalt:''

      },
      
      produktDefault: {         
          Sifra:'',
          Slug:'',
          NazivRoba:'',
          Datum:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          Stanje:0,
          Staracijena:0, 
          Cijena:0,
          Valuta:'KM',
          Akcija:0, 
          Akcijadatumstart:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
          Akcijadatumkraj:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
          Popust:'', 
          Akcijatekst:'',
          Freeshiping:0, 
          Ribbon:0, 
          Ribbontext:'', 
          Prioritet:0,
          Topprodukt:0,
          Cijenanaupit:0, 
          Menukategorija:0,
          Kategorija:0,
          Subkategorija:0,
          SubSubkategorija:0,
          SlikaString:0,
          OpisRoba:0,
          KratkiOpisRoba:0,
          KategorijaNaziv:0,            
          Brand:'',
          Status:0,
          Frontpagepic:'',
          Frontpagepicalt:''
      },
      montirano:false,
      montirano1:false,
      montirano2:false,
      montirano3:false,
      cmbStrSearch:"",
      Pretraga:'',
      PretragaSifra:'',
      Sifra: '',
      pagination: {},
      edit: false,

      isChecked:false, 
      parentsifraprodukt:0,

      alert:false,
      alerttext:'',

      alertsnackbar:false,
      snackbartekst:'',      
      multiLine: true,

      alertsnackbar2:false,
      snackbartekst2:'',

      //====== za vuetify kontrole
      date: new Date().toISOString().substr(0, 10),
      search:'',
      cmbSearch:'',
      adialog:false,
      dialogDelete: false,
      deleteproduktslug:'',
      deleteproduktsifra:'',
      deleteproduktnaziv:'',


     
      chkenabled: true,//gledam aktivne artikle
      status:1,

      chkenabled1: true,//artikli na akciji, default sve uključeno
      akcija:1,

      chkenabled2: true,//top artikli, default sve uključeno
      topartikl:1,
      
      chkenabled3: true,
      noviartikl:1,
      
      chkenabled4: true,            
      sviartikli:1,

      chkenabled5: true,            
      artiklinastanju:1,      

      /*
      menu1: false,
      menu2: false,
      menu3: false,
                    */
      sifrarules: [
        v => !!v || 'Šifra je obavezna!',
        v => (v && v.length <= 50) || 'Šifra mora biti kraća od 50 karaktera.',
      ],
      nazivrules: [
        v => !!v || 'Naziv je obavezan!',
        v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
      ],
      trirules: [
        v => !!v || 'Vrijednost je obavezna!',
        v => (v && v.length <= 3) || 'Unesena vrijednost mora biti kraći od 3 karaktera.',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      numberRules:[
            v => !!v || 'Vrijednost je obavezna!',
        ],
      //wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],


     
    //====== za vuetify kontrole kraj



      headers: [
        {
          title: 'Šifra',
          align: 'start',
          filterable: true,
          key: 'Sifra',
        },
        { title: 'Naziv', key: 'NazivRoba', filterable: true },
        { title: 'Brand', key: 'Brand' },
        { title: 'Cijena', key: 'Cijena' },
        { title: 'Actions', key: 'actions', sortable: false }
      ]

    };
  },

  props: {
      publicTransDialogSifraRoba:'',

  },

  created() {
    this.loadBrandoviTree();
    this.loadKategorijeTree();
    this.loadSubkategorijeTree();
    this.loadProdukti();
   


  },
 
 
  components:{
   // 'KreiranjeTabela-Edit':KreiranjeTabelaEdit,
   // 'Kreiranje-Detalji-Edit':KreiranjeDetaljiEdit,
   // 'Kreiranje-Medija-Edit':KreiranjeMedijaEdit,

  },

  mounted(){
       /* this.$root.$on('komponentatabelaprodukata', (data) => {
           
           this.pokrenutosaroot(data);

        });*/
  },

  computed:{

    // Mapiranje state-a iz Vuex store-a
    ...mapState({

      store_RefreshGrid: state => state.trebaRefreshovatGrid,

    }),

  },

  watch: {
      selected: {
          handler: function () {
              this.loadBrandoviTree();
              this.loadKategorijeTree();
              this.loadSubkategorijeTree();
              this.loadSubSubkategorijeTree();
              this.loadProdukti();
             
          },
          deep: true

      },

      store_RefreshGrid(newVal) {
        if (newVal) {
         //this.loadProdukti();
          this.pokrenutosaroot({ refreshgrid: newVal });
          // Resetujemo stanje nazad na false
          this.$store.commit('setRefreshGrida', false);
        }
    },


  },
  
  methods: {

    pokrenutosaroot(data){
      console.log("Pokrenuta funkcija sa podacima:", data); // Debug
        if(data.refreshgrid==true){
            this.loadProdukti();
        }

    },


    loadBrandoviTree: function () {
        //console.log('selektovani brandovitems ' + this.selected.branditems);
        axios.get('/api/apiwsbrandovisvefront', {
                //params: this.selected['branditems']
            })
            .then((response) => {

                //this.branditems=response.data.data;
                this.loadBrandoviTreeMontiranje(response.data.data);
                this.branditems=[];
                this.brandovichild=[];
                var cnt =  Object.keys(response.data.data).length;

                for (var i =0; i < cnt; i++ ){
                    this.brandovichild.push({
                        
                        Sifra: response.data.data[i].Sifra,
                        Naziv:response.data.data[i].Naziv,
                        Opis:response.data.data[i].Opis,
                        SlikaString:response.data.data[i].SlikaString,
                        Prioritet:response.data.data[i].Prioritet,
                        Aktivan:response.data.data[i].Aktivan,
                        Rb:response.data.data[i].Rb, 
                        name:response.data.data[i].Sifra,
                        id:response.data.data[i].Sifra 

                    });

                    
                };
                
                this.branditems.push({id: 1, name: 'Brandovi', children: this.brandovichild})



            })

            .catch(function (error) {
                console.log(error);
            });
    },

    loadKategorijeTree: function () {
        //console.log('selektovani kategorije ' + this.selected.kategorijeitems);
        axios.get('/api/apiwskategorijesvefront', {
                //params: _.omit(this.selected, 'kategorije')
                //params: this.selected['kategorije']
            })
            .then((response) => {
                this.loadKategorijeTreeMontiranje(response.data.data);
                this.kategorijeitems=[];
                this.kategorijechild=[];
                var cnt =  Object.keys(response.data.data).length;

                for (var i =0; i < cnt; i++ ){
                    this.kategorijechild.push({
                        
                        Sifra: response.data.data[i].Sifra,
                        Naziv:response.data.data[i].Naziv,
                        Opis:response.data.data[i].Opis,
                        SlikaString:response.data.data[i].SlikaString,
                        Prioritet:response.data.data[i].Prioritet,
                        Aktivan:response.data.data[i].Aktivan,
                        Rb:response.data.data[i].Rb, 
                        name:response.data.data[i].Naziv,
                        id:response.data.data[i].Sifra 

                    });

                    
                };
                
                this.kategorijeitems.push({id: 0, name: 'Kategorije', children: this.kategorijechild})
            })
            .catch(function (error) {
                console.log(error);
            });
    },     


    loadSubkategorijeTree: function () {
        //console.log('selektovani kategorije ' + this.selected.kategorijeitems);
        axios.get('/api/apiwssubkategorijesvefront', {
                //params: _.omit(this.selected, 'kategorije')
                //params: this.selected['kategorije']
            })
            .then((response) => {
                this.loadSubkategorijeTreeMontiranje(response.data.data);
                this.subkategorijeitems=[];
                this.subkategorijechild=[];
                var cnt =  Object.keys(response.data.data).length;

                for (var i =0; i < cnt; i++ ){
                    this.subkategorijechild.push({
                        
                        Sifra: response.data.data[i].Sifra,
                        Naziv:response.data.data[i].Naziv,
                        Opis:response.data.data[i].Opis,
                        SlikaString:response.data.data[i].SlikaString,
                        Prioritet:response.data.data[i].Prioritet,
                        Aktivan:response.data.data[i].Aktivan,
                        Rb:response.data.data[i].Rb, 
                        name:response.data.data[i].Naziv,
                        id:response.data.data[i].Sifra 

                    });

                    
                };
                
                this.subkategorijeitems.push({id: 0, name: 'Subkategorije', children: this.subkategorijechild})
            })
            .catch(function (error) {
                console.log(error);
            });
    },   

    loadSubSubkategorijeTree: function () {
        //console.log('selektovani kategorije ' + this.selected.kategorijeitems);
        axios.get('/api/apiwssubsubkategorijesvefront', {
                //params: _.omit(this.selected, 'kategorije')
                //params: this.selected['kategorije']
            })
            .then((response) => {
                this.loadSubSubkategorijeTreeMontiranje(response.data.data);
                this.subsubkategorijeitems=[];
                this.subsubkategorijechild=[];
                var cnt =  Object.keys(response.data.data).length;

                for (var i =0; i < cnt; i++ ){
                    this.subsubkategorijechild.push({
                        
                        Sifra: response.data.data[i].Sifra,
                        Naziv:response.data.data[i].Naziv,
                        Opis:response.data.data[i].Opis,
                        SlikaString:response.data.data[i].SlikaString,
                        Prioritet:response.data.data[i].Prioritet,
                        Aktivan:response.data.data[i].Aktivan,
                        Rb:response.data.data[i].Rb, 
                        name:response.data.data[i].Naziv,
                        id:response.data.data[i].Sifra 

                    });

                    
                };
                
                this.subsubkategorijeitems.push({id: 0, name: 'SubSubkategorije', children: this.subsubkategorijechild})
            })
            .catch(function (error) {
                console.log(error);
            });
    }, 

    loadBrandoviTreeMontiranje: function(data){
                if(this.montirano2==true) return;
                var cArr=[];
                var cnt =  Object.keys(data).length;

                for (var i =0; i < cnt; i++ ){
                        //console.log("Brand ime:  " + data[i].Naziv);
                        cArr.push(data[i].Naziv);        
                    }
                this.selected.branditems= Array.from(cArr); 
                this.montirano2=true;
            }, 

    loadKategorijeTreeMontiranje: function(data){
                if(this.montirano==true) return;
                var cArr=[];
                var cnt =  Object.keys(data).length;

                for (var i =0; i < cnt; i++ ){
                        //console.log("Kategorija ime:  " + data[i].Sifra);
                        cArr.push(data[i].Sifra); 
                           
                    }
                this.selected.kategorijeitems= Array.from(cArr); 
                this.montirano=true;

            },  
            
    loadSubkategorijeTreeMontiranje: function(data){
                if(this.montirano1==true) return;
                var cArr=[];
                var cnt =  Object.keys(data).length;

                for (var i =0; i < cnt; i++ ){
                        //console.log("Kategorija ime:  " + data[i].Sifra);
                        cArr.push(data[i].Sifra); 
                           
                    }
                this.selected.subkategorijeitems= Array.from(cArr); 
                this.montirano1=true;

            },  
 
    loadSubSubkategorijeTreeMontiranje: function(data){
        if(this.montirano3==true) return;
        var cArr=[];
        var cnt =  Object.keys(data).length;

        for (var i =0; i < cnt; i++ ){
                //console.log("Kategorija ime:  " + data[i].Sifra);
                cArr.push(data[i].Sifra); 
                    
            }
        this.selected.subsubkategorijeitems= Array.from(cArr); 
        this.montirano3=true;

    }, 
            

    loadajProcedure(){
      this.loadBrandoviTree();
      this.loadKategorijeTree();
      this.loadSubkategorijeTree();
      this.loadSubSubkategorijeTree();
      this.loadProdukti();
    },

    handleChangeAktivni(){
      this.status=0;
      if(this.chkenabled==true){
        this.status=1;
      }
      this.loadajProcedure();
    },

    handleChangeAkcija(){
      this.akcija=0;
      if(this.chkenabled1==true){
        this.akcija=1;
      }
      this.loadajProcedure();
    },

    handleChangeTop(){
      this.topartikl=0;
      if(this.chkenabled2==true){
        this.topartikl=1;
      }
      this.loadajProcedure();
    },

    handleChangeNovi(){
      this.noviartikl=0;
      if(this.chkenabled3==true){
        this.noviartikl=1;
      }
      this.loadajProcedure();
    },

    handleChangeNastanju(){
      this.artiklinastanju=0;
      if(this.chkenabled5==true){
        this.artiklinastanju=1;
      }
      this.loadajProcedure();
    },

    handleChangeSvi(){
      this.sviartikli=0;
      if(this.chkenabled4==true){
        this.sviartikli=1;

        this.chkenabled=true;this.status=1;
        this.chkenabled1=true;this.akcija=1;
        this.chkenabled2=true;this.topartikl=1;
        this.chkenabled3=true;this.noviartikl=1;
        this.chkenabled5=true;this.artiklinastanju=1;

      }
      this.loadajProcedure();
    },
    
    async loadProdukti(page_url) { 



      axios.get('/api/apiwssviproduktikreiranje', {
              params:{ 
                  
                  "kategorije":this.selected.kategorijeitems, 
                  "subkategorije":this.selected.subkategorijeitems,
                  "subsubkategorije":this.selected.subsubkategorijeitems, 
                  "brandovi":this.selected.branditems,
                  "status":this.status,
                  "topartikl":this.topartikl,
                  "akcija":this.akcija,
                  "noviartikl":this.noviartikl,
                  "sviartikli":this.sviartikli,
                  "nastanju":this.artiklinastanju                             
                  //"pretraganaziv": this.robaPretraga
                  
              }
          })
          .then((response) => {

              /* LOAD MORE DUGME
              this.produktifull = response.data.data;  
              this.produkti=this.produktifull.slice(0,this.length); 
              */           
            
              this.sviprodukti = response.data.data;
              
              
              
          })
          .catch(function (error) {
              console.log(error);
          });

    },



    async obavijestHeading(strTekst){
        this.snackbartekst2=strTekst;
        this.alertsnackbar2=true;
    },

    additem(){
        this.edit = false;
        this.produkt = Object.assign({}, this.produktDefault)

        //this.posaljikomponentamaslug("");//praznim kontrole na detaljima i medijima
        this.posaljikontejneru( this.produkt,  this.edit) //true je za edit, false je za add
        //
        
      },

    posaljikomponentamaslug(slug){
      /* vue 2
      this.$root.$emit('komponentadetalji',{Slug:slug}); 
      this.$root.$emit('komponentamediji',{Slug:slug}); 
      */
     /*vue 3*/
      //this.$emit('komponentadetalji',{Slug:slug}); 
      //this.$emit('komponentamediji',{Slug:slug}); 

    },

    editItem (item) {
      this.edit = true;
      
      this.produkt = this.sviprodukti.indexOf(item);
      this.produkt = Object.assign({}, item);

      
      //console.log(this.$refs);

      //this.adialog = true;

      this.posaljikontejneru(this.produkt,  this.edit) //true je za edit, false je za add


    },



    deleteItem (item) {
      this.produkt = this.sviprodukti.indexOf(item)
      this.produkt = Object.assign({}, item)
      this.deleteproduktslug=this.produkt.Slug
      this.deleteproduktsifra= this.produkt.Sifra
      this.deleteproduktnaziv= this.produkt.NazivRoba
      this.dialogDelete = true
    },
    closeDelete () {
      this.dialogDelete = false
      //this.ispraznikontrole()
    },
    deleteItemConfirm () {
      //this.desserts.splice(this.editedIndex, 1)
      this.deleteprodukt( this.deleteproduktslug);
      this.closeDelete()
    },
    
    deleteprodukt(slug) {
      //if (confirm('Da li ste sigurni u brisanje?')) {
        fetch(`api/apiwsproduktkreiranje/${slug}`, {
          method: 'delete'
        })
          .then(res => res.json())
            .then(res => {
                if(res.greska==true){
                    this.obavijestHeading(res.data);
                    return;
                }
                this.obavijestHeading("Produkt je obrisan!");
                this.loadProdukti();
          })
          .catch(
               err => this.obavijestHeading("Greška brisanja produkta: " + err.toString())              
              );
    },


    posaljikontejneru( produkt, modrada) {
      this.eventBus.emit('kontejnerinstanca',{transprodukt:produkt, transedit:modrada,
                                       transtabshow:true, transtabelashow:false}); //modrada je true za edit, false za add
    },
/*
    WSKreiranjeEditClick(adialogprikaz, editmodrada, refreshgrid, poruka){
        
        this.adialog=adialogprikaz;
        this.edit=editmodrada;
        if(refreshgrid==true){
            this.loadProdukti();
        }
        if (poruka!="odustao"){
           this.obavijestHeading(poruka);
        }

      },
*/

  }
};
</script>