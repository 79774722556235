 <template>
        <v-card>
        
        <!--Web-Kreiranje @komponentadetalji="pokrenutosaroot"/>
        <Web-Kreiranje-Edit @komponentadetalji="pokrenutosaroot"/-->

        <v-card-text  style="max-height: 470px; overflow-y: auto;">

            <v-container>

                <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                    <v-text-field
                        v-model="produktdetalji.Slug"
                        label="Slug artikla*"
                        id="Slug-input"
                        name="Slug-input"
                        required
                        counter="50"                                    
                        flat
                        :disabled="true"
                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                        variant="outlined"
                    ></v-text-field>
                    </v-col>   
                </v-row>



                    
                <v-row>

                    <v-col
                    cols="12"
                    sm="12" class="font-weight-medium">
                        <v-checkbox
                            v-model="chkenabled"
                            hide-details
                            
                            label="Prikaži kratki opis"
                            
                        >
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    sm="12" >

                        <vue-editor   
                        :editorOptions="editorSettings"                  
                        v-model="produktdetalji.Kratkiopis"></vue-editor>

                    </v-col> 
                </v-row>

                     

                <v-row>

                    <v-col
                    cols="12"
                    sm="12" class="font-weight-medium">
                        <v-checkbox
                            v-model="chkenabled1"
                            hide-details
                            
                            label="Prikaži opis"
                            
                        >
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    sm="12"
                    
                    >
                        <vue-editor                        
                        :editorOptions="editorSettings"
                        v-model="produktdetalji.Opis"></vue-editor>
                    </v-col> 
                </v-row>

  
                <v-row>
                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled2"
                            hide-details
                            
                            label="Prikaži brand"
                        >
                        </v-checkbox>
                    </v-col>  
                    <v-col
                    cols="12"
                    sm="9"
                    
                    >
                        <v-img
                            class="image-fit"
                            contain
                            lazy-src="https://picsum.photos/id/11/10/6"
                            max-height="100"
                            max-width="200"
                            :src="brandslikaputanja"
                        ></v-img>
                    </v-col>                    
                </v-row>  
                <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.MadeIn"
                            label="Proizvedeno u"
                            id="MadeIn-input"
                            name="MadeIn-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled3"
                            hide-details                            
                            label="Prikaži zemlju porijekla"
                        >
                        </v-checkbox>
                    </v-col>                                      
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Materijal"
                            label="Materijal "
                            id="Materijal-input"
                            name="Materijal-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled4"
                            hide-details                            
                            label="Prikaži materijal"
                        >
                        </v-checkbox>
                    </v-col>                                      
                </v-row>   

                <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Tezina"
                            label="Težina "
                            id="Tezina-input"
                            name="Tezina-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled5"
                            hide-details                            
                            label="Prikaži težinu"
                        >
                        </v-checkbox>
                    </v-col>                                      
                </v-row>       
                <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Velicina"
                            label="Veličina "
                            id="Velicina-input"
                            name="Velicina-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled6"
                            hide-details                            
                            label="Prikaži veličinu"
                        >
                        </v-checkbox>
                    </v-col>                                      
                </v-row>  

               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Kubikaza"
                            label="Kubikaža "
                            id="Kubikaza-input"
                            name="Kubikaza-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled7"
                            hide-details                            
                            label="Prikaži kubikažu"
                        >
                        </v-checkbox>
                    </v-col>                                      
                </v-row> 

               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Snaga"
                            label="Snaga "
                            id="Snaga-input"
                            name="Snaga-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled8"
                            hide-details                            
                            label="Prikaži snagu"
                        >
                        </v-checkbox>
                    </v-col>                                      
                </v-row>       

               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Dimenzije"
                            label="Dimenzije "
                            id="Dimenzije-input"
                            name="Dimenzije-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled9"
                            hide-details                            
                            label="Prikaži dimenzije"
                        >
                        </v-checkbox>
                    </v-col>                                      
               </v-row>      
    


               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Visina"
                            label="Visina "
                            id="Visina-input"
                            name="Visina-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled10"
                            hide-details                            
                            label="Prikaži visinu"
                        >
                        </v-checkbox>
                    </v-col>                                      
               </v-row>      


               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Duzina"
                            label="Dužina "
                            id="Duzina-input"
                            name="Duzina-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled11"
                            hide-details                            
                            label="Prikaži visinu"
                        >
                        </v-checkbox>
                    </v-col>                                      
               </v-row>      


               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Sirina"
                            label="Širina "
                            id="Širina-input"
                            name="Širina-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled12"
                            hide-details                            
                            label="Prikaži širinu"
                        >
                        </v-checkbox>
                    </v-col>                                      
               </v-row>  

               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Boja"
                            label="Boja "
                            id="Boja-input"
                            name="Boja-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled13"
                            hide-details                            
                            label="Prikaži boju"
                        >
                        </v-checkbox>
                    </v-col>                                      
               </v-row>    

               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Promjer"
                            label="Promjer "
                            id="Promjer-input"
                            name="Promjer-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled14"
                            hide-details                            
                            label="Prikaži promjer"
                        >
                        </v-checkbox>
                    </v-col>                                      
               </v-row>  

               <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    
                    >
                        <v-text-field
                            v-model="produktdetalji.Novopolovno"
                            label="Novo/Polovno "
                            id="Novopolovno-input"
                            name="Novopolovno-input"
                            required
                            counter="50"                                    
                            flat
                            clearable
                            prepend-inner-icon="mdi-order-alphabetical-ascending"
                            variant="outlined"
                        ></v-text-field>
                    </v-col>  

                    <v-col
                    cols="12"
                    sm="3"
                                    
                    >                  
                        <v-checkbox
                            v-model="chkenabled15"
                            hide-details                            
                            label="Prikaži stanje"
                        >
                        </v-checkbox>
                    </v-col>                                      
               </v-row>                                                 
            </v-container>

            <!--Web-Interaktivna-Mapa
                :TransmapaZona="{
                    AktivneZone_zb: produktdetalji.AktivneZone_zb,
                    SuhoVlazno_zb: produktdetalji.SuhoVlazno_zb,
                    SunceSjena_zb: produktdetalji.SunceSjena_zb,
                    Visinabiljke_zb:produktdetalji.Visinabiljke_zb,
                    Sirinabiljke_zb:produktdetalji.Sirinabiljke_zb,
                    Bojalista_zb:produktdetalji.Bojalista_zb,
                    Bojacvijeta_zb:produktdetalji.Bojacvijeta_zb,
                    Zivotinjetoksicna_zb:produktdetalji.Zivotinjetoksicna_zb,
                    Vrijemecvijetanja_zb:produktdetalji.Vrijemecvijetanja_zb,
                    Prikazi_zb:produktdetalji.Prikazi_zb
                }"
            ></Web-Interaktivna-Mapa-->
            <Web-Interaktivna-Mapa
                v-model="produktdetalji"
                @zone-update-data="updateProduktDetalji"
            ></Web-Interaktivna-Mapa>

            <Web-Vrijeme-Cvijetanja
            v-model="produktdetalji.Vrijemecvijetanja_zb"
            @updateMjeseca="updateVrijemeCvijetanja">
        
            </Web-Vrijeme-Cvijetanja>
        </v-card-text>


        <v-card-actions>


            <v-snackbar
                v-model="alertsnackbar"
                :icon="ikonasnackbar"
                :color="colorsnackbar"
                vertical
                transition="slide-y-transition"
                >
                <v-row justify="space-around">
                    <v-icon  size="large" :icon="ikonasnackbar"></v-icon>
                </v-row>

                <div >
                    <strong>{{ snackbartitle }}</strong>
                </div>
                <p>{{ snackbartekst }}</p>
                    
                
                <template v-slot:actions>
                    <v-btn
                    color="white"
                    variant="text"
                    
                    @click="alertsnackbar = false"
                    >
                    Zatvori
                    </v-btn>
                </template>
            </v-snackbar>



            <v-spacer></v-spacer>
        
            <!--v-btn
            color="red darken-1"
            text
            @click="zatvoriFormu(false,'odustao')"
            
            >
            Odustani
            </v-btn-->

            <v-btn
            class="same-width-btn text-none"
            color="green-darken-2"
            rounded="0"
            variant="outlined"
            prepend-icon="mdi-content-save-all" 
            @click="adddetalje();"
            
            >
            UPIŠI DETALJE
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
        


     </v-card>
</template>

<script>

/*
import { VueEditor, Quill  } from 'vue2-editor'//QuillEditor
import { ImageResize } from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module'


Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)
*/


//import { ImageDrop } from "quill-image-drop-module";
//import { ImageResize } from "quill-image-resize-module";


//import { components } from 'vuetify/dist/vuetify-labs.js';
//import WebShopKreiranjeTabela from './WebShopKreiranjeTabela.vue';
//import WebShopKreiranjeTabelaEdit from './WebShopKreiranjeTabelaEdit.vue';

import axios from 'axios';
import javnefunkcije from '../../javnefunkcije'
import WebInteraktivnaMapa from './WebShopKreiranjeInteraktivnaMapa.vue';
import WebVrijemeCvijetanja from './WebShopKreiranjeVrijemeCvijetanja.vue';

export default {
    components: {

        
        'Web-Interaktivna-Mapa':WebInteraktivnaMapa,
        'Web-Vrijeme-Cvijetanja':WebVrijemeCvijetanja,
        
        },
    data() {
            return {



                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                        }
                },

                chkenabled: false,
                chkenabled1: false,
                chkenabled2: false,
                chkenabled3: false,
                chkenabled4: false,  
                chkenabled5: false,
                chkenabled6: false,
                chkenabled7: false,
                chkenabled8: false,
                chkenabled9: false,  
                chkenabled10: false,
                chkenabled11: false,
                chkenabled12: false,
                chkenabled13: false,
                chkenabled14: false,  
                chkenabled15: false,

                produktdetalji:{
                    Slug:'',
                    Kratkiopis:'',
                    Kratkiopisprikazi: 0,
                    Opis:'',
                    Opisprikazi: 0,
                    Brand:'',
                    Brandprikazi:0,
                    MadeIn:'',	
                    MadeInprikazi:0,
                    Materijal: 0,
                    Materijalprikazi:0,
                    Tezina:'',
                    Tezinaprikazi:0,
                    Velicina:'',
                    Velicinaprikazi:0,
                    Kubikaza:0,
                    Kubikazaprikazi:0,
                    Snaga:'',
                    Snagaprikazi:0,
                    Dimenzije:'',
                    Dimenzijeprikazi:0,
                    Visina:'',
                    Visinaprikazi:0,
                    Duzina:'',
                    Duzinaprikazi:0,
                    Sirina:'',
                    Sirinaprikazi:0,
                    Boja:'',
                    Bojaprikazi:0,
                    Promjer:'',
                    Promjerprikazi:0,
                    Novopolovno:'',
                    Novopolovnoprikazi:0,
                    AktivneZone_zb: '', // Zone koje trebaju biti aktivne
                    SuhoVlazno_zb:'',
                    SunceSjena_zb:'',
                    Visinabiljke_zb:'',
                    Sirinabiljke_zb:'',
                    Bojalista_zb:'',
                    Bojacvijeta_zb:'',
                    Zivotinjetoksicna_zb:'',
                    Vrijemecvijetanja_zb:'',
                    Orezivanje_zb:'',
                    Prikazi_zb:0                      
                    },

                defaultproduktdetalji:{
                    Slug:'',
                    Opis:'',
                    Opisprikazi: 0,
                    Brand:'',
                    Brandprikazi:0,
                    MadeIn:'',	
                    MadeInprikazi:0,
                    Materijal: 0,
                    Materijalprikazi:0,
                    Tezina:'',
                    Tezinaprikazi:0,
                    Velicina:'',
                    Velicinaprikazi:0,
                    Kubikaza:0,
                    Kubikazaprikazi:0,
                    Snaga:'',
                    Snagaprikazi:0,
                    Dimenzije:'',
                    Dimenzijeprikazi:0,
                    Visina:'',
                    Visinaprikazi:0,
                    Duzina:'',
                    Duzinaprikazi:0,
                    Sirina:'',
                    Sirinaprikazi:0,
                    Boja:'',
                    Bojaprikazi:0,
                    Promjer:'',
                    Promjerprikazi:0,
                    Novopolovno:'',
                    Novopolovnoprikazi:0,
                    AktivneZone_zb: '', // Zone koje trebaju biti aktivne
                    SuhoVlazno_zb:'',
                    SunceSjena_zb:'',
                    Visinabiljke_zb:'',
                    Sirinabiljke_zb:'',
                    Bojalista_zb:'',
                    Bojacvijeta_zb:'',
                    Zivotinjetoksicna_zb:'',
                    Vrijemecvijetanja_zb:'',
                    Orezivanje_zb:'',
                    Prikazi_zb:0                     

                },

                brandslikaputanja:'',

                alert:false,
                alerttext:'',

                alertsnackbar:false,
                snackbartekst:'',      
                multiLine: true,

                alertsnackbar2:false,
                snackbartekst2:'',
                

                
            }
        },

    props:{
        
        edit: false,
        
        produkt: {           
            Sifra:'',
            Slug:'',
            NazivRoba:'',
            Datum:null,
            Stanje:0,
            Staracijena:0, 
            Cijena:0,
            Valuta:'',
            Akcija:0, 
            Akcijadatumstart:'', 
            Akcijadatumkraj:'', 
            Popust:'', 
            Akcijatekst:'',
            Freeshiping:0, 
            Ribbon:0, 
            Ribbontext:'', 
            Prioritet:0,
            Topprodukt:0,
            Cijenanaupit:0, 
            Menukategorija:0,
            Kategorija:0,
            Subkategorija:0,
            SubSubkategorija:0,
            SlikaString:0,
            OpisRoba:0,
            KratkiOpisRoba:0,
            KategorijaNaziv:0,           
            Brand:'',
            Status:0,
            Noviartikl:0,
            Noviartikldatumstart:'', 
            Noviartikldatumstop:'',
            Frontpagepic:'',
            Frontpagepicalt:'' 

        },
        produktSlug:''
    },


    
    computed: {

        },

    created() {

        // ==== bus aktiviraj instancu
        //bus.$on('kreiranjedetaljiinstanca',(data)=>{this.metodpokrenutsabusa(data)})

    },   

    mounted() {  
        /* isključeno iz vue2
        this.$root.$on('komponentadetalji', (data) => {
           
           this.pokrenutosaroot(data);

        });*/

        this.popunikontrole(this.produkt.Slug);

    },

    watch:{
        
        edit: function(newVal, oldVal) {        
            if (newVal==false){ //ako je add
                
            } 
        },

        produkt: {
            handler(newVal, oldVal) {
            //console.log("Promena u produktu:", newVal, oldVal);
            this.popunikontrole(newVal.Slug);
            },
            deep: true, // Praćenje dubinskih promena unutar objekta
        },

    },

    methods:{

        updateProduktDetalji(updatedData) {
            //this.produktdetalji = { ...this.produktdetalji, ...updatedData };
            //console.log('apdejtovano prikaži zb ' + updatedData.Prikazi_zb)
            this.produktdetalji.AktivneZone_zb=updatedData.AktivneZone_zb;
            this.produktdetalji.SuhoVlazno_zb = updatedData.SuhoVlazno_zb;
            this.produktdetalji.SunceSjena_zb = updatedData.SunceSjena_zb;
            this.produktdetalji.Visinabiljke_zb = updatedData.Visinabiljke_zb;
            this.produktdetalji.Sirinabiljke_zb = updatedData.Sirinabiljke_zb;
            this.produktdetalji.Bojalista_zb = updatedData.Bojalista_zb;
            this.produktdetalji.Bojacvijeta_zb = updatedData.Bojacvijeta_zb;
            this.produktdetalji.Zivotinjetoksicna_zb = updatedData.Zivotinjetoksicna_zb;
            this.produktdetalji.Vrijemecvijetanja_zb = updatedData.Vrijemecvijetanja_zb;
            this.produktdetalji.Orezivanje_zb = updatedData.Orezivanje_zb,
            this.produktdetalji.Prikazi_zb = updatedData.Prikazi_zb;
        },
        updateVrijemeCvijetanja(updatedData){
            this.produktdetalji.Vrijemecvijetanja_zb=updatedData.join(',');

            //console.log('vrijeme cvijetanja ' + updatedData)
        },
        pokrenutosaroot(data){
            if(data.SLug==""){
                this.produktdetalji = Object.assign({}, this.defaultproduktdetalji);
                return;
            }
            this.popunikontrole(data.SLug);

        },


        async obavijest(strTekst, cTip){
            
            this.alertsnackbar=true;
            await javnefunkcije.obavijest(strTekst, cTip, this);
            
        },
        
        
        popunikontrole(slug){

            if (slug==""){
                
                this.produktdetalji = Object.assign({}, this.defaultproduktdetalji)
                return
            }
            try{
               axios.get('/api/apiwsproduktdetaljikreiranje/'+ slug, {
                    })
                    .then((response) => {                      
                        this.produktdetalji = response.data.data;
                        //console.log(JSON.stringify(this.produktdetalji, null, 2));
                        this.popunicheckove();
                       
                    })
                    .catch(function (error) {
                        console.log(error);
                        
                    });
                 }
            catch(error){
                console.log(error);
               // this.metodpokrenutsabusa();
            }


        },
        
        async dajputanjuslikebranda(brand){
            
                try {

                    let putanja = '/api/apifunkcijeRetFieldValue/wshop_brandovi/SlikaString/Sifra/'+brand;
                    
                    
                    const res = await fetch(putanja, {
                        method: 'get'
                        });

                    const resdata = await res.json();
                     
                    this.brandslikaputanja=resdata.data[0].SlikaString;
                    
                    return;

                }catch(e) 
                {
                    
                    this.obavijest("Greška u dohvatanju slike branda! Originalna greška: " + e.toString(),"error");
                    //console.log('u asyncu sam i nije vraćen broj stavke naloga');
                    //return "slobodanbroj";

                }   
        },


        popunicheckove(){

            this.chkenabled=false;
            if(this.produktdetalji.Kratkiopisprikazi==1)
            {this.chkenabled=true;}

            this.chkenabled1=false;

            if(this.produktdetalji.Opisprikazi==1)
            {this.chkenabled1=true;}

            this.chkenabled2=false;
            this.brandslikaputanja="";
            if(this.produktdetalji.Brandprikazi==1)
            {
                this.chkenabled2=true;                     
                this.dajputanjuslikebranda(this.produktdetalji.Brand);

            }

            this.chkenabled3=false;
            if(this.produktdetalji.MadeInprikazi==1)
            {this.chkenabled3=true;}

            this.chkenabled4=false;
            if(this.produktdetalji.Materijalprikazi==1)
            {this.chkenabled4=true;}

            this.chkenabled5=false;
            if(this.produktdetalji.Tezinaprikazi==1)
            {this.chkenabled5=true;}

            this.chkenabled6=false;
            if(this.produktdetalji.Velicinaprikazi==1)
            {this.chkenabled6=true;} 

            this.chkenabled7=false;
            if(this.produktdetalji.Kubikazaprikazi==1)
            {this.chkenabled7=true;}  
            
            this.chkenabled8=false;
            if(this.produktdetalji.Snagaprikazi==1)
            {this.chkenabled8=true;}  
            
            this.chkenabled9=false;
            if(this.produktdetalji.Dimenzijeprikazi==1)
            {this.chkenabled9=true;} 
            
            this.chkenabled10=false;
            if(this.produktdetalji.Visinaprikazi==1)
            {this.chkenabled10=true;} 
            
            this.chkenabled11=false;
            if(this.produktdetalji.Duzinaprikazi==1)
            {this.chkenabled11=true;} 
            
            this.chkenabled12=false;
            if(this.produktdetalji.Sirinaprikazi==1)
            {this.chkenabled12=true;}  
            
            this.chkenabled13=false;
            if(this.produktdetalji.Bojaprikazi==1)
            {this.chkenabled13=true;} 
            
            this.chkenabled14=false;
            if(this.produktdetalji.Promjerprikazi==1)
            {this.chkenabled14=true;} 
            
            this.chkenabled15=false;
            if(this.produktdetalji.Novopolovnoprikazi==1)
            {this.chkenabled15=true;}                         
            //console.log(this.produktdetalji.Slug);

        },

        popunidetaljeizcheckova(){


            this.produktdetalji.Kratkiopisprikazi=0;
            if(this.chkenabled==true) {
                this.produktdetalji.Kratkiopisprikazi=1;
            }

            this.produktdetalji.Opisprikazi=0;
            if(this.chkenabled1==true)
            {
                this.produktdetalji.Opisprikazi=1;
            }
        
            this.produktdetalji.Brandprikazi=0;     
            if(this.chkenabled2==true)                
            {
                this.produktdetalji.Brandprikazi=1;                       
            }

            this.produktdetalji.MadeInprikazi=0;
            if(this.chkenabled3==true)            
            {
                this.produktdetalji.MadeInprikazi=1;
            }

            this.produktdetalji.Materijalprikazi=0;
            if(this.chkenabled4==true)
            {
                this.produktdetalji.Materijalprikazi=1;
            }

            this.produktdetalji.Tezinaprikazi=0;
            if(this.chkenabled5==true)
            {
                this.produktdetalji.Tezinaprikazi=1;
            }

            this.produktdetalji.Velicinaprikazi=0;
            if(this.chkenabled6==true)
            {
                this.produktdetalji.Velicinaprikazi=1;
            } 

            this.produktdetalji.Kubikazaprikazi=0;
            if(this.chkenabled7==true)
            {
                this.produktdetalji.Kubikazaprikazi=1;
            }  
            
            this.produktdetalji.Snagaprikazi=0;
            if(this.chkenabled8==true)
            {
                this.produktdetalji.Snagaprikazi=1;
            }  
            
            this.produktdetalji.Dimenzijeprikazi=0;
            if(this.chkenabled9==true)
            {
                this.produktdetalji.Dimenzijeprikazi=1;
            } 
            
            this.produktdetalji.Visinaprikazi=0;
            if(this.chkenabled10==true)
            {
                this.produktdetalji.Visinaprikazi=1;
            } 
            
            this.produktdetalji.Duzinaprikazi=0;
            if(this.chkenabled11==true)
            {
                this.produktdetalji.Duzinaprikazi=1;
            } 
            
            this.produktdetalji.Sirinaprikazi=0;
            if(this.chkenabled12==true)
            {
                this.produktdetalji.Sirinaprikazi=1;
            }  
            
            this.produktdetalji.Bojaprikazi=0;
            if(this.chkenabled13==true)
            {
                this.produktdetalji.Bojaprikazi=1;
            } 
            
            this.produktdetalji.Promjerprikazi=0;
            if(this.chkenabled14==true)
            {
                this.produktdetalji.Promjerprikazi=1;
            } 
            
            this.produktdetalji.Novopolovnoprikazi=0;
            if(this.chkenabled15==true)
            {
                this.produktdetalji.Novopolovnoprikazi=1;
            }                         
        
        },

        adddetalje(){

                this.popunidetaljeizcheckova();
                
                fetch('/api/apiwsproduktdetaljikreiranje', {
                    method: 'put',
                    body: JSON.stringify(this.produktdetalji),
                    headers: {
                        'content-type': 'application/json'
                    }
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (!data.greska)
                        {  
                    
                            this.obavijest("Detalji produkta su izmjenjeni!","success");
                        }      
                        else 
                        {
                            this.obavijest("Došlo je do greške pri izmjeni detalja produkta u bazi! Originalna greška: " + data.data, "error");
                        }
                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijest("Došlo je do greške upisa! " + err.toString(),"error")
                    
                    );


        },

        zatvoriFormu (editmodusrada, poruka) {
        
            this.$emit('ws_KreiranjeEditClick',false,editmodusrada,true, poruka ) 

        },

    }
}
</script>
