<template>


    <!-- modaledit -->
    <v-dialog 
        v-model="editproduktdialog"
        
        max-width="90%" > 

        <v-toolbar
            class="sivi-toolbar"
        >

            <v-toolbar-title>{{produkti.NazivRoba}} </v-toolbar-title>
            
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="closeDialog()"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-items>
            </v-toolbar-items>
        </v-toolbar>

        <v-card class="no-outline">
            <v-card-text>
                <v-card
                    color=""
                    elevation="0"
                    class="no-outline   pa-6"
                >
               
                    <v-card-subtitle> <!-- Dodatni prostor iznad naslova -->
                        <!-- Ovde možete dodati tekst ili ostaviti prazno -->
                    </v-card-subtitle>



                    <!--v-card-title     
                        class="headline">
                        Pregled artikla
                    
                        <v-spacer></v-spacer>
                    </v-card-title-->
                        <!--v-btn 
                            class="ma-2"
                            variant="outlined"
                            color=""                        
                            @click="zatvoriFormu (true, 'odustao')" >
                            Povratak na shop
                            
                        </v-btn-->
                    

                    <v-card class="no-outline" elevation="0">
                        <v-progress-circular
                        v-if="loading"
                        indeterminate
                            model-value="100"
                            color="blue-grey"
                        ></v-progress-circular>
                        
                                
                                

                        



                    </v-card>

                    <v-card class="no-outline" elevation="0">
                        <!--v-card-text-->

                            <v-row>
                                <v-col
                                    cols="12" sm="12" md="6"
                                >             
                                
                                    <Galerija :images="images">
                                    </Galerija>

                                </v-col>
                                                    
                                <v-col
                                    cols="12" sm="10" md="6" class="mx-auto" 

                                >
                                    <v-card-text>
                                        <v-row>
                                            <v-col class="pa-4" cols="auto">
                                                Adresa artikla : {{baseUrl}} 
                                            </v-col>
                                            <v-col class="pa-0" cols="auto">
                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">     
                                                        <v-btn
                                                            class="ma-2"                                                                                                
                                                            variant="outlined"
                                                            v-bind="props"
                                                            @click="copyToClipboard"
                                                        >
                                                            
                                                            <v-icon>
                                                                mdi-content-copy
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                        <span>Kopiraj URL adresu artikla</span>
                                                </v-tooltip>                                                     
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-card class="wseditartikla-kratki-opis" elevation="0">
                                                <v-card-title>
                                                    {{produkti.NazivRoba}}
                                                </v-card-title>

                                                <v-card-text>
                                                <div v-if="produkti.Kratkiopisrobaprikazi == 1"  v-html=produkti.KratkiOpisRoba > </div> 

                                                <!--div v-html=produkti.OpisRoba > </div-->   
                                                </v-card-text>                                        
                                                <v-card-actions class="justify-start" >
                                                    <v-snackbar
                                                        :icon="ikonasnackbar"
                                                        v-model="alertsnackbar"
                                                        :multi-line="multiLine"
                                                        :color="colorsnackbar"
                                                        relative
                                                        centered
                                                        middle
                                                        >
                                                        <v-layout align-center pr-4>
                                                            <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
                                                            <v-layout column>
                                                            <div>
                                                                <strong>{{ snackbartitle }}</strong>
                                                            </div>
                                                            <div>{{ snackbartekst }}</div>
                                                            </v-layout>
                                                        </v-layout>
                                                        

                                                        <template v-slot:action="{ attrs }">
                                                            <v-btn
                                                            color="white"
                                                            text
                                                            v-bind="attrs"
                                                            @click="alertsnackbar = false"
                                                            >
                                                            <v-icon>clear</v-icon>
                                                            </v-btn>
                                                        </template>
                                                    </v-snackbar>     
                                                    <v-spacer></v-spacer>
        
                                                    <v-spacer></v-spacer>                                          
                                                </v-card-actions>



                                                

                                            </v-card>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="12" >

                                                <v-text-field
                                                    v-model="produkti.Kolicina"
                                                    label="Količina"
                                                    id="Kolicina-input"
                                                    name="Kolicina-input"
                                                    required
                                                    flat
                                                    type="number"
                                                    clearable
                                                    variant="outlined"
                                                    prepend-inner-icon="mdi-order-numeric-ascending"
                                                    style="width: 310px;">
                                                            <!-- Dugme unutar textfield-a koristeći prepend slot -->
                                                    <template v-slot:append>
                                                        <v-tooltip text="Tooltip">
                                                            <template v-slot:activator="{ props }">  
                                                                <v-btn
                                                                    class="ma-2"                                                                                                
                                                                    variant="outlined"
                                                                    v-bind="props"
                                                                    @click="$store.commit('dodajUCartIzEdita', produkti); obavijestHeading('Produkt je dodat u korpu!', 'success')"
                                                                >                                                                
                                                                    <v-icon>
                                                                        mdi-cart
                                                                    </v-icon>
                                                                </v-btn>
                                                            </template>
                                                                <span>Dodaj artikal u kart</span>
                                                        </v-tooltip> 

                                                        <v-tooltip text="Tooltip">
                                                            <template v-slot:activator="{ props }">                                                                                                                                                  
                                                                <v-btn
                                                                    class="ma-2"
                                                                    variant="outlined"
                                                                    v-bind="props"                                                    
                                                                    @click="$store.commit('dodajUKomparaciju', produkti); obavijestHeading('Produkt je dodat u komparaciju!', 'info')"
                                                                >
                                                                    
                                                                    <v-icon
                                                                    dark
                                                                    
                                                                    >
                                                                    mdi-compare-horizontal
                                                                    </v-icon>
                                                                </v-btn> 
                                                            </template>
                                                            <span>Dodaj u komparaciju</span>
                                                        </v-tooltip>                                                            
                                                    </template>
                                                    
                                                </v-text-field>  
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-col>                            
                            </v-row>   
                            <v-row>
                                <v-card elevation="0">
                                    <v-card-text>
                                        <div v-if="produkti.Opisrobaprikazi == 1" v-html=produkti.OpisRoba > </div> 
                                    </v-card-text> 
                                </v-card>

                            </v-row>                         
                            <v-row>
                                <v-card class="no-outline" elevation="0">
                                    <v-card-text>
                                        <Interaktivna-Mapa v-model="produktidetalji"></Interaktivna-Mapa>
                                    </v-card-text>
                            </v-card>
                            </v-row>                         

                        <!--/v-card-text-->
                    </v-card>

                    <v-card class="no-outline">
                        <v-tabs
                        v-model="tabs"
                        bg-color="green"
                        grow=""
                        >
                        <v-tab  value="specifikacija">Specifikacija</v-tab>
                        <v-tab value="srodniartikli">Srodni artikli</v-tab>

                        </v-tabs>

                        <v-card-text>
                        <v-window v-model="tabs">
                            <v-window-item value="specifikacija">
                                <v-card flat elevation="14">
                                    <v-card-text>
                                        <Produkt-Detalji
                                        v-model="produktidetalji"> 
                                        </Produkt-Detalji> 
                                        
                                    </v-card-text>
                                
                                </v-card>
                            </v-window-item>

                            <v-window-item value="srodniartikli">
                                <Srodni-Produkti 
                                :srodniprodukti="srodniprodukti"
                                >
                                </Srodni-Produkti>                            
                            </v-window-item>

                        </v-window>
                        </v-card-text>
                    </v-card>


                        
                </v-card>
            </v-card-text>
        </v-card>
    
    </v-dialog>  
    


</template>
<script>
import axios from "axios";
//import { bus } from '../../app.js'//--- busproba za vue2
import ProduktDetalji from './ProduktDetaljiWShop.vue'
import SrodniProdukti from './SrodniProdukti.vue'
import InteraktivnaMapa from './InteraktivnaMapaZona/InteraktivnaMapaZona.vue'

import Galerija from './Galerija/Galerija.vue'
import InteraktivnaMapaZona from "./InteraktivnaMapaZona/InteraktivnaMapaZona.vue";
//import { inject } from 'vue';


    export default {
        inject:["eventBus"],//bus mitt vue3
        //props: ['editProduktSlug'],  /* modaledit */
        props:{
            editProduktSlug:''
        },
        components: {

            'Produkt-Detalji' : ProduktDetalji,
            'Srodni-Produkti' : SrodniProdukti,
            'Galerija':Galerija,
            'Interaktivna-Mapa':InteraktivnaMapa
            
        },
        data: function () {
            return {



                tab: null,
                loading: false, //loader

                baseUrl:'',
                editproduktdialog:false, /* modaledit */
                publicSlug:'',
                //srproduktindex: null,
                tabs: null,
                cijene: [],
                kategorije: [],
                brandovi: [],
                /*produkti: {
                    Sifra:'',
                    Slug:'',
                    NazivRoba:'',
                    Datum:'',
                    Staracijena:0,
                    Cijena: 0,
                    Valuta: '',
                    Akcija: 0,
                    Akcijadatumstart: '',
                    Akcijadatumkraj: '',
                    Popust: 0,
                    Akcijatekst: '',
                    Freeshiping: 0,
                    Ribbon:0,
                    Ribbontext:'',
                    Prioritet: 0,
                    Topprodukt:0,
                    Cijenanaupit: 0,
                    Menukategorija: 0,
                    Kategorija: 0,
                    Subkategorija: 0,
                    SlikaString:'',
                    OpisRoba: '',
                    Kolicina:1
                },*/
                produkti:[],

                srodniprodukti:[],
                produktidetalji: [],
                images: [],
                loading: true,
                breadcrumbs: [
                    {
                    text: 'Home',
                    route: {
                        link: '#'
                    }
                    },
                    {
                    text: 'Category',
                    route: {
                        link: '#'
                    }
                    },
                    {
                    text: 'Pants',
                    route: {
                        link: '#'
                    }
                    }
                ],
                alert:false,
                alerttext:'',
                alertsnackbar:false,
                snackbartekst:'',
                snackbartitle:'',
                multiLine: true,
                ikonasnackbar:'',
                colorsnackbar:'',                            

            }
        },



        watch:{

            editProduktSlug:  function(newVal, oldVal) {   

                
                this.publicSlug=newVal;
                this.loadProduktiPics();
                this.loadProdukti();
                this.loadDetalji();
                this.editproduktdialog= true;
                this.vratiURL();
            
            },
   
        },

        created() { 
            

            
            
                // Pozovi proceduru za postavljanje publicSlug-a i sačekaj da se završi
            this.setPublicSlug()
                .then(() => {
                    // Nastavi sa daljim izvršavanjem koda
                    // Ovde možete postaviti kod koji zavisi od postavljanja publicSlug-a
                    ////this.$nextTick(this.loadProduktiPics());
                    //bus.$on('editproduktinstanca',(data)=>{this.posaljidata(data)}); bus za vue2
                    this.eventBus.on('editproduktinstanca',(data)=>{this.posaljidata(data)});//bus mitt vue3
                    this.loadProduktiPics();
                    this.loadProdukti();
                    this.loadDetalji();
                    this.vratiURL();

                })
                .catch((error) => {
                    // Ukoliko je došlo do greške, obradi je ovde
                    console.log('Error setting publicSlug:', error);
                });



           
        },
        beforeMount(){

        },
        mounted() {


            document.addEventListener('backbutton', function (e) {
                e.preventDefault();
                console.log('Back button prevented page from going back');
                // Put axios code here
            }, false);
        },

        beforeDestroy () {

        },

        computed: {            
        },
        methods: {
            copyToClipboard() {
                navigator.clipboard.writeText(this.baseUrl)
                    .then(() => {
                    // Prikazivanje obaveštenja da je URL uspešno kopiran
            
                    this.obavijestHeading("URL je uspešno kopiran u clipboard!", "info");
                    })
                    .catch(err => {
                    // Greška ako kopiranje nije uspelo
                    this.obavijestHeading("Došlo je do greške prilikom kopiranja.", "error");
                    });
            },

            // Procedura za postavljanje publicSlug-a
            setPublicSlug() {
                // Prikaz loadera
                this.loading = true;

                // Vrati novi Promise koji će se izvršiti kada se DOM procedura završi
                return new Promise((resolve, reject) => {
                    // Provjeri je li vue route prazan
                    if (!this.$route.params.editProduktSlug) {
                        // Pričekaj neko kratko vreme pre daljeg izvršavanja
                        setTimeout(() => {
                            // Provjeri postoji li element s id-jem 'divSlug'
                            const appElement = document.getElementById('divSlug');
                            if (appElement) {
                                // Ako postoji, pročitaj vrijednost iz dataset-a
                                this.publicSlug = appElement.dataset.editProduktSlug || '';
                                console.log('Vrijednost editProduktSlug preko adresbara:', this.publicSlug);
                                // Reši Promise ako je sve u redu
                                resolve();
                            } else {
                                console.log('Element with id divSlug not found.');
                                // Odbaci Promise ako element nije pronađen
                                reject(new Error('Element with id "divSlug" not found.'));
                            }
                        }, 100);
                    } else {
                        // Inače, koristi vrijednost iz vue route
                        this.publicSlug = this.$route.params.editProduktSlug;
                        console.log('Vrijednost editProduktSlug:', this.$route.params.editProduktSlug);
                        // Reši Promise jer je sve već postavljeno
                        resolve();
                    }
                }).finally(() => {
                    // Sakrij loader nakon završetka izvršavanja
                    this.loading = false;
                });
            },

            posaljidata(data){
                
                this.publicSlug=data.transproduktslug;
                this.loadProduktiPics();
                this.loadProdukti();
                this.loadDetalji();
                this.vratiURL();
                this.obavijestHeading('Produkt je promijenjen!','info');
                
            },
            vratiURL(){
                this.baseUrl = window.location.origin + '/url_wsprodukteditfront/' + this.publicSlug;
            },

            isprazniprodukte(){
                this.produkti = [];
                this.srodniprodukti = [];
                this.produktidetalji = [];
                this.images=[];
            },
            closeDialog(){
                this.isprazniprodukte();
                this.editproduktdialog = false;
                //this.$router.back();
                //this.$router.go(-1);
            },
            formatCurrency(amount) {
                //amount = (amount / 100);
                return amount.toLocaleString('en-US', { style: 'currency', currency: 'BAM' });
            },
            
            loadProdukti () {
               
                var param = this.publicSlug //this.$route.params.slug;    /* modaledit */
                axios.get('/api/apiwsprodukteditfront/'+param, {
                //axios.get('/web/webwsprodukteditfront/'+param, {
                    
                        //params 

                    })
                    .then((response) => {
                        this.produkti = response.data.data[0];
                        this.produkti.Kolicina=1;
                        //console.log('napunio produkte ' +  JSON.stringify(this.produkti, null, 2));

                        this.loadSrodniProdukti();
                        this.loading = false;


                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            loadSrodniProdukti(){

                var params = new URLSearchParams();
                    /*console.log('srodni produkti kategorija ' 
                    + this.produkti.Kategorija + ' cijena ' + this.produkti.Cijena)*/

                    params.append("kategorija", this.produkti.Kategorija);
                    params.append("cijena", this.produkti.Cijena);

                axios.get('/api/apiwssrodniprodukti', {
                        //params 
                        params: {
                            kategorija: this.produkti.Kategorija,
                            cijena: this.produkti.Cijena
                        }

                    })
                    .then((response) => {
                        this.srodniprodukti = response.data.data;
                        
                        this.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });                
            },
            
            loadDetalji: function () {

                var param = this.publicSlug //this.$route.params.slug;    /* modaledit */
                //console.log("route parametar "  + param);
                axios.get('/api/apiwsprodukteditfrontdetalji/'+param, {
                        //params 

                    })
                    .then((response) => {
                        this.produktidetalji = response.data.data[0];
                        if (response.data.greska==true){
                            this.obavijestHeading("Greška! Originalna greška: " + response.data.data, "error");
                        }
                        //console.log('napunio detalje ' +  JSON.stringify(this.produktidetalji, null, 2));


                        this.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },            

            async pozovislike() {
                // ...
                //let status = await this.loadProduktiPics();
            },
            
            async loadProduktiPics() {
                try {
                    var param = this.publicSlug;

                    const response = await axios.get('/api/apiwsprodukteditfrontpics/' + param);

                    //console.log('Vratio je response', response);

                    if (response.data && response.data.data && response.data.data.length !== 0) {
                        this.images = []; // Poništavamo trenutne slike
                        response.data.data.forEach((value) => {
                            if (value.Tip === "video") {
                                this.images.push({
                                    title: 'Neki naslov',
                                    content: 'This Youtube video has params in the URL and extra attributes on the iframe.',
                                    image: value.Src,
                                    thumb: value.Thumb,
                                    video: value.Src,
                                    tip: value.Tip,
                                    format: value.Format
                                });
                            } else {
                                this.images.push({
                                    title: 'Neki naslov',
                                    content: 'This Youtube video has params in the URL and extra attributes on the iframe.',
                                    image: window.location.origin + '/' + value.Src, //app_public_medija_path + value.Src
                                    thumb: window.location.origin + '/' + value.Thumb,
                                    tip: value.Tip,
                                    format: value.Format
                                });
                            }
                        });
                    }
                } catch (error) {
                    console.log('Greška slike', error);
                } finally {
                    this.loading = false;
                }
            },

            


            setAltImg(event) { 
                //event.target.src = "https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png" 
                event.target.src ="https://dummyimage.com/640x640"
            },
            
            ocistiprazneobjekte(obj) {
                for (var propName in obj) {
                    //console.log('objekat iz matrice ' + obj[propName] );
                    if (obj[propName] === null || obj[propName] === undefined) {
                    delete obj[propName];
                    }
                }

                for (var propName in obj) {
                    console.log('objekat iz matrice nakon brisanja ' + obj[propName] );
                    /*if (obj[propName] === null || obj[propName] === undefined || obj[propName] === empty) {
                    delete obj[propName];
                    }*/
                }

                return obj
            },

            zatvoriFormu (dialogprikaz, poruka) {

               
                this.eventBus.emit('kontejnerinstanca',{transproduktslug:'',transtabshow:false, transtabelashow:true}); //modrada je true za edit, false za add

            },

            async obavijestHeading(strTekst, tip){

                switch(tip) {
                case 'info':
                    this.ikonasnackbar="info";
                    this.colorsnackbar="info";
                    this.snackbartitle="Info"
                    break;
                case 'success':
                    this.ikonasnackbar="check_circle";
                    this.colorsnackbar="success";
                    this.snackbartitle="Obavijest"
                    break;
                case 'error':
                    this.ikonasnackbar="error";
                    this.colorsnackbar="error";
                    this.snackbartitle="Greška"
                    break; 
                case 'warning':
                    this.ikonasnackbar="warning";
                    this.colorsnackbar="warning";
                    this.snackbartitle="Upozorenje"
                    break;                                        
                default:

                }

                this.snackbartekst=strTekst;
                this.alertsnackbar=true;


            },               

            

        }
    }
</script>
<style>
/*
    .aktivnaSlika{
        width: 900px;
        height: 600px;
        

    }

    .aktivnaSlika img{
        width: 100%;
        height: 600px;
       object-fit: contain;
         
    }

    .skroler{
        width: 900px;
        display: flex;      
    }

    .skroler img{
        width:  80%;
        height: 100px;
        object-fit: cover;  
        margin: 15px;        
    }

    .active {
        border: 2px solid greenyellow;
    }*/

    .frontheader{
        font-weight: bold !important;
        font-size: 20px !important;
        color: white !important;
        background: linear-gradient(110deg, #0c0c0cbd 60%, rgb(137, 139, 138))!important;

         /*background: linear-gradient(110deg,  #3F4040 60%, #838485 60%);
       background: linear-gradient(110deg, #4A6572 60%, #4A6572 60%);*/
    }

    .dugmad {
        color: #344955 60% !important; /*primary (je za plavo)*/
    }


    .custom-tab {
        background-color: #3AAB39; /* Postavite željenu boju pozadine */
        color: white; /* Postavite željenu boju teksta */
}
</style>

