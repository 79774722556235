<template>

    <v-card>
        <v-card-text>
            Uputstvo: Kliknikte na srodni artikal da se pokažu detalji 
            <!--v-sheet
                    class="mx-auto"
                    elevation="8"
                    max-width="800"
                -->
            <v-sheet
                class="mx-auto"
                max-width="1000"
                >

                    <v-slide-group
                    v-model="srproduktindex"
                    class="pa-4"
                    center-active
                    show-arrows
                    >
                        <v-slide-group-item 
                        v-for="sprodukt in srodniprodukti "
                        :key="sprodukt.Slug"
                        v-slot="{ active, toggle }"
                        >

                            <v-card 
                            :color="active ? 'primary' : 'white'"
                            @click="toggle"
                            class="ma-2" max-width="200" >

                                <!--v-img class="white--text align-end" height="200px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"-->
                                <v-img class="white--text align-end" height="200px" :src="sprodukt.SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png">
                                    <v-card-title class="card_title_transparent_black">{{ sprodukt.NazivRoba }}</v-card-title>
                                </v-img>

                                <v-card-text class="text--primary">
                                Klikni na karticu za prikaz detalja
                                </v-card-text>

                                <v-card-actions>





                                <!--v-btn color="orange" text>
                                    Explore
                                </v-btn-->
                                </v-card-actions>
                                <v-row class="fill-height" align="center" justify="center">
                                <v-scale-transition>
                                    <v-icon  color="white" size="48" v-text="'mdi-close-circle-outline'"></v-icon>
                                </v-scale-transition>
                                </v-row>
                            </v-card>

                        </v-slide-group-item>
                    

                    </v-slide-group>

                    <v-expand-transition>
                        <v-sheet
                            v-if="srproduktindex != null"
                            tile
                            
                        >
                            <v-container fluid class="pa-12">
                                <v-row>
                                <v-card elevation="0">

                                    <v-card-actions class="justify-start" >

                                            <v-snackbar
                                            :icon="ikonasnackbar"
                                            v-model="alertsnackbar"
                                            :multi-line="multiLine"
                                            :color="colorsnackbar"
                                            relative
                                            centered
                                            middle
                                            >
                                            <v-layout align-center pr-4>
                                                <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
                                                <v-layout column>
                                                <div>
                                                    <strong>{{ snackbartitle }}</strong>
                                                </div>
                                                <div>{{ snackbartekst }}</div>
                                                </v-layout>
                                            </v-layout>
                                            

                                            <template v-slot:action="{ attrs }">
                                                <v-btn
                                                color="white"
                                                text
                                                v-bind="attrs"
                                                @click="alertsnackbar = false"
                                                >
                                                <v-icon>clear</v-icon>
                                                </v-btn>
                                            </template>
                                            </v-snackbar>                                        
                                            <v-btn
                                                class="ma-2"
                                                outlined
                                                color="primary"                                                        
                                                @click="$store.commit('dodajUKomparaciju', srodniprodukti[srproduktindex]); obavijestHeading('Produkt je dodat u komparaciju!', 'info')"
                                            >
                                                Kompariraj
                                                <v-icon
                                                dark
                                                
                                                >
                                                mdi-compare-horizontal
                                                </v-icon>
                                            </v-btn> 

                                            <v-btn
                                                class="ma-2"                                                                                                
                                                outlined
                                                color="primary"
                                                @click="$store.commit('dodajUCart', srodniprodukti[srproduktindex]); obavijestHeading('Produkt je dodat u korpu!', 'success')"
                                            >
                                                Dodaj u cart
                                                <v-icon>
                                                    mdi-cart
                                                </v-icon>
                                            </v-btn>  


                                            <v-btn
                                                class="ma-2"                                                                                                
                                                outlined
                                                color="primary"
                                                @click="otvoriprodukt(srodniprodukti[srproduktindex].Slug);"
                                            >
                                                Otvori produkt
                                                <v-icon>
                                                    mdi-cart
                                                </v-icon>
                                            </v-btn>                                                                                        
                                    </v-card-actions>
                                </v-card>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <span class="text-h6">{{ srodniprodukti[srproduktindex].NazivRoba }}</span> <br>                        
                                        <span class="text-subtitle-1">Cijena : {{ srodniprodukti[srproduktindex].Cijena }}</span>
                                         <div v-html=srodniprodukti[srproduktindex].OpisRoba > </div>   
                                        <!--p class="text-justify mt-4">
                                           
                                            {{  srodniprodukti[srproduktindex].OpisRoba }}
                                        </p-->
                                    </v-col>
                                    <v-col cols="12" sm="6" class="d-flex align-center justify-center">
                                        <div class="thumbnail">
                                            <img :src="srodniprodukti[srproduktindex].SlikaString"  style="width: 100%;" />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-sheet>
                    </v-expand-transition>
            </v-sheet>
        </v-card-text>
    </v-card>
</template>

<script>


//import { bus } from '../../app.js'//--- busproba vue2 


export default {
    inject: ["eventBus"],//bus vue3 mitt

    data() {
       return { 
        srproduktindex: null,
        alert:false,
        alerttext:'',
        alertsnackbar:false,
        snackbartekst:'',
        snackbartitle:'',
        multiLine: true,
        ikonasnackbar:'',
        colorsnackbar:'', 

       }
    },

    props: { 
        srodniprodukti:[]
    },

    components: {


    },

    computed:{
      
    },
    
    created(){

    },

    methods: {  
        setAltImg(event) { 
            event.target.src = "https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png" 
        },

        otvoriprodukt(slug){
            /*bus.$emit('kontejnerinstanca',{transproduktslug:produktslug, 
                                        transtabshow:true, transtabelashow:false}); */
            //bus.$emit('editproduktinstanca', {transproduktslug:slug}); vue2
            this.eventBus.emit('editproduktinstanca', {transproduktslug:slug}); //vue3 mitt bus

        },

        async obavijestHeading(strTekst, tip){

            switch(tip) {
            case 'info':
                this.ikonasnackbar="info";
                this.colorsnackbar="info";
                this.snackbartitle="Info"
                break;
            case 'success':
                this.ikonasnackbar="check_circle";
                this.colorsnackbar="success";
                this.snackbartitle="Obavijest"
                break;
            case 'error':
                this.ikonasnackbar="error";
                this.colorsnackbar="error";
                this.snackbartitle="Greška"
                break; 
            case 'warning':
                this.ikonasnackbar="warning";
                this.colorsnackbar="warning";
                this.snackbartitle="Upozorenje"
                break;                                        
            default:

            }

            this.snackbartekst=strTekst;
            this.alertsnackbar=true;


        },           
    }
}

</script>
<style >
.card_title_transparent_black{
    background-color:#070707!important;
    border-color: transparent!important;
    opacity: 0.65!important;
    font-weight: bold!important;
    color: white !important;;
  /*width: 30%;*/
}

</style>