<template>



    <!--div class="container" :class="{'loading': loading}"-->

        <!-- modaledit
        <v-modal-edit-produkt 
            v-model="prikazieditprodukt"
            :editProduktSlug=transSlug>
        </v-modal-edit-produkt> --> 
        
        <!-- div class="row" -->
    <v-card
        elevation="0">

        
        <v-card-actions>
            <v-snackbar
            :icon="ikonasnackbar"
            v-model="alertsnackbar"
            :multi-line="multiLine"
            :color="colorsnackbar"
            relative
            centered
            middle
            >
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
                <v-layout column>
                <div>
                    <strong>{{ snackbartitle }}</strong>
                </div>
                <div>{{ snackbartekst }}</div>
                </v-layout>
            </v-layout>
            

            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="alertsnackbar = false"
                >
                <v-icon>clear</v-icon>
                </v-btn>
            </template>
            </v-snackbar>


        
        </v-card-actions>
        <v-card-text>
            <v-row>
                <v-col  cols="12" md="2"  >
                    <!--div class="col-lg-3 mb-4"-->
             

                        <v-text-field
                            v-model="robaPretraga"
                            label="Pretraga"
                            id="txtSearch"
                            name="txtSearchinput"

                        
                            counter="50"
                            hint=" može sadržavati i slova i brojeve, npr. A0000123"               
                            @keyup.enter="pretraziProdukte(true)"
                            @click:append="pretraziProdukte(true)"
                            @click:clear="pretraziProdukte(true)"
                            flat
                            clearable
                            prepend-inner-icon="mdi-magnify"
                            variant="outlined"

                            
                        >
                            <template v-slot:append>


                                    
                                    <v-btn
                                        class="same-width-btn-50-search dugmad"
                                       
                                        
                                        variant="outlined"
                                        
                                        @click="pretraziProdukte(true)">  
                                        <v-icon                               
                                        dark
                                        >
                                        mdi-account-search
                                        </v-icon>                              
                                        
                                    </v-btn>     




                            </template>

                    
                        
                        </v-text-field>

                        <!--https://www.codeply.com/p/qzrKTPSzrB-->
                        <!--v-list>

                        
                            <v-list-group :value="true">
                                <template v-slot:activator="{ props }">
                                    <v-list-item v-bind="props" title="Kategorije"></v-list-item>
                                </template>  

                                <v-list-item  v-for="(group, i) in kategorijeitems" :key="i"
                                    v-model="selected.kategorijeitems"
                                    append-icon="fas fa-chevron-down fa-sm"
                                    :value="group.name"> 

                                    <v-list-item v-for="(child, j) in group.children" :key="j">
                                        <v-checkbox 
                                            :label="child.name" 
                                            v-model="selected.kategorijeitems" 
                                            :value="child.Sifra"
                                            @change="handleCheckboxChange"
                                            ></v-checkbox>
                                    </v-list-item>
                                    
                                </v-list-item>

        
                            
                            </v-list-group>
                        </v-list-->



                        <v-list   class="custom-list">
                            <v-list-group  :value="true" class="custom-list-group">
                                <template v-slot:activator="{ props }">
                                    <v-list-item  v-bind="props" class="custom-list-item" title="Kategorije"></v-list-item>
                                </template>

                                <v-list-item 
                                    v-for="(group, i) in kategorijeitems"
                                    :key="i"
                                    class="custom-group-item"
                                    
                                >
                                    <!--v-list-item-title class="custom-list-item-title">{{ group.name }}</v-list-item-title-->

                                    <div 
                                    v-for="(child, j) in group.children"
                                    :key="j"
                                    
                                   
                                    >
                                     
                                    <v-checkbox 
                                            :label="child.name" 
                                            v-model="selected.kategorijeitems" 
                                            :value="child.Sifra"
                                            @change="handleCheckboxChange"
                                            class="custom-checkbox"
                                            density="compact"
                                            inline
                                            color="info"
                                        ></v-checkbox>
                                    </div>
                                </v-list-item>
                            </v-list-group>
                        </v-list>

                        <v-list class="custom-list">

                            <v-list-group :value="true"  class="custom-list-group">
                                <template v-slot:activator="{ props }">
                                    <v-list-item class="custom-list-item" v-bind="props" title="Subkategorije"></v-list-item>
                                </template>  

                                <v-list-item class="custom-group-item"   v-for="(group, i) in subkategorijeitems" :key="i"
                                    v-model="selected.subkategorijeitems"
                                    append-icon="fas fa-chevron-down fa-sm"
                                    :value="group.name"> 

                                    <div v-for="(child, j) in group.children" 
                                    :key="j">
                                        
                                            <v-checkbox 
                                            :label="child.name" 
                                            v-model="selected.subkategorijeitems" 
                                            :value="child.Sifra"
                                            class="custom-checkbox"
                                            density="compact"
                                            inline
                                            color="success"
                                            ></v-checkbox>
                                        
                            </div>
                                    
                                </v-list-item>

        
                            
                            </v-list-group>

                        
                        </v-list>  
                        <v-list class="custom-list">

                            <v-list-group :value="true" class="custom-list-group">
                                <template v-slot:activator="{ props }">
                                    <v-list-item class="custom-list-item" v-bind="props" title="Subsubkategorije"></v-list-item>
                                </template>  

                                <v-list-item  
                                    v-for="(group, i) in subsubkategorijeitems" 
                                    :key="i"
                                    class="custom-group-item" 
                                    v-model="selected.subsubkategorijeitems"
                                    
                                    :value="group.name"
                                    > 

                                    <div v-for="(child, j) in group.children" 
                                    :key="j">
                                        
                                            <v-checkbox 
                                            :label="child.name" 
                                            v-model="selected.subsubkategorijeitems" 
                                            :value="child.Sifra"
                                            class="custom-checkbox"
                                            density="compact"
                                            inline
                                            color="error"
                                            ></v-checkbox>

                                    </div>
                                    
                                </v-list-item>



                            </v-list-group>


                        </v-list>                                        
                            <!--v-list dense v-model="selected.kategorijeitems" :prepend-icon="group.icon" :value="group.name" v-for="(group, i) in kategorijeitems" :key="i">
                                <v-list-subheader>{{ group.name }}</v-list-subheader>
                                <v-list-item v-for="(child, j) in group.children" :key="j">
                                    <v-checkbox :label="child.name" v-model="selected.kategorijeitems" :value="child.Sifra"></v-checkbox>
                                </v-list-item>
                            </v-list-->

                            <!--v-list dense v-model="selected.subkategorijeitems" :prepend-icon="group.icon" :value="group.name" v-for="(group, i) in subkategorijeitems" :key="i">
                                <v-list-subheader>{{ group.name }}</v-list-subheader>
                                <v-list-item v-for="(child, j) in group.children" :key="j">
                                    <v-checkbox :label="child.name" v-model="selected.subkategorijeitems" :value="child.Sifra"></v-checkbox>
                                </v-list-item>
                            </v-list-->


                        <!--            BRANDOVI 

                        <v-treeview
                            
                            selected-color="#344955 60% "
                            v-model="selected.branditems"
                            :items="branditems"
                            :selection-type="selectionType"
                            selectable                                
                            open-all  
                            open-on-click
                            transition                                                              
                        ></v-treeview-->

                        <!--v-treeview
                            selected-color="#344955 60% "
                            v-model="selected.kategorijeitems"
                            :items="kategorijeitems"
                            :selection-type="selectionType"
                            selectable                                
                            open-all                                                                
                        ></v-treeview>

                    
                        <v-treeview
                            selected-color="#344955 60% "
                            v-model="selected.subkategorijeitems"
                            :items="subkategorijeitems"
                            :selection-type="selectionType"
                            selectable                                
                            open-all     
                            open-on-click
                            transition                                                             
                        ></v-treeview-->
                    
                        <!-- h3 class="mt-2">Brand</h3>
                        <div class="form-check" v-for="(brand, index) in brandovi">
                            <input class="form-check-input" type="checkbox" :value="brand.Sifra" :id="'brand'+index" v-model="selected.brandovi">
                            <label class="form-check-label" :for="'brand' + index">
                                {{ brand.Sifra }}
                            </label>
                        </div>
                        <h3 class="mt-2">Kategorije</h3>
                        <div class="form-check" v-for="(kategorija, index) in kategorije">
                            <input class="form-check-input" type="checkbox" :value="kategorija.Sifra" :id="'kategorija'+index" v-model="selected.kategorije">
                            <label class="form-check-label" :for="'kategorija' + index">
                                {{ kategorija.Naziv }} ({{ kategorija.BrojProdukta }})
                            </label>
                        </div-->

                    <!--/div-->
                </v-col>
                <!--router-link
                    class="block relative h-48 rounded overflow-hidden"
                    :to="{name: 'carousels'}"
                    
                >
                    carousels link
                </router-link-->
                <!--router-view></router-view--> 

                    
                <v-col cols="12" md="10" >

                    <!-- ##### Produkti kartice START ##### -->  
                    <v-card class="no-outline" color="transparent">

                        <!--v-item-group mandatory-->
                            <!--v-container-->

                            
                            <v-row >
                                <template v-for="(produkt) in produkti" :key="produkt.Slug" >
                                    <v-col                                   
                                        cols="12"
                                        md="3"                            
                                    >
                                        
                                        <v-hover
                                            v-slot="{ isHovering, props }"
                                            open-delay="200"
                                        >
                                            <v-card 
                                            
                                                :elevation="hover ? 10 : 2"
                                                :class="{ 'wskartica': true, 'on-hover': isHovering}"
                                                v-bind="props"
                                                >

                                                <v-card class="wskartica-izgled no-outline">
                                                        <v-img
                                                    
                                                            :src="isHovering?produkt.Frontpagepicalt:produkt.Frontpagepic"
                                                            lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"
                                                            height="200px" 
                                                            width="100%"
                                                            :aspect-ratio="16/9"
                                                            class="  align-end"
                                                            cover
                                                            @click.stop="setTransSlug(produkt.Slug)"
                                                            >
                                                            <div class="wskartica-transparentna_traka">
                                                                <v-card-text class="wskartica-naslov-transparentna_traka">{{ produkt.NazivRoba.length > 28 ? produkt.NazivRoba.slice(0, 28) + "..." : produkt.NazivRoba }} </v-card-text>

                                                                <v-card-title v-if="produkt.Cijenanaupit == 0" class="wskartica-cijena-desno">Cijena: {{ produkt.Cijena }} {{ produkt.Valuta }}</v-card-title>
                                                                
                                                                <v-card-title v-else class="wskartica-cijena-desno" > Cijena na upit </v-card-title>
                                                            </div>
                                                        
                                                            <v-expand-transition>
                                                                <div
                                                                    v-if="isHovering"
                                                                    class="d-flex transition-fast-in-fast-out bg-grey-darken-2 v-card--reveal text-h2"
                                                                    style="height: 100%;"
                                                                >
                                                                Detaljnije
                                                                </div>
                                                            </v-expand-transition>
                                                        </v-img>

                                                            <!--v-row  class="px-4" style="min-height:75px;">
                                                                <v-col class="wskartica-centrirantext">
                                                                    <v-card-title class="wskartica-title">{{ produkt.NazivRoba }}</v-card-title>
                                                                    
                                                                    <v-card-subtitle class="wskartica-subtitle">{{ produkt.KategorijaNaziv }}</v-card-subtitle>
                                                                    <v-card-text class="wskartica-subtitle">{{ produkt.KratkiOpisRoba }}</v-card-text>

                                                                    <v-card-title v-if="produkt.Cijenanaupit == 0" class="wskartica-cijena">                                              
                                                                    </v-card-title>

                                                                    <v-card-title v-else class="wskartica-cijena" @click.stop="openInfoArtikl(produkt.Slug)">
                                                                        Cijena na upit
                                                                    </v-card-title>

                                                                </v-col>
                                                            </v-row-->
                                                    



                                                        <!-- Dodatni tekst ili opis -->
                                                        <!--
                                                        <v-card-text style="overflow-y: auto; height:280px; padding: 0px 15px;">
                                                            <div height="280">{{ produkt.OpisRoba }}</div>
                                                        </v-card-text>
                                                        <v-divider light></v-divider>
                                                        -->

                                                        <v-expand-transition>
                                                            <v-card class="no-outline" v-if="isHovering">
                                                                <!-- Akcije kartice -->
                                                                <v-row align="center" justify="space-between" class="px-8 py-2" >

                                                                

                                                                        

                                                                        
                                                                        <v-btn
                                                                            v-if="produkt.Cijenanaupit == 0"
                                                                            class="ma-2 wskartica-dugmad"
                                                                            variant="text"
                                                                            @click="$store.commit('dodajUKomparaciju', produkt); obavijestHeading('Produkt je dodat u komparaciju!', 'info')"
                                                                        >   
                                                                            <v-icon>mdi-compare-horizontal</v-icon>
                                                                        </v-btn>
                                                                    
                                                                        <v-btn
                                                                            v-else
                                                                            class="ma-2 wskartica-dugmad"
                                                                            variant="text"
                                                                            @click="$store.commit('dodajUKomparaciju', produkt); obavijestHeading('Produkt je dodat u komparaciju!', 'info')"
                                                                        >
                                                                            <v-icon>mdi-compare-horizontal</v-icon>
                                                                        </v-btn>
                    
                                                                        <hr class="wskartica-custom-line-vertikalna-kartica-2" />

                                                                        <v-btn
                                                                            v-if="produkt.Cijenanaupit == 0"
                                                                            class="ma-2 wskartica-dugmad"
                                                                            variant="text"
                                                                            @click="$store.commit('dodajUCart', produkt); obavijestHeading('Produkt je dodat u korpu!', 'success')"
                                                                        >
                                                                            <v-icon>mdi-cart</v-icon>
                                                                        </v-btn>
                                                                        
                                                                        <div v-if="produkt.Cijenanaupit == 0">
                                                                        <hr  class="wskartica-custom-line-vertikalna-kartica-2" />
                                                                        </div>
                                                                        
                                                                        <v-btn
                                                                            class="ma-2 wskartica-dugmad"
                                                                            variant="text"
                                                                            @click="setTransSlug(produkt.Slug)"
                                                                        >
                                                                            <v-icon>mdi-information-variant</v-icon>
                                                                        </v-btn>

                                                            
                                                                </v-row>


                                                            </v-card>
                                                        </v-expand-transition>                                                    
                                                </v-card>


                                            </v-card>
                                        </v-hover>
                                    
                                    </v-col>
                                </template>
                            </v-row>
                                


                            <!--/v-container-->
                        <!--/v-item-group-->
                    </v-card>
                    <!-- ##### Produkti kartice END ##### -->  

                    <!-- ##### Paginacija, load more kartica START ##### --> 
                    <v-card  color="transparent" class="justify-center no-outline">
                        <!-- LOAD MORE DUGME
                        <v-row align="center" justify="center" style="min-height:75px;">
                            <v-btn 
                                class="mx-2"
                                color="blue-grey" 
                                dark
                                small                             
                                width="100px"
                                @click="loadMore">
                                <v-icon>mdi-download</v-icon>
                                Load
                            </v-btn>
                        </v-row>
                        -->

                        <v-row align="center" justify="center" class="pt-6 pb-6">
                            <!--p>Page {{ pageNumber }} of {{ numOfPages }}</p-->
                            <v-pagination
                                class="dugme"
                                v-model="pageNumber"
                                :length="paginationSize"
                                :total-visible="visiblePages"
                                @input="setPage"
                                @update:model-value="setPage"
                                color="#344955"
                            ></v-pagination>
                        </v-row>
                    </v-card>
                    <!-- ##### Paginacija, load more kartica END ##### --> 


                </v-col>
            
            </v-row>
        </v-card-text>
        <!-- /div -->   
        <v-dialog 
            v-model="progressdialog"

            max-width="25%"
            >
        <!--v-dialog
            v-model="progressdialog"        
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        -->      
                   
            <v-card class="d-flex justify-center mb-6 noborderCardTransparent">

                    <v-progress-circular
                        :rotate="-90"
                        :size="150"
                        :width="15"
                        :value="value"
                        class="ma-2 dugmad"

                        >
                        {{ value }}
                    </v-progress-circular>    

            </v-card>
        </v-dialog>



    </v-card>


</template>

<script>
    import axios from "axios";
    //import { bus } from '../../app.js'//--- busproba
    import ModaleEditProdukt from './EditProduktWShop';
   

    export default {
        inject:["eventBus"],//bus mitt vue3
        components:{

           'v-modal-edit-produkt': ModaleEditProdukt,


        },

        static: { /* paginacija */
            visibleItemsPerPageCount: 4
        },
        data: function () {
            return {

                groupOpen: true,  // Dodato kako bismo kontrolisali otvorenost liste
                isHovered: false,  // Dodato stanje za hover
                isHovering: false,


                //loader
                progressdialog:false,
                interval: {},
                value: 0,
                loadovano:false,

                montirano:false,
                montirano1:false,
                montirano2:false,

                adialog:false,
                transSlug:'',
                prikazieditprodukt:false, /* modaledit */
                bSearchPrazan:true,
                //showModal: false,//MODAL 1
                length:4,
                cijene: [],

                kategorije: [],
                kategorijeitems: [],
                kategorijechild: [],                                
                
                subkategorije: [],
                subkategorijeitems: [],
                subkategorijechild: [],                                

                subsubkategorije: [],
                subsubkategorijeitems: [],
                subsubkategorijechild: [],                                

                brandovi: [],
                brandovichild:[],
                branditems:[],
                produkti: [],
                produktifull: [],
                loading: true,
                selected: {
                    cijene: [],
                    kategorije: [],
                    subkategorije: [],
                    subsubkategorije: [],
                    brandovi: [],
                    branditems:[],
                    kategorijeitems: [],
                    subkategorijeitems: [],
                    subsubkategorijeitems: []
                },
                selectionType: "leaf",/* v-tree vrsta selekcije */
                robaPretraga:'',

                /*paginacija */
                brojKartica: 12,
                visiblePages: 7,
                pageNumber: 1,
                //itemsShowed: [],
                offset: 0,
                limit: 0,
                numOfPages: 0,
                paginationSize: 0,

                alert:false,
                alerttext:'',
                alertsnackbar:false,
                snackbartekst:'',
                snackbartitle:'',
                multiLine: true,
                ikonasnackbar:'',
                colorsnackbar:'',


            }
        },
        created() {  
            //this.loadBrandoviTree();
        },

        async mounted() {
            this.pokrenitimeripopup();
            //this.loadBrandoviTree();
            this.loadKategorijeTree();
            this.loadSubkategorijeTree();
            this.loadSubSubkategorijeTree();
            await this.loadProdukti();            
        },
        watch: {
            selected: {
                handler: function () {
                    //this.loadBrandoviTree();
                    this.loadKategorijeTree();
                    this.loadSubkategorijeTree();
                    this.loadSubSubkategorijeTree();
                    this.loadProdukti();

                    //this.loadManufacturers();
                    //this.loadPrices();
                    
                },
                deep: true

            },



        },
        methods: {
            handleCheckboxChange() {
            // Metoda pozvana kada se promeni vrednost checkbox-a
            // Ova metoda je ovde da možeš dodati bilo kakvu dodatnu logiku ako je potrebna.
            this.groupOpen = true;  // Lista ostaje otvorena nakon selekcije
            },
            pokrenitimeripopup(){               
                if (this.loadovano==false){ 
                    this.progressdialog=true;
                    this.interval = setInterval(() => {
                        if (this.value === 100) {                            
                                return (
                                    this.value = 0,
                                    this.loadovano=true,
                                    this.progressdialog=false,
                                    clearInterval(this.interval)
                                )                                
                            }
                            this.value += 10
                        }, 500)                             
                }
            },

            loadBrandovi: function () {
                console.log('selektovani brandovi ' + this.selected['brandovi']);
                axios.get(app_public_path+'/api/apiwsbrandovisvefront', {
                        //params: this.selected
                    })
                    .then((response) => {
                        this.brandovi = response.data.data;

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            loadBrandoviTree: function () {
                //console.log('selektovani brandovitems ' + this.selected.branditems);
                axios.get(app_public_path+'/api/apiwsbrandovisvefront', {
                        //params: this.selected['branditems']
                    })
                    .then((response) => {

                        //this.branditems=response.data.data;
                        this.loadBrandoviTreeMontiranje(response.data.data);
                        this.branditems=[];
                        this.brandovichild=[];
                        var cnt =  Object.keys(response.data.data).length;

                        for (var i =0; i < cnt; i++ ){
                            this.brandovichild.push({
                                
                                Sifra: response.data.data[i].Sifra,
                                Naziv:response.data.data[i].Naziv,
                                Opis:response.data.data[i].Opis,
                                SlikaString:response.data.data[i].SlikaString,
                                Prioritet:response.data.data[i].Prioritet,
                                Aktivan:response.data.data[i].Aktivan,
                                Rb:response.data.data[i].Rb, 
                                name:response.data.data[i].Sifra,
                                id:response.data.data[i].Sifra 

                            });
                            
                            

                        };
                        
                       
                       this.branditems.push({id: 1, name: 'Brandovi', children: this.brandovichild})
                        


                    })

                    .catch(function (error) {
                        console.log(error);
                    });
            },

            loadBrandoviTreeMontiranje: function(data){
                if(this.montirano==true) return;
                var cArr=[];
                var cnt =  Object.keys(data).length;

                for (var i =0; i < cnt; i++ ){
                        //console.log("Brand ime:  " + data[i].Naziv);
                        cArr.push(data[i].Naziv);        
                    }
                this.selected.branditems= Array.from(cArr); 

            }, 

            loadKategorije: function () {
                //console.log('selektovani kategorije ' + this.selected['kategorije']);
                axios.get('/api/apiwskategorijesvefront', {
                        //params: _.omit(this.selected, 'kategorije')
                        //params: this.selected['kategorije']
                    })
                    .then((response) => {
                        this.kategorije = response.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            loadKategorijeTree: function () {
                //console.log('selektovani kategorije ' + this.selected.kategorijeitems);
                axios.get('/api/apiwskategorijesvefront', {
                        //params: _.omit(this.selected, 'kategorije')
                        //params: this.selected['kategorije']
                    })
                    .then((response) => {
                        //console.log("Odgovor loadKategorijeTree: ", JSON.stringify(response.data.data));
                        this.loadKategorijeTreeMontiranje(response.data.data);
                        this.kategorijeitems=[];
                        this.kategorijechild=[];
                        //var cnt =  Object.keys(response.data.data).length;
                        var cnt = response.data.data.length;

                        for (var i =0; i < cnt; i++ ){
                            this.kategorijechild.push({
                                
                                Sifra: response.data.data[i].Sifra,
                                Naziv:response.data.data[i].Naziv,
                                Opis:response.data.data[i].Opis,
                                SlikaString:response.data.data[i].SlikaString,
                                Prioritet:response.data.data[i].Prioritet,
                                Aktivan:response.data.data[i].Aktivan,
                                Rb:response.data.data[i].Rb, 
                                name:response.data.data[i].Naziv,
                                id:response.data.data[i].Sifra 

                            });
                            
                            
                        };
                      
                       this.kategorijeitems.push({id: 0, name: 'Kategorije', children: this.kategorijechild})
                       //this.selected.kategorijeitems.push(this.kategorijechild)
                    })
                    .catch(function (error) {
                        console.log('Greška u loadKategorijeTree: ' + error);
                    });
            },

            /*loadKategorijeTree: function () {
                
                axios.get('/api/apiwskategorijesvefront')
                    .then((response) => {
                        console.log("Odgovor loadKategorijeTree: ", JSON.stringify(response.data.data));
                        
                       
                        this.kategorijechild = response.data.data.map(item => {
                            return {
                                id: item.Sifra,
                                name: item.Naziv,
                                selected: false
                            };
                        });
                        
                       
                        this.kategorijeitems = [{
                            id: 0,
                            name: 'Kategorije',
                            children: this.kategorijechild
                        }];
                    })
                    .catch(function (error) {
                        console.log('Greška u loadKategorijeTree: ' + error);
                    });
            },*/            
                        

            loadKategorijeTreeMontiranje: function(data){
                if(this.montirano==true) return;
                var cArr=[];
                var cnt =  Object.keys(data).length;

                for (var i =0; i < cnt; i++ ){
                        console.log("Kategorija šifra:  " + data[i].Sifra);
                        cArr.push(data[i].Sifra); 
                           
                    }
                this.selected.kategorijeitems= Array.from(cArr); 
                this.montirano=true;

            }, 

            loadSubkategorijeTree: function () {
                //console.log('selektovani kategorije ' + this.selected.kategorijeitems);
                axios.get('/api/apiwssubkategorijesvefront', {
                        //params: _.omit(this.selected, 'kategorije')
                        //params: this.selected['kategorije']
                    })
                    .then((response) => {
                        //console.log("Odgovor loadSubkategorijeTree: ", JSON.stringify(response.data.data));
                        this.loadSubkategorijeTreeMontiranje(response.data.data);
                        this.subkategorijeitems=[];
                        this.subkategorijechild=[];
                        var cnt =  Object.keys(response.data.data).length;

                        for (var i =0; i < cnt; i++ ){
                            this.subkategorijechild.push({
                                
                                Sifra: response.data.data[i].Sifra,
                                Naziv:response.data.data[i].Naziv,
                                Opis:response.data.data[i].Opis,
                                SlikaString:response.data.data[i].SlikaString,
                                Prioritet:response.data.data[i].Prioritet,
                                Aktivan:response.data.data[i].Aktivan,
                                Rb:response.data.data[i].Rb, 
                                name:response.data.data[i].Naziv,
                                id:response.data.data[i].Sifra 

                            });

                            
                        };
                        
                        this.subkategorijeitems.push({id: 0, name: 'Subkategorije', children: this.subkategorijechild})
                    })
                    .catch(function (error) {
                        console.log('Greška u loadSubkategorijeTree: ' + error);
                    });
            },   

            loadSubkategorijeTreeMontiranje: function(data){
                if(this.montirano1==true) return;
                var cArr=[];
                var cnt =  Object.keys(data).length;

                for (var i =0; i < cnt; i++ ){
                        //console.log("Kategorija ime:  " + data[i].Sifra);
                        cArr.push(data[i].Sifra); 
                           
                    }
                this.selected.subkategorijeitems= Array.from(cArr); 
                this.montirano1=true;

            },  
            
            loadSubSubkategorijeTree: function () {
                //console.log('selektovani kategorije ' + this.selected.kategorijeitems);
                axios.get('/api/apiwssubsubkategorijesvefront', {
                        //params: _.omit(this.selected, 'kategorije')
                        //params: this.selected['kategorije']
                    })
                    .then((response) => {
                        //console.log("Odgovor loadSubkategorijeTree: ", JSON.stringify(response.data.data));
                        this.loadSubSubkategorijeTreeMontiranje(response.data.data);
                        this.subsubkategorijeitems=[];
                        this.subsubkategorijechild=[];
                        var cnt =  Object.keys(response.data.data).length;

                        for (var i =0; i < cnt; i++ ){
                            this.subsubkategorijechild.push({
                                
                                Sifra: response.data.data[i].Sifra,
                                Naziv:response.data.data[i].Naziv,
                                Opis:response.data.data[i].Opis,
                                SlikaString:response.data.data[i].SlikaString,
                                Prioritet:response.data.data[i].Prioritet,
                                Aktivan:response.data.data[i].Aktivan,
                                Rb:response.data.data[i].Rb, 
                                name:response.data.data[i].Naziv,
                                id:response.data.data[i].Sifra 

                            });

                            
                        };
                        
                        this.subsubkategorijeitems.push({id: 0, name: 'Subsubkategorije', children: this.subsubkategorijechild})
                    })
                    .catch(function (error) {
                        console.log('Greška u loadSubSubkategorijeTree: ' + error);
                    });
            },   

            loadSubSubkategorijeTreeMontiranje: function(data){
                if(this.montirano2==true) return;
                var cArr=[];
                var cnt =  Object.keys(data).length;

                for (var i =0; i < cnt; i++ ){
                        //console.log("Kategorija ime:  " + data[i].Sifra);
                        cArr.push(data[i].Sifra); 
                           
                    }
                this.selected.subsubkategorijeitems= Array.from(cArr); 
                this.montirano2=true;

            },              

            async loadProdukti() {
                console.log('tekst pretraga ' + this.robaPretraga);
                axios.get('/api/apiwsproduktisvefront', {
                        params:{ 
                            //"kategorije":this.selected["kategorije"], 
                            "kategorije":this.selected.kategorijeitems, 
                            "subkategorije":this.selected.subkategorijeitems, 
                            "subsubkategorije":this.selected.subsubkategorijeitems, 
                            //"brandovi":this.selected.branditems, 
                            "status":1, //samo aktivni                            
                            "pretraganaziv": this.robaPretraga
                            
                        }
                    })
                    .then((response) => {

                        /* LOAD MORE DUGME
                        this.produktifull = response.data.data;  
                        this.produkti=this.produktifull.slice(0,this.length); 
                        */           
                      
                        this.produktifull = response.data.data;
                        
                        this.setajKartice();/* paginacija */
                        
                        this.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            setajKartice(){ /* paginacija */
                
                this.offset = (this.pageNumber - 1) * this.brojKartica;
                this.limit = this.offset + this.brojKartica;
                this.numOfPages = Math.ceil(this.produktifull.length / this.brojKartica);
                if (this.offset > this.produktifull.length) {
                    this.pageNumber = this.numOfPages;
                }

                this.produkti = this.produktifull.slice(this.offset, this.brojKartica + this.offset);                        


                this.paginationSize = Math.ceil(this.produktifull.length / this.brojKartica);
                
            },

            setPage(n) { /* paginacija */
                console.log('okida set page');
                this.pageNumber = n;
                this.setajKartice();
                return this.produkti;
            },
            
            loadMore: function(){/* load more */
                if (this.length > this.produkti.length) return;
                this.length = this.length + 3;
                //this.loadKategorije();
                this.loadProdukti();

            },
            
            async pretraziProdukte(blur){

                await this.loadProdukti();
                return;

                if (!this.robaPretraga) //=""
                {
                    console.log('prazna robapretraga');
                    if(!blur){ //=false
                        //reset pretrage
                        await this.loadProdukti();        
                    }

                    return;
                }
                
                console.log('nije prazna robapretraga');
                if(blur) {
                    await this.loadProdukti();
                }

            },

            /*loadManufacturers: function () {
                axios.get('/api/manufacturers', {
                        params: _.omit(this.selected, 'manufacturers')
                    })
                    .then((response) => {
                        this.manufacturers = response.data.data;
                        this.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            loadPrices: function () {
                axios.get('/api/prices', {
                        params: _.omit(this.selected, 'prices')
                    })
                    .then((response) => {
                        this.prices = response.data;
                        this.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }*/

            setAltImg(event) { 
                event.target.src = "https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png" 
            },

            setTransSlug(slug){ /* modaledit */
                //console.log('promjena slug ' + slug);
                this.transSlug = slug;
                this.prikazieditprodukt=true;
                this.posaljikontejneru(this.transSlug)
            },

            posaljikontejneru( produktslug) {
                this.eventBus.emit('kontejnerinstanca',{transproduktslug:produktslug, 
                                            transtabshow:true, transtabelashow:true}); //modrada je true za edit, false za add
            },
            openInfoArtikl(slug){
                
                alert('Info za artikal-slug ' + slug);

            },
            async obavijestHeading(strTekst, tip){

                switch(tip) {
                case 'info':
                    this.ikonasnackbar="info";
                    this.colorsnackbar="info";
                    this.snackbartitle="Info"
                    break;
                case 'success':
                    this.ikonasnackbar="check_circle";
                    this.colorsnackbar="success";
                    this.snackbartitle="Obavijest"
                    break;
                case 'error':
                    this.ikonasnackbar="error";
                    this.colorsnackbar="error";
                    this.snackbartitle="Greška"
                    break; 
                case 'warning':
                    this.ikonasnackbar="warning";
                    this.colorsnackbar="warning";
                    this.snackbartitle="Upozorenje"
                    break;                                        
                default:

                }

                this.snackbartekst=strTekst;
                this.alertsnackbar=true;


            },



        }
    }
</script>

<style>
/*
.centrirano{
  position: fixed; 
  top: 50%;
  left: 50%;
}

.noborderCard{
    border-style: none !important;
    
    
}

.noborderCardTransparent{

   background-color: rgba(255, 255, 255, 0.7) !important;
   border-style: none !important;

}

.dugmad {
    color: #344955 60% !important; 
}*/

</style>