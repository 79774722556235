<template>



    <v-dialog
        v-model="localDialog"

        persistent
        
        scrollable
        width="auto"
        
    >
    
    <v-card  
        class="v-card--pozadina-formi-zelena"
    >
        <v-btn icon @click="zatvoriFormu (true, 'odustao')" >
            <v-icon>mdi-close</v-icon>
        </v-btn> 

        <v-card-title >
            Unos i izmjena zaglavlja narudžbe

        </v-card-title>
        <v-card-text class="v-card-text-bg_bijelo-fr_crno">
            <v-card-text>
        


            
                    <v-row>    
                            <v-col
                            cols="12"
                            sm="2"
                            
                            >
                            <v-text-field
                                v-model="narudzba.Id"
                                label="Narudzba ID"
                                id="narudzbabr-input"
                                name="narudzbabr-input"
                                required
                                type="number"
                                hint="Broj narudzbe je obavezan za unos, predloženi broj je sljedeći broj iz baze."               
                                flat
                                clearable
                                :rules="numberRules" 
                                disabled
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"

                            ></v-text-field>
                            </v-col>

                        

                            <v-col
                            cols="12"
                            sm="5"
                            
                            >
                            <v-text-field
                                v-model="narudzba.Narudzba"
                                label="Broj narudžbe"
                                id="narudzba-input"
                                name="narudzba-input"
                                required
                                counter="200"
                                hint="Opisni broj narudžbe može biti u formatu: 0001"               
                                flat
                                clearable
                                disabled  
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"                       
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="5"
                            
                            >
                            <v-text-field
                                v-model="narudzba.UserID"
                                label="UserID"
                                id="UserID-input"
                                name="UserID-input"
                                required
                                counter="200"
                                hint="UserID je id korisnika u tabeli korisnici"               
                                flat
                                clearable
                                disabled   
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"                      
                            ></v-text-field>
                            </v-col>                    
                
                    </v-row>  

                    <v-row>
                            <v-col
                            cols="12"
                            sm="6"
                            
                            >
                                <LookUp-Kupci
                                v-model="narudzba.UserID" 
                                :publicSifraRS="publicTransDialogSifraKupac"
                                :publicEdit="disableKupac"
                                @updateRS="updateKupac"
                                ></LookUp-Kupci>


                            </v-col>
                            <v-col
                            cols="12"
                            sm="6"                                        
                            >                       

                                <v-menu
                                
                                v-model="menu1"
                                :close-on-content-click="false"
                                
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                >
                                <template v-slot:activator="{props }">
                                    <v-text-field
                                    v-model="formattedDate1"
                                    label="Datum narudzba"
                                    prepend-inner-icon="mdi-calendar"
                                    variant="outlined" 
                                    v-bind="props"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                v-model="odabraniDatum1"
                                @input="menu1 = false"
                                >

                                </v-date-picker>
                                </v-menu>                                       
                            </v-col>  


                    </v-row>     
                    <v-row>
                            <v-col
                            cols="12"
                            sm="12"
                            >
                                <v-expansion-panels focusable >

                                    <v-expansion-panel                     
                                    >
                                        <v-expansion-panel-title 
                                            color="gray darken-19"
                                            class="white--text">
                                            Detalji o kupcu, adresi i mjestu dostave narudžbe
                                        </v-expansion-panel-title>
                                        
                                        <v-spacer></v-spacer>
                                        <v-expansion-panel-text  >
                                            <v-card elevation="0">
                                                <v-card-text>
                                                    <Web-Narudzbe-Kupac
                                                        :kupac="kupacinfo"
                                                        @novi-kupac-dodan="handleNoviKupacDodan"
                                                    ></Web-Narudzbe-Kupac>
                                                </v-card-text>

                                            </v-card>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>

                                    <v-expansion-panel                     
                                    >
                                        <v-expansion-panel-title 
                                            color="gray darken-1"
                                            class="white--text">
                                            Detalji o alternativnom primaocu pošiljke, adresi i mjestu dostave narudžbe
                                        </v-expansion-panel-title>
                                        
                                        <v-spacer></v-spacer>
                                        <v-expansion-panel-text  >
                                            <v-card elevation="0">
                                                <v-card-text>
                                                    <Web-Narudzbe-altKupac
                                                        :altkupac="altkupacinfo"
                                                        :imaaltkupac="imaaltkupac"
                                                    ></Web-Narudzbe-altKupac>
                                                </v-card-text>

                                            </v-card>
                                        </v-expansion-panel-text>
                                    </v-expansion-panel>


                                </v-expansion-panels>

                            </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        
                        >
                        <v-text-field
                            v-model="narudzba.StripeID"
                            label="Stripe ID"
                            id="stripeid-input"
                            name="stripeid-input"
                            required
                            counter="200"
                            hint="Stripe ID"               
                            flat
                            clearable
                            :disabled="!editmod?false:true"
                            variant="outlined"
                            prepend-inner-icon="mdi-order-alphabetical-ascending"                         
                        ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        
                        >
                        <v-text-field
                            v-model="narudzba.Utime"
                            label="Unix time"
                            id="Utime-input"
                            name="Utime-input"
                            required

                            hint="Vrijeme narudžbe"               
                            flat
                            clearable
                            :disabled="!editmod?false:true"    
                            variant="outlined"
                            prepend-inner-icon="mdi-order-numeric-ascending"              
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>

                            <v-col
                            cols="12"
                            sm="6"
                            
                            >
                            <v-text-field
                                v-model="narudzba.Iznos"
                                label="Iznos narudžbe"
                                id="iznos-input"
                                name="iznos-input"
                                required
                                type="number"
                                flat
                                clearable
                                :disabled="!editmod?false:true" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"                             
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="6"
                            
                            >
                            <v-text-field
                                v-model="narudzba.Nacinplacanja"
                                label="Nacin plaćanja"
                                id="iznos-input"
                                name="iznos-input"
                                required
                                :disabled="!editmod?false:true"     
                                flat
                                clearable
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"                      
                            ></v-text-field>
                            </v-col>

                    </v-row>
                    <v-row>
                            <v-col
                            cols="12"
                            sm="8"
                            
                            >
                                                    
                                <v-textarea
                                v-model="narudzba.Komentar"

                                    clearable
                                    clear-icon="fa-close"
                                    label="Komentar"
                                    value=""
                                    hint=""
                                    counter="500"
                                    rows="3"
                                    variant="outlined"
                                    prepend-inner-icon="mdi-comment-text-outline"
                                ></v-textarea>  

                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"            
                            >                  
                                <v-checkbox
                                v-model="chkenabled"
                                hide-details
                                
                                label="Web plaćanje"
                                >
                                </v-checkbox>
                            </v-col>                    
                    </v-row>
                    <v-row>


                        <v-col
                            cols="12"
                            sm="3"            
                        >                  
                            <v-checkbox
                            v-model="chkenabled1"
                            hide-details
                            
                            label="Poslano"
                            >
                            </v-checkbox>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="3"            
                        >                  
                            <v-checkbox
                            v-model="chkenabled2"
                            hide-details
                            
                            label="Dostavljeno"
                            >
                            </v-checkbox>
                        </v-col>
    
                        <v-col
                            cols="12"
                            sm="3"            
                        >                  
                            <v-checkbox
                            v-model="chkenabled3"
                            hide-details
                            
                            :label="!chkenabled3?'Fakturiši':'Fakturisano'"
                            >
                            </v-checkbox>
                        </v-col>   

                        <v-col
                        cols="12"
                        sm="3"
                        
                        >
                        
                        <v-text-field
                            v-model="narudzba.FakZag"
                            label="Broj fakture"
                            id="fakture-input"
                            name="fakture-input"
                            required
                            hint="Broj fakture"               
                            flat
                            clearable
                            disabled
                            variant="outlined"
                            prepend-inner-icon="mdi-order-numeric-ascending"
                        ></v-text-field>
                        </v-col>  
                    </v-row>

                    


                


            
                
            </v-card-text>
            <v-card-actions>
                        <v-col
                        cols="12"
                        sm="6"
                        
                        > <small>*indikacija za obavezno polje</small> </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        class="text-end"
                        > 
                            <v-btn

                                class="flex-grow-1 text-none"
                                color="green-darken-2"
                                rounded="0"
                                variant="outlined"
                                prepend-icon="mdi-content-save-all" 
                                @click=" upisinarudzba()"

                                >
                                SAČUVAJ PROMJENE ZAGLAVLJA NARUDŽBE
                            </v-btn>                                  
                        </v-col>
                    
            </v-card-actions>
            <v-card-text         
            >
                    
                        <v-card-title >
                            Pregled stavki narudžbe
                        </v-card-title>

                        <Narudzbe-Stavke-Tab
                            :stavkedialog="stavkedialogovde"
                            :brojnarudzba="transbrojnarudzba3"
                            @w_javiosataba="javiosataba"
                        ></Narudzbe-Stavke-Tab>

            </v-card-text>
        </v-card-text>


        <v-card-actions variant="tonal">
            <v-card class="mx-auto" >
                <v-alert 
                v-model="alert"
                type="error">
                {{alerttext}}
                </v-alert>

                <v-snackbar
                v-model="alertsnackbar"
                :icon="ikonasnackbar"
                :color="colorsnackbar"
                vertical
                transition="slide-y-transition"
                >
                <v-row justify="space-around">
                    <v-icon  size="large" :icon="ikonasnackbar"></v-icon>
                </v-row>

                <div >
                    <strong>{{ snackbartitle }}</strong>
                </div>
                <p>{{ snackbartekst }}</p>
                    
                
                <template v-slot:actions>
                    <v-btn
                    color="white"
                    variant="text"
                    
                    @click="alertsnackbar = false"
                    >
                    Zatvori
                    </v-btn>
                </template>
                </v-snackbar>

                <v-spacer></v-spacer>
                <v-row>
                    <v-col
                                cols="12"
                                sm="6"
                                class="text-center"
                                > 

                        <v-btn

                            class="same-width-btn text-none"
                            color="green-darken-2"
                            rounded="0"
                            variant="outlined"
                            prepend-icon="mdi-content-save-all" 
                            @click=" upisinarudzba()"

                            >
                            UPIŠI ZAGLAVLJE 
                        </v-btn>      
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="text-center"
                        > 
                            <v-btn
                                
                                class="same-width-btn text-none"
                                color="red-darken-2"
                                rounded="0"
                                variant="outlined"
                                prepend-icon="mdi-exit-to-app" 
                                @click="zatvoriFormu (true, 'odustao')"
                            
                            >
                            ZATVORI FORMU
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-card-actions>   
  







    </v-card>
       



   
   
            <!--v-row>
            <v-card elevation="0">
            
                <v-card-title >
                
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="zatvoriFormu (true, 'odustao')"
                        
                        >
                        ZATVORI FORMU
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row-->


   


    </v-dialog>





</template>

<script>
import LookupRoba from '../Registarroba/RobaLookUp.vue';
import LookupKupci from '../Registarlica/KupciLookUp.vue';
//import WebNarudzbeKupac from '../WebShopNarudzbe/WebNarudzbeKupac.vue';
//import WebNarudzbealtKupac from '../WebShopNarudzbe/WebNarudzbeAltKupac.vue';
import WebNarudzbeKupac from '../Registarlica/WebKupac.vue';
import WebNarudzbealtKupac from '../Registarlica/WebAltKupac.vue';

import WebShopNarudzbeStavkeTab from '../WebShopNarudzbe/WebShopNarudzbeStavkeTab_implement.vue'
import VueHtml2pdf from 'vue-html2pdf'
import axios from 'axios';
import javnefunkcije from '../../javnefunkcije'

export default {


  data() {
    return {

      localDialog: this.adialog,  

      submitted: false,
      svinalozikontrola: [],
      svinalozi: [],
      publicTransDialogSifraKupac:'',
      /*narudzba: {
                brojzag: 0,
                brojzagstr:'', 
                sifrarobe:'', 
                nazivrobe:'', 
                regbrojrobe:'', 
                garantnilist:'',
                datumgarantnilist:'',
                datumistekagarantnilist:'',
                garancija:0,
                trgovac:'',
                trgovacnaziv:'',
                datumnarudzba:'',
                komentar:'',
                brojradnihsati:0,
                zakljucen:0
            },*/
      Barkodovi:[],
      cmbStrSearch:"",
      Pretraga:'',
      PretragaSifra:'',
      Sifra: '',
      pagination: {},
      
      disableKupac:false,

      transbrojnarudzba3:'',

      isChecked:false, 
      parentsifrarobe:'',

       kupacinfo: {
            Sifra:0,
            Naziv:'',
            Ime:'',
            Prezime:'',
            Email:'',
            Telefon:'',
            Adresa:'',
            Grad:'',
            Drzava:'',
            ZipCode:'',
            UserID:'',
            Pravno:0,
            IDbroj:'',
            PDVbroj:'',
            Komentar:''
        },

       kupacinfodefault: {
            Sifra:0,
            Naziv:'',
            Ime:'',
            Prezime:'',
            Email:'',
            Telefon:'',
            Adresa:'',
            Grad:'',
            Drzava:'',
            ZipCode:'',
            UserID:'',
            Pravno:0,
            IDbroj:'',
            PDVbroj:'',
            Komentar:''
        },

        altkupacinfodefault: {
            Id:0,
            Narudzba:'',
            Naziv:'',
            Ime:'',
            Prezime:'',
            Email:'',
            Telefon:'',
            Adresa:'',
            Grad:'',
            Drzava:'',
            ZipCode:'',
            UserID:'',
            Pravno:0,
            IDbroj:'',
            PDVbroj:'',
            Komentar:''
        },

        altkupacinfo: {
            Id:0,
            Narudzba:'',
            Naziv:'',
            Ime:'',
            Prezime:'',
            Email:'',
            Telefon:'',
            Adresa:'',
            Grad:'',
            Drzava:'',
            ZipCode:'',
            UserID:'',
            Pravno:0,
            IDbroj:'',
            PDVbroj:'',
            Komentar:''
        },

        narudzbadefault:{
            Id: 0,
            UserID:'', 
            StripeID:'', 
            Narudzba:'',
            Iznos:'', 
            Datum:'', 
            Utime:'', 
            WebPay:'',
            Nacinplacanja:'',
            Poslano:0,
            Isporuceno:0,
            Fakturisano:0,
            FakZag:0,
            Komentar:''           
        },

        imaaltkupac:false,

        /* ================= */
        /*    stavke tab     */
        /* ================= */
        stavkedialogovde:true,
        //transbrojnarudzba:'',
        /* ================= */

      //====== za vuetify kontrole

      date: new Date(), //new Date().toISOString().substr(0, 10),
      odabraniDatum1: null,

      alert:false,
      alerttext:'',
      alertsnackbar:false,
      snackbartekst:'',
      multiLine: true,
      ikonasnackbar:'',
      colorsnackbar:'',   
      snackbartitle:'', 

      menu1: false,
      menu2: false,
      menu3: false,

      cmbSearch:'',
      //adialog:false,
      dialogDelete: false,
      deletenarudzba:'',

      chkenabled: false,
      chkenabled1:false,
      chkenabled2:false,
      chkenabled3:false,

      sifrarules: [
        v => !!v || 'Šifra je obavezna!',
        v => (v && v.length <= 50) || 'Šifra mora biti kraća od 50 karaktera.',
      ],
      nazivrules: [
        v => !!v || 'Naziv je obavezan!',
        v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
      ],
      trirules: [
        v => !!v || 'Vrijednost je obavezna!',
        v => (v && v.length <= 3) || 'Unesena vrijednost mora biti kraći od 3 karaktera.',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      numberRules:[
            v => !!v || 'Vrijednost je obavezna!',
        ],
     
    //====== za vuetify kontrole kraj

    };
  },
    props: {
        narudzba:{
            Id: 0,
            UserID:'', 
            StripeID:'', 
            Narudzba:'',
            Iznos:'', 
            Datum:'', 
            Utime:'', 
            WebPay:'',
            Nacinplacanja:'',
            Poslano:0,
            Isporuceno:0,
            Fakturisano:0,
            FakZag:0,
            Komentar:''           
        },

        transbrojnarudzba2:'',

        adialog:false,
        value: Boolean,
        editmod: Boolean,
        publicTransDialogSifraRoba:'',

        dialnarudzba: {
            Id: 0,
            UserID:'', 
            StripeID:'', 
            Narudzba:'',
            Iznos:'', 
            Datum:'', 
            Utime:'', 
            WebPay:'',
            Naciplacanja:'',
            Poslano:0,
            Isporuceno:0,
            Fakturisano:0,
            FakZag:0,
            Komentar:''  
        },


    },

    components:{
        //'LookUp-Roba':LookupRoba,
        'LookUp-Kupci':LookupKupci,
        'Web-Narudzbe-Kupac': WebNarudzbeKupac,
        'Web-Narudzbe-altKupac': WebNarudzbealtKupac,
        'Narudzbe-Stavke-Tab': WebShopNarudzbeStavkeTab

    },

    computed:{
        formattedDate1() {
            return this.odabraniDatum1 ? this.formatDate(new Date(this.odabraniDatum1)) : this.computedDateFormatted1();
        },
     
        /*
        computedDateFormatted1() {

            if (this.editmod==true){ //update
                this.disableKupac=true;
                return this.formatDate(this.narudzba.Datum);
            } 
            else 
            { //add
                this.disableKupac=false;
                //return this.narudzba.Datum=this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),);
                return this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),);
            }            
            
        }
        */

       
    },


    watch:{
        odabraniDatum1(newDate) {
            // Update produkt.Datum whenever odabraniDatum1 changes
            this.narudzba.Datum = this.formatDateForMySQL(new Date(newDate));
        },

        adialog(newVal) {
                this.localDialog = newVal;
                },

        localDialog(newVal) {
                this.$emit('update:adialog', newVal);
                },
              
        /*
        narudzba: function(newVal) {

           
            this.transbrojnarudzba=newVal.Narudzba;

            this.chkenabled=false;
            if(newVal.WebPay==1)
            {this.chkenabled=true;}

            this.chkenabled1=false;
            if(newVal.Poslano==1)
            {this.chkenabled1=true;}

            this.chkenabled2=false;
            if(newVal.Isporuceno==1)
            {this.chkenabled2=true;}


            this.publicTransDialogSifraKupac='';
            

            if(newVal.UserID!=""){
                this.publicTransDialogSifraKupac=newVal.UserID;
                this.updateKupac(newVal.UserID,"");
            }

            
            
        },*/
        transbrojnarudzba2:function(newVal){
                
                this.transbrojnarudzba3=newVal;//šalje na implementiran tabstavke
        },
        narudzba:function(newVal){



                this.chkenabled=false;
                if(newVal.WebPay==1)
                {this.chkenabled=true;}

                this.chkenabled1=false;
                if(newVal.Poslano==1)
                {this.chkenabled1=true;}

                this.chkenabled2=false;
                if(newVal.Isporuceno==1)
                {this.chkenabled2=true;}


                this.chkenabled3=false;
                if(newVal.Fakturisano==1)
                {this.chkenabled3=true;}

                this.publicTransDialogSifraKupac='';
                

                if(newVal.UserID!=""){
                    this.publicTransDialogSifraKupac=newVal.UserID;
                    this.updateKupac(newVal.UserID,"");
                }
          
            
            
        },        


      
  
    },  

    mounted() {
        //function here

    },

    created() { 
           //alert("created");
        //this.checkAddStatus();
        this.aktivirajcheckove();
 
    },

    methods: {

        computedDateFormatted1() {

            if (this.editmod==true){ //update
                this.disableKupac=true;
                return this.formatDate(new Date(this.narudzba.Datum));
            } 
            else 
            { //add
                this.disableKupac=false;
                //return this.narudzba.Datum=this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),);
                //return this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),);
                return this.formatDate(new Date());
            }            

            },
        // Format date to yyyy-mm-dd for MySQL
        formatDateForMySQL(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        aktivirajcheckove(){
                      
            var stat= this.narudzba.WebPay;

            this.chkenabled=false;
            if (stat==1){
                this.chkenabled=true;
            }
            
        },



        updateRoba (transsifra,transnaziv){
            
                
            this.narudzba.sifrarobe=transsifra;
            this.narudzba.nazivrobe=transnaziv;
            //console.log ('nalozitabela roba je updeatovana : '+ transsifra + " naziv: " + transnaziv)
        },

        async updateKupac (sifra,naziv){
           // this.obavijest("ušo u update kupac " +sifra ,'info');
            this.narudzba.UserID=sifra;
            //this.popunikupca(this.narudzba.UserID);
            this.popunikupca(sifra);

            if(this.editmod==false)
            {
                //ako je add
                //daj max id narudzbe, kreiraj brojnarudzbe = userid + maxidnarudzbe
                try {

                        let putanja = "/api/apifunkcijeGetMaxBroj/wshop_narudzbe/Id";
                        
                        const res = await fetch(putanja, {
                            method: 'get'
                            });

                        const resdata = await res.json();

                        //console.log ("vratio je " + resdata.retmaxsifra);              
                        
                        if(resdata.greska==true){
                            this.obavijest("Došlo je do greške u dohvaćanju maksimalnog broja narudžbe! Proces je obustavljen",'error');
                            return;
                        }
                        
                        this.narudzba.Id=resdata.retmaxsifra;
                        this.narudzba.Narudzba=this.narudzba.UserID+this.narudzba.Id;
                      
                        
                        return ;

                    }catch(e) 
                    {
                        this.obavijest("Došlo je do greške u dohvaćanju maksimalnog broja narudžbe! Proces je obustavljen. Originalna greška " + e.toString(),'error');
                        return;

                    }                


                
            }            
            //console.log ('nalozitabela kupac je updeatovana : '+ newData)
        },

        handleNoviKupacDodan(noviKupac){                        
            this.updateKupac(noviKupac.data.UserID,"");
        },

        popunialternativnogkupca(){
 


            axios.get('/api/apialtkupac/'+this.narudzba.Narudzba, {

              })
              .then((response) => {
                
                    if(response.data.greska==true){
                        
                        
                        this.altkupacinfo.UserID=this.narudzba.UserID;
                        this.altkupacinfo.Narudzba=this.narudzba.Narudzba;
                        return;
                    }
                    this.imaaltkupac=true;
                    this.altkupacinfo = response.data.data;
                        //console.log("vratio vrijednost " + this.altkupacinfo.UserID + " " + this.altkupacinfo.Narudzba);
                  
              })
              .catch(function (error) {
                   
                        this.altkupacinfo.UserID=this.narudzba.UserID;
                        this.altkupacinfo.Narudzba=this.narudzba.Narudzba;
                        this.obavijest("Došlo je do greške popunjavanja altkupca! " + error.toString(),'error')
                  
              });
        },

        popunikupca(UserID){


            if(UserID==""){
                //isprazni matrice kupaca i altkupaca jer je add mode
                this.kupacinfo = Object.assign({}, this.kupacinfodefault);
                this.altkupacinfo = Object.assign({}, this.altkupacinfodefault)
                return;
            }

            axios.get('/api/apikupac/'+ UserID, {
                  params:{                            
                  }
              })
              .then((response) => {
                this.kupacinfo = response.data.data;
                
                  
                  try {
                        //this.popunialternativnogkupca();
                  } catch (error) 
                  {
                    this.obavijest("Došlo je do greške popunjavanja altkupca! " + error.toString(),'error')
                    this.altkupacinfo.UserID=this.narudzba.UserID;
                    this.altkupacinfo.Narudzba=this.narudzba.Narudzba;
                  }

                  //console.log("vratio vrijednost " + this.kupacinfo.UserID + " " + this.kupacinfo.Email);
                  
                  
              })
              .catch(function (error) {
                  console.log("greska popunikupca" + error);
              });
        },
/*
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },*/
        formatDate(date) {



            // If it's a Date object, convert it to the correct format (YYYY-MM-DD)
            if (date instanceof Date) {
                // Convert Date object to 'YYYY-MM-DD' string
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
                const day = date.getDate().toString().padStart(2, '0');
                return `${day}/${month}/${year}`;
            }

            // If it's a string, proceed with splitting
            if (typeof date === 'string') {
                const [year, month, day] = date.split("-");
                return `${day}/${month}/${year}`;
            }

            return null;

        },
        async upisinarudzba() {
            this.submitted=true;
            
           /* if (this.narudzba.brojzag=="" || this.narudzba.brojzag==null  ) {
                this.snackbartekst="Broj narudzbaa je obavezan za unos!"
                this.alertsnackbar=true;
                return;
            }*/
        
            if (this.narudzba.UserID=="" || this.narudzba.UserID==null ){

                this.obavijest("Odredite kupca, ta vrijednost ne može ostati prazna! Proces upisa će biti obustavljen!",'warning');
                return;
            }
            
            this.narudzba.WebPay=0;
            if(this.chkenabled==true){
                this.narudzba.WebPay=1;
            }

            this.narudzba.Poslano=0;
            if(this.chkenabled1==true){
                this.narudzba.Poslano=1;
            }

            this.narudzba.Isporuceno=0;
            if(this.chkenabled2==true){
                this.narudzba.Isporuceno=1;
            } 

            this.narudzba.Fakturisano=0;
            if(this.chkenabled3==true){
                this.narudzba.Fakturisano=1;
            } 
            
            
            
            if (this.editmod === false) {
                // Add     
                fetch('api/apiwsnarudzbe', {
                method: 'post',
                body: JSON.stringify(this.narudzba),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(res => {
                    
                    if (res.greska==true)
                    {
                        this.obavijest("Došlo je do greške upisa na dodavanju narudžbe, originalna poruka: " + res.data,'error');
                        //console.log("Došlo je do greške upisa na dodavanju narudžbe, originalna poruka: " + res.data.data);
                        return;
                    }
                    //this.zatvoriFormu(true, "Zaglavlje narudžbe je dodato!")
                    this.obavijest("Uspješno je dodata nova narudžba. Možete nastaviti sa procesom dodavanja stavki robe!",'success');


                })
                .catch(
                    //err => console.log(err)
                    err=> this.obavijest("Došlo je do greške upisa! " + err.toString(),'error')
                    
                    );
            } else {
                // Update        

                fetch('api/apiwsnarudzbe', {
                method: 'put',
                body: JSON.stringify(this.narudzba),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(res => {

                    if(res.greska==true){
                    
                        this.obavijest("Dopšlo je do greške upisa na editu narudžbe, originalna poruka: " + res.data,'error');
                        return;
                    }else
                    {
                        //this.zatvoriFormu(false, "Zaglavlje narudžbe je izmijenjeno!")
                        this.obavijest("Zaglavlje narudžbe je izmijenjeno!",'success');
                    }

                    

                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijest("Došlo je do greške upisa! " + err.toString(),'error')
                    );
            }
            },
/*
        async kontrolanarudzbaa(narudzba) {

            try {
                const res = await fetch(`api/apiradninalozisvi/${narudzba}`, {
                    method: 'get'
                    });
                const resdata = await res.json();
                this.svinalozikontrola=resdata.data;
                const brnarudzbaa= this.svinalozikontrola.brojzag;
                //console.log('u asyncu sam vraćen je br: ' + brnarudzbaa);
                return brnarudzbaa;

            }catch(e) 
            {
                //console.log('u asyncu sam i nije vraćen narudzba');
                return "slobodanbroj";
            }
        },
*/
        async obavijest(strTekst, cTip){
            
            /*switch(tip) {
                case 'info':
                    this.ikonasnackbar="info";
                    this.colorsnackbar="info";
                    this.snackbartitle="Info"
                    break;
                case 'success':
                    this.ikonasnackbar="check_circle";
                    this.colorsnackbar="success";
                    this.snackbartitle="Obavijest"
                    break;
                case 'error':
                    this.ikonasnackbar="alert-circle";
                    this.colorsnackbar="error";
                    this.snackbartitle="Greška"
                    break; 
                case 'warning':
                    this.ikonasnackbar="mdi-alert";
                    this.colorsnackbar="warning";
                    this.snackbartitle="Upozorenje"
                    break;                                        
                default:

                }*/

            //this.snackbartekst=strTekst;
            this.alertsnackbar=true;
            await javnefunkcije.obavijest(strTekst, cTip, this);
            
        },



        zatvoriFormu (editmodusrada, poruka) {
            //this.narudzba = Object.assign({}, this.narudzbadefault);
            this.kupacinfo =  Object.assign({}, this.kupacinfodefault);
            this.altkupacinfo = Object.assign({}, this.altkupacinfodefault);
            this.$emit('ws_NarudzbaEditClick',false,editmodusrada,true, poruka ) 

        },

        javiosataba(poruka){
            //rezervna funkcija koja ceka sa webshopnarudzbestavke_implement watch: varijabla brojnarudzba: -- trenutno diseblirano
            this.obavijest(poruka,'info');

        }

     

    }
}
</script>