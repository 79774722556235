<template>

                
                
                <v-card
                    outlined
                    color="blue darken-2"
                    class="white--text"
                >
                
                <!--v-card-title lass="headline">
                    Unos i izmjena stavki narudžbe
                </v-card-title-->

                <v-card>
                    <v-card-text>
                        <v-container>

                        <v-row   >

                            <v-col
                            cols="12"
                            sm="3"
   
                            
                            >
                            <v-text-field
                                v-model="lokoclon_stavkenarudzba.Id"
                                label="Id*"
                                id="narudzbabr-input"
                                name="narudzbabr-input"
                                required
                                type="number"
                                hint="Id broj stavke, autoinkrement."               
                                flat
                                clearable
                                :rules="numberRules" 
                                disabled
                                prepend-inner-icon="mdi-order-numeric-ascending"
                                variant="outlined"
                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="4"
                            
                            >
                            <v-text-field
                                v-model="lokoclon_stavkenarudzba.Narudzba"
                                label="Narudzba"
                                id="Narudzba-input"
                                name="Narudzba-input"
                                required
                                hint=""               
                                flat
                                clearable
                                disabled
                                prepend-inner-icon="mdi-order-alphabetical-ascending"  
                                variant="outlined"

                            ></v-text-field>
                            </v-col>                            
                        </v-row >
                         <v-row >

                            <v-col
                            cols="12"
                            sm="8"
                            
                            >
                                <!--LookUp-Roba
                                    v-model="lokoclon_stavkenarudzba.Sifra" 
                                    :publicSifraRoba.sync="lokoclon_stavkenarudzba.Sifra"
                                    :publicEdit="!disableRoba"
                                    @updateRoba="updateRoba"
                                ></LookUp-Roba-->
                                <LookUp-Roba
                                    v-model="lokoclon_stavkenarudzba.Sifra" 
                                    :publicSifraRS="lokoclon_stavkenarudzba.Sifra" 
                                    :publicEdit="!disableRoba"
                                    @updateRoba="updateRoba"
                                    
                                ></LookUp-Roba>
                            </v-col>


                            <v-col
                            cols="12"
                            sm="4"
                            
                            >
                            <v-text-field
                                v-model="lokoclon_stavkenarudzba.Slug"
                                label="Slug"
                                id="Slug-input"
                                name="Slug-input"
                                required
                                hint="Slug artikla je se automatski proizvodi."               
                                flat
                                clearable
                                disabled
                                prepend-inner-icon="mdi-order-alphabetical-ascending"  
                                variant="outlined"
                            ></v-text-field>
                            </v-col>

                         </v-row>
                         <v-row >
                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lokoclon_stavkenarudzba.Kolicina"
                                label="Kolicina"
                                id="Kolicina-input"
                                name="Kolicina-input"
                                required
                                flat
                                type="number"
                                clearable
                                prepend-inner-icon="mdi-order-numeric-ascending"
                                variant="outlined"                                     
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lokoclon_stavkenarudzba.Cijena"
                                label="Cijena"
                                id="Cijena-input"
                                name="Cijena-input"
                                required
                                flat
                                type="number"
                                clearable
                                prepend-inner-icon="mdi-order-numeric-ascending"
                                variant="outlined"                                
                            ></v-text-field>
                            </v-col>

                            <v-col
                              cols="12"
                              sm="3"
                              
                            >

                            <v-text-field
                                v-model="lokoclon_stavkenarudzba.Iznos"
                                label="Iznos"
                                id="Iznos-input"
                                name="Iznos-input"
                                required
                                flat
                                type="number"
                                clearable
                                prepend-inner-icon="mdi-order-numeric-ascending"
                                variant="outlined"                               
                            ></v-text-field>
                            </v-col>      

                        </v-row >

                        
                        </v-container>
                        <small>*indikacija za obavezno polje</small>
                    </v-card-text>


                    <v-card-actions>
                        
                        <v-snackbar
                        v-model="alertsnackbar"
                        multi-line
                        middle
                        shaped
                        vertical
                        >
                        {{ snackbartekst }}

                        <template v-slot:actions>
                            <v-btn
                            class="flex-grow-1 text-none"
                            color="red-darken-2"
                            rounded="0"
                            variant="outlined"
                            prepend-icon="mdi-exit-to-app" 
                            
                            @click="alertsnackbar = false"
                            >
                            Zatvori
                            </v-btn>
                        </template>
                        </v-snackbar>

                        <v-spacer></v-spacer>
                        <v-btn
                        class="same-width-btn text-none"
                        color="red-darken-2"
                        rounded="0"
                        variant="outlined"
                        prepend-icon="mdi-exit-to-app" 
                        @click="zavrsenupis()"
                        
                        >
                        Odustani
                        </v-btn>
                        <v-btn

                        class="same-width-btn text-none"
                        color="green-darken-2"
                        rounded="0"
                        variant="outlined"
                        prepend-icon="mdi-content-save-all" 
                        @click=" upisinarudzba()"
                        
                        >
                        Upiši
                        </v-btn>
                    </v-card-actions>   

                </v-card>
            </v-card>


</template>

<script>

import LookupRoba from '../WebShopKreiranje/WebShopProduktiLookUp.vue';
import axios from 'axios';
export default {


  data() {
    return {

      lokoclon_stavkenarudzba: { ...this.stavkenarudzba }, // Kreirajte lokalnu kopiju


      submitted: false,
      svenarudzbekontrola: [],
      //svinalozi: [],
      kontrolasluga:'',
    


      Barkodovi:[],
      cmbStrSearch:"",
      Pretraga:'',
      PretragaSifra:'',
      Sifra: '',
      pagination: {},
      
      isChecked:false, 
      parentsifrarobe:'',


      //====== za vuetify kontrole

      date: new Date().toISOString().substr(0, 10),

      alert:false,
      alerttext:'',
      alertsnackbar:false,
      snackbartekst:'',
      multiLine: true,

      disableRoba:true,

      menu1: false,
      menu2: false,
      menu3: false,

      cmbSearch:'',
      //adialog:false,
      dialogDelete: false,
      deletenarudzba:'',
      chkenabled1: false,
      chkenabled2:false,
      sifrarules: [
        v => !!v || 'Šifra je obavezna!',
        v => (v && v.length <= 50) || 'Šifra mora biti kraća od 50 karaktera.',
      ],
      nazivrules: [
        v => !!v || 'Naziv je obavezan!',
        v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
      ],
      trirules: [
        v => !!v || 'Vrijednost je obavezna!',
        v => (v && v.length <= 3) || 'Unesena vrijednost mora biti kraći od 3 karaktera.',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      numberRules:[
            v => !!v || 'Vrijednost je obavezna!',
        ],
     
    //====== za vuetify kontrole kraj

    };
  },
    props: {
        //value: Boolean,
        dialogedit: Boolean,
        publicTransDialogSifraRoba:'',
        publicTransnarudzbaBrZag:0,
        publicTransnarudzbaBrStav:0,


        stavkenarudzba: {
            Id:0,
            Sifra: '',
            Slug:'', 
            NazivRoba:'', 
            Kolicina:0, 
            Cijena:0, 
            Iznos:0,
            Narudzba:''
        },
        stavkenarudzbadefault: {
            Id:0,
            Sifra: '',
            Slug:'', 
            NazivRoba:'', 
            Kolicina:0, 
            Cijena:0, 
            Iznos:0,
            Narudzba:''
        },

    },

    components:{
        'LookUp-Roba':LookupRoba,

    },

    computed:{
      
               
       
    },
    
   created(){

    },
    watch:{ 
        stavkenarudzba: {
            handler(newVal) {
                this.lokoclon_stavkenarudzba ={ ...newVal};
            },
            immediate: true,
            deep: true
        },

        dialogedit: function(newVal, oldVal) {
           
           this.disableRoba=false;
           if (newVal==false){
                this.disableRoba=true;
           }
            
        },

    },
    methods: {

 
        ispraznikontrole(){
            try {
                this.$nextTick(() => {
                this.lokoclon_stavkenarudzba = Object.assign({}, this.stavkenarudzbadefault)
                //this.$emit('update:narudzba',Object.assign({}, this.defaultItem));                
                //this.$emit('update:dialogedit', false);                
                //this.$emit('isprazniModelNaparent');
                ///this.$emit('popuniGridNaParent');

                //this.adialog = false;
                //console.log('trebo da je očistio. dialogedit je ' + this.dialogedit + ' narudzba je '  );
                //this.narudzba = -1
            })
            
            } catch (error) {
                console.log(error.log);
            
            }

        },

        updateRoba (robasifra ,robanaziv, robaslug, robacijena){
            this.lokoclon_stavkenarudzba.Sifra=robasifra;
            this.lokoclon_stavkenarudzba.NazivRoba=robanaziv;
            //console.log(robasifra + ' ' + robanaziv + ' ' + robaslug + ' ' + robacijena);
            //if(this.dialogedit === false) {
                this.lokoclon_stavkenarudzba.Cijena=robacijena;
                this.lokoclon_stavkenarudzba.Slug=robaslug;
            //}
            
           
        },

        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },

        async upisinarudzba() {
            this.submitted=true;
            
            if (this.lokoclon_stavkenarudzba.Slug=="" || this.lokoclon_stavkenarudzba.Slug==null  ) {
                //this.alerttext="Broj narudzba je obavezan za unos!"
                //this.alert=true;
                this.snackbartekst="Slug je obavezan za unos!"
                this.alertsnackbar=true;
                return;
            }
      
            if (this.lokoclon_stavkenarudzba.Sifra=="" || this.lokoclon_stavkenarudzba.Sifra==null  ) {

                this.snackbartekst="Roba je obavezna za unos!"
                this.alertsnackbar=true;
                return;
            }

            if (this.dialogedit === false) {
                // AKO JE ADD
                /*
                this.kontrolasluga= await this.kontrolanarudzba(this.lokoclon_stavkenarudzba.Slug);

                if (this.kontrolasluga!="slobodanbroj") {
                    this.snackbartekst="Slug "+this.kontrolasluga+" već postoji, unesite drugu vrijednost slug-a!"
                    this.alertsnackbar=true;
                    return;
                }else
                {
                    //console.log('nije našao broj i vraćeno je ' + this.kontrolanarudzbabrzag );
                
                }
                */

            }




            
            
            if (this.dialogedit === false) {
                // Add     
                fetch('api/apinarudzbestavke', {
                method: 'post',
                body: JSON.stringify(this.lokoclon_stavkenarudzba),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(res => {
                    
                    //this.ispraznikontrole(); 
                    console.log('uradio add');
                    this.zavrsenupis();

                })
                .catch(err => console.log('Greška add -> ' + err));
            } else {
                // Update        

                fetch('api/apinarudzbestavke', {
                method: 'put',
                body: JSON.stringify(this.lokoclon_stavkenarudzba),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(res => {
                console.log('uradio apdejt');

                    //this.ispraznikontrole(); 
                    this.zavrsenupis();       
  
                })
                .catch(err => console.log('Greška update -> ' + err.toString()));
            }
            },

        async kontrolanarudzba(slug) {

                try {
                    const res = await fetch(`api/apinarudzbestavkekontrola/${slug}`, {
                        method: 'get'
                        });
                    const resdata = await res.json();
                    this.svenarudzbekontrola=resdata.data;
                    const brnarudzba= this.svenarudzbekontrola.Slug;
                    
                    
                    return brnarudzba;

                }catch(e) 
                {
                    
                    //console.log('u asyncu sam i nije vraćen broj stavke narudzba');
                    return "slobodanbroj";
                }
            },

            zavrsenupis(){
                this.lokoclon_stavkenarudzba = Object.assign({}, this.stavkenarudzbadefault)
                //this.lokoclon_stavkenarudzba.Sifra="";
                //console.log('završen upis!');
                this.$emit('zavrsenupis',true ) //true za edit
                return;
            }
    }
}
</script>        