<template>

  <v-app>

    <v-main>

      <v-card
        outlined
        color="blue darken-2"
        class="white--text"
      >
      
        <v-card-title class="headline">
          Narudžbe
          <v-spacer></v-spacer>
        </v-card-title>


            <!--v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field-->


            <v-snackbar
            v-model="alertsnackbar"
            :multi-line="multiLine"
            absolute
            top
            shaped
            middle
            >
            {{ snackbartekst }}

              <template v-slot:action="{ attrs }">
                  <v-btn
                  color="red"
                  text
                  v-bind="attrs"
                  @click="alertsnackbar = false"
                  >
                  Zatvori
                  </v-btn>
              </template>
            </v-snackbar>


            <v-data-table
            fixed-header
            :headers="headers"
            :items="svenarudzbe"
            :search="search"
            class="elevation-1"
          >



              <template v-slot:top>
                <v-card>
                  <v-card-text>
                    <v-row >
                      <!-- polja -->

                                  <v-btn
                                    color="blue-darken-2"
                                    rounded="0"
                                    variant="outlined"
                                    prepend-icon="mdi-plus"
                                    @click="addItem()"
                                    
                                  >
                                    
                                  DODAVANJE
                                  </v-btn>

                                  <v-spacer></v-spacer>

                      <v-text-field
                        v-model="search"

                        label="Pretraga"
                        single-line
                        hide-details
                        variant="outlined"
                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                        append-inner-icon="mdi-magnify"                        
                      ></v-text-field>                            

                      <Narudzbe-Tabela-Edit-Form
                        :adialog="pokaziDialogEditnarudzba"
                        :narudzba="narudzba"
                        :transbrojnarudzba2="transbrojnarudzba1"
                        :editmod="edit"
                        @popuniGridNaParent="popuniGrid"
                        @isprazniModelNaparent="ispraznikontrole"
                        @ws_NarudzbaEditClick="WSNarudzbaEditClick"
                      >
                      </Narudzbe-Tabela-Edit-Form>

                        <!--v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="headline">Jeste li sigurni u brisanje narudzbe broj {{deletenarudzbabr}} ?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                        </v-dialog-->
                        <v-dialog v-model="dialogDelete" max-width="500px">
                          <v-card class="delete-dialog"> 
                            <v-card-title class="headline">Potvrda brisanja</v-card-title>
                            <v-card-text class="text-center">
                                <p>
                                <strong>Jeste li sigurni u brisanje narudzbe broj {{deletenarudzbabr}} ?</strong> <br />
                                </p>
              
                            </v-card-text>
                            <v-card-actions class="justify-center">
                              <v-spacer></v-spacer>
                              <v-btn class="same-width-btn text-none" color="red-darken-2" variant="outlined" @click="closeDelete">Cancel</v-btn>
                              <v-btn class="same-width-btn text-none" color="green-darken-2" variant="outlined" @click="deleteItemConfirm">OK</v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                      <!-- polja kraj -->
                    </v-row>
                
                  </v-card-text>
                </v-card>
              </template>

              <template v-slot:[`item.actions`]="{ item }">

                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      class="mr-2"
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>

                    <v-icon
                      small
                      class="mr-2"
                      @click="pokazinarudzbaestavketab(item)"
                    >
                      mdi-menu-open
                    </v-icon>

                    <v-icon
                      small
                      class="mr-2"
                      @click="printajnarudzbaestavketab(item)"
                    >
                      mdi-printer
                    </v-icon>
              </template>
            </v-data-table>

          <!-- dialog stavke -->

          <Narudzbe-Stavke-Tab
           :stavkedialog="stavkedialogovde"
           :brojnarudzba="transbrojnarudzba"
           @ws_NarudzbaStavkeTabClick="WSNarudzbaStavkeTabClick"
          ></Narudzbe-Stavke-Tab>

          <Narudzba-Print
            :adialog="pokaziDialogPrintnarudzba"
            :narudzba="narudzba"
            :brojnarudzba="transbrojnarudzba"
            @w_javiosaPrinta="javiosaPrinta"
          ></Narudzba-Print>

          <!--Print-Site
            :adialog="pokaziDialogPrintnarudzba"
            :narudzba_prntsite="narudzba"
            :brojnarudzba_prntsite="transbrojnarudzba"
            @w_javiosaPrinta="javiosaPrinta"
          ></Print-Site-->

      </v-card>

    </v-main>
  </v-app>
</template>




<script>


import NarudzbeTabelaEditForm from './WebShopNarudzbeTabelaEditForm.vue';
import NarudzbeStavkeTab from './WebShopNarudzbeStavkeTab.vue';
import LookupRoba from '../Registarroba/RobaLookUp.vue';
import NarudzbaPrint from './WebShopNarudzbaPrint.vue'
import PrintSite from './WebShopPrintSite.vue'
import axios from 'axios';

var numeral = require('numeral');


import { jsPDF } from "jspdf";
//const doc = new jsPDF();
import 'jspdf-autotable';

export default {

  data() {
    return {
      stavkedialogovde:false,
      submitted: false,
      svenarudzbekontrola: [],
      svenarudzbe: [],
      narudzba: {
            Id: 0,
            UserID:'', 
            StripeID:'', 
            Narudzba:'',
            Iznos:'', 
            Datum:'', 
            Utime:'', 
            WebPay:'',
            Nacinplacanja:'',
            Poslano:0,
            Isporuceno:0,
            Komentar:'' 
      },
      narudzbadefault: {
            Id: 0,
            UserID:'', 
            StripeID:'', 
            Narudzba:'',
            Iznos:'', 
            Datum:'', 
            Utime:'', 
            WebPay:'',
            Nacinplacanja:'',
            Poslano:0,
            Isporuceno:0,
            Komentar:'' 
      },
      narudzbatab: {
            Id: 0,
            UserID:'', 
            StripeID:'', 
            Narudzba:'',
            Iznos:'', 
            Datum:'', 
            Utime:'', 
            WebPay:'',
            Nacinplacanja:'',
            Poslano:0,
            Isporuceno:0,
            Komentar:'' 
      },
      transbrojnarudzba:0,
      transbrojnarudzba1:0,
/*
      Barkodovi:[],
      cmbStrSearch:"",
      
      Pretraga:'',
      PretragaSifra:'',
      Sifra: '',
      pagination: {},
      
      isChecked:false, */
      edit: false,
      parentsifrarobe:'',
      parentkupac:'',
      search:'',

      //====== za vuetify kontrole
      pokaziDialogPrintnarudzba:false,
      pokaziDialogEditnarudzba:false,
      dialogDelete: false,
      deletenarudzbabr:'',


      stavkedialog:'',

      alert:false,
      alerttext:'',
      alertsnackbar:false,
      snackbartekst:'',
      multiLine: true,


      /*
      date: new Date().toISOString().substr(0, 10),
      




      alert:false,
      alerttext:'',
      alertsnackbar:false,
      snackbartekst:'',
      multiLine: true,


      menu1: false,
      menu2: false,
      menu3: false,

      cmbSearch:'',
      
      adialog:false,

      chkenabled1: false,
      chkenabled2:false,
      sifrarules: [
        v => !!v || 'Šifra je obavezna!',
        v => (v && v.length <= 50) || 'Šifra mora biti kraća od 50 karaktera.',
      ],
      nazivrules: [
        v => !!v || 'Naziv je obavezan!',
        v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
      ],
      trirules: [
        v => !!v || 'Vrijednost je obavezna!',
        v => (v && v.length <= 3) || 'Unesena vrijednost mora biti kraći od 3 karaktera.',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],      */
      numberRules:[
            v => !!v || 'Vrijednost je obavezna!',
        ],
      
      //wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],


     
    //====== za vuetify kontrole kraj


      headers: [
        {
          title: 'Id',
          align: 'start',
          filterable: true,
          key: 'Id',
        },
        { title: 'UserID', key: 'UserID', filterable: true },
        { title: 'Narudzba', key: 'Narudzba', filterable: true },
        { title: 'Datum', key: 'Datum', sortable: false },
        { title: 'Iznos', key: 'Iznos', sortable: false },
        { title: 'Actions', key: 'actions', sortable: false }
      ]

    };
  },


  created() {
    this.popuniGrid();
    alert=false;

  },
 

  components:{
     'LookUp-Roba':LookupRoba,
     'Narudzbe-Tabela-Edit-Form':NarudzbeTabelaEditForm,
     'Narudzbe-Stavke-Tab':NarudzbeStavkeTab,
     'Narudzba-Print':NarudzbaPrint,
     'Print-Site':PrintSite

    
  },
 
  computed:{
    /*referenca https://www.youtube.com/watch?v=kECp7rv-noU*/
    computedDateFormatted1() {
      return this.formatDate(this.narudzba.datumnarudzba);
    },
    computedDateFormatted2() {
      return this.formatDate(this.narudzba.datumgarantnilist);
    },
    computedDateFormatted3() {
      return this.formatDate(this.narudzba.datumistekagarantnilist);
    },

  },
    watch: {
      /*
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },*/



    },

  methods: {

    formatBroj(val){
        var numBr = numeral(val);
        var strBr = numBr.format('0.00');
        return strBr;

    },      

    formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
    },
    async popuniGrid() {

          axios.get('/api/apiwssvenarudzbe', {
                  params:{                            
                  }
              })
              .then((response) => {

                  /* LOAD MORE DUGME
                  this.produktifull = response.data.data;  
                  this.produkti=this.produktifull.slice(0,this.length); 
                  */           
                
                  this.svenarudzbe = response.data.data;
                  
                  
                  
              })
              .catch(function (error) {
                  console.log(error);
              });

    },

    deletenarudzba(narudzba) {
      //if (confirm('Da li ste sigurni u brisanje?')) {
        fetch(`api/apiwsnarudzbe/${narudzba}`, {
          method: 'delete'
        })
          .then(res => res.json())
          .then(data => {
           // alert('narudzba je obrisan!');
            this.obavijestHeading("Narudžba je obrisana!");
            this.popuniGrid();
          })
          .catch(err => console.log(err.toString()));
          //console.error(this.props.url, status, err.toString()));
      //}
    },



    pokazinarudzbaestavketab(item){
        //console.log("narudzbatabela");
        this.pokaziDialogEditnarudzba=false;
        this.narudzbatab = this.svenarudzbe.indexOf(item)
        this.narudzbatab = Object.assign({}, item)

        this.transbrojnarudzba=this.narudzbatab.Narudzba;
        this.stavkedialogovde=true;
        /* 
        parent to child
        ceka ga gore u templejtu da proslijedi watch-u na NaloziStavkeTab.vue
        --------------------------------------------------------------------------
        <Nalozi-Stavke-Tab
           :brojnarudzbaa="transbrojnarudzbaa"
          ></Nalozi-Stavke-Tab>
        --------------------------------------------------------------------------  
        NaloziStavkeTab.vue          
        watch:{
            brojnarudzbaa:  function(newVal, oldVal) {
            console.log("brojnarudzbaa je tab " + newVal);
            this.stavkedialog= true;
            }
        },          
        --------------------------------------------------------------------------
        */
        console.log("brojnarudzbaa je narudzbatabela  " + this.transbrojnarudzba);
        //this.$emit('brojnarudzbaa', this.transbrojnarudzbaa )

    },

 

    addItem (item) {
  
        
        //this.narudzba = this.svenarudzbe.indexOf(item)
        this.narudzba = Object.assign({}, this.narudzbadefault)
        this.transbrojnarudzba1=0;
        this.edit = false;//editovanje
        this.pokaziDialogEditnarudzba=true;


      },

    editItem (item) {
  
        
        this.narudzba = this.svenarudzbe.indexOf(item)
        this.narudzba = Object.assign({}, item)
        this.transbrojnarudzba1=this.narudzba.Narudzba;//šalje na implementiran tabstavke
        this.edit = true;//editovanje
        this.pokaziDialogEditnarudzba=true;


      },
      deleteItem (item) {
        this.narudzba = this.svenarudzbe.indexOf(item)
        this.narudzba = Object.assign({}, item)
        this.deletenarudzbabr= this.narudzba.Id
        this.dialogDelete = true
      },

    printajnarudzbaestavketab(item){

        this.narudzba = this.svenarudzbe.indexOf(item)
        this.narudzba = Object.assign({}, item)

        this.transbrojnarudzba=this.narudzba.Narudzba;
        this.pokaziDialogPrintnarudzba= true;
        

    },      
      closeDelete () {
        this.dialogDelete = false
        this.ispraznikontrole()
        this.popuniGrid();
      },
      deleteItemConfirm () {
        //this.desserts.splice(this.editedIndex, 1)
        this.deletenarudzba( this.deletenarudzbabr);
        this.closeDelete()
      },
      
      ispraznikontrole(){
        try {
            this.$nextTick(() => {
            this.narudzba = Object.assign({}, this.defaultItem)
            //this.narudzba = -1
        })
          
        } catch (error) {
                      
        }

      },
      
      WSNarudzbaEditClick(adialogprikaz, editmodrada, refreshgrid, poruka){
        
        this.pokaziDialogEditnarudzba=adialogprikaz;
        this.edit=editmodrada;
        if(refreshgrid==true){
            this.popuniGrid();
        }
        if (poruka!="odustao"){
           this.obavijestHeading(poruka);
        }

      },


      WSNarudzbaStavkeTabClick(dialogprikaz, poruka){

        this.stavkedialogovde = dialogprikaz;

        if (poruka!="odustao"){
           this.obavijestHeading(poruka);
        }

      },

      async obavijestHeading(strTekst){
          this.snackbartekst=strTekst;
          this.alertsnackbar=true;
      },


      javiosaPrinta(poruka, printdialog){
            //rezervna funkcija koja ceka sa info sa Printa
            this.pokaziDialogPrintnarudzba=printdialog;
            if(poruka!=""){
              this.obavijestHeading(poruka);
              }

        }


  }
};
</script>