<template>
  <div>
    <v-autocomplete
      v-model="internalValue" 
      :items="opcijeRS"     
      item-title="naziv" 
      item-value="sifra"
      clearable
      @update:modelValue="selectedOption"
      variant="outlined"
      prepend-inner-icon="mdi-order-alphabetical-ascending"
      label="Artikal"
      :disabled="publicEdit"
    >
    </v-autocomplete>
  </div>
  <!--div>Odabrana sifra: {{ internalValue }}</div-->
</template>

<script>

export default {
  props: {
    publicSifraRS: { //javni prop koji vraća vrijednost sa roditelja
      type: String,
      default: ''
    },
    publicEdit: Boolean,
  },
  data() {
    return {
      internalValue: this.publicSifraRS, // Interna vrednost koja prati prop vrednost
      opcijeRS: [], // Lista opcija za autocomplete
      /*opcijeRoba: [
        { naziv: 'TROŠKOVI BRZE POŠTE DO 10KG - (BHPOSTA01)', sifra: 'BHPOSTA01' },
        { naziv: 'TROŠKOVI BRZE POŠTE OD 10 - 20KG - (BHPOSTA02)', sifra: 'BHPOSTA02' }
      ]*/
    };
  },
  watch: {
    // Prati promenu prop vrednosti sa roditeljskih formi i sinhronizuj sa lokalnom vrednošću
    publicSifraRS(newVal) {
      this.internalValue = newVal;
    },
    // Kada se interna vrednost promeni, emituje nazad roditelju
    internalValue(newVal) {
      this.$emit('input', newVal); // Emituje promenjenu vrednost za v-model
      //this.$emit('update:updateRS', newVal); // Emituje za updateRoba događaj
      //console.log('Emitovanje vrednosti:', newVal);
    }
  },
  created() {
    this.vratiPodatke(); // Inicijalno punjenje podataka
  },
  methods: {


    vratiPodatke(page_url) {
        page_url = page_url || '/api/apirobasvalookup';
        fetch(page_url)
          .then(res => res.json())
          .then(res => {
            this.opcijeRS = res.data.map(roba => ({
              naziv: roba.Naziv,
              sifra: roba.Sifra,
              stanje: roba.Stanje,
              cijena: roba.Cijena
            }));

            //console.log('sKonvertovani podaci:', this.opcijeRS);
          })
          .catch(err => console.log('Greška pri dohvaćanju podataka:', err));
      
    },

    refreshData() { 
      /*zovem je preko ref iz roditeljske forme
      this.$refs.lookUpRoba.refreshData()
      a u implementaciju kontrole u roditelju stavljam
      ref="lookUpRoba"
      */
      this.vratiPodatke(); // Ponovno dohvatite podatke iz baze
    },

    selectedOption(v) {
      let selectedRS = this.opcijeRS.find(item => item.sifra === v);
      if (selectedRS) {
        //console.log('Izabrana opcija:', selectedRoba);
        this.$emit('updateRS', selectedRS.sifra, selectedRS.naziv, selectedRS.stanje, selectedRS.cijena); // Emitovanje šifre i naziva
      }
    }
  }
};
</script>
