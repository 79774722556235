<template>
    
  <div>
 
 
       <v-autocomplete        
       v-model="internalValue" 
       :items="opcijeRS"     
       item-title="naziv" 
       item-value="slug"
       clearable
       @update:modelValue="selectedOption"
       variant="outlined"
       prepend-inner-icon="mdi-order-alphabetical-ascending"
       label="Odaberi template"
       :disabled="publicEdit"
         >              
      </v-autocomplete>
  </div>
    <!--div>Odabrana sifra: {{ internalValue }}</div-->
 </template>
 
 
 <script>
 
 export default {
   
   props: {
     publicSifraRS: { //javni prop koji vraća vrijednost sa roditelja
       type: String,
       default: ''
     },
     publicEdit: Boolean,
   },
 
   data(){
       return{
         internalValue: this.publicSifraRS, // Interna vrednost koja prati prop vrednost
         opcijeRS: [], // Lista opcija za autocomplete
         /*opcijeRoba: [
           { naziv: 'TROŠKOVI BRZE POŠTE DO 10KG - (BHPOSTA01)', sifra: 'BHPOSTA01' },
           { naziv: 'TROŠKOVI BRZE POŠTE OD 10 - 20KG - (BHPOSTA02)', sifra: 'BHPOSTA02' }
         ]*/
       };
       
     
     
     },
 
     watch: {
     // Prati promenu prop vrednosti sa roditeljskih formi i sinhronizuj sa lokalnom vrednošću
     publicSifraRS(newVal) {
       this.internalValue = newVal;
     },
     // Kada se interna vrednost promeni, emituje nazad roditelju
     internalValue(newVal) {
       this.$emit('input', newVal); // Emituje promenjenu vrednost za v-model
       this.$emit('update:updateRST', newVal); // Emituje za updateRoba događaj
       //console.log('Emitovanje vrednosti:', newVal);
     }
   },
   created() {
     
           this.vratiPodatke();
        
 
         },
 
   methods:{
        vratiPodatke(page_url){
           
                 this.svekategorije = [];
                
                 page_url = page_url || '/api/apiWebShopTemplejtiLookup';
                 fetch(page_url)
                     .then(res => res.json())
                     .then(res => {
                       this.opcijeRS = res.data.map(data => ({
                         slug: data.Slug,
                         naziv: data.Naziv,
                         opis: data.Opis
                       }));
 
                      // console.log('Konvertovani podaci - templejt look up :', this.opcijeRS);
                     })
                     .catch(err => console.log(err));
                 },
 
         selectedOption(v) {
           let selectedRS = this.opcijeRS.find(item => item.slug === v);
           if (selectedRS) {
             //console.log('Izabrana opcija:', selectedRS);
             this.$emit('updateRST', selectedRS.slug, selectedRS.naziv, selectedRS.opis); // Emitovanje šifre i naziva
           }
         }
  
     }
 
 }
 
 </script>