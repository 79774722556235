<template>
    
 <div>
      <!--v-autocomplete        
        :value="publicSifraKupac"
        :items="opcijers"
        label="Kupac"
        item-text="naziv"
        item-value="sifra"
        v-on:change="selectedOption"
        clearable
        >        
      </v-autocomplete-->
      <v-autocomplete
      v-model="internalValue" 
      :items="opcijeRS"     
      item-title="naziv" 
      item-value="sifra"
      clearable
      @update:modelValue="selectedOption"

      label="Kupac"
      :disabled="publicEdit"
      variant="outlined"
      prepend-inner-icon="mdi-order-alphabetical-ascending"
    >
    </v-autocomplete>

  </div>


</template>


<script>

import numeral from 'numeral';
//import Input from '../../../../vendor/laravel/breeze/stubs/inertia-vue/resources/js/Components/Input.vue';


/* 
dovstrano bindovanje https://stackoverflow.com/questions/48979636/vue-two-way-prop-binding?rq=1
                
*/

export default {
  props: {
    publicSifraRS: { //javni prop koji vraća vrijednost sa roditelja
      type: String,
      default: ''
    },
    publicEdit: Boolean,
  },
  data() {
    return {
      internalValue: this.publicSifraRS, // Interna vrednost koja prati prop vrednost
      opcijeRS: [], // Lista opcija za autocomplete

    };
  },
  watch: {
    // Prati promenu prop vrednosti sa roditeljskih formi i sinhronizuj sa lokalnom vrednošću
    publicSifraRS(newVal) {
      this.internalValue = newVal;
    },
    // Kada se interna vrednost promeni, emituje nazad roditelju
    internalValue(newVal) {
      this.$emit('input', newVal); // Emituje promenjenu vrednost za v-model
      this.$emit('update:updateRS', newVal); // Emituje za updateRoba događaj
      //console.log('Emitovanje vrednosti:', newVal);
    }
  },
  created() {
    this.vratiPodatke(); // Inicijalno punjenje podataka
  },
  methods: {
    vratiPodatke(page_url) {
        page_url = page_url || '/api/apikupcisvilookup';
        fetch(page_url)
          .then(res => res.json())
          .then(res => {
            this.opcijeRS = res.data.map(roba => ({
              naziv: roba.Naziv,
              sifra:  roba.UserID
            }));

            //console.log('sKonvertovani podaci:', this.opcijeRS);
          })
          .catch(err => console.log('Greška pri dohvaćanju podataka:', err));
      
    },
    selectedOption(v) {
      let selectedRS = this.opcijeRS.find(item => item.sifra === v);
      if (selectedRS) {
        console.log('Opcije RS:', this.opcijeRS);
        this.$emit('updateRS', selectedRS.sifra, selectedRS.naziv); // Emitovanje šifre i naziva
      }
    }
  }
};

/* stari kod
export default {
  //components: { Input },
  props: {
          'optionList':null,
          'naziv':null, 
          'size':null,
          'default':null,
          publicSifraKupac:String

      },

  data(){
      return{
        
       model:this.publicSifraKupac,
        Pretraga:'',
        PretragaSifra:'',
        svikupci:[],
        svikupciarr:[],
        opcijers:[],
        kupac:{
            Sifra: '',
            Naziv: ''
        },
        sifraCombo:this.publicSifraKupac
      }
    },
    computed:{

    },
    created() {
    
      this.vratiPodatke();
        
    },


    methods:{
    
      setujCombo(siftb){
        
        this.sifraCombo=siftb;
        console.log('tblookup setujcombo publicSifraRoba je : ' + this.publicSifraKupac)
      },

      vratiPodatke(page_url){
              

              this.svikupci = [];
              
              page_url = page_url || '/api/apikupcisvilookup';
              fetch(page_url)
                  .then(res => res.json())
                  .then(res => {
                      this.svikupci = res.data; 
                     

                      this.svikupciarr = 
                        this.svikupci.map(wshop_kupci=>({naziv:wshop_kupci.Naziv, sifra:wshop_kupci.UserID}));
                      
                      this.opcijers=this.svikupciarr;

                      //console.log('vratio matricu ' + this.svikupciarr[0].naziv + '-' + this.svikupciarr[0].sifra);

                  })
                  .catch(err => console.log(err));
              },


      selectedOption: function(value){
            
            let kupacnaziv = "";
              // naziv iz matrice
              //for (var i in this.opcijers) 
              //{			 	 
              //  if (this.opcijers[i].sifra == value) 
              //  {
                  
              //    kupacnaziv=	this.opcijers[i].naziv;

              //  }
              //}
              

              let kupacsifra = value;
              let page_url = page_url || '/api/apifunkcijeRetFieldValue/wshop_kupci/Naziv/UserID/'+kupacsifra+'';
              fetch(page_url)
                  .then(res => res.json())
                  .then(res => {
                        //console.log('u asyncu sam kupcilookup vraćen je naziv: ' +  res.data[0].Naziv);
                    
                    kupacnaziv=res.data[0].Naziv;

                  })
                  .catch(err => console.log(err));

            this.$emit('updateKupac',kupacsifra ,kupacnaziv);
            
            
          
          }    

    }


}*/

</script>