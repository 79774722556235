<template>
    <!--div class="w-full">
        <div class="lg:w-2/3 w-full mx-auto mt-8 overflow-auto">
            <h2
                class="text-sm title-font text-gray-500 tracking-widest"
                v-text="'Transaction ID: ' + order.transaction_id"
            ></h2>
            <h1 class="text-gray-900 text-3xl title-font font-medium mb-4">Thank you for your purchase</h1>
            <table class="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                <tr>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Item</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Quantity</th>
                    <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Price</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in order.products" :key="item.id">
                    <td class="p-4" v-text="item.name"></td>
                    <td class="p-4" v-text="item.pivot.quantity"></td>
                    <td class="p-4" v-text="cartLineTotal(item)"></td>
                </tr>
                <tr>
                    <td class="p-4 font-bold">Total Amount</td>
                    <td class="p-4 font-bold" v-text="orderQuantity"></td>
                    <td class="p-4 font-bold" v-text="orderTotal"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div-->



    <v-app>



 

                        <div>
                            <vue3-html2pdf
                                :show-layout="false"
                                :float-layout="true"
                                :enable-download="true"
                                :preview-modal="true"
                                :paginate-elements-by-height="1400"
                                filename="hee hee"
                                :pdf-quality="2"
                                :manual-pagination="false"
                                pdf-format="a4"
                                pdf-orientation="landscape"
                                pdf-content-width="800px"

                                @progress="onProgress($event)"
                                @hasStartedGeneration="hasStartedGeneration()"
                                @hasGenerated="hasGenerated($event)"
                                ref="html2Pdf"
                            >
                                <section slot="pdf-content">

                                        <row>


                                            <v-col         
                                                class="d-flex align-center"
                                                cols="12"
                                                sm="12">  
                                                    <v-card
                                                        elevation="0"
                                                        
                                                        min-width="80%"
                                                        class="mx-auto pa-4 "
                                                    
                                                    >
                                            
                                                        <v-card-title
                                            
                                                        class="headline">
                                                        Obrazac zaprimljene narudžbe
                                                        <v-spacer></v-spacer>
                                                        
                                                        </v-card-title>

                                                        <v-card elevation="0">
                                                            <v-row>
                                                                <v-col         
                                                                
                                                                cols="12"
                                                                sm="12"> 
                                                                    <v-card elevation="0">

                                                                        <v-card-title

                                                                        class="headline">
                                                                        Podaci o kupcu
                                                                        <v-spacer></v-spacer>
                                                                        
                                                                        </v-card-title>

                                                                        <v-simple-table>
                                                                            <template v-slot:default>
                                                                                <tbody v-for="(item, index) in kupci">
                                                                                
                                                                                        <tr >
                                                                                            <td  class="elevation-1 table_td_left">Naziv/Ime : </td>
                                                                                            <td v-text="item.Ime"></td>                                                                

                                                                                            <td class="table_td_nastavak">Prezime : </td>
                                                                                            <td v-text="item.Prezime"></td>
                                                                                            <td></td><td></td>
                                                                                        </tr>
                                                                                        <tr >
                                                                                            <td class="elevation-1 table_td_left">Adresa : </td>
                                                                                            <td v-text="item.Adresa"></td>

                                                                                            <td class="table_td_nastavak" >Grad : </td>
                                                                                            <td v-text="item.Grad"></td>
                                                                                            <td class="table_td_nastavak" >Država : </td>
                                                                                            <td v-text="item.Drzava"></td>
                                                                                        </tr>    
                                                                                        <tr >
                                                                                            <td  class="elevation-1 table_td_left">Email : </td>
                                                                                            <td v-text="item.Email"></td>

                                                                                            <td  class="table_td_nastavak">Telefon : </td>
                                                                                            <td v-text="item.Telefon"></td>
                                                                                            <td></td><td></td>
                                                                                        </tr>     
                                                                                                                                                                                                                                                
                                                                                </tbody>
                                                                            </template>
                                                                        </v-simple-table>
                                                                    </v-card>

                                                                        
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col         
                                                                
                                                                cols="12"
                                                                sm="12"> 
                                                                    <v-card elevation="0" v-if="altkupci || altkupci.length != 0">

                                                                        <v-card-title

                                                                        class="headline">
                                                                        Dostava na drugu adresu
                                                                        <v-spacer></v-spacer>
                                                                        
                                                                        </v-card-title>

                                                                        <v-simple-table>
                                                                            <template v-slot:default>
                                                                                <tbody v-for="(item, index) in altkupci">
                                                                                
                                                                                        <tr >
                                                                                            <td  class="elevation-1 table_td_left">Naziv/Ime : </td>
                                                                                            <td v-text="item.Ime"></td>                                                                

                                                                                            <td class="table_td_nastavak">Prezime : </td>
                                                                                            <td v-text="item.Prezime"></td>
                                                                                            <td></td><td></td>
                                                                                        </tr>
                                                                                        <tr >
                                                                                            <td class="elevation-1 table_td_left">Adresa : </td>
                                                                                            <td v-text="item.Adresa"></td>

                                                                                            <td class="table_td_nastavak" >Grad : </td>
                                                                                            <td v-text="item.Grad"></td>
                                                                                            <td class="table_td_nastavak" >Država : </td>
                                                                                            <td v-text="item.Drzava"></td>
                                                                                        </tr>    
                                                                                        <tr >
                                                                                            <td  class="elevation-1 table_td_left">Email : </td>
                                                                                            <td v-text="item.Email"></td>

                                                                                            <td  class="table_td_nastavak">Telefon : </td>
                                                                                            <td v-text="item.Telefon"></td>
                                                                                            <td></td><td></td>
                                                                                        </tr>                                                                                                                                                             
                                                                                </tbody>
                                                                            </template>
                                                                        </v-simple-table>
                                                                    </v-card>

                                                                        
                                                                </v-col>                                    
                                                            </v-row>

                                                        <v-row>
                                                                <v-col         
                                                                
                                                                cols="12"
                                                                sm="8"> 
                                                                    <v-card elevation="0">

                                                                        <v-card-title

                                                                        class="headline">
                                                                        Podaci o narudžbi
                                                                        <v-spacer></v-spacer>
                                                                        
                                                                        </v-card-title>

                                                                        <v-simple-table>
                                                                            <template v-slot:default>
                                                                                <tbody v-for="(item, index) in orderZag">
                                                                                
                                                                                        <tr >
                                                                                            <td  class="elevation-1 table_td_left">Narudžba : </td>
                                                                                            <td v-text="item.Narudzba"></td>                                                                


                                                                                            
                                                                                        </tr>
                                                                                        <tr >
                                                                                        

                                                                                            <td  class="elevation-1 table_td_left">Datum : </td>
                                                                                            <td v-text="item.Datum"></td>
                                                                                            
                                                                                        </tr>
                                                                                        <tr >
                                                                                            <td class="elevation-1 table_td_left">Način plaćanja : </td>
                                                                                            <td v-text="item.Nacinplacanja"></td>


                                                                                        </tr>    
                                                                                                                                                                                                                                                
                                                                                </tbody>
                                                                            </template>
                                                                        </v-simple-table>
                                                                    </v-card>

                                                                        
                                                                </v-col>
                                                            
                                                            </v-row>

        <v-row><br/></v-row>
                                                            <v-row>

                                                            <v-card elevation="0">
                                                                    <v-card-title
                                                                    class="headline">
                                                                        Tabela artikala narudžbe
                                                                        <v-spacer></v-spacer>

                                                                    </v-card-title>
                                                                    <v-simple-table
                                                                    fixed-header
                                                                    
                                                                    min-height="300px"
                                                                    >
                                                                        <thead >
                                                                        <tr class="">
                                                                            <!--th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Proizvod</th-->
                                                                            <td class="table_td_sivo">Proizvod</td>
                                                                            <td class="table_td_sivo">Količina</td>
                                                                            <td class="table_td_sivo">Cijena</td>
                                                                            <td class="table_td_sivo">Iznos</td>
                                                                            <td class="table_td_sivo"></td>
                                                                        </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="(item, index) in orderStav" :key="item.Slug">
                                                                                <td class="p-4 width: 50%;" v-text="item.NazivRobe"></td>
                                                                                <td class="p-4" v-text="formatBroj(item.Kolicina)"></td>
                                                                                <td class="p-4" v-text="formatBroj(item.Cijena)"></td>
                                                                                <td class="p-4" v-text="formatBroj(item.Iznos)"></td>



                                                                            </tr>
                                                                            <tr>
                                                                                <td class="p-4 table_td_sivo">TOTAL :</td>
                                                                                <td class="p-4 table_td_sivo"></td>
                                                                                <td class="p-4 table_td_sivo"></td>                                            
                                                                                <td class="p-4 table_td_sivo" v-text="formatBroj(orderTotal)"></td>
                                                                                <td class="w-10 table_td_sivo"></td>
                                                                            </tr>
                                                                        </tbody>        
                                                                    </v-simple-table>
                                                            </v-card>

                                                            </v-row>
                                                        </v-card>




                                                    </v-card>
                                            </v-col>
                                        </row> 
                                
                                </section>
                            </vue3-html2pdf>
                        </div>





                                <v-card
                                    elevation="0"
                                    
                                    min-width="80%"
                                    class="mx-auto pa-4 "
                                
                                >
                        
                                    <v-card-title
                        
                                    class="headline">
                                    Obrazac zaprimljene narudžbe
                                    <v-spacer></v-spacer>
                                    
                                    </v-card-title>

                                    <v-card-text>
                                        <v-row>
                                            <v-col         
                                            
                                            cols="12"
                                            sm="6"> 
                                                <v-card elevation="0">

                                                    <v-card-title

                                                    class="headline">
                                                    Podaci o kupcu
                                                    <v-spacer></v-spacer>
                                                    
                                                    </v-card-title>

                                                    <v-simple-table>
                                                        <template v-slot:default>
                                                            <tbody v-for="(item, index) in kupci">
                                                            
                                                                    <tr >
                                                                        <td  class="elevation-1 table_td_left">Naziv/Ime : </td>
                                                                        <td v-text="item.Ime"></td>                                                                

                                                                        <td class="table_td_nastavak">Prezime : </td>
                                                                        <td v-text="item.Prezime"></td>
                                                                        <td></td><td></td>
                                                                    </tr>
                                                                    <tr >
                                                                        <td class="elevation-1 table_td_left">Adresa : </td>
                                                                        <td v-text="item.Adresa"></td>

                                                                        <td class="table_td_nastavak" >Grad : </td>
                                                                        <td v-text="item.Grad"></td>
                                                                        <td class="table_td_nastavak" >Država : </td>
                                                                        <td v-text="item.Drzava"></td>
                                                                    </tr>    
                                                                    <tr >
                                                                        <td  class="elevation-1 table_td_left">Email : </td>
                                                                        <td v-text="item.Email"></td>

                                                                        <td  class="table_td_nastavak">Telefon : </td>
                                                                        <td v-text="item.Telefon"></td>
                                                                        <td></td><td></td>
                                                                    </tr>     
                                                                                                                                                                                                                            
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </v-card>

                                                    
                                            </v-col>
                                            <v-col         
                                            
                                            cols="12"
                                            sm="6"> 
                                                <v-card elevation="0" v-if="altkupci || altkupci.length != 0">

                                                    <v-card-title

                                                    class="headline">
                                                    Dostava na drugu adresu
                                                    <v-spacer></v-spacer>
                                                    
                                                    </v-card-title>

                                                    <v-simple-table>
                                                        <template v-slot:default>
                                                            <tbody v-for="(item, index) in altkupci">
                                                            
                                                                    <tr >
                                                                        <td  class="elevation-1 table_td_left">Naziv/Ime : </td>
                                                                        <td v-text="item.Ime"></td>                                                                

                                                                        <td class="table_td_nastavak">Prezime : </td>
                                                                        <td v-text="item.Prezime"></td>
                                                                        <td></td><td></td>
                                                                    </tr>
                                                                    <tr >
                                                                        <td class="elevation-1 table_td_left">Adresa : </td>
                                                                        <td v-text="item.Adresa"></td>

                                                                        <td class="table_td_nastavak" >Grad : </td>
                                                                        <td v-text="item.Grad"></td>
                                                                        <td class="table_td_nastavak" >Država : </td>
                                                                        <td v-text="item.Drzava"></td>
                                                                    </tr>    
                                                                    <tr >
                                                                        <td  class="elevation-1 table_td_left">Email : </td>
                                                                        <td v-text="item.Email"></td>

                                                                        <td  class="table_td_nastavak">Telefon : </td>
                                                                        <td v-text="item.Telefon"></td>
                                                                        <td></td><td></td>
                                                                    </tr>                                                                                                                                                             
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </v-card>

                                                    
                                            </v-col>                                    
                                        </v-row>

                                        <v-row>
                                            <v-col         
                                            
                                            cols="12"
                                            sm="12"> 
                                                <v-card elevation="0">

                                                    <v-card-title

                                                    class="headline">
                                                    Podaci o narudžbi
                                                    <v-spacer></v-spacer>
                                                    
                                                    </v-card-title>

                                                    <v-simple-table>
                                                        <template v-slot:default>
                                                            <tbody v-for="(item, index) in orderZag">
                                                            
                                                                    <tr >
                                                                        <td  class="elevation-1 table_td_left">Narudžba : </td>
                                                                        <td v-text="item.Narudzba"></td>                                                                


                                                                        
                                                                    </tr>
                                                                    <tr >
                                                                    

                                                                        <td  class="elevation-1 table_td_left">Datum : </td>
                                                                        <td v-text="item.Datum"></td>
                                                                        
                                                                    </tr>
                                                                    <tr >
                                                                        <td class="elevation-1 table_td_left">Način plaćanja : </td>
                                                                        <td v-text="item.Nacinplacanja"></td>


                                                                    </tr>    
                                                                                                                                                                                                                            
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </v-card>

                                                    
                                            </v-col>
                                        
                                        </v-row>


                                        <v-row>
                                            <v-col         
                                            
                                            cols="12"
                                            sm="12">                                             
                                                <v-card elevation="0">
                                                        <v-card-title
                                                        class="headline">
                                                            Tabela artikala narudžbe
                                                            <v-spacer></v-spacer>

                                                        </v-card-title>
                                                        <v-simple-table
                                                        fixed-header
                                                        
                                                        min-height="300px"
                                                        >
                                                            <thead >
                                                            <tr class="">
                                                                <!--th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Proizvod</th-->
                                                                <td class="p-4 table_td_sivo">Proizvod</td>
                                                                <td class="p-4 table_td_sivo">Količina</td>
                                                                <td class="p-4 table_td_sivo">Cijena</td>
                                                                <td class="p-4 table_td_sivo">Iznos</td>
                                                                <td class="p-4 table_td_sivo"></td>
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr v-for="(item, index) in orderStav" :key="item.Slug">
                                                                    <td class="p-4 width: 50%;" v-text="item.NazivRobe"></td>
                                                                    <td class="p-4" v-text="formatBroj(item.Kolicina)"></td>
                                                                    <td class="p-4" v-text="formatBroj(item.Cijena)"></td>
                                                                    <td class="p-4" v-text="formatBroj(item.Iznos)"></td>



                                                                </tr>
                                                                <tr>
                                                                    <td class="p-4 table_td_sivo">TOTAL :</td>
                                                                    <td class="p-4 table_td_sivo" ></td>
                                                                    <td class="p-4 table_td_sivo"></td>                                            
                                                                    <td class="p-4 table_td_sivo" v-text="formatBroj(orderTotal)"></td>
                                                                    <td class="w-10 table_td_sivo"></td>
                                                                </tr>
                                                            </tbody>        
                                                        </v-simple-table>
                                                </v-card>
                                            </v-col>
                                        </v-row>

                                        <v-row   class="justify-center">

                                            <v-col         
                                                class="d-flex justify-center"
                                                cols="12"
                                                sm="12">  
                                                <v-btn
                                                    class="same-width-btn text-none"
                                                    color="blue-darken-2"
                                                    rounded="0"
                                                    variant="outlined"
                                                    prepend-icon="mdi-printer" 
                                                    @click="create_PDFmake()"
                                                    
                                                    >
                                                    Štampaj narudžbu
                                                </v-btn>
                                            </v-col>

                                        </v-row>  
                                    </v-card-text>




                                </v-card>
            




    </v-app>

</template>
<script>
    import axios from 'axios';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable';

    //import VueHtml2pdf from 'vue-html2pdf'

    import pdfMake from "pdfmake/build/pdfmake";
    import pdfFonts from "pdfmake/build/vfs_fonts";
    pdfMake.vfs = pdfFonts.pdfMake.vfs;


    var numeral = require('numeral');
    //import VueHtml2pdf from 'vue-html2pdf'
    import Vue3Html2pdf from 'vue3-html2pdf'

    export default {
        data(){
            return {

                lokobrojnarudzbe:'',

                narudzba:{
                    Id: 0,
                    UserID:'', 
                    StripeID:'', 
                    Narudzba:'',
                    Iznos:'', 
                    Datum:'', 
                    Utime:'', 
                    WebPay:'',
                    Nacinplacanja:'',
                    Poslano:0,
                    Isporuceno:0,
                    Fakturisano:0,
                    FakZag:0,
                    Komentar:''           
                },                
                narudzbaDefault:{
                    Id: 0,
                    UserID:'', 
                    StripeID:'', 
                    Narudzba:'',
                    Iznos:'', 
                    Datum:'', 
                    Utime:'', 
                    WebPay:'',
                    Nacinplacanja:'',
                    Poslano:0,
                    Isporuceno:0,
                    Fakturisano:0,
                    FakZag:0,
                    Komentar:''           
                }, 
                
                narudzbastav: {
                    Id:0,
                    Sifra: '',
                    Slug:'', 
                    NazivRoba:'', 
                    Kolicina:0, 
                    Cijena:0, 
                    Iznos:0,
                    narudzbastav:0,

                },
                
                narudzbastavSuma:0,

                narudzbastavDefault: {
                    Id:0,
                    Sifra: '',
                    Slug:'', 
                    NazivRoba:'', 
                    Kolicina:0, 
                    Cijena:0, 
                    Iznos:0,
                    narudzbastav:0,

                },                  
                ziroracuni: {
                    Id:0,
                    Banka:'',
                    Ziroracun:'',                        
                    Komentar:''
                },
                
                telefoni: {
                    Id:0,
                    Tip:'',
                    Broj:'',                        
                    Komentar:''
                },
                
                email: {
                    Id:0,
                    Tip:'',
                    Email:'',                        
                    Komentar:''
                },
                
                firma: {
                    Id:0,
                    Naziv:'',
                    Nazivopis:'',                        
                    Adresa:'',
                    Grad:'',
                    Drzava:'',
                    IDbroj:'',
                    PDVbroj:'',
                    Sudbroj:''
                }, 
                kupacinfo: {
                        Sifra:0,
                        Naziv:'',
                        Ime:'',
                        Prezime:'',
                        Email:'',
                        Telefon:'',
                        Adresa:'',
                        Grad:'',
                        Drzava:'',
                        ZipCode:'',
                        UserID:'',
                        Pravno:0,
                        IDbroj:'',
                        PDVbroj:'',
                        Komentar:''
                },

                kupacinfodefault: {
                        Sifra:0,
                        Naziv:'',
                        Ime:'',
                        Prezime:'',
                        Email:'',
                        Telefon:'',
                        Adresa:'',
                        Grad:'',
                        Drzava:'',
                        ZipCode:'',
                        UserID:'',
                        Pravno:0,
                        IDbroj:'',
                        PDVbroj:'',
                        Komentar:''
                },

                altkupacinfodefault: {
                    Id:0,
                    Narudzba:'',
                    Naziv:'',
                    Ime:'',
                    Prezime:'',
                    Email:'',
                    Telefon:'',
                    Adresa:'',
                    Grad:'',
                    Drzava:'',
                    ZipCode:'',
                    UserID:'',
                    Pravno:0,
                    IDbroj:'',
                    PDVbroj:'',
                    Komentar:''
                },

                altkupacinfo: {
                    Id:0,
                    Narudzba:'',
                    Naziv:'',
                    Ime:'',
                    Prezime:'',
                    Email:'',
                    Telefon:'',
                    Adresa:'',
                    Grad:'',
                    Drzava:'',
                    ZipCode:'',
                    UserID:'',
                    Pravno:0,
                    IDbroj:'',
                    PDVbroj:'',
                    Komentar:''
                },                                   
            }
        },
        setup() {
            const onProgress = (event) => {
            console.log("Progress:", event);
            };

            const hasStartedGeneration = () => {
            console.log("Generacija PDF-a je počela.");
            };

            const hasGenerated = (event) => {
            console.log("PDF generisan:", event);
            };
        },
        mounted() {
            this.initMethods();
        },
        methods: {

            formatBroj(val){
                var numBr = numeral(val);
                var strBr = numBr.format('0.00');
                return strBr;

            },  
            cartLineTotal(item) {
                let amount = item.price * item.pivot.quantity;
                amount = (amount / 100);
                return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }, 

            printaj(){
                this.$refs.html2Pdf.generatePdf()
            },

            async  popunifirma() {
                try{ 
                    await axios.get('/api/apiwsfirma/')
                        .then((response) => {
                            this.firma = response.data.data; 
                            
                        
                        })
                        .catch((error) => {
                            this.obavijestHeading("Došlo je do greške popunjavanja firme! " + error.toString());
                        });
                    }
                    catch(error){
                    console.log('sjebo je firmu ' + error);
                // this.metodpokrenutsabusa();
                }
            },


            async popuniemail(){
                await axios.get('/api/apiwsemail/', {

                })
                .then((response) => {
                    
                        /*if(response.greska==true){                                                       
                            this.obavijestHeading("Došlo je do greške popunjavanja stavki narudžbe! Originalna greška : " + response.data);
                            return;
                        }*/                       
                    this.email = response.data.data;
                    

                })
                .catch(function (error) {
                    this.obavijestHeading("Došlo je do greške popunjavanja email-a!  " + error.toString())
                    
                });
            },

            async popunitelefone(){
                await axios.get('/api/apiwstelefoni/', {

                })
                .then((response) => {
                    
                        /*if(response.greska==true){                                                       
                            this.obavijestHeading("Došlo je do greške popunjavanja stavki narudžbe! Originalna greška : " + response.data);
                            return;
                        }*/                       
                    this.telefoni = response.data.data;
                    

                })
                .catch(function (error) {
                    this.obavijestHeading("Došlo je do greške popunjavanja telefona!  " + error.toString())
                    
                });
            },

            async popuniziroracune(){
                await axios.get('/api/apiwsziroracuni/', {

                })
                .then((response) => {
                    
                        /*if(response.greska==true){                                                       
                            this.obavijestHeading("Došlo je do greške popunjavanja stavki narudžbe! Originalna greška : " + response.data);
                            return;
                        }*/                       
                    this.ziroracuni = response.data.data;
                    

                })
                .catch(function (error) {
                    this.obavijestHeading("Došlo je do greške popunjavanja žiroračuna!  " + error.toString())
                    
                });
            },

            async initMethods() {
                await this.popunifirma(); // Sačekajte da ova metoda završi
                this.popuniziroracune();
                this.popunitelefone();
                this.popuniemail();
            },

            async   create_PDFmake(){
                
                this.popunifirma(); // Sačekajte da popunifirma završi
                this.popuniziroracune();
                this.popunitelefone();
                this.popuniemail(); 

                const storeobjektkupac = this.$store.state.kupac[0];

                // Kopiranje svojstava samo ako postoje u oba objekta
                this.kupacinfo = {
                    ...this.kupacinfo, // Originalni `kupacinfo`
                    ...storeobjektkupac // Kopiranje svojstava iz `storeobjekt`
                };

                const storeobjektaltkupac = this.$store.state.altkupac[0];
                                // Kopiranje svojstava samo ako postoje u oba objekta
                this.altkupac = {
                    ...this.altkupac, // Originalni `kupacinfo`
                    ...storeobjektaltkupac // Kopiranje svojstava iz `storeobjekt`
                };

                const storeobjektnarudzba = this.$store.state.narudzba[0];
                                // Kopiranje svojstava samo ako postoje u oba objekta
                this.narudzba = {
                    ...this.narudzba, // Originalni `kupacinfo`
                    ...storeobjektnarudzba // Kopiranje svojstava iz `storeobjekt`
                };


                this.narudzbastav = this.$store.state.narudzbastav;
                //const ime = this.$store.state.kupac.map(objekat => objekat.Ime);

                

                


                // ====== popunjavanje firma

                let firmaloko=[];
                let firmaloko2=[];
                try{ 
                    var bPrvikrugFirma=true;
                    var cBordersTblFirma={
                        borders:{
                            lijevo:true,
                            gore:false, 
                            desno:false,
                            dole:false
                        }

                    };

                    var cBordersTblFirma2={

                        borders:{
                            lijevo:false,
                            gore:false, 
                            desno:false,
                            dole:false
                        },

                    }; 
                                           
                    for ( var key in this.firma) 
                    {
                        if (this.firma.hasOwnProperty(key))
                        {
                            var data = this.firma[key];
                            
                            var row = new Array();
                            row.push(this.sloziredtabelePDFmake(data.Naziv,'center','','ime_firme','',cBordersTblFirma));
                            firmaloko.push(row);
                            
                            var row1 = new Array();
                            row1.push(this.sloziredtabelePDFmake(data.Nazivopis,'center','','ime_firme_podnaslov','',cBordersTblFirma));
                            firmaloko.push(row1);
                            
                            var row2 = new Array();
                            row2.push(this.sloziredtabelePDFmake(data.Adresa + ' - ' + data.Grad + ' - ' + data.Drzava,'center','','ime_firme_podnaslov','',cBordersTblFirma));
                            firmaloko.push(row2);
                        
                            var row3 = new Array();
                            row3.push(this.sloziredtabelePDFmake('ID broj: ' + data.IDbroj,'left','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                            row3.push(this.sloziredtabelePDFmake('PDV broj: ' + data.PDVbroj,'center','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                            row3.push(this.sloziredtabelePDFmake('Sud br.: ' + data.Sudbroj,'right','','ime_firme_podnaslov_generalije','',cBordersTblFirma));

                            firmaloko2.push(row3);
                        }
                    
                    }
                    
                   /*
                    console.log("naziv firme pdfmake " + this.firma.Naziv);
                    var row = new Array();
                    row.push(this.sloziredtabelePDFmake( this.firma.Naziv,'center','','ime_firme','',cBordersTblFirma));
                    firmaloko.push(row);
                    
                    var row1 = new Array();
                    row1.push(this.sloziredtabelePDFmake( this.firma.Nazivopis,'center','','ime_firme_podnaslov','',cBordersTblFirma));
                    firmaloko.push(row1);
                    
                    var row2 = new Array();
                    row2.push(this.sloziredtabelePDFmake( this.firma.Adresa,'center','','ime_firme_podnaslov','',cBordersTblFirma));
                    firmaloko.push(row2);

                    var row3 = new Array();
                    row3.push(this.sloziredtabelePDFmake( this.firma.IDbroj,'center','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                    row3.push(this.sloziredtabelePDFmake( this.firma.PDVbroj,'center','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                    row3.push(this.sloziredtabelePDFmake( this.firma.Sudbroj,'center','','ime_firme_podnaslov_generalije','',cBordersTblFirma));
                    firmaloko.push(row3);                        
                    */
                    
                }catch (error) 
                {
                    this.obavijestHeading("Došlo je do greške popunjavanja firme! Originalna greška : " + error.toString());

                }                                       
                // ============= popunjavanje firma kraj




                // ====== popunjavanje email

                let emailloko=[];
                try{ 
                    var bPrvikrugEmail=true;
                    var cBordersTblEmail={
                        borders:{
                            lijevo:true,
                            gore:false, 
                            desno:false,
                            dole:false
                        }

                    };

                    var cBordersTblEmail2={

                        borders:{
                            lijevo:false,
                            gore:false, 
                            desno:false,
                            dole:false
                        },

                    };                        
                    for ( var key in this.email) 
                    {
                        if (this.email.hasOwnProperty(key))
                        {
                            var data = this.email[key];
                            var row = new Array();
                            if (bPrvikrugEmail==true){
                                row.push(this.sloziredtabelePDFmake('Email','left','','','#eeeeee',cBordersTblEmail2));
                                bPrvikrugEmail=false;
                            }else
                            {row.push(this.sloziredtabelePDFmake('','left','','','#eeeeee',cBordersTblEmail2));}

                            //row.push(this.sloziredtabelePDFmake(data.Tip,'left','','','',cBordersTblTel));
                            row.push(this.sloziredtabelePDFmake(data.Email,'center','','','',cBordersTblEmail));

                            emailloko.push(row);
                        }
                    
                    }   
                    
                }catch (error) 
                {
                    this.obavijestHeading("Došlo je do greške popunjavanja email! Originalna greška : " + error.toString());

                }                                       
                // ============= popunjavanje email kraj


                // ====== popunjavanje telefoni

                let telefoniloko=[];
                try{ 
                    var bPrvikrugTel=true;
                    var cBordersTblTel={
                        borders:{
                            lijevo:true,
                            gore:false, 
                            desno:false,
                            dole:false
                        }

                    };

                    var cBordersTblTel2={

                        borders:{
                            lijevo:false,
                            gore:false, 
                            desno:false,
                            dole:false
                        },

                    };                        
                    for ( var key in this.telefoni) 
                    {
                        if (this.telefoni.hasOwnProperty(key))
                        {
                            var data = this.telefoni[key];
                            var row = new Array();
                            if (bPrvikrugTel==true){
                                row.push(this.sloziredtabelePDFmake('Telefoni','left','','','#eeeeee',cBordersTblTel2));
                                bPrvikrugTel=false;
                            }else
                            {row.push(this.sloziredtabelePDFmake('','left','','','#eeeeee',cBordersTblTel2));}

                            row.push(this.sloziredtabelePDFmake(data.Tip,'left','','','',cBordersTblTel));
                            row.push(this.sloziredtabelePDFmake(data.Broj,'center','','','',cBordersTblTel));

                            telefoniloko.push(row);
                        }
                    
                    }   
                    
                }catch (error) 
                {
                    this.obavijestHeading("Došlo je do greške popunjavanja telefona! Originalna greška : " + error.toString());

                }                                       
                // ============= popunjavanje telefoni kraj




                // ====== popunjavanje ziroracuna

                let ziroracuniloko=[];
                try{ 
                    var bPrvikrug=true;
                    var cBordersTblZiro={
                        borders:{
                            lijevo:true,
                            gore:false, 
                            desno:false,
                            dole:false
                        }

                    };

                    var cBordersTblZiro2={

                        borders:{
                            lijevo:false,
                            gore:false, 
                            desno:false,
                            dole:false
                        },

                    };                        
                    for ( var key in this.ziroracuni) 
                    {
                        if (this.ziroracuni.hasOwnProperty(key))
                        {
                            var data = this.ziroracuni[key];
                            var row = new Array();
                            if (bPrvikrug==true){
                                row.push(this.sloziredtabelePDFmake('Žiroračuni','left','','','#eeeeee',cBordersTblZiro2));
                                bPrvikrug=false;
                            }else
                            {row.push(this.sloziredtabelePDFmake('','left','','','#eeeeee',cBordersTblZiro2));}

                            row.push(this.sloziredtabelePDFmake(data.Banka,'left','','','',cBordersTblZiro));
                            row.push(this.sloziredtabelePDFmake(data.Ziroracun,'center','','','',cBordersTblZiro));

                            ziroracuniloko.push(row);
                        }
                    
                    }   
                    
                }catch (error) 
                {
                    this.obavijestHeading("Došlo je do greške popunjavanja žiroračuna! Originalna greška : " + error.toString());

                }                                       
                // ============= popunjavanje ziroracuna kraj

                let tabelastavke=[];


                var headers = {
                    zaglavljetbl:{
                        col_1:{ text: 'Roba', style: 'tableHeader', alignment: 'left',margin: [0, 8, 0, 0],fillColor:'#eeeeee'},
                        col_2:{ text: 'Količina', style: 'tableHeader', alignment: 'center',margin: [0, 8, 0, 0],fillColor:'#eeeeee' }, 
                        col_3:{ text: 'Cijena', style: 'tableHeader', alignment: 'center',margin: [0, 8, 0, 0],fillColor:'#eeeeee' },
                        col_4:{ text: 'Iznos', style: 'tableHeader', alignment: 'center',margin: [0, 8, 0, 0] , fillColor:'#eeeeee'}
                    }
                }


                try{ 

                    for (var key in headers){
                        if (headers.hasOwnProperty(key)){
                            var header = headers[key];
                            var row = new Array();
                            row.push( header.col_1 );
                            row.push( header.col_2 );
                            row.push( header.col_3 );
                            row.push( header.col_4 );

                            tabelastavke.push(row);
                        }
                    }

                    /* ========  TABELA SA STAVKAMA START ========  */                  
                    var cBordersTblStavke={
                        borders:{
                            lijevo:true,
                            gore:true, 
                            desno:true,
                            dole:true
                        }
                    };


                    var cSumaIznos=0;
                    var cFormatSuma=0;
                    /*
                    for (var key in this.narudzbastav) 
                    {
                        if (this.narudzbastav.hasOwnProperty(key))
                        {
                            var data = this.narudzbastav[key];
                            var row = new Array();

                            var strKolicina=this.formatBroj(data.Kolicina);
                            var strCijena=this.formatBroj(data.Cijena);
                            var strIznos=this.formatBroj(data.Iznos);
                            
                            row.push(this.sloziredtabelePDFmake(data.NazivRoba.toString(),'left','','','',cBordersTblStavke));
                            row.push(this.sloziredtabelePDFmake(strKolicina,'right','','','',cBordersTblStavke));
                            row.push(this.sloziredtabelePDFmake(strCijena,'right','','','',cBordersTblStavke));
                            row.push(this.sloziredtabelePDFmake(strIznos, 'right','','','',cBordersTblStavke));

                            cSumaIznos=cSumaIznos + data.Iznos;

                            tabelastavke.push(row);
                        }

                    }*/

                    this.narudzbastav.forEach(data => {
                        var row = [];

                        var strKolicina = this.formatBroj(data.Kolicina);
                        var strCijena = this.formatBroj(data.Cijena);
                        var strIznos = this.formatBroj(data.Iznos);

                        row.push(this.sloziredtabelePDFmake(data.NazivRobe.toString(), 'left', '', '', '', cBordersTblStavke));
                        row.push(this.sloziredtabelePDFmake(strKolicina, 'right', '', '', '', cBordersTblStavke));
                        row.push(this.sloziredtabelePDFmake(strCijena, 'right', '', '', '', cBordersTblStavke));
                        row.push(this.sloziredtabelePDFmake(strIznos, 'right', '', '', '', cBordersTblStavke));

                        cSumaIznos += data.Iznos;

                        tabelastavke.push(row);
                    });                    
                    cFormatSuma=this.formatBroj(cSumaIznos);
                    

                    var tablefooters = {
                        footertbl:{
                            col_1:{ text: 'Ukupno ', style: 'tableFooter', alignment: 'left',fillColor:'#eeeeee'},
                            col_2:{ text: '', style: 'tableFooter', alignment: 'center',fillColor:'#eeeeee' }, 
                            col_3:{ text: '', style: 'tableFooter', alignment: 'center',fillColor:'#eeeeee' },
                            col_4:{ text: cFormatSuma, style: 'tableFooter', alignment: 'right', fillColor:'#eeeeee'}
                        }
                    }




                    for (var key in tablefooters){
                        if (tablefooters.hasOwnProperty(key)){
                            var footer = tablefooters[key];
                            var row = new Array();
                            row.push( footer.col_1 );
                            row.push( footer.col_2 );
                            row.push( footer.col_3 );
                            row.push( footer.col_4 );

                            tabelastavke.push(row);
                        }
                    }                        
                    
                    //console.log("1. tabelastavke (this.tabelastavke) : " + tabelastavke + " , 2. tabelastavke (this.tabelastavke[0]) :" + tabelastavke[0] +  " , 3. citanje pojedinacne vrijednosti (this.tabelastavke[0][0]), npr. 0 gdje je naziv  : " + tabelastavke[0][0]);
                    //odgovor: 1. tabelastavke (this.tabelastavke) : VIKING MA 339,1,459.2,459.2 , 2. tabelastavke (this.tabelastavke[0]) :VIKING MA 339,1,459.2,459.2 , 3. citanje pojedinacne vrijednosti (this.tabelastavke[0][0]), npr. 0 gdje je naziv  : VIKING MA 339
                    
                    /* ======== TABELA SA STAVKAMA KRAJ ======== */

                }catch (error) 
                {
                    this.obavijestHeading("Došlo je do greške popunjavanja vrijednosti stavki u tabeli štampe! " + error.toString())

                }      

                var docDefinition = {
                    pageMargins: [40,100,40,70],
                    //pageOrientation: 'landscape',
                    pageOrientation: 'portrait',

                    header: function(currentPage) {
                        return {

                            margin:[0,5,0,0],

                        
                            table:{ // firma
                                body:[
                                    
                                        [
                                            {columns: 
                                                [
                                                    {
                                                        
                                                    },
                                                    { 
                                                        width: 'auto',
                                                        //margin: [40,0,40,0],
                                                        //widths: ['auto','*','auto'],
                                                        table: {

                                                                body:firmaloko,
                                                            },
                                                            layout: 'noBorders'
                                                        
                                                    },
                                                    {
                                                        
                                                    }

                                                    
                                                ]
                                            }                                          
                                        ],
                                        [
                                            {columns: 
                                                [
                                                    {
                                                        
                                                    },                                                
                                                    { // firma id brojevi
                                                    width: 'auto',
                                                    //margin: [40,0,0,0],
                                                    //widths: [ '*', '*', '*'],
                                                    //style: 'ultrasmall',
                                                    table: {

                                                            body:firmaloko2,
                                                        },
                                                        layout: 'noBorders'
                                                        
                                                    },
                                                    {
                                                        
                                                    }

                                                    
                                                ]
                                            }                                                             
                                            
                                            

                                        ],
                                        [ 
                                            {canvas: [   // linija headera                         
                                                    {
                                                        type: 'line',
                                                        //x1: 0, y1: 10, // x1 horizontalni start od page margine, y1 vertikalna visina na str.
                                                        //x2: 515, y2:10 , // x2 duzina linije, y2 nagib linije (- prema gore, + prema dole, y1==y2 je horizontalan polozaj)
                                                        x1: 10, y1: 0, 
                                                        x2: 595-10, y2: 0,
                                                        lineWidth: 0.3,
                                                        lineColor: 'gray'
                                                        //lineCap: 'round' // krajevi linije vide se kad je debljina linije veca
                                                        //lineCap: 'round'
                                                    },    
                                                        
                                                    
                                            ] },                                  
                                        ]                                              
                                        

                                ]
                                },
                                layout: 'noBorders'
                        }
                    },
                    
                    footer: function(currentPage, pageCount) { 
                        
                        return { 

                        table:{ 
                            body:[       
                                    [ 
                                        {canvas: [   // crtanje geometrijskih oblika                         
                                                /*
                                                {
                                                    type: 'polyline',
                                                    lineWidth: 1,
                                                    closePath: true,
                                                    points: [{ x: 10, y: 10 }, { x: 35, y: 40 }, { x: 100, y: 40 }, { x: 125, y: 10 }]
                                                },   
                                                {
                                                    type: 'ellipse',
                                                    x: 150, y: 140,
                                                    color: 'red',
                                                    fillOpacity: 0.5,
                                                    r1: 80, r2: 60
                                                },  */  
                                                {
                                                    type: 'line',
                                                    //x1: 0, y1: 10, // x1 horizontalni start od page margine, y1 vertikalna visina na str.
                                                    //x2: 515, y2:10 , // x2 duzina linije, y2 nagib linije (- prema gore, + prema dole, y1==y2 je horizontalan polozaj)
                                                    x1: 10, y1: 5, 
                                                    x2: 595-10, y2: 5,
                                                    lineWidth: 0.3,
                                                    lineColor: 'gray'
                                                    //lineCap: 'round' // krajevi linije vide se kad je debljina linije veca
                                                    //lineCap: 'round'
                                                },    
                                                    
                                                
                                        ] },                                  
                                    ],
                                    [   
                                        {columns: [

                                                                
                                                                { // žiroračuni
                                                                width: '*',
                                                                margin: [40,0,0,0],
                                                                style: 'ultrasmall',
                                                                table: {
                                                                        //headerRows: 1,
                                                                        widths: [ 'auto', 'auto','auto' ],
                                                                        body:ziroracuniloko,
                                                                        /*body: [
                                                                        [ { text: 'Žiroračuni ', bold: true }, 'Ime firme', '', ''],
                                                                            [ '', '', '***', '**'],
                                                                            [ 'Value 1', 'Value 2', 'Value 3', 'Value 4' ],
                                                                            [ 'Value 1', 'Value 2', 'Value 3', 'Value 4' ],
                                                                            [ 'Value 1', 'Value 2', 'Value 3', 'Value 4' ]
                                                                    
                                                                        ]*/
                                                                    },
                                                                    layout: {
                                                                        hLineWidth: function (i, node) {
                                                                            //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                                                            return (i === 0 || i === node.table.body.length) ? 0.3 : 0.3;
                                                                        },
                                                                        vLineWidth: function (i, node) {
                                                                            //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                                                            return (i === 0 || i === node.table.widths.length) ? 0.3  : 0.3 ;
                                                                        },
                                                                        hLineColor: function (i, node) {
                                                                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                                                        },
                                                                        vLineColor: function (i, node) {
                                                                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                                                        },
                                                                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                        // paddingLeft: function(i, node) { return 4; },
                                                                        // paddingRight: function(i, node) { return 4; },
                                                                        // paddingTop: function(i, node) { return 2; },
                                                                        // paddingBottom: function(i, node) { return 2; },
                                                                        // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                                                    }
                                                                    
                                                                },

                                                            { //telefoni
                                                                width: '*',
                                                                margin: [20,0,0,0],
                                                                style: 'ultrasmall',
                                                                table: {
                                                                        //headerRows: 1,
                                                                        widths: [ 'auto', 'auto','auto' ],
                                                                        body:telefoniloko,

                                                                    },
                                                                    layout: {
                                                                        hLineWidth: function (i, node) {
                                                                            //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                                                            return (i === 0 || i === node.table.body.length) ? 0.3 : 0.3;
                                                                        },
                                                                        vLineWidth: function (i, node) {
                                                                            //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                                                            return (i === 0 || i === node.table.widths.length) ? 0.3 : 0.3;
                                                                        },
                                                                        hLineColor: function (i, node) {
                                                                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                                                        },
                                                                        vLineColor: function (i, node) {
                                                                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                                                        },
                                                                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                        // paddingLeft: function(i, node) { return 4; },
                                                                        // paddingRight: function(i, node) { return 4; },
                                                                        // paddingTop: function(i, node) { return 2; },
                                                                        // paddingBottom: function(i, node) { return 2; },
                                                                        // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                                                    }
                                                                    
                                                                },   
                                                                
                                                            { //email
                                                                width: 'auto',
                                                                margin: [20,0,0,0],
                                                                style: 'ultrasmall',
                                                                table: {
                                                                        //headerRows: 1,
                                                                        widths: [ 'auto', 'auto'],
                                                                        body:emailloko,

                                                                    },
                                                                    layout: {
                                                                        hLineWidth: function (i, node) {
                                                                            //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                                                            return (i === 0 || i === node.table.body.length) ? 0.3 : 0.3;
                                                                        },
                                                                        vLineWidth: function (i, node) {
                                                                            //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                                                            return (i === 0 || i === node.table.widths.length) ? 0.3 : 0.3;
                                                                        },
                                                                        hLineColor: function (i, node) {
                                                                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                                                        },
                                                                        vLineColor: function (i, node) {
                                                                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                                                        },
                                                                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                        // paddingLeft: function(i, node) { return 4; },
                                                                        // paddingRight: function(i, node) { return 4; },
                                                                        // paddingTop: function(i, node) { return 2; },
                                                                        // paddingBottom: function(i, node) { return 2; },
                                                                        // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                                                    }
                                                                    
                                                                },
                                                                { // broj stranice
                                                                width: 'auto',
                                                                margin: [20,0,40,0],
                                                                style: 'ultrasmall',
                                                                table: {
                                                                        //headerRows: 1,
                                                                        widths: [ 'auto', 'auto'],
                                                                        body:[                                                           
                                                                                [
                                                                                    {text:'Stranica ',alignment:'center',fillColor:'#eeeeee',border: [false, false, false, false]},
                                                                                    {text:currentPage.toString() + ' / ' + pageCount,alignment:'center',border: [true, false, false, false]},
                                                                                ]
                                                                            ]
                                                                            /*
                                                                        body: [
                                                                            [ 
                                                                            
                                                                            'Stranica ', currentPage.toString() + ' / ' + pageCount
                                                                            ],

                                                                        ]*/
                                                                    
                                                                    },
                                                                    layout: {
                                                                        hLineWidth: function (i, node) {
                                                                            //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                                                            return (i === 0 || i === node.table.body.length) ? 0.3 : 0.3;
                                                                        },
                                                                        vLineWidth: function (i, node) {
                                                                            //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                                                            return (i === 0 || i === node.table.widths.length) ? 0.3 : 0.3;
                                                                        },
                                                                        hLineColor: function (i, node) {
                                                                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                                                        },
                                                                        vLineColor: function (i, node) {
                                                                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                                                        },
                                                                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                                                        // paddingLeft: function(i, node) { return 4; },
                                                                        // paddingRight: function(i, node) { return 4; },
                                                                        // paddingTop: function(i, node) { return 2; },
                                                                        // paddingBottom: function(i, node) { return 2; },
                                                                        // fillColor: function (rowIndex, node, columnIndex) { return null; }
                                                                    }
                                                                    
                                                                },

                                            /*
                                                        { text:'Žiro računi', alignment: 'center', style:'brojevistranica' },
                                                        { text:'NLB Banka : 123546857987654' , alignment: 'center', style:'brojevistranica' },
                                                        { text:' '+ currentPage.toString() + ' / ' + pageCount, alignment: 'right', style:'brojevistranica' },*/
                                            
                                                    //{image: 'sampleImage.jpg', alignment: 'right', width: 50}
                                            ]}
                                    ]
                                ]
                            },
                            layout: 'noBorders'
                        }
                                

                    },

                    /* originalni footer
                    footer: function(currentPage, pageCount) {
                        return { text:' '+ currentPage.toString() + ' / ' + pageCount, alignment: 'center',margin:[0,30,0,0], style:'brojevistranica' };
                    },*/



                    content: [
                        
                        
                        
                        {text: 'Obrazac zaprimljene narudžbe', style: 'naslov', margin: [0, 10]},
                        
                        {canvas: [   // crtanje geometrijskih oblika                         
                            /*
                            {
                                type: 'polyline',
                                lineWidth: 1,
                                closePath: true,
                                points: [{ x: 10, y: 10 }, { x: 35, y: 40 }, { x: 100, y: 40 }, { x: 125, y: 10 }]
                            },   
                            {
                                type: 'ellipse',
                                x: 150, y: 140,
                                color: 'red',
                                fillOpacity: 0.5,
                                r1: 80, r2: 60
                            },  
                            {
                                type: 'line',
                                x1: 0, y1: 60, // x1 horizontalni start od page margine, y1 vertikalna visina na str.
                                x2: 515, y2: 560, // x2 duzina linije, y2 nagib linije (- prema gore, + prema dole, y1==y2 je horizontalan polozaj)
                                lineWidth: 0.3,
                                lineColor: 'gray'
                                //lineCap: 'round' // krajevi linije vide se kad je debljina linije veca
                                //lineCap: 'round'
                            },    
                                
                            */     
                        ]},


                        {text: 'Podaci o kupcu', style: 'podnaslov', margin: [0, 5]},

                        {

                            table: {
                                style: 'tabelastavke',
                                headerRows: 1,
                                //widths: [ 80, 200, 80, 200],
                                widths: [ 'auto', '*', 'auto', '*'],

                                body:[
                                        [
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, true, false, false],
                                                fillColor: '#eeeeee',
                                                text: 'Naziv/Ime : '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [false, false, false, false],
                                                //fillColor: '#dddddd',
                                                text: this.kupacinfo.Ime
                                            },
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [true, true, true, true],
                                                fillColor: '#eeeeee',
                                                text: 'Prezime: '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [true, true, true, true],
                                                //fillColor: '#eeeeee',
                                                text: this.kupacinfo.Prezime
                                            }                                            
                                        ], 
                                        [
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, true, false, false],
                                                fillColor: '#eeeeee',
                                                text: 'Adresa : '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [false, false, false, false],
                                                //fillColor: '#dddddd',
                                                text: this.kupacinfo.Adresa
                                            },
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [true, true, true, true],
                                                fillColor: '#eeeeee',
                                                text: 'Država/Grad: '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [true, true, true, true],
                                                //fillColor: '#eeeeee',
                                                text: this.kupacinfo.Drzava + '/' + this.kupacinfo.Grad
                                            }                                            
                                        ],  
                                        [
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, true, false, false],
                                                fillColor: '#eeeeee',
                                                text: 'Email : '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [false, false, false, false],
                                                //fillColor: '#dddddd',
                                                text: this.kupacinfo.Email
                                            },
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [true, true, true, true],
                                                fillColor: '#eeeeee',
                                                text: 'Telefon: '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [true, true, true, true],
                                                //fillColor: '#eeeeee',
                                                text: this.kupacinfo.Telefon
                                            }                                            
                                        ],                                                                                                                                                        
                                ]
                                
                            },  
                            layout: {
                                hLineWidth: function (i, node) {
                                    //return (i === 0 || i === node.table.body.length) ? 2 : 1; --- deblji vanjski border
                                    return (i === 0 || i === node.table.body.length) ? 1 : 1;
                                },
                                vLineWidth: function (i, node) {
                                    //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                    return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                                },
                                hLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                },
                                vLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                },
                                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                // paddingLeft: function(i, node) { return 4; },
                                // paddingRight: function(i, node) { return 4; },
                                // paddingTop: function(i, node) { return 2; },
                                // paddingBottom: function(i, node) { return 2; },
                                // fillColor: function (rowIndex, node, columnIndex) { return null; }
                            }                          

                        },



                        {text: '',   margin: [0, 10]},
                        {text: 'Dostava na drugu adresu', style: 'podnaslov', margin: [0, 5]},

                        {

                            table: {
                                style: 'tabelastavke',
                                headerRows: 1,
                                //widths: [ 80, 200, 80, 200],
                                widths: [ 'auto', '*', 'auto', '*'],

                                body:[
                                        [
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, true, false, false],
                                                fillColor: '#eeeeee',
                                                text: 'Naziv/Ime : '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [false, false, false, false],
                                                //fillColor: '#dddddd',
                                                text: this.altkupacinfo.Ime
                                            },
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [true, true, true, true],
                                                fillColor: '#eeeeee',
                                                text: 'Prezime: '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [true, true, true, true],
                                                //fillColor: '#eeeeee',
                                                text: this.altkupacinfo.Prezime
                                            }                                            
                                        ], 
                                        [
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, true, false, false],
                                                fillColor: '#eeeeee',
                                                text: 'Adresa : '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [false, false, false, false],
                                                //fillColor: '#dddddd',
                                                text: this.altkupacinfo.Adresa
                                            },
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [true, true, true, true],
                                                fillColor: '#eeeeee',
                                                text: 'Država/Grad: '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [true, true, true, true],
                                                //fillColor: '#eeeeee',
                                                text: this.altkupacinfo.Drzava + '/' + this.altkupacinfo.Grad
                                            }                                            
                                        ],  
                                        [
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, true, false, false],
                                                fillColor: '#eeeeee',
                                                text: 'Email : '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [false, false, false, false],
                                                //fillColor: '#dddddd',
                                                text: this.altkupacinfo.Email
                                            },
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [true, true, true, true],
                                                fillColor: '#eeeeee',
                                                text: 'Telefon: '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [true, true, true, true],
                                                //fillColor: '#eeeeee',
                                                text: this.altkupacinfo.Telefon
                                            }                                            
                                        ],                                                                                                                                                        
                                ]
                                
                            },  
                            layout: {
                                hLineWidth: function (i, node) {
                                    //return (i === 0 || i === node.table.body.length) ? 2 : 1; --- deblji vanjski border
                                    return (i === 0 || i === node.table.body.length) ? 1 : 1;
                                },
                                vLineWidth: function (i, node) {
                                    //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                    return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                                },
                                hLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                },
                                vLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                },
                                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                // paddingLeft: function(i, node) { return 4; },
                                // paddingRight: function(i, node) { return 4; },
                                // paddingTop: function(i, node) { return 2; },
                                // paddingBottom: function(i, node) { return 2; },
                                // fillColor: function (rowIndex, node, columnIndex) { return null; }
                            }                          

                        },




                        {text: '',   margin: [0, 10]},
                        {text: 'Podaci o narudžbi', style: 'podnaslov', margin: [0, 5]},

                        {

                            table: {
                                style: 'tabelastavke',
                                headerRows: 1,
                                //widths: [ 80, 200, 80, 200],
                                widths: [ 'auto', '*'],

                                body:[
                                        [
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, true, false, false],
                                                fillColor: '#eeeeee',
                                                text: 'Narudžba : '
                                            },
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, false, false, false],
                                                //fillColor: '#dddddd',
                                                text: this.narudzba.Narudzba
                                            },
                                        ],
                                        [ 

                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [true, true, true, true],
                                                fillColor: '#eeeeee',
                                                text: 'Datum: '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [true, true, true, true],
                                                //fillColor: '#eeeeee',
                                                text: this.narudzba.Datum
                                            }                                            
                                        ], 
                                        [
                                            {
                                                style: 'tabelastavke_bold',
                                                //border: [false, true, false, false],
                                                fillColor: '#eeeeee',
                                                text: 'Način plaćanja : '
                                            },
                                            {
                                                style: 'tabelastavke',
                                                //border: [false, false, false, false],
                                                //fillColor: '#dddddd',
                                                text: this.narudzba.Nacinplacanja
                                            },
                                        
                                        ],  
                                                                                                                                                    
                                ]
                                
                            },  
                            layout: {
                                hLineWidth: function (i, node) {
                                    //return (i === 0 || i === node.table.body.length) ? 2 : 1; --- deblji vanjski border
                                    return (i === 0 || i === node.table.body.length) ? 1 : 1;
                                },
                                vLineWidth: function (i, node) {
                                    //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                    return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                                },
                                hLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                },
                                vLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                },
                                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                // paddingLeft: function(i, node) { return 4; },
                                // paddingRight: function(i, node) { return 4; },
                                // paddingTop: function(i, node) { return 2; },
                                // paddingBottom: function(i, node) { return 2; },
                                // fillColor: function (rowIndex, node, columnIndex) { return null; }
                            }                          

                        },


                        {text: '',   margin: [0, 10]},
                        {text: 'Tabela stavki narudžbe', style: 'podnaslov',  margin: [0, 5]},
                                            
                        {

                            //layout: 'lightHorizontalLines', // optional
                            style: 'tabelastavke',
                            table: {

                                headerRows: 1,
                                widths: [ '*', 80, 80, 80 ],

                                body:tabelastavke,
                                
                                
                            },  
                                                    
                            layout: {
                                hLineWidth: function (i, node) {
                                    //return (i === 0 || i === node.table.body.length) ? 2 : 1; //deblji vanjski border
                                    return (i === 0 || i === node.table.body.length) ? 1 : 1;
                                },
                                vLineWidth: function (i, node) {
                                    //return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                                    return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                                },
                                hLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                },
                                vLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                },
                                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                                // paddingLeft: function(i, node) { return 4; },
                                // paddingRight: function(i, node) { return 4; },
                                // paddingTop: function(i, node) { return 2; },
                                // paddingBottom: function(i, node) { return 2; },
                                // fillColor: function (rowIndex, node, columnIndex) { return null; }
                            }



                        }
                    ],



                    styles: {

                        

                        ime_firme: {
                            fontSize: 18,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 5, 0, 0]                            
                        },

                        ime_firme_podnaslov: {
                            fontSize: 7,
                            bold: true,
                            alignment: 'center',
                            //margin: [30, 190, 0, 80]                            
                        },             
                        ime_firme_podnaslov_generalije: {
                            fontSize: 7,
                            bold: true,
                            alignment: 'center',
                            //margin: [30, 190, 0, 80]                            
                        },                                                        
                        naslov: {
                            fontSize: 15,
                            bold: true,
                            alignment: 'center',
                            //margin: [0, 0, 0, 0]                            
                        },                        
                        podnaslov: {
                            fontSize: 12,
                            bold: true
                        },
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 8
                        },
                        ultrasmall:{
                            fontSize: 6
                        },
                        sta: {
                            fontSize: 11,
                            bold: false,
                            alignment: 'justify'
                        },
                        tableHeader: {
                            fontSize: 10,
                            bold: true
                        },
                        tableFooter: {
                            fontSize: 10,
                            bold: true
                        },                            
                        tabelastavke:{
                            fontSize: 10,
                        }, 
                        tabelastavke_bold:{
                            fontSize: 10,
                            bold: true
                        },                             
                        brojevistranica:{
                            fontSize:6
                        }                       
                    }
                };
                pdfMake.createPdf(docDefinition).open();
                },

            sloziredtabelePDFmake(cRov, chAlign, cvAlign, cStyle, cFillColor, cBorder )
            {

            var arBorder = [];

            for (var key in cBorder) 
            {
                if (cBorder.hasOwnProperty(key))
                {
                    var data = cBorder[key];
                    var row = new Array();
                    row.push(data.lijevo);
                    row.push(data.gore);
                    row.push(data.desno);
                    row.push(data.dole);
                    
                    arBorder.push(row);
                }

            }


            //console.log("arborder je " + arBorder[0]);


            var red={

                text:cRov,
                alignment:chAlign,
                style:cStyle,
                fillColor:cFillColor,
                border:arBorder[0] //border:[false, false, false, false]
                
            };
            return red;

                }, 


            async obavijestHeading(strTekst){
                this.snackbartekst=strTekst;
                this.alertsnackbar=true;
            },
        },

        components: {
          //VueHtml2pdf
          Vue3Html2pdf
        },

        computed: {

            orderZag() {
                this.narudzba=this.$store.state.narudzba;
                return this.$store.state.narudzba;
            },

            orderStav() {

                this.narudzbastav = this.$store.state.narudzbastav;
                return this.$store.state.narudzbastav;
                
            },
            orderQuantity() {
                
                return this.$store.state.narudzbastav.reduce((acc, item) => acc + item.Kolicina, 0);
            },

            orderTotal() {
                
                let amount = this.$store.state.narudzbastav.reduce((acc, item) => acc + (item.Cijena * item.Kolicina), 0);
                
                return amount.toLocaleString('en-US', { style: 'currency', currency: 'BAM' });
            }, 
            
            kupci() {
                this.kupacinfo=this.$store.state.kupac;
                return this.$store.state.kupac;
            },

            altkupci() {
                this.altkupacinfo=this.$store.state.altkupac;
                return this.$store.state.altkupac;
            }


        }
    }
</script>

<style >

</style>