<template>

<v-app>

  <topnavbar></topnavbar>
  
</v-app>

</template>

<script type="text/javascript">
  import Topnavbar from './TopBarNav.vue'
  export default{
    components:{
      //Topnavbar // register component
       'topnavbar' : Topnavbar
    },

  data(){
    return {

    }
  },    
  }

</script>