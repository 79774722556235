<template>
    
 <div>


      <v-autocomplete        
      v-model="internalValue" 
      :items="opcijeRS"     
      item-title="naziv" 
      item-value="sifra"
      clearable
      @update:modelValue="selectedOption" 
      variant="outlined"
      prepend-inner-icon="mdi-order-alphabetical-ascending"
      label="Kategorija"
      :disabled="publicEdit"
        >              
     </v-autocomplete>
 </div>
   <!--div>Odabrana sifra: {{ internalValue }}</div-->
</template>


<script>

export default {
  
  props: {
    publicSifraRS: { //javni prop koji vraća vrijednost sa roditelja
      type: String,
      default: ''
    },
    publicEdit: Boolean,
  },
  emits: ['update:publicSifraRS', 'updateRS'],
  data(){
      return{
        internalValue: this.publicSifraRS, // Interna vrednost koja prati prop vrednost
        opcijeRS: [], // Lista opcija za autocomplete
        /*opcijeRoba: [
          { naziv: 'TROŠKOVI BRZE POŠTE DO 10KG - (BHPOSTA01)', sifra: 'BHPOSTA01' },
          { naziv: 'TROŠKOVI BRZE POŠTE OD 10 - 20KG - (BHPOSTA02)', sifra: 'BHPOSTA02' }
        ]*/
      };
      
    
    
    },

    watch: {
    // Prati promenu prop vrednosti sa roditeljskih formi i sinhronizuj sa lokalnom vrednošću
    publicSifraRS(newVal) {
      this.internalValue = newVal;
    },
    // Kada se interna vrednost promeni, emituje nazad roditelju
    internalValue(newVal) {
      this.$emit('input', newVal); // Emituje promenjenu vrednost za v-model
      this.$emit('update:publicSifraRS', newVal); // Emituje za updateRoba događaj
      //console.log('Emitovanje vrednosti:', newVal);
    }
  },
  created() {
    
          this.vratiPodatke();
       

        },

  methods:{
       vratiPodatke(page_url){
          
                this.svekategorije = [];
               
                page_url = page_url || '/api/apiKategorijesvelookup';
                fetch(page_url)
                    .then(res => res.json())
                    .then(res => {
                      this.opcijeRS = res.data.map(roba => ({
                        naziv: roba.Naziv,
                        sifra: roba.Sifra
                      }));

                      console.log('Konvertovani podaci:', this.opcijeRS);
                    })
                    .catch(err => console.log(err));
                },

        selectedOption(v) {
          
          let selectedRS = this.opcijeRS.find(item => item.sifra === v);

          console.log('Izabrana opcija kategorije:', selectedRS.sifra);

          if (selectedRS) {
            
            this.$emit('updateRS', selectedRS.sifra, selectedRS.naziv); // Emitovanje šifre i naziva
          }
        }
 
    }

}

</script>