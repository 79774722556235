<template>
  
   
    <v-card class="mx-auto my-4" max-width="650">
      <v-card-title>
        Vrijeme cvijetanja
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            v-for="(month, index) in months"
            :key="index"
            :cols="4"       
            :sm="3"         
            :xs="2"         
            class="text-center"
          >
            <v-btn
              :color="modelValue.includes(index + 1) ? 'success' : 'default'"
              class="ma-0 pa-2 month-btn"
              elevation="0"
              rounded
              block
              :class="{'non-interactive': true}"
              @click="toggleMonth(index + 1)"
            >
              {{ month }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  props: {
    modelValue: {
        type: Array, // Očekuje se niz brojeva [1, 2, 3]
        required: false, // Opciono prosleđivanje
        default: () => [], // Prazan niz ako nije prosleđeno
      },
  },
  watch: { 
    /*
    modelValue: {
          immediate: true, // Pokreće se i prilikom mount-a
          deep: true,
          handler(newValue) {
            //this.mapaZona = { ...newValue }; // Ažuriranje `mapaZona` novim podacima
            
          },
        },*/
  },
  computed: {
    months() {
      return ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
    },
  },
  methods: {
    /*
    toggleMonth(month) {
      const index = this.modelValue.indexOf(month);
      const updatedMonths = [...this.modelValue];
      if (index === -1) {
        // Dodaj mesec ako nije u nizu
        updatedMonths.push(month);
      } else {
        // Ukloni mesec ako je u nizu
        updatedMonths.splice(index, 1);
      }
      // Emituje ažurirani niz
      this.$emit("update:modelValue", updatedMonths.sort((a, b) => a - b));
    },*/
    toggleMonth(month) {
      const index = this.modelValue ? this.modelValue.indexOf(month) : -1;
      const updatedMonths = this.modelValue ? [...this.modelValue] : [];
      if (index === -1) {
        updatedMonths.push(month);
      } else {
        updatedMonths.splice(index, 1);
      }
      //this.$emit("update:modelValue", updatedMonths.sort((a, b) => a - b));
    },
  },
};
</script>

<style scoped>

.v-btn.default {
  background-color: #f5f5f5;
  color: #000;
}
.v-btn.success {
  background-color: #82ae84; /* Green shade for highlight */
  color: white;
}
.non-interactive {
  pointer-events: none; /* Onemogućava klikove i hover */
}
/* Prikazivanje meseca u malim slovima */
.month-btn {
  text-transform: lowercase; /* Prikazivanje malih slova */
}
/* Prilagodite dugmad na manjim ekranima */
@media (max-width: 600px) {
  .month-btn {
    font-size: 12px; /* Smanjite font */
    padding: 4px;    /* Manje padding-a */
  }
}
</style>
