 <template>
        <v-card>
            {{ produkt.Slug }} - 
            {{ produkt.NazivRoba }} 
        <!--WebShopKreiranjeTabela @komponentadetalji="pokrenutosaroot"/>
        <WebShopKreiranjeTabelaEdit @komponentadetalji="pokrenutosaroot"/-->


        <v-expansion-panels focusable>
            <v-expansion-panel>                           
                <v-expansion-panel-title                                                 
                    color="#007BFF"
                    class="ma-2 white--text"
                    >

                    Dodavanje slike ili video fajla +
                </v-expansion-panel-title>

                <v-expansion-panel-text color="#D6D8D9">
                    <v-card  class="ma-2 white--text">
                        <v-card-text>
                            <v-row>
                                <v-col
                                cols="12"
                                sm="4"
                                
                                >
                                    
                                    <v-text-field
                                        v-model="addproduktimediji.Thumb"
                                        label="Thumb image/video artikla*"
                                        id="Thumb-input"
                                        name="Thumb-input"
                                        required 
                                        clearable                                                                                    
                                        flat                            
                                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                                        variant="outlined"
                                    ></v-text-field>

                                    <v-img
                                        class="image-fit"
                                        contain
                                        
                                        max-height="20"
                                        max-width="40"
                                        :src="addproduktimediji.Thumb"
                                        ></v-img>
                                </v-col>   

                                <v-col
                                cols="12"
                                sm="4"
                                
                                >
                                    
                                    <v-text-field
                                        v-model="addproduktimediji.Src"
                                        label="Slika/video artikla*"
                                        id="Slika-input"
                                        name="Slika-input"
                                        required 
                                        clearable                                                                                    
                                        flat                            
                                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                                        variant="outlined"
                                    ></v-text-field>
                                    <input type="file"  @change="onSrcChanged">    
                                    <v-img
                                        class="image-fit"
                                        contain
                                        
                                        max-height="100"
                                        max-width="200"
                                        :src="addproduktimediji.Src"
                                        ></v-img>
    	                                                                    
                                </v-col>     
                                <v-col
                                cols="12"
                                sm="2"
                                
                                >
                                    
                                    <v-autocomplete
                                    v-model="addproduktimediji.Tip"
                                    :value="addproduktimediji.Tip"
                                    :items="tipovimedijaselect"
                                                                
                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                    variant="outlined"
                                    label="Tip medija"
                                    ></v-autocomplete>
                                </v-col>   

                                <v-col
                                cols="12"
                                sm="2"
                                
                                >
                                    
                                    <v-autocomplete
                                    v-model="addproduktimediji.Format"
                                    :value="addproduktimediji.Format"
                                    :items="tipoviformataselect"
                                                                
                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                    variant="outlined"
                                    label="Format"
                                    ></v-autocomplete>
                                </v-col>                                                                                                                                            
                            </v-row>  
                            <v-row class="justify-center">                                               

                                <v-btn
                                @click="addmedia()"
                                class="same-width-btn text-none"
                                color="green-darken-2"
                                rounded="0"
                                variant="outlined"
                                prepend-icon="mdi-content-save-all"
                                style="width: 200px;" 
                                >
                                SAČUVAJ MEDIJ
                                </v-btn>                                       
                            </v-row>                                          
                        </v-card-text>

                        <v-card-text>
                           
                            
                        </v-card-text>

                        <v-card-action >

                           
                        </v-card-action>
                    </v-card>
                </v-expansion-panel-text>
            
            </v-expansion-panel>
        </v-expansion-panels>
        
        <v-card-text>


            <v-container>

                <v-row>

                    <v-table
                    fixed-header
                    height="500px"
                    style="width:100%"

                    
                   
                    >


                        <thead>
                        <tr>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 ">Thumb</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Src</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Tip</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200">Format</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 text-right">Dlt</th>
                            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 text-right">Upd</th>
                        </tr>

                        </thead>

                        <tbody>



                            <tr v-for="(item) in produktimediji" :key="item.Rb">
                                <td style="width:10%">
                                    {{ item.Thumb }}
                                    <!--v-text-field
                                        v-model="item.Thumb"
                                        label="Thumb artikla*"
                                        id="Thumb-input"
                                        name="Thumb-input"
                                        required 
                                        clearable                                                                                    
                                        flat
                                    ></v-text-field-->                                    
                                    <v-img
                                    alt="img" 
                                        class="image-fit"
                                        contain
                                        lazy-src="https://picsum.photos/id/11/10/6"
                                        max-height="20"
                                        max-width="40"
                                        :src="item.Thumb"
                                        ></v-img>
                                </td>
                                <td style="width:50%;">
                                    {{ item.Src }}
                                    <!--v-text-field
                                        
                                        v-model="item.Src"
                                        label="Src slike*"
                                        id="Src-slike"
                                        name="Src-slike"
                                        required 
                                        clearable                                                                                    
                                        flat
                                    ></v-text-field-->   
                                    <v-img
                                        class="image-fit"
                                        contain
                                        lazy-src="https://picsum.photos/id/11/10/6"
                                        max-height="100"
                                        max-width="200"
                                        :src="item.Src"
                                        ></v-img>

                                    <input type="file"  @change="onEditSrcChanged">    
                                </td>

                                <td style="width:20%;">

                                    <v-autocomplete

                                    v-model="item.Tip"
                                    :value="item.Tip"
                                    :items="tipovimedijaselect"
                                                                
                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                    variant="outlined"
                                    ></v-autocomplete>

                                </td>
                                <td style="width:20%;">
                                    <v-autocomplete
                                    v-model="item.Format"
                                    :value="item.Format"
                                    :items="tipoviformataselect"
                                                                
                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                    variant="outlined"
                                    ></v-autocomplete>

                                </td>

                                <td class="text-right">
                                    <v-btn
                                                                             
                                        icon="mdi-delete"
                                        variant="text"
                                        @click="deleteItem(item.Rb, item.Slug, item.Src)"
                                    ></v-btn>
                                </td>
                                <td class="text-right">

                                    <v-btn
                                                                           
                                        icon="mdi-content-save"
                                        variant="text"
                                        @click="updatemedia(item.Rb, item.Slug, item.Thumb, item.Src, item.Tip, item.Format)"
                                    ></v-btn>

                                </td>                            
                            </tr>

                        </tbody>        
                    </v-table>
                </v-row>

            </v-container>

        </v-card-text>


        <v-card-actions>


            <v-snackbar
            v-model="alertsnackbar"
            :multi-line="multiLine"
            relative
            centered
            shaped
            middle
            vertical
            >
            {{ snackbartekst }}

            <template v-slot:actions>
                <v-btn
                color="red"
                text

                @click="alertsnackbar = false"
                >
                Zatvori
                </v-btn>
            </template>
            </v-snackbar>



            <v-spacer></v-spacer>
        
            <!--v-btn
            color="red darken-1"
            text
            @click="zatvoriFormu(false,'odustao')"
            
            >
            Odustani
            </v-btn-->

            <!--v-btn
            class="ma-2"
            outlined            
            color="green darken-1"
            @click="adddetalje();"
            
            >
            Upiši
            </v-btn-->
            <v-spacer></v-spacer>
        </v-card-actions>

        <v-dialog 
            v-model="dialogDelete" max-width="500px"       
            class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
            color="blue-grey darken-3"
            dark>
            <v-card>
                <v-card-title class="headline">Jeste li sigurni u brisanje stavke {{deleterb}} - {{ deletesifra }} ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"        
                    class="ma-1"
                    color="grey"
                    plain text 
                    @click="closeDelete">Cancel</v-btn>
                <v-btn   
                    class="ma-1"
                    color="error"
                    plain text 
                    @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>        


     </v-card>
</template>                

<script>
import axios from "axios";
/*import WebShopKreiranjeTabela from './WebShopKreiranjeTabela.vue';
import WebShopKreiranjeTabelaEdit from './WebShopKreiranjeTabelaEdit.vue';*/


export default {

    data() {
        return {

            components:{

                },

            selectedThumbFile: null,
            selectedSrcFile: null,
            selectedSrcFileImage:'',
            selectedEditSrcFile: null,
            selectedEditSrcFileImage:'',            
            //assetpath:require('@assets/mediji/ms-651.jpg'),

            produktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1,
                ThumbSlika:'',
                ThumbSlikaIme:'',
                SrcSlika:'',
                SrcSlikaIme:'',
                PublicPathMedija:''

            },
            defaultproduktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1,
                ThumbSlika:'',
                ThumbSlikaIme:'',
                SrcSlika:'',
                SrcSlikaIme:'',
                PublicPathMedija:''
            },
            exportproduktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1,
                ThumbSlika:'',
                ThumbSlikaIme:'',
                SrcSlika:'',
                SrcSlikaIme:'',
                PublicPathMedija:''
            }, 
            
            addproduktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1,
                ThumbSlika:'',
                ThumbSlikaIme:'',
                SrcSlika:'',
                SrcSlikaIme:'',
                PublicPathMedija:''
            },
            tipovimedijaselect:['image', 'video'],
            tipoviformataselect:['jpg', 'gif', 'png', 'mp4', 'mpeg', 'youtube'],
            alert:false,
            alerttext:'',

            alertsnackbar:false,
            snackbartekst:'',      
            multiLine: true,

            alertsnackbar2:false,
            snackbartekst2:'',

            deletesifra:'',
            deletenaziv:'',
            deleterb:0,
            deleteproduktmedij:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1,
                ThumbSlika:'',
                ThumbSlikaIme:'',
                SrcSlika:'',
                SrcSlikaIme:'',
                PublicPathMedija:''
            },
            dialogDelete:false,
            loading: false,                           
        }
    },

    props:{
        edit: false,
        
        produkt: {
            
            Sifra:'',
            Slug:'',
            NazivRoba:'',
            Datum:'',
            Staracijena:0, 
            Cijena:0,
            Valuta:'',
            Akcija:0, 
            Akcijadatumstart:'', 
            Akcijadatumkraj:'', 
            Popust:'', 
            Akcijatekst:'',
            Freeshiping:0, 
            Ribbon:0, 
            Ribbontext:'', 
            Prioritet:0,
            Topprodukt:0,
            Cijenanaupit:0, 
            Menukategorija:0,
            Kategorija:0,
            Subkategorija:0,
            SlikaString:0,
            OpisRoba:0,
            KratkiOpisRoba:0,
            KategorijaNaziv:0,           
            Brand:'',
            Status:0

        }
    },

    components: {
     
    },   
    
    computed:{
       

        
    },
    created(){
       

    },

    mounted() {  
        /* vue3
        this.$root.$on('komponentamediji', (data) => {
           
           this.pokrenutosaroot(data);

        });*/

        this.popunikontrole(this.produkt.Slug);

    },
    
    watch:{ 
        produkt: {
            handler(newVal, oldVal) {

            this.popunikontrole(newVal.Slug);
            },
            deep: true, // Praćenje dubinskih promena unutar objekta
        },

    },

    methods:{

        onSrcChanged (event) {
            this.selectedSrcFile = event.target.files[0]
            
            if (this.selectedSrcFile!=""){
                this.addproduktimediji.Thumb =app_public_medija_path + this.selectedSrcFile["name"]
                this.addproduktimediji.Src=app_public_medija_path + this.selectedSrcFile["name"]
                this.createImg( this.selectedSrcFile, false);
                
            }
        },
        onEditSrcChanged (event) {
            this.selectedEditSrcFile = event.target.files[0]
         
            if (this.selectedEditSrcFile!=""){
                //this.item.Thumb =app_public_medija_path + this.selectedSrcFile["name"]
                //this.item.Src=app_public_medija_path + this.selectedSrcFile["name"]
                this.createImg( this.selectedEditSrcFile, true);
                
            }
        },

        createImg(file, edit){
            // we will preview our image before upload
            var image = new Image;
            var reader = new FileReader;

            reader.onload = (e) =>{
                if(edit==false){//ako je dodavanje medija
                    this.selectedSrcFileImage = e.target.result;
                }else
                {//ako je izmjena
                    this.selectedEditSrcFileImage = e.target.result;
                }
            };
            reader.readAsDataURL(file);
        },

        pokrenutosaroot(data){
            if(data.SLug==""){
                this.produktimediji = Object.assign({}, this.defaultproduktimediji);
                return;
            };
            this.popunikontrole(data.SLug);
        },

        async obavijestHeading(strTekst){
            this.snackbartekst=strTekst;
            this.alertsnackbar=true;
        },
        
        zatvoriFormu (editmodusrada, poruka) {
        
            this.$emit('ws_KreiranjeEditClick',false,editmodusrada,true, poruka ) 

        },
        updatemedia(rb,Slug, Thumb, Src, Tip, Format){
                this.exportproduktimediji.Rb=rb;
                this.exportproduktimediji.Slug=Slug;
                this.exportproduktimediji.Thumb=Thumb;
                this.exportproduktimediji.Src=Src;
                this.exportproduktimediji.Tip=Tip;
                this.exportproduktimediji.Format=Format;
                this.exportproduktimediji.Aktivan=1;

                if( this.selectedEditSrcFileImage!="")
                {//ako je u izmjeni određen lokani fajl, a ne link na internetu
                    this.exportproduktimediji.PublicPathMedija=app_public_medija_path;
                    this.exportproduktimediji.SrcSlika=this.selectedEditSrcFileImage;
                    this.exportproduktimediji.SrcSlikaIme=this.selectedEditSrcFile["name"]; 
                    this.exportproduktimediji.Thumb=app_public_medija_path + this.selectedEditSrcFile["name"];
                    this.exportproduktimediji.Src=app_public_medija_path + this.selectedEditSrcFile["name"];     
                            
                }
                fetch('/api/apiwsproduktmediakreiranje', {
                    method: 'put',
                    body: JSON.stringify(this.exportproduktimediji),
                    headers: {
                        'content-type': 'application/json'
                    }
                    })
                    .then(res => res.json())
                    .then(data => {

                        if(data.greska==true){
                            this.obavijestHeading("Došlo je do greške u izmjeni medija produkta. Originalna greška: " + data.data);
                            return;
                        }                    
                        
                        this.obavijestHeading("Mediji produkta su izmjenjeni!");
                        this.popunikontrole(Slug);
                        this.exportproduktimediji.SrcSlika='';
                        this.exportproduktimediji.SrcSlikaIme='';
                        this.exportproduktimediji.PublicPathMedija='';

                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijestHeading("Došlo je do greške upisa! " + err.toString())
                    
                    );


        },

        addmedia(){
                
                this.addproduktimediji.Slug=this.produkt.Slug;
                this.addproduktimediji.PublicPathMedija=app_public_medija_path;
                this.addproduktimediji.SrcSlika=this.selectedSrcFileImage;
                this.addproduktimediji.SrcSlikaIme=this.selectedSrcFile["name"];

                fetch('/api/apiwsproduktmediakreiranje', {
                    method: 'post',
                    body: JSON.stringify(this.addproduktimediji),
                    headers: {
                        'content-type': 'application/json'
                    }
                    })
                    .then(res => res.json())
                    .then(data => {

                        if(data.greska==true){
                            this.obavijestHeading("Došlo je do greške u dodavanju medija produkta. Originalna greška: " + data.data);
                            return;
                        }
                    
                        this.obavijestHeading("Mediji produkta su dodati! Poruka : "+ data.data);
                        this.popunikontrole(this.produkt.Slug);
                        this.addproduktimediji.Thumb='';
                        this.addproduktimediji.Src='';
                        this.addproduktimediji.Format='';
                        this.addproduktimediji.Tip='';
                        this.addproduktimediji.SrcSlika='';
                        this.addproduktimediji.SrcSlikaIme='';
                        this.addproduktimediji.PublicPathMedija='';

                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijestHeading("Došlo je do greške upisa! " + err.toString())
                    
                    );


        },

        popunikontrole(slug){
            if (slug==""){
                
                this.produktimediji = Object.assign({}, this.defaultproduktimediji)
                return;
            }           
               axios.get('/api/apiwsproduktmediakreiranje/'+ slug, {
                    })
                    .then((response) => {                      
                        this.produktimediji = response.data.data;
                        
                        
                      
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

        }, 


        obrisimedia(rb) {
        //if (confirm('Da li ste sigurni u brisanje?')) {
            fetch(`api/apiwsproduktmediakreiranje/${rb}`, {
            method: 'delete'
            })
            .then(res => res.json())
            .then(data => {
                //alert('brandova je obrisana!');
                this.obavijestHeading("Slog je obrisan!");
                this.popunikontrole(this.deletesifra);
            })
            .catch(err => console.log(err.toString()));
            //console.error(this.props.url, status, err.toString()));
        //}
        },
        deleteItem (rb, slug, src) {
           //alert(rb + slug+src); 
            //this.deleteproduktmedij = this.produktimediji.indexOf(item)
            //this.deleteproduktmedij = Object.assign({}, item)
            this.deletesifra= slug
            this.deletebrandnaziv= src
            this.deleterb=rb
            
            this.dialogDelete = true
        },
        async closeDelete () {
             await this.loadingslova;
            this.dialogDelete = false;

            //this.ispraznikontrole()
        },
        deleteItemConfirm () {
            //this.desserts.splice(this.editedIndex, 1)
            this.obrisimedia( this.deleterb);
            this.closeDelete()
        },

        async loadingslova () {
            this.loading = true
            await new Promise(resolve => setTimeout(resolve, 3000))
            this.loading = false
        },


    },


    
}
</script>