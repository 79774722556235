<template>

  <v-app>

    <v-main >
        <v-row>
          <v-col         
            class="d-flex align-center"
            cols="12"
            sm="12">  

            <v-card
                elevation="0"
                
                min-width="80%"
                class="mx-auto pa-4 "

                
            >

                <v-card-title

                class="headline">
                Korpa
                <v-spacer></v-spacer>
                
                </v-card-title>

                <v-row>
                    <v-col         
                        class="align-center"
                        cols="12"
                        sm="8">  
                        
                        <v-card                    
                                outlined
                                color="green lighten-4  "
                                class="black--text"

                            >
                            
                            <v-card-title class="headline">
                                Podaci za plaćanje
                            </v-card-title>
                            
                            <v-card>
                                    <v-card-text>
                           

                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="12"  
                                                                
                                                >                  
                                                    <v-checkbox
                                                    v-model="chkenabled1"
                                                    hide-details
                                                    :disabled="chkenabled2"
                                                    label="Novi sam korisnik, želim otvoriti nalog (zapamtite email i password)"
                                                    >
                                                    </v-checkbox>
                                                </v-col>

                                            </v-row>

                                          
                                            <v-row>
                                                <v-col
                                                    cols="12" sm="12"  
                                                                
                                                >                  
                                                    <v-checkbox
                                                    v-model="chkenabled2"
                                                    :disabled="chkenabled1"
                                                    
                                                    label="Promjena podataka poput imena, adrese, grada za postojećeg korisnika"
                                                    >
                                                    </v-checkbox>
                                                </v-col>

                                            </v-row>    
                                            <v-row>
                                                <v-col
                                                cols="12"
                                                sm="6"

                                                >
                                                <v-text-field

                                                    type="text"
                                                    id="first_name"
                                                    name="first_name"                                        
                                                    v-model="customer.first_name"
                                                    :disabled="paymentProcessing"
                                                    label="Ime lica/Naziv firme"
                                                    required
                                                    hint="Ime lica/Naziv firme je obavezan za unos"               
                                                    flat
                                                    clearable
                                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                    variant="outlined"
                                                    :rules="imerules" 

                                                ></v-text-field>
                                                </v-col>

                                                <v-col
                                                cols="12"
                                                sm="6"
                                                
                                                >
                                                <v-text-field
                                                    type="text"  
                                                    id="last_name"
                                                    name="last_name"
                                                    v-model="customer.last_name"
                                                    :disabled="paymentProcessing"
                                                    label="Prezime"
                                                    required
                                                    hint="Prezime je obavezno za unos (ukoliko se radi o pravnom licu unesite samo crticu)"               
                                                    clearable
                                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                    variant="outlined"
                                                    :rules="imerules" 
                                                ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col
                                                cols="12"
                                                sm="6"
                                                
                                                >
                                                <v-text-field

                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        v-model="customer.email"
                                                        
                                                        label="Email/Korisničko ime*"
                                                        required
                                                        hint="Email je obavezan za unos"               
                                                        flat
                                                        clearable
                                                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                        variant="outlined"
                                                        :rules="textrules" 
                                                ></v-text-field>
                                                </v-col>

                                                <v-col
                                                cols="12"
                                                sm="3"
                                                
                                                >
                                                <v-text-field

                                                        
                                                        id="password"
                                                        name="password"
                                                        v-model="customer.password"
                                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show1 ? 'text' : 'password'"
                                                        @click:append="show1 = !show1"
                                                        label="Password* :"
                                                        required
                                                        hint="Password je obavezan za unos"               
                                                        flat
                                                        clearable
                                                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                        variant="outlined"
                                                        :rules="textrules" 
                                                ></v-text-field>
                                                </v-col>

                                                <v-col
                                                cols="12"
                                                sm="3"
                                                
                                                >
                                                <v-text-field

                                                        type="phone"
                                                        id="telefon"
                                                        name="telefon"
                                                        v-model="customer.telefon"
                                                        :disabled="paymentProcessing"
                                                        label="Telefon"
                                                        required
                                                        hint="Telefon je obavezan za unos"               
                                                        flat
                                                        clearable
                                                        prepend-inner-icon="mdi-order-numeric-ascending"
                                                        variant="outlined"
                                                        :rules="textrules" 
                                                ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col
                                                cols="12"
                                                sm="6"
                                                
                                                >
                                                <v-text-field

                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        v-model="customer.address"
                                                        :disabled="paymentProcessing"
                                                        label="Adresa"
                                                        required
                                                        hint="Adresa je obavezna za unos"               
                                                        flat
                                                        clearable
                                                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                        variant="outlined"
                                                        :rules="textrules" 
                                                ></v-text-field>
                                                </v-col>

                                                <v-col
                                                cols="12"
                                                sm="2"
                                                
                                                
                                                >
                                                <v-text-field

                                                        type="text"
                                                        id="city"
                                                        name="city"
                                                        v-model="customer.city"
                                                        :disabled="paymentProcessing"
                                                        label="Grad/Mjesto"
                                                        required
                                                        hint="Grad je obavezan za unos"               
                                                        flat
                                                        clearable
                                                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                        variant="outlined"
                                                        :rules="textrules" 
                                                ></v-text-field>
                                                </v-col>

                                                <v-col
                                                cols="12"
                                                sm="2"
                                                
                                                >
                                                <v-text-field
                                                        type="text"
                                                        id="state"
                                                        name="state"
                                                        v-model="customer.state"
                                                        :disabled="paymentProcessing"
                                                        label="Država"
                                                        required
                                                        hint="Država je obavezna za unos"               
                                                        flat
                                                        clearable
                                                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                        variant="outlined"
                                                        :rules="textrules" 
                                                ></v-text-field>
                                                </v-col>
                    
                                                <v-col
                                                cols="12"
                                                sm="2"
                                                
                                                >
                                                <v-text-field
                                                        type="text"
                                                        id="zip_code"
                                                        name="zip_code"
                                                        v-model="customer.zip_code"
                                                        :disabled="paymentProcessing"                            
                                                        label="Poštanski broj"
                                                        required
                                                        hint="Poštanski kod je obavezan za unos"               
                                                        flat
                                                        clearable
                                                        prepend-inner-icon="mdi-order-numeric-ascending"
                                                        variant="outlined"
                                                        :rules="textrules" 
                                                ></v-text-field>
                                                </v-col>
                                            </v-row>




                                            <v-row>

                                                <v-expansion-panels focusable>

                                                        <v-expansion-panel
                                                        >
                                                            <v-expansion-panel-title>Dostavljanje na različitu adresu</v-expansion-panel-title>
                                                            <v-spacer></v-spacer>
                                                            <v-expansion-panel-text  >
                                                                
                                                                <v-card elevation="0">
                                                                        <v-card-text>
                                                                            	
                                                                            
                                                                                <v-card elevation="0">
                                                                                    <v-card-text>
                                                                                        <v-row>
                                                                                            Ukoliko vam se razlikuje adresa plaćanja i adresa na koju želite da vam bude dostavljena narudžba, popunite sljedeće podatke.
                                                                                        </v-row>

                                                                                        <v-row>
                                                                                        <!-- ================================================ start ========================================================================== -->  

                                                                                            <v-col
                                                                                            cols="12"
                                                                                            sm="6"

                                                                                            >
                                                                                            <v-text-field

                                                                                                type="text"
                                                                                                id="first_name"
                                                                                                name="first_name"                                        
                                                                                                v-model="altcustomer.first_name"
                                                                                                :disabled="paymentProcessing2"
                                                                                                label="Ime :"
                                                                                                required

                                                                                                hint="Ime je obavezno za unos"               
                                                                                                flat
                                                                                                clearable
                                                                                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                                                                variant="outlined"
                                                                                                :rules="imerules" 

                                                                                            ></v-text-field>
                                                                                            </v-col>

                                                                                            <v-col
                                                                                            cols="12"
                                                                                            sm="6"
                                                                                            
                                                                                            >
                                                                                            <v-text-field
                                                                                                type="text"  
                                                                                                id="last_name"
                                                                                                name="last_name"
                                                                                                v-model="altcustomer.last_name"
                                                                                                :disabled="paymentProcessing2"
                                                                                                label="Prezime :"
                                                                                                required
                                                                                                hint="Prezime je obavezno za unos"               
                                                                                                flat
                                                                                                clearable
                                                                                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                                                                variant="outlined"
                                                                                                :rules="imerules" 
                                                                                            ></v-text-field>
                                                                                            </v-col>
                                                                                        </v-row>

                                                                                        <v-row>
                                                                                            <v-col
                                                                                            cols="12"
                                                                                            sm="6"
                                                                                            
                                                                                            >
                                                                                            <v-text-field

                                                                                                    type="email"
                                                                                                    id="email"
                                                                                                    name="email"
                                                                                                    v-model="altcustomer.email"
                                                                                                    :disabled="paymentProcessing2"
                                                                                                    label="Email :"
                                                                                                    required
                                                                                                    hint="Email je obavezan za unos"               
                                                                                                    flat
                                                                                                    clearable
                                                                                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                                                                    variant="outlined"
                                                                                                    :rules="textrules" 
                                                                                            ></v-text-field>
                                                                                            </v-col>

                                                                                            <v-col
                                                                                            cols="12"
                                                                                            sm="6"
                                                                                            
                                                                                            >
                                                                                            <v-text-field

                                                                                                    type="phone"
                                                                                                    id="telefon"
                                                                                                    name="telefon"
                                                                                                    v-model="altcustomer.telefon"
                                                                                                    :disabled="paymentProcessing2"
                                                                                                    label="Telefon"
                                                                                                    required
                                                                                                    hint="Telefon je obavezan za unos"               
                                                                                                    flat
                                                                                                    clearable
                                                                                                    prepend-inner-icon="mdi-order-numeric-ascending"
                                                                                                    variant="outlined"
                                                                                                    :rules="textrules" 
                                                                                            ></v-text-field>
                                                                                            </v-col>
                                                                                        </v-row>

                                                                                        <v-row>
                                                                                            <v-col
                                                                                            cols="12"
                                                                                            sm="6"
                                                                                            
                                                                                            >
                                                                                            <v-text-field

                                                                                                    type="text"
                                                                                                    id="address"
                                                                                                    name="address"
                                                                                                    v-model="altcustomer.address"
                                                                                                    :disabled="paymentProcessing2"
                                                                                                    label="Adresa"
                                                                                                    required
                                                                                                    hint="Adresa je obavezna za unos"               
                                                                                                    flat
                                                                                                    clearable
                                                                                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                                                                    variant="outlined"
                                                                                                    :rules="textrules" 
                                                                                            ></v-text-field>
                                                                                            </v-col>

                                                                                            <v-col
                                                                                            cols="12"
                                                                                            sm="2"
                                                                                            
                                                                                            
                                                                                            >
                                                                                            <v-text-field

                                                                                                    type="text"
                                                                                                    id="city"
                                                                                                    name="city"
                                                                                                    v-model="altcustomer.city"
                                                                                                    :disabled="paymentProcessing2"
                                                                                                    label="Grad/Mjesto"
                                                                                                    required
                                                                                                    hint="Grad je obavezan za unos"               
                                                                                                    flat
                                                                                                    clearable
                                                                                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                                                                    variant="outlined"
                                                                                                    :rules="textrules" 
                                                                                            ></v-text-field>
                                                                                            </v-col>

                                                                                            <v-col
                                                                                            cols="12"
                                                                                            sm="2"
                                                                                            
                                                                                            >
                                                                                            <v-text-field
                                                                                                    type="text"
                                                                                                    id="state"
                                                                                                    name="state"
                                                                                                    v-model="altcustomer.state"
                                                                                                    :disabled="paymentProcessing2"
                                                                                                    label="Država"
                                                                                                    required
                                                                                                    hint="Država je obavezna za unos"               
                                                                                                    flat
                                                                                                    clearable
                                                                                                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                                                                                                    variant="outlined"
                                                                                                    :rules="textrules" 
                                                                                            ></v-text-field>
                                                                                            </v-col>
                                                                
                                                                                            <v-col
                                                                                            cols="12"
                                                                                            sm="2"
                                                                                            
                                                                                            >
                                                                                            <v-text-field
                                                                                                    type="text"
                                                                                                    id="zip_code"
                                                                                                    name="zip_code"
                                                                                                    v-model="altcustomer.zip_code"
                                                                                                    :disabled="paymentProcessing2"                            
                                                                                                    label="Poštanski broj"
                                                                                                    required
                                                                                                    hint="Poštanski kod je obavezan za unos"               
                                                                                                    flat
                                                                                                    clearable
                                                                                                    prepend-inner-icon="mdi-order-numeric-ascending"
                                                                                                    variant="outlined"
                                                                                                    :rules="textrules" 
                                                                                            ></v-text-field>
                                                                                            </v-col>
                                                                                        </v-row>   

                                                                                        <v-row>
                                                                                        <v-col
                                                                                            cols="12"
                                                                                                        
                                                                                        >                  
                                                                                            <v-checkbox
                                                                                            v-model="chkenabled"
                                                                                            hide-details
                                                                                            
                                                                                            label="Pošalji narudžbu na ovu adresu"
                                                                                            >
                                                                                            </v-checkbox>
                                                                                        </v-col>

                                                                                        </v-row>
                                                                                    </v-card-text>
                                                                                </v-card>
                                                                            
                                                                        </v-card-text>
                                                                </v-card>                                                             
                                                            </v-expansion-panel-text>
                                                        </v-expansion-panel>


                                                    </v-expansion-panels>                                                 
                                            </v-row>

                                      
                                        <v-card elevation="0">
                                            <v-card-text>        
                                                <small>* je indikacija za obavezno polje</small><br>
                                                <small style="color:red">NAPOMENA: Postojeći kupac može u poljima, osim email i password, promijeniti sve vrijednosti za slučaj da je došlo do promjene lokacije ili imena lica</small>
                                            </v-card-text>
                                        </v-card>
                                    </v-card-text>

                                    <v-card-actions>

                                            <!--v-snackbar
                                            v-model="alertsnackbar"
                                            :multi-line="multiLine"
                                            relative
                                            left
                                            shaped
                                            middle
                                            >
                                            {{ snackbartekst }}

                                            <template v-slot:action="{ attrs }">
                                                <v-btn
                                                    class="ma-2"
                                                    outlined
                                                    color="red"
                                                    text
                                                    v-bind="attrs"
                                                    @click="alertsnackbar = false"
                                                >
                                                Zatvori
                                                </v-btn>
                                            </template>
                                            </v-snackbar-->

                                            <v-snackbar
                                                :icon="ikonasnackbar"
                                                v-model="alertsnackbar"
                                                :multi-line="multiLine"
                                                :color="colorsnackbar"
                                                relative
                                                centered
                                                middle
                                                >
                                                <v-layout align-center pr-4>
                                                    <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
                                                    <v-layout column>
                                                    <div>
                                                        <strong>{{ snackbartitle }}</strong>
                                                    </div>
                                                    <div>{{ snackbartekst }}</div>
                                                    </v-layout>
                                                </v-layout>
                                                

                                                <template v-slot:action="{ attrs }">
                                                    <v-btn
                                                    color="white"
                                                    text
                                                    v-bind="attrs"
                                                    @click="alertsnackbar = false"
                                                    >
                                                    <v-icon>clear</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-snackbar>   


                                    </v-card-actions>
                            </v-card>
                            
                        </v-card>
                    </v-col>

                    <v-col         
                       class="align-center "
                        cols="12"
                        sm="4"
                        
                        >  

                        <v-card
                            outlined
                            color="green lighten-4"
                            class="black--text"
                        >

                            <v-card-title class="headline">
                                Vaša narudžba
                            </v-card-title>

                            <v-card>

                                <v-simple-table class="custom-v-table"
                                fixed-header
                                dense
                                style="max-height: 400px; 
                                overflow-y: auto;"
                                min-height="300px"
                                >
                                    <thead >
                                    <tr class="">
                                        <!--th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-200 rounded-tl rounded-bl">Proizvod</th-->
                                        <th style="width: 50%; text-align: left;">Proizvod</th>
                                        <th style=" text-align: right;">Kol</th>
                                        <th style=" text-align: right;">Cijena</th>
                                        <th style=" text-align: right;">Iznos</th>
                                        <th></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(item, index) in cart" :key="item.Slug">
                                            <td style="width: 50%; text-align: left;" v-text="item.NazivRoba"></td>
                                            <td style=" text-align: right;" v-text="formatBroj(item.quantity)"></td>
                                            <td style=" text-align: right;" v-text="formatBroj(item.Cijena)"></td>
                                            <td style=" text-align: right;" v-text="formatBroj(item.Iznos)"></td>
                                            <!--td class="p-4" v-text="cartLineTotal(item)"></td-->
                                            <td class="">
                                                <v-icon
                                                    small
                                                    class="mr-2"
                                                    @click="$store.commit('obrisiIzCarta', index)"
                                                    >
                                                    mdi-delete
                                                </v-icon>

                                            </td>
                                        </tr>
                                        <tr class="custom-total-row">
                                            <td class="p-4" style="width: 50%; text-align: left;">TOTAL :</td>
                                            <td class="p-4 "></td>
                                            <td class="w-10"></td>
                                            <td class="p-4 " style=" text-align: right;" v-text="formatBroj(cartTotal)"></td>
                                            <td class="w-10 "></td>
                                        </tr>
                                    </tbody>        
                                </v-simple-table>
                            </v-card>
                        </v-card>  
                    </v-col>

                
                </v-row>


                <v-row>
                    <v-col         
                        class="align-center"
                        cols="12"
                        sm="8">  
                            <v-card                     
                                outlined
                                color="#A0D6FA"
                                class="black--text kartice_1"

                                >
                                
                                <v-card-title class="headline">
                                    Načini plaćanja
                                </v-card-title>
                                <v-card-text>

                                    <v-expansion-panels focusable >

                                        <v-expansion-panel
                                        >
                                            <v-expansion-panel-title>
                                                Plaćanje po preuzimanju (gotovina)
                                            </v-expansion-panel-title>
                                            <v-spacer></v-spacer>
                                            <v-expansion-panel-text  >
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                        Plaćanje se vrši pri preuzimanju pošiljke (gotovina) na navedenoj adresi. Ovde možete odrediti alternativno lice i adresu dostave ukoliko nije isto kao u podacima za plaćanje. U slučaju da primatelj nije u mogućnosti zaprimiti pošiljku na definiranoj dostavnoj adresi u trenutku dostave, dostavljač ostavlja obavijest o prispijeću pošiljke.
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end">
                                                        <v-btn
                                                            class="same-width-btn-300 text-none"
                                                            color="green-darken-2"
                                                            rounded="0"
                                                            variant="outlined"
                                                            prepend-icon="mdi-cash-100" 
                                                            @click="processPaymentGotovina"
                                                            :disabled="paymentProcessing1"                                                            
                                                            >
                                                            {{ paymentProcessing1 ? 'Processing' : 'PLATI GOTOVINOM NA ADRESI' }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>

                                        <v-expansion-panel
                                        >
                                            <v-expansion-panel-title>
                                                Plaćanje po predračunu (virman)
                                            </v-expansion-panel-title>
                                            <v-spacer></v-spacer>
                                            <v-expansion-panel-text>
                                                
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                        Dobićete e-mail sa predračunom i računima banaka na koje možete izvršiti plaćanje. Uplatu možete izvršiti korištenjem internet bankarstva, mobilnog bankarstva, putem banke ili pošte. Po zaprimljenoj uplati, šaljemo vam naručene proizvode dostavom na naznačenu adresu. Ukoliko ste se odlučili za preuzimanje robe u poslovnici, istu ćemo Vam pripremiti i obavijestiti Vas o vremenu preuzimanja.
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end">
                                                        <v-btn

                                                            class="same-width-btn-400 text-none"
                                                            color="green-darken-2"
                                                            rounded="0"
                                                            variant="outlined"
                                                            prepend-icon="mdi-receipt-text" 
                                                            @click="processPaymentVirman"
                                                            :disabled="paymentProcessing1"
                                                            >
                                                            {{ paymentProcessing1 ? 'Processing' : 'NARUČITE I PLATITE UPLATNICOM' }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>                                                
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>

                                        <v-expansion-panel  >
                                            <v-expansion-panel-title >
                                                Kartično plaćanje (Stripe)
                                            </v-expansion-panel-title>
                                            <v-spacer></v-spacer>
                                            <v-expansion-panel-text>
                                                
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                            
                                                            <v-btn
                                                                class="ma-2"
                                                                outlined
                                                                color="green darken-1"
                                                                text
                                                                @click="mountedStripe(true)"
                                                                   
                                                                    >
                                                            </v-btn>

                                                            <div class="lg:w-2/3 w-full mx-auto mt-8">

                                                            <div id="card-element"/>
                                                                <!--v-btn

                                                                    color="green darken-1"
                                                                    text
                                                                    @click="processPayment"
                                                                    :disabled="paymentProcessing1"
                                                                    v-text="paymentProcessing1 ? 'Processing' : 'Plati karticom'"
                                                                    
                                                                    >

                                                                </v-btn-->

                                                                <!--v-btn

                                                                    color="red darken-1"
                                                                    text
                                                                    @click="mountedStripe(false)"
                                                                    
                                                                    >
                                                                    Otkaži plaćanje
                                                                </v-btn-->

                                                            
                                                            </div>
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end">
                                                        <v-btn
                                                            class="ma-2"
                                                            outlined
                                                            color="red darken-1"
                                                            text
                                                            @click="mountedStripe(false)"
                                                            
                                                            >
                                                            Otkaži plaćanje
                                                        </v-btn>                                                        
                                                        <v-btn

                                                            class="ma-2"
                                                            outlined
                                                            color="green darken-1"
                                                            text
                                                            @click="processPayment"
                                                            :disabled="paymentProcessing1"
                                                            v-text="paymentProcessing1 ? 'Processing' : 'Plati karticom'"
                                                            
                                                            >

                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>                                                
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>

                                        <v-expansion-panel  >
                                            <v-expansion-panel-title >
                                                Kartično plaćanje (Monri)
                                            </v-expansion-panel-title>
                                            <v-spacer></v-spacer>
                                            <v-expansion-panel-text>
                                                
                                                <v-card elevation="0">
                                                    <v-card-text>
                                                        Monri je prvi domaći online servis za sigurno plaćanje. Svaki kupac koji želi da izvrši kupovinu na našoj web trgovini može izvršiti uplatu putem Monri. Ovom uslugom moguće je plaćanje svim MasterCard, Maestro i VISA karticama. Sva plaćanja će se vršiti u domaćoj BAM valuti.

                                                    </v-card-text>
                                                    <v-card-text>
                                                            Ukoliko nisu automatski prikazane kontrole za unos broja kreditne kartice pritisnite dugme "Prikaži komponente za unos"
                                                            <v-btn
                                                                class="ma-2"
                                                                outlined
                                                                color="green darken-1"
                                                                text
                                                                @click="mountedMonri(true)"                                                                   
                                                                    >
                                                                    Prikaži komponente za unos 
                                                            </v-btn>

                                                            <form action="" method="post" id="payment-form">
                                                                <div class="form-row">
                                                                    <label for="card-element">
                                                                        Kreditna ili debitna kartica
                                                                    </label>
                                                                    <div id="card-element">
                                                                        <!-- A Monri Component will be inserted here. -->
                                                                    </div>

                                                                    <!-- Used to display Component errors. -->
                                                                    <div id="card-errors" role="alert"></div>
                                                                </div>
                                                                <div align="right">
                                                                <v-btn
                                                                    class="same-width-btn text-none"
                                                                    color="green-darken-2"
                                                                    rounded="0"
                                                                    variant="outlined"
                                                                    prepend-icon="mdi-account-credit-card" 
                                                                    type="submit"
                                                                    >
                                                                    Plati karticom
                                                            </v-btn>
                                                       
                                                                </div>
                                                            </form>
                                                    </v-card-text>

                                                    <v-card-actions class="justify-end">
                                                        <!--v-btn
                                                            class="ma-2"
                                                            outlined
                                                            color="red darken-1"
                                                            text
                                                            @click="mountedStripe(false)"
                                                            
                                                            >
                                                            Otkaži plaćanje
                                                        </v-btn-->                                                        



                                                    </v-card-actions>
                                                </v-card>                                                
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>

                                    </v-expansion-panels>                                    
                                </v-card-text>
                            </v-card>
                    </v-col>
                </v-row>

            </v-card>   

          </v-col>
        </v-row>
    </v-main>
  </v-app>              
</template>
<script>
    var numeral = require('numeral');
    //import { bus } from '../../app.js'//--- busproba
    import { loadStripe } from '@stripe/stripe-js';
    import axios from 'axios';
    export default {
        inject:["eventBus"],//bus mitt vue3
        data() {
            return {
                
                brojMonrinarudzbe:'',

                show1:false,

                /*start stripe */
                stripe: {},
                cardElement: {},
                /* end stripe */

                chkenabled2:false,
                chkenabled1:false,
                chkenabled:false,
                tabs: null,

                aCart:[],
                trnsaltcust:[],
                altcustomer:{
                    first_name: '',
                    last_name: '',
                    email: '',
                    telefon:'',
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',                    
                },
                customer: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password:'',
                    telefon:'',
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    payment_method_id:'',
                    amount:0,
                    cart:[],
                    stripe_id:'',
                    monri_id:'',
                    komentar:'',
                    izmjenipodatkepostojecegusera:false,//ako je postojeci korisnik, a trebalo bi mu izmijeniti ime, adresu osim email i passworda
                    novikorisnikchkenabled:false, //ako je true onda je novi korisnik i treba mu kreriati novi acc  
                    altcustomerchkenabled:false, //ako je true onda se narudzba salje na drugu adresu
                    altcustomer:[],//ukoliko nije isto mjesto placanja i dostave
                    nacinplacanja:'', //opisno: virman, gotovina, kartica
                    valuta:'',
                    monri_key:'',
                    monri_authenticity_token:''


                },
                paymentProcessing: true,
                paymentProcessing1:false,
                paymentProcessing2: false,
                paymentProcessingMorni:false,

                alert:false,
                alerttext:'',
                alertsnackbar:false,
                snackbartekst:'',
                multiLine: true,
                ikonasnackbar:'',
                colorsnackbar:'',   
                snackbartitle:'',  

                narZag:[],                        
                narStav:[],

                nazivrules: [
                    v => !!v || 'Naziv je obavezan!',
                    v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
                ],
                imerules: [
                    v => !!v || 'Ime je obavezno!',
                    v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
                ],   
                textrules: [
                    v => !!v || 'Vrijednost je obavezna!',
                    v => (v && v.length <= 200) || 'Vrijednost mora biti kraći od 200 karaktera.',
                ],                              
            }
        },



        methods: {
            formatBroj(val){
                var numBr = numeral(val);
                var strBr = numBr.format('0.00');
                return strBr;

            },   
            pokreni(){
                //this.$router.push({ name: 'apiwssummary' });
            },

            /* start stripe */
            async mountedStripe(pokaži) {
                
                if (!pokaži)
                {
                    this.cardElement.unmount('#card-element');
                    return;
                }
                
                try {
                        this.stripe = await loadStripe(process.env.MIX_STRIPE_KEY);
                        const elements = this.stripe.elements();
                        this.cardElement = elements.create('card', {
                            classes: {
                                base: 'bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 p-3 leading-8 transition-colors duration-200 ease-in-out'
                            }
                        });
                        this.cardElement.mount('#card-element');
                    
                } catch (error) {
                        //this.obavijest("Molimo sačekajte trenutak !");
                        //drugi pokušaj uspostavljanja konekcije
                        this.stripe = await loadStripe(process.env.MIX_STRIPE_KEY);
                        const elements = this.stripe.elements();
                        this.cardElement = elements.create('card', {
                            classes: {
                                base: 'bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 p-3 leading-8 transition-colors duration-200 ease-in-out'
                            }
                        });
                        this.cardElement.mount('#card-element');
                }
            },
            /* end stripe */

            /* start Monri */
            async mountedMonri(pokaži) {
                if (!pokaži)
                {return;}

                if(this.customer.address==""||this.customer.first_name==""||this.customer.last_name){
                    //prazne kontrole

                    axios.post('/api/monripurchasekupackontrola', this.customer)
                        .then((response) => {
                            this.paymentProcessing1 = false;

                            if (response.data.greska==true){
                                this.obavijest(response.data.data,'error');
                                return;
                            }

                            //this.obavijest(response.data.Adresa + response.data.Ime);
                            this.customer.address=response.data.Adresa,
                            this.customer.first_name=response.data.Ime, 
                            this.customer.last_name=response.data.Prezime,
                            this.customer.city=response.data.Grad,
                            this.customer.zip_code=response.data.ZipCode,
                            this.customer.telefon=response.data.Telefon,
                            this.customer.state=response.data.Drzava

                            
                                     

                        })
                        .catch((error) => {
                            this.paymentProcessing1 = false;
                            this.obavijest("Došlo je do greške, proces plaćanja biće prekinut! Originalna greška: " + error,'error');
                            //console.error(error);
                        });  
                }

                
                this.customer.amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)), 0);
                this.customer.valuta="BAM";
                this.customer.monri_key=Monri_Key;
                this.customer.monri_authenticity_token=Monri_authenticity_token;
                //this.obavijest(this.customer);
                //return;

                axios.post('/api/monriload', this.customer)
                    .then((response) => {
                        //this.paymentProcessing = false;
                        //console.log(response.data[0]);
                        if(response.data.greska=='true'){
                            this.obavijest("Došlo je do greške 1. montiranja Monri komponenti! Originalna greška: " + response.data.opis_greska, 'error');
                            return;
                        }
                        //this.obavijest(response.data.data['client_secret']);
                        this.brojMonrinarudzbe=response.data.narudzbaMonriID;
                        this.montiranjeMonriKomponenti(response.data.data['client_secret'], response.data.narudzbaMonriID);
                        return;

                        
                    })
                    .catch((error) => {
                        //this.paymentProcessing = false;
                        this.obavijest("Došlo je do greške 2. montiranja Monri komponenti! Originalna greška: " + error, 'error');
                        //console.error(error);
                    });

            },

            montiranjeMonriKomponenti(client_secret, monrinarudzbaid){
                

                var monri = Monri(Monri_authenticity_token, {locale: 'hr'});
                var components = monri.components({"clientSecret": client_secret});

                var style = {
                        base: {
                            // Add your base input styles here. For example:
                            //fontSize: '16px',
                            //color: '#663399',
                        },

                        invalid: {
                            color: 'red'
                        }, 
                        label: {
                            base: {
                                //color: 'blue',
                                textTransform: 'none'
                            },
                            invalid: {
                                color: 'gray'
                            },
                            complete: {
                                color: 'green'
                            }
                        },  
                        selectPaymentMethod: {
                            base: {
                                fontSize: '15px',
                                color: "#663399"
                            }
                        }                                                                     
                    };

                // Create an instance of the card Component.
                var card = components.create('card', {style: style});
                // Add an instance of the card Component into the `card-element` <div>.
                card.mount('card-element');

                card.onChange(function (event) {
                    var displayError = document.getElementById('card-errors');
                    
                    if (event.error) {
                        
                        displayError.textContent = event.error.message;
                        
                    } else {
                        displayError.textContent = '';
                        
                    }
                });    
                

                var adrs=this.customer.address;
                var f_name = this.customer.first_name + ' ' + this.customer.last_name;
                var cty = this.customer.city;
                var z_ip=this.customer.zip_code;
                var t_el=this.customer.telefon;
                var c_try= this.customer.state;
                var e_mail= this.customer.email;
                
                var form = document.getElementById('payment-form');

                form.addEventListener('submit', function (event) {
                    event.preventDefault();
                    
                    const transactionParams = {};
                    transactionParams.address=adrs;
                    transactionParams.fullName=f_name;
                    transactionParams.city=cty;
                    transactionParams.zip=z_ip;
                    transactionParams.phone=t_el;
                    transactionParams.country=c_try;
                    transactionParams.email=e_mail;
                    transactionParams.orderInfo="Testna trx";
                    

                   
                    monri.confirmPayment(card, transactionParams).then(function (result) {
                        if (result.error) {
                            // Inform the customer that there was an error.
                            var errorElement = document.getElementById('card-errors');
                            errorElement.textContent = "Greška pri procesu plaćanja : " + result.error.message;
                           
                        } else {
                            
                            handlePaymentResult(result.result)
                        }
                    });
                    


                //probna handlePaymentResult("approved") //proba js vue obriši

                });  

                /* probna
                function handlePaymentResult(paymentResult) {
                    // Handle PaymentResult
                    var errorElement = document.getElementById('card-errors');
                    errorElement.textContent = "došo";//monrinarudzbaid;
                    if (paymentResult == 'approved') {
                        var vuefunkcija = function() {
                            bus.$emit("monriprocesplacanja",{result:paymentResult});
                        };
                        vuefunkcija();
                     
                    } else {
                        var vuefunkcijaerr = function() {
                            bus.$emit("monriprocesplacanjaerror",{greska:"Transakcija je odbijena. Proces plaćanja je obustavljen"});
                        };
                        vuefunkcijaerr();

                    }                                    
                }
                */

                
                function handlePaymentResult(paymentResult) {
                    // Handle PaymentResult
                    if (paymentResult.status == 'approved') {
                        var vuefunkcija = function() {
                            //bus.$emit("monriprocesplacanja",{result:paymentResult});
                            this.eventBus.emit("monriprocesplacanja",{result:paymentResult});
                        };
                        vuefunkcija();
                        
                       
                    } else {
                        var vuefunkcijaerr = function() {
                            //bus.$emit("monriprocesplacanjaerror",{greska:"Transakcija je odbijena. Proces plaćanja je obustavljen"});
                            this.eventBus.emit("monriprocesplacanjaerror",{greska:"Transakcija je odbijena. Proces plaćanja je obustavljen"});
                        };
                        vuefunkcijaerr();

                    }
                }


            },


            async processPaymentMonri(data) {
                
                try{
                    this.customer.monri_id=this.brojMonrinarudzbe;
                    this.customer.amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)), 0);
                    this.customer.cart = JSON.stringify(this.$store.state.cart);

                    if(this.chkenabled1==true){
                        this.customer.novikorisnikchkenabled=true; //novi korisnik
                    }
                    if(this.chkenabled2==true){
                        this.customer.izmjenipodatkepostojecegusera=true; //novi korisnik
                    }                
                    if (this.chkenabled==true){
                        this.customer.altcustomerchkenabled=true;
                        this.trnsaltcust.push(this.altcustomer);
                        this.customer.altcustomer=JSON.stringify(this.trnsaltcust);
                    }
                    this.customer.nacinplacanja='kartica';


                    axios.post('/api/monripurchase', this.customer)
                        .then((response) => {
                            this.paymentProcessing1 = false;

                            if (response.data.greska==true){

                                //console.log(response.data.data);
                                //alert(response.data.data);
                                this.obavijest(response.data.data, 'error');
                                return;


                            }
                            //this.obavijest(response.data.data);

                            //sad apdejta narudžbe u store, briše kart i otvara summary sajt   

                            this.$store.commit("updateNarudzbuZag",response.data.narudzbaZag);
                            this.$store.commit("updateNarudzbuStav",response.data.narudzbaStav);
                            this.$store.commit("updateKupac",response.data.kupac);
                            this.$store.commit("updatealtKupac",response.data.altkupac);
                            //this.$store.commit('updateNarudzbu', response.data.narudzbaZag, response.data.narudzbaStav, response.data.kupac, response.data.altkupac);
                            //this.$store.commit('updateNarudzbu', response.data.narudzbaStav);
                            this.$store.dispatch('clearCart');
                            this.$router.push({ name: 'apiwssummary' });
                        })
                        .catch((error) => {
                            this.paymentProcessing1 = false;
                            this.obavijest("Došlo je do greške, proces plaćanja biće prekinut! Originalna greška: " + error, 'error');
                            console.error(error);
                        });   
                    } catch (error) {     
                        this.obavijest("Došlo je do greške, proces upisa u nardžbe biće prekinut! Originalna greška: " + error, 'error');
                    }

            },

            /* end Monri */

            cartLineTotal(item) {
                let amount = item.price * item.quantity;
                //amount = (amount / 100);
                return amount.toLocaleString('en-US', { style: 'currency', currency: 'BAM' });
            },

            /* stripe start */
            async processPayment() {
                this.paymentProcessing = true;
                const {paymentMethod, error} = await this.stripe.createPaymentMethod(
                    'card', this.cardElement, {
                        billing_details: {
                            name: this.customer.first_name + ' ' + this.customer.last_name,
                            email: this.customer.email,
                            address: {
                                line1: this.customer.address,
                                city: this.customer.city,
                                state: this.customer.state,
                                postal_code: this.customer.zip_code
                            }
                        }
                    }
                );
                if (error) {
                    this.paymentProcessing = false;
                    this.obavijest("Došlo je do greške: " + error, 'error');
                    console.error(error);

                } else {
                    console.log(paymentMethod);
                    this.customer.payment_method_id = paymentMethod.id;
                    this.customer.amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)*100), 0);
                    this.customer.cart = JSON.stringify(this.$store.state.cart);
                    //this.customer.stripe_id=this.paymentMethod.stripe.id;

                    axios.post('/api/purchase', this.customer)
                        .then((response) => {
                            this.paymentProcessing = false;
                            console.log(response.data[0]);

                            //sad apdejta narudžbe u store, briše kart i otvara summary sajt
                            
                            this.$store.commit('updateNarudzbu', response.data);
                            this.$store.dispatch('clearCart');
                            this.$router.push({ name: 'apiwssummary' });
                        })
                        .catch((error) => {
                            this.paymentProcessing = false;
                            this.obavijest("Došlo je do greške, proces plaćanja biće prekinut! Originalna greška: " + error, 'error');
                            console.error(error);
                        });
                }
            },  
            /* stripe end */


            async processPaymentGotovina() {
                this.customer.amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)), 0);
                this.customer.cart = JSON.stringify(this.$store.state.cart);

                if(this.chkenabled1==true){
                    this.customer.novikorisnikchkenabled=true; //novi korisnik
                }
                if(this.chkenabled2==true){
                    this.customer.izmjenipodatkepostojecegusera=true; //novi korisnik
                }                
                if (this.chkenabled==true){
                    this.customer.altcustomerchkenabled=true;
                    this.trnsaltcust.push(this.altcustomer);
                    this.customer.altcustomer=JSON.stringify(this.trnsaltcust);
                }
                this.customer.nacinplacanja='gotovina'

                axios.post('/api/gotovinapurchase', this.customer)
                    .then((response) => {
                        this.paymentProcessing1 = false;

                        if (response.data.greska==true){

                            this.obavijest(response.data.data, 'error');
                            return;


                        }


                        //sad apdejta narudžbe u store, briše kart i otvara summary sajt   

                        this.$store.commit("updateNarudzbuZag",response.data.narudzbaZag);
                        this.$store.commit("updateNarudzbuStav",response.data.narudzbaStav);
                        this.$store.commit("updateKupac",response.data.kupac);
                        this.$store.commit("updatealtKupac",response.data.altkupac);

                        /*osiguraj da se cart isprazni prije navigacije*/
                        this.$store.dispatch('clearCart').then(() => {
                            this.$router.push({ name: 'apiwssummary' });
                        });
                    })
                    .catch((error) => {
                        this.paymentProcessing1 = false;
                        this.obavijest("Došlo je do greške, proces plaćanja biće prekinut! Originalna greška: " + error, 'error');
                        console.error(error);
                    });                

            },


            async processPaymentVirman() {
                this.customer.amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)), 0);
                this.customer.cart = JSON.stringify(this.$store.state.cart);

                if(this.chkenabled1==true){
                    this.customer.novikorisnikchkenabled=true; //novi korisnik
                }
                if(this.chkenabled2==true){
                    this.customer.izmjenipodatkepostojecegusera=true; //novi korisnik
                }                
                if (this.chkenabled==true){
                    this.customer.altcustomerchkenabled=true;
                    this.trnsaltcust.push(this.altcustomer);
                    this.customer.altcustomer=JSON.stringify(this.trnsaltcust);
                }
                this.customer.nacinplacanja='virman'

                axios.post('/api/virmanpurchase', this.customer)
                    .then((response) => {
                        this.paymentProcessing1 = false;

                        if (response.data.greska==true){


                            this.obavijest(response.data.data, 'error');
                            return;


                        }


                        //sad apdejta narudžbe u store, briše kart i otvara summary sajt                        
                        /*
                        this.$store.commit('updateNarudzbu', response.data);
                        this.$store.dispatch('clearCart');
                        this.$router.push({ name: 'apiwssummary' });*/
                        //sad apdejta narudžbe u store, briše kart i otvara summary sajt   

                        this.$store.commit("updateNarudzbuZag",response.data.narudzbaZag);
                        this.$store.commit("updateNarudzbuStav",response.data.narudzbaStav);
                        this.$store.commit("updateKupac",response.data.kupac);
                        this.$store.commit("updatealtKupac",response.data.altkupac);

                        /*osiguraj da se cart isprazni prije navigacije*/
                        this.$store.dispatch('clearCart').then(() => {
                            this.$router.push({ name: 'apiwssummary' });
                        });                        
                    })
                    .catch((error) => {
                        this.paymentProcessing1 = false;
                        this.obavijest("Došlo je do greške, proces plaćanja biće prekinut! Originalna greška: " + error, 'error');
                        console.error(error);
                    });                

            },


            obavijest(strTekst, tip){
                switch(tip) {
                case 'info':
                    this.ikonasnackbar="info";
                    this.colorsnackbar="info";
                    this.snackbartitle="Info"
                    break;
                case 'success':
                    this.ikonasnackbar="check_circle";
                    this.colorsnackbar="success";
                    this.snackbartitle="Obavijest"
                    break;
                case 'error':
                    this.ikonasnackbar="error";
                    this.colorsnackbar="error";
                    this.snackbartitle="Greška"
                    break; 
                case 'warning':
                    this.ikonasnackbar="warning";
                    this.colorsnackbar="warning";
                    this.snackbartitle="Upozorenje"
                    break;                                        
                default:

                }

                this.snackbartekst=strTekst;
                this.alertsnackbar=true;

            }
        },

        mounted() {
            //function here
            this.pokreni();
            this.mountedMonri(true);

            this.eventBus.on("monriprocesplacanja",(data)=>{this.processPaymentMonri(data)});
            


            this.eventBus.on("monriprocesplacanjaerror", (data)=>{this.obavijest(data.greska, "error")});

        },

        computed: {


            cart() {
                this.aCart=this.$store.state.cart;
                return this.$store.state.cart;
            },
            cartQuantity() {
                return this.$store.state.cart.reduce((acc, item) => acc + item.quantity, 0);
            },
            cartTotal() {
                let amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)), 0);
                //let amount = this.$store.state.cart.reduce((acc, item) => acc + ((item.Cijena * item.quantity)*100), 0);
                //amount = (amount / 100);
                
                return amount.toLocaleString('en-US', { style: 'currency', currency: 'BAM' });
            }
        },

        watch: {
            
            cart(newVal) {
                this.aCart = newVal;
            },

            chkenabled1: function(newVal, oldVal) {
            this.paymentProcessing=false;

            if (newVal==false){
                    this.paymentProcessing=true;
            }
            
            },

            chkenabled2: function(newVal, oldVal) {
            this.paymentProcessing=false;

            if (newVal==false){
                    this.paymentProcessing=true;
            }
            
            },

        },        
    }
</script>

<style>
.table_td_sivo{
  background-color:#f2f3f4;
  font-weight: bold;
  width: 50%;
}

.kartice_1{
    background-color:"green lighten-4" 
}
.card_transparent{
    background-color:transparent!important;
    border: rgb(9, 243, 60)!important;
    /*opacity: 0.65;*/
    font-weight: bold;
  /*width: 30%;*/
}
</style>