/*global __webpack_public_path__:writable 

exported __webpack_public_path__ */

__webpack_public_path__ = 'http://botanicaplantshop.ba/'  // JAVNA PUTANJA

//

/* javna putanja app_public_path = '/lv_webshop/public' */
//app_public_path = ''
app_public_path = 'http://botanicaplantshop.ba/public'
/* javna putanja za deponovanje slika i videa */
//app_public_medija_path='http://localhost:8000/'
app_public_medija_path='images/'
//app_public_medija_path='http://botanicaplantshop.ba/public/images/'


/* Monri podešenja  */
Monri_Key= 'key-00dd5a40ab8ddc73584c11f93179afc1'//testni ključ
Monri_authenticity_token= '7d453d976476eb964a61195bdf4fb1b064b8c84e'//testni token