 <template>
        
        <v-card>
            <v-card-actions>
                <v-snackbar
                v-model="alertsnackbar"
                :multi-line="multiLine"
                absolute
                left
                shaped
                middle
                >
                {{ snackbartekst }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="alertsnackbar = false"
                    >
                    Zatvori
                    </v-btn>
                </template>
                </v-snackbar>
            </v-card-actions>
 
            <v-data-table
            :headers="headers"
            :items="svenarudzbe"
            class="elevation-1"
           
          >


                <template v-slot:item="{ item }">
                    <tr  :class="{'primary': item.Id===selectedId}">
                        <td @click="editItem(item)">{{item.Sifra}}</td>
                        <td @click="editItem(item)">{{item.NazivRoba}}</td>
                        <td @click="editItem(item)">{{item.Kolicina}}</td>
                        <td @click="editItem(item)">{{item.Cijena}}</td>
                        <td @click="editItem(item)">{{item.Iznos}}</td>
                        <td>   
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item) "
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item)"
                            >
                                mdi-delete
                            </v-icon>

                            <v-dialog v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                    <v-card-title class="headline">Jeste li sigurni u brisanje stavke narudžbe broj {{deletenarudzbastavid}} pod nazivom {{deletenarudzbastavrobanaziv}} ?</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                    </v-card>
                            </v-dialog>
                        </td>
                    </tr>

                </template>

 
                
            </v-data-table>

        </v-card>

</template>

<script>
import axios from 'axios';
export default {

    data() {
        return {
             
            brnarudzbe:0, 
            svenarudzbe: [],
            narudzbastav: {
                Id:0,
                Sifra: '',
                Slug:'', 
                NazivRoba:'', 
                Kolicina:0, 
                Cijena:0, 
                Iznos:0,
                narudzbastav:0,

            },

            selectedId:0,
            parentsifrarobe:'',

            pokaziDialogEditnarudzbastav:false,
            dialogDelete: false,
            deletenarudzbastavid:0,
            deletenarudzbastavrobanaziv:'',

            alert:false,
            alerttext:'',
            alertsnackbar:false,
            snackbartekst:'',
            multiLine: true,

            headers: [
                { title: 'Šifra', key: 'Sifra' },
                { title: 'Naziv', key: 'NazivRoba' },
                { title: 'Kol', key: 'Kolicina' },
                { title: 'Cijena', key: 'Cijena' },
                { title: 'Iznos', key: 'Iznos' },
                { title: 'Actions', key: 'actions', sortable: false }
            ], 



        }
    },
    props:{
        narudzbestavkebrojnarudzbe:0,
        transrefreshtabele:false,
    },
    watch:{
        narudzbestavkebrojnarudzbe: function(newVal, oldVal) {

            this.popuniGrid();
            alert=false;
        },

         transrefreshtabele:  function(newVal, oldVal) {
            if (newVal){
               // console.log('došao je i trebo je refreshovat tabelu : nova ' + newVal + ' stara: ' + oldVal );
                this.popuniGrid();
               
                alert=false;
                

            }
        }
    },
    
    created() {
        this.popuniGrid();
        alert=false;

    },
    
    computed:{

    },
    methods: {

/*
        popuniGrid() {
            let vm = this;
        
            var page_url = '/api/apinarudzbestavke/'+ this.narudzbestavkebrojnarudzbe;
            console.log('page_url je : ' + page_url );
            fetch(page_url)
                .then(res => res.json())
                .then(res => {
                this.svenarudzbe = res.data;
                //vm.makePagination(res.meta, res.links);
                
                })
                .catch(err => console.log('greška u popuni grid narudžbe stavke, page_url : ' + page_url + ' originalna greška :' + err));
        },
*/

        popuniGrid() {
            let vm = this;
            
           
            //var page_url = '/api/apinarudzbestavke/' + this.narudzbestavkebrojnarudzbe;
            //console.log('page_url je : ' + page_url);

            axios.get('/api/apinarudzbestavke/' + this.narudzbestavkebrojnarudzbe)
                .then(response => {
                    this.svenarudzbe = response.data.data;
                    console.log('Prošao Axios! ' + JSON.stringify(this.svenarudzbe, null, 2)); 
                    //vm.makePagination(response.data.meta, response.data.links);
                })
                .catch(error => {
                    console.log('Axios greška u popuni grid narudžbe, originalna greška: ' + error);
                });
        },


        editItem (item) {
   
            this.narudzbastav = this.svenarudzbe.indexOf(item)
            this.narudzbastav = Object.assign({}, item)
            this.selectedId = this.narudzbastav.Id;
            console.log('id je ' +this.selectedId)
           
            
            this.$emit('tblstavkeclick', this.narudzbastav, true ) //true za edit
            this.$emit('selektovantab', this.narudzbastav, true ) //true za edit



        },
        deleteItem (item) {
            this.narudzbastav = this.svenarudzbe.indexOf(item)
            this.narudzbastav = Object.assign({}, item)
            this.deletenarudzbastavid= this.narudzbastav.Id
            this.deletenarudzbastavrobanaziv=this.narudzbastav.NazivRoba
            this.dialogDelete = true
        },
        closeDelete () {
            this.dialogDelete = false
            //this.ispraznikontrole()
            this.popuniGrid();
        },
        deleteItemConfirm () {
            //this.desserts.splice(this.editedIndex, 1)
            this.deletenarudzbastav( this.deletenarudzbastavid);
            this.closeDelete()
        },
        deletenarudzbastav(deletenarudzbastavid) {
        //if (confirm('Da li ste sigurni u brisanje?')) {
            fetch(`api/apinarudzbestavke/${deletenarudzbastavid}`, {
            method: 'delete'
            })
            .then(res => res.json())
            .then(data => {
                this.obavijestHeading("Stavka je obrisana!");
                this.popuniGrid();
            })
            .catch(err => console.log(err.toString()));
            //console.error(this.props.url, status, err.toString()));
        //}
        },
        async obavijestHeading(strTekst){
            this.snackbartekst=strTekst;
            this.alertsnackbar=true;
        },

   }
}
</script>

<style scoped>
/*.primary {
    background-color: red;
    font-weight: bold;
}*/
</style>