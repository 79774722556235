<template>
  
   
    <v-card class="mx-auto my-4" max-width="650">
      <v-card-title>
        Vrijeme cvijetanja
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            v-for="(month, index) in months"
            :key="index"
            cols="1"
            class="text-center"
          >
            <v-btn
              :color="modelValue.includes(index + 1) ? 'success' : 'default'"
              class="ma-0 pa-2"
              elevation="0"
              rounded
              block
              @click="toggleMonth(index + 1)"
            >
              {{ month }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array, // Niz brojeva [1, 2, 3]
      required: true,
    },
  },
  computed: {
    months() {
      return ["JAN", "FEB", "MAR", "APR", "MAJ", "JUN", "JUL", "AVG", "SEP", "OKT", "NOV", "DEC"];
    },
  },
  methods: {
    toggleMonth(month) {
      const index = this.modelValue.indexOf(month);
      const updatedMonths = [...this.modelValue];
      if (index === -1) {
        // Dodaj mesec ako nije u nizu
        updatedMonths.push(month);
      } else {
        // Ukloni mesec ako je u nizu
        updatedMonths.splice(index, 1);
      }
      // Emituje ažurirani niz
      this.$emit("update:modelValue", updatedMonths.sort((a, b) => a - b));
    },
  },
};
</script>

<style scoped>
.v-btn.default {
  background-color: #f5f5f5;
  color: #000;
}
.v-btn.success {
  background-color: #82ae84; /* Green shade for highlight */
  color: white;
}
</style>
