<template>

    <v-dialog 
        v-model="komparacijadialog"
        max-width="90%"
        >
        <v-toolbar
            class="sivi-toolbar"
        >


            <v-toolbar-title> Komparacija proizvoda </v-toolbar-title>
            
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="closeDialog()"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            
            <v-toolbar-items>
            </v-toolbar-items>
        </v-toolbar>

        <!--v-row>
          <v-col         
            class="d-flex align-center"
            cols="12"
            sm="12"--> 
                <v-card
                    outlined
                    color="blue darken-2"
                    min-width="100%"
                    height="90vh"
                    class="mx-auto white--text"
                >

                    <div class="scrollable-card" >
                        <v-card >

                            <v-item-group multiple>
                                <v-container >
                                <v-row>
                                    <v-col
                                    v-for="(item, index) in komparacijskatabela" :key="item.Slug"
                                    cols="6"
                                    md="4"
                                    >
                                    <v-item >
                                        <v-card
                                        >
                                            <!--v-img class="white--text align-end" height="200px" src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"-->
                                            <!--img class="card-img-top"  :src="item[0].SlikaString" @error="setAltImg"-->

                                            <v-card-text class="justify-center" >
                                                <v-img class="image-fit" contain :src="item[0].SlikaString"  lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png">
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                    ></v-progress-circular>
                                                </v-img>
                                                <!--img class="image-top" :src="item[0].SlikaString" @error="setAltImg"-->
                                            </v-card-text>

                                            <v-card-title>{{ item[0].NazivRoba }}</v-card-title>
                                            
                                            <v-card-subtitle class="pb-0">{{ item[0].Brand }}</v-card-subtitle>
                                            
                                            <v-divider light style="margin-top:15px;"></v-divider>
                                            <v-card-text style="overflow-y: auto; height:280px; padding: 0px 15px 0px 15px;" >
                                                
                                                <div v-if="item[0].Opisprikazi" height="280" v-html=item[0].Opis ></div>                                    
                                                
                                            </v-card-text>
                                            <v-divider light></v-divider>
                                            
                                            <!--v-card-text style="overflow-y: auto; height:100px" >
                                                <div v-if="item[0].Brandprikazi" height="400">{{ item[0].Brand }}</div>
                                                <v-divider light style="margin-top:15px;"></v-divider>
                                                <v-divider light></v-divider>
                                            </v-card-text-->

                                                                            
                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Zemlja porijekla :</div>
                                                <div v-if="item[0].MadeInprikazi" >{{ item[0].MadeIn }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>

                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Materijal :</div>
                                                <div v-if="item[0].Materijalprikazi" >{{ item[0].Materijal }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Težina :</div>
                                                <div v-if="item[0].Tezinaprikazi" >{{ item[0].Tezina }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Veličina :</div>
                                                <div v-if="item[0].Velicinaprikazi" >{{ item[0].Velicina }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Kubikaža :</div>
                                                <div v-if="item[0].Kubikazaprikazi" >{{ item[0].Kubikaza }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Snaga :</div>
                                                <div v-if="item[0].Snagaprikazi" >{{ item[0].Snaga }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Dimenzija :</div>
                                                <div v-if="item[0].Dimenzijeprikazi" >{{ item[0].Dimenzije }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Visina :</div>
                                                <div v-if="item[0].Visinaprikazi" >{{ item[0].Visina }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Dužina :</div>
                                                <div v-if="item[0].Duzinaprikazi" >{{ item[0].Duzina }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Širina :</div>
                                                <div v-if="item[0].Sirinaprikazi" >{{ item[0].Sirina }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Boja :</div>
                                                <div v-if="item[0].Bojaprikazi" >{{ item[0].Boja }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>

                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Promjer :</div>
                                                <div v-if="item[0].Promjerprikazi" >{{ item[0].Promjer }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>                                


                                            <v-card-text style="height:auto; padding: 0px 15px 0px 15px;" >
                                                <div>Stanje :</div>
                                                <div v-if="item[0].Novopolovnoprikazi" >{{ item[0].Novopolovno }}</div>                                   
                                            </v-card-text>
                                            <v-divider light></v-divider>  

                                            <v-card-actions class="justify-center">

                                                <v-btn
                                                    class="ma-2"
                                                    outlined
                                                    color="primary"
                                                    @click="$store.commit('obrisiIzKomparacije', index)"
                                                >OBRIŠI
                                                <v-icon
                                                dark
                                                right
                                                >
                                                mdi-cancel
                                                </v-icon>
                                                </v-btn>

                                            <router-link
                                                :to="{name: 'wsprodukteditfront', params: {editProduktSlug:item[0].Slug}}"
                                            >
                                                <v-btn
                                                    class="ma-2"
                                                    outlined
                                                    color="primary"

                                                >
                                                Detalji
                                                <v-icon
                                                dark
                                                right
                                                >
                                                mdi-checkbox-marked-circle
                                                </v-icon>
                                                </v-btn>
                                            </router-link>

                                            </v-card-actions>                                                       
                                        </v-card>
                                    </v-item>
                                    </v-col>
                                </v-row>
                                </v-container>
                            </v-item-group>

                        </v-card>  
                    </div>

                </v-card>   
            <!--/v-col>
        </v-row-->
    </v-dialog>              
</template>
<script>
    //import { bus } from '../../app.js'//--- busproba
    export default {
        inject:["eventBus"],//bus mitt vue3
        data() {
            return {
                aKomparacija:[],
                komparacijadialog:false,    
            }
        },

        props:{
            prikazidialog:false

        },
        watch: {
            prikazidialog: function(newVal, oldVal){
                this.komparacijadialog=newVal;
            }
        },  
        methods: {
            setAltImg(event) { 
                event.target.src = "https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png" 
                //this.$forceUpdate()
            }, 

            closeDialog(){
                this.eventBus.emit('kontejnerinstancadialozi',{komparacijadialogbool:false, 
                            cartdialog:false}); 
            }
        },
        computed: {
            komparacijskatabela() {
                this.aKomparacija=this.$store.state.komparacija;
                return this.$store.state.komparacija;;
            }

        }
    }
</script>
<style >
    .button {
    border: none;
    border-radius: 2px;
    }

    .button-close {
    background-color: red;
    }

    .image-top{
      /*height: 300px;*/
      /*object-fit: cover;*/
      align: center;
      height:300px;

    }
    .image-fit{
        max-height:250px;
        min-height: 250px;
        /*max-width:150px;*/
        object-fit: cover;

    }
    .scrollable-card {
        /*height: 300px;*/ /* Adjust the height as needed */
        overflow-y: auto; /* Enable vertical scrolling */
        }
</style>