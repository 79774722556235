

import Vuex from 'vuex';
import axios from 'axios';

import { createStore } from 'vuex';
//import Vuex from 'vuex';
var numeral = require('numeral');


//Vue.use (Vuex)



export const store = new createStore({
    state:{
            produkti :[],
            cart:[],
            komparacija:[],
            produktidetalji: [],
            narudzba:[],
            narudzbastav:[],
            kupac:[],
            altkupac:[],
            trebaRefreshovatGrid:false,
    },

    mutations:{ //bez čekanja i odmah se izvršavaju, sinhrone, nisu za API
                //zove se sa .commit (primjer: this.$store.commit('reducePrice');)

            updateProdukti (state, produkti){
                
                state.produkti = produkti;

                console.log('updateProdukti dužina : ' + produkti.lenght);

                /*let i;
                for (i = 0; i < state.produkti.length; i++)
                {
                    console.log('state produkti: ' + state.produkti.NazivRoba);
                 }*/


            },
            /*************************************/
            /*       cart       */
            /*************************************/
            dodajUCartIzEdita(state, produkti){
                let productInCartIndex = state.cart.findIndex(item => item.Slug === produkti.Slug);
                if (productInCartIndex !== -1) {

                    var numBr = state.cart[productInCartIndex].quantity;
                    var nKol= numBr  + produkti.Kolicina;
                    state.cart[productInCartIndex].quantity = nKol;

                    var Iznos=0;
                    Iznos= state.cart[productInCartIndex].quantity * state.cart[productInCartIndex].Cijena;
                    state.cart[productInCartIndex].Iznos= Iznos;

                    return;
                }    
                produkti.quantity = produkti.Kolicina;
                produkti.Iznos= produkti.Kolicina * produkti.Cijena;

                state.cart.push(produkti);

            },            
            dodajUCart(state, produkti){
                let productInCartIndex = state.cart.findIndex(item => item.Slug === produkti.Slug);
                if (productInCartIndex !== -1) {
                   
                    state.cart[productInCartIndex].quantity++;//orig

                    //dodat dio start
                    
                    var Iznos=0;
                    Iznos= state.cart[productInCartIndex].quantity * state.cart[productInCartIndex].Cijena;
                    state.cart[productInCartIndex].Iznos= Iznos;
                    
                    //dodat dio kraj

                    return;
                }
    
                produkti.quantity = 1;
                produkti.Iznos= produkti.quantity * produkti.Cijena;


                state.cart.push(produkti);

            },
            obrisiIzCarta(state, index) {
                state.cart.splice(index, 1);
            },
            /*
            updateNarudzbu(state, narudzba) {
                //state.narudzba = narudzba;
                state.narudzba = state.cart;

            },*/
            
            updateNarudzbuZag(state, narudzba) {
                //state.narudzba.push(narudzba);
                state.narudzba = [narudzba]; // Kreira novi niz sa jednim elementom
            },
            
            updateNarudzbuStav(state, narudzbastavke) {
                console.log('narudzbastavke:', narudzbastavke);
            
                // Isprazni prethodne stavke
                state.narudzbastav = [];
            
                // Proveri da li je narudzbastavke niz
                if (Array.isArray(narudzbastavke)) {
                    // Ako jeste, dodaj sve stavke
                    state.narudzbastav.push(...narudzbastavke);
                } else {
                    // Ako nije, dodaj samo jednu stavku kao niz
                    state.narudzbastav.push(narudzbastavke);
                }
            },

            updateKupac(state, kupac) {
                //state.kupac.push(kupac);
                state.kupac = [kupac]; // Zamenjuje kupca novim kupcem

            },
            updatealtKupac(state, altkupac) {
                //state.altkupac.push(altkupac);
                state.altkupac = [altkupac]; // Zamenjuje kupca novim kupcem

            },
            /*
            updateNarudzbu(state,narudzbastavke) {
                
                state.narudzbastav.push(narudzbastavke);

            },*/
            updateCart(state, cart) {
                state.cart = cart;
            },


            /*************************************/
            /*       komparacija proizvoda       */
            /*************************************/
            dodajUKomparaciju(state, produkti){

                var param = produkti.Slug;
                

                axios.get('/api/apiwsprodukteditfrontkomparacija/'+param, {
                        //params 

                    })
                    .then((response) => {
                        state.komparacija.push(response.data.data);

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                

            },
            obrisiIzKomparacije(state, index) {
                state.komparacija.splice(index, 1);
            },



            /*************************************/
            /*       Web shop kreiranje mutacije */
            /*************************************/

            // Mutacija za postavljanje vrednosti refreshGrid stanja
            setRefreshGrida(state, doneseniparametri) {
                //state.trebaRefreshovatGrid = doneseniparametri.refreshgrid;
                state.trebaRefreshovatGrid = doneseniparametri
            },
 
    },

    actions: { //za operacije čekanja i za API, asinhrone, mogu pozivati mutacije
               // zove se sa .dispach ( primjer: this.$store.dispatch('reducePrice');)

        getProdukte({commit}) {
            // fetch the categories and attached products from the api
            console.log('getprodukti ušao');
            axios.get('/api/apiwsproduktisvefront',
                {
                    params:{ page: 1 } 
                })
                .then((response) => {

                    commit('updateProdukti', response.data.data);

                })
                .catch((error) => console.error(error));
        },
        clearCart({commit}) {
            commit('updateCart', []);
        }
    } 


})