<template>

    <v-dialog
        v-model="localDialog"
        persistent
        min-width="60%"
        max-width="60%"
        
    >
        <v-app>

            <v-main>
                    <v-card  elevation="0">


                            <v-toolbar
                            color="blue darken-2"
                            light
                            extended
                            >
                            <!--v-app-bar-nav-icon></v-app-bar-nav-icon-->
                            <v-toolbar-title class="white--text">
                            Pregled i štampa dokumenata
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
    
                            <template v-slot:extension>

                                <v-speed-dial

                                    v-model="fab"
                                    :top="top"
                                    :bottom="bottom"
                                    :right="right"
                                    :left="left"
                                    :direction="direction"
                                    :open-on-hover="hover"
                                    :transition="transition"
                                                            
                                
                                
                                    >
                                    <template v-slot:activator>
                                        <v-btn
                                        v-model="fab"
                                        color="blue darken-2"
                                        dark
                                        fab
                                        

                                        >
                                        <v-icon v-if="fab"  size="15">
                                            fa-close
                                        </v-icon>
                                        <v-icon v-else size="15">
                                            fa-list
                                        </v-icon>
                                        </v-btn>

                                        
                                    </template>

                                    <v-btn
                                        fab
                                        dark
                                        small
                                        color="green"

                                        
                                    >
                                        <v-icon size="15">fa-refresh</v-icon>
                                    </v-btn>

                                    <v-btn
                                        fab
                                        dark
                                        small
                                        color="green"
                                        @click="printaj()"
                                    >
                                        <v-icon size="15">fa-print</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        small
                                        color="red"
                                        @click="zatvoriFormu(false,'')"
                                    >
                                        <v-icon size="15">fa-times-circle</v-icon>
                                    </v-btn>

                                </v-speed-dial>

                            </template>
                            </v-toolbar>



                        <v-card-actions>
                            <v-snackbar
                            v-model="alertsnackbar"
                            :multi-line="multiLine"
                            absolute
                            left
                            shaped
                            middle
                            >
                            {{ snackbartekst }}

                            <template v-slot:action="{ attrs }">
                                <v-btn
                                color="red"
                                text
                                v-bind="attrs"
                                @click="alertsnackbar = false"
                                >
                                Zatvori
                                </v-btn>
                            </template>
                            </v-snackbar>


                        </v-card-actions>   

                        <v-card-text>
                            <vue-html2pdf
                                :show-layout="false"
                                :float-layout="false"
                                :enable-download="true"
                                :preview-modal="true"
                                :paginate-elements-by-height="1400"
                                filename="hee hee"
                                :pdf-quality="2"
                                :manual-pagination="true"
                                pdf-format="a4"
                                pdf-orientation="portrait"
                                pdf-content-width="550px"

                                @progress="onProgress($event)"
                                @hasStartedGeneration="hasStartedGeneration()"
                                @hasGenerated="hasGenerated($event)"
                                ref="html2Pdf"
                            >
                                
                                <section slot="pdf-content">
                                                                
                                    <Narudzba-Print
                                        :adialog="adialog"
                                        :narudzba="narudzba_prntsite"
                                        :brojnarudzba="brojnarudzba_prntsite"
                                        @w_javiosaPrinta="javiosaPrinta"
                                    ></Narudzba-Print>
                                    
                                </section>
                            </vue-html2pdf>

                            <!-- ugaseno jer je :float-layout="false"
                            <Narudzba-Print
                                :adialog="adialog"
                                :narudzba="narudzba_prntsite"
                                :brojnarudzba="brojnarudzba_prntsite"
                                @w_javiosaPrinta="javiosaPrinta"
                            ></Narudzba-Print>-->
                        </v-card-text>        
                </v-card>  

            </v-main>
        </v-app>
    </v-dialog>
</template>

<script>



import VueHtml2pdf from 'vue-html2pdf'
import NarudzbaPrint from './WebShopNarudzbaPrint.vue'

export default {

    data() {
        return {
        localDialog: this.adialog,    

        stavkedialogovde:false,
        submitted: false,
        svenarudzbekontrola: [],
        svenarudzbe: [],
        narudzba: {
                Id: 0,
                UserID:'', 
                StripeID:'', 
                Narudzba:'',
                Iznos:'', 
                Datum:'', 
                Utime:'', 
                WebPay:'',
                Nacinplacanja:'',
                Poslano:0,
                Isporuceno:0,
                Komentar:'' 
        },
        narudzbadefault: {
                Id: 0,
                UserID:'', 
                StripeID:'', 
                Narudzba:'',
                Iznos:'', 
                Datum:'', 
                Utime:'', 
                WebPay:'',
                Nacinplacanja:'',
                Poslano:0,
                Isporuceno:0,
                Komentar:'' 
        },
        narudzbatab: {
                Id: 0,
                UserID:'', 
                StripeID:'', 
                Narudzba:'',
                Iznos:'', 
                Datum:'', 
                Utime:'', 
                WebPay:'',
                Nacinplacanja:'',
                Poslano:0,
                Isporuceno:0,
                Komentar:'' 
        },
        transbrojnarudzba:0,
        transbrojnarudzba1:0,

        edit: false,
        parentsifrarobe:'',
        parentkupac:'',
        search:'',

        //====== za vuetify kontrole
        pokaziDialogPrintnarudzba:false,
        pokaziDialogEditnarudzba:false,
        dialogDelete: false,
        deletenarudzbabr:'',


        stavkedialog:'',

        alert:false,
        alerttext:'',
        alertsnackbar:false,
        snackbartekst:'',
        multiLine: true,

        /* toolbar */
        /*dugme*/
        direction: 'right',
        fab: false,
        fling: false,
        hover: false,
        tabs: false,
        top: true,
        right: false,
        bottom: false,
        left: true,
        transition: 'slide-y-reverse-transition',
        

        };
    },

    components:{
        'Narudzba-Print':NarudzbaPrint,
        VueHtml2pdf
        },

        props:{
            adialog:false,
            brojnarudzba_prntsite:'',
            narudzba_prntsite:{
                Id: 0,
                UserID:'', 
                StripeID:'', 
                Narudzba:'',
                Iznos:'', 
                Datum:'', 
                Utime:'', 
                WebPay:'',
                Nacinplacanja:'',
                Poslano:0,
                Isporuceno:0,
                Fakturisano:0,
                FakZag:0,
                Komentar:''           
            },



        },

        mounted() {

                    this.brojnarudzba=this.brojnarudzba_prntsite; //ovde je popunjavam jer prvi put je ne vidi watch: jer nije newVal 
                    //console.log("mounted brojnaružbe  " + this.brojnarudzba)
                    //popunipodatke();
                                                            
            },

        watch:{
            adialog(newVal) {
                this.localDialog = newVal;
                },

            localDialog(newVal) {
                this.$emit('update:adialog', newVal);
                },
            
            narudzba_prntsite:  function(newVal) {

                //var poruka ="javio sa Printa broj narudžbe je " + newVal;
                //this.$emit('w_javiosaPrinta', poruka );
                console.log("brojnaružbe  " + newVal.Narudzba)
                this.brojnarudzba=newVal.Narudzba;
                
                
            },

        },        

        methods: {

            zatvoriFormu (formadialog, poruka) {
                this.narudzba = Object.assign({}, this.narudzbaDefault);
                this.kupacinfo =  Object.assign({}, this.kupacinfodefault);
                this.altkupacinfo = Object.assign({}, this.altkupacinfodefault);
                this.$emit('w_javiosaPrinta',poruka, formadialog );

            }, 
            
            printaj(){
                this.$refs.html2Pdf.generatePdf()
            }
            
 
        }        
}
</script>