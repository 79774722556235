<template>
    
 <div>

    <div></div>
      
      <!--v-autocomplete   
        :value="publicSifraRoba"
        :items="opcijers"
        label="Artikal"
        item-text="naziv"
        item-value="sifra"
        :lokonaziv="publicSifraRoba"
        @change="selectedOption($event,'')"
        clearable
        :disabled="publicEdit"
        >      
      </v-autocomplete--->
      <v-autocomplete
        v-model="internalValue" 
        :items="opcijeRS"     
        item-title="naziv" 
        item-value="sifra"
        clearable
        @update:modelValue="selectedOption"

        label="Artikal"
        :disabled="publicEdit"
        prepend-inner-icon="mdi-order-alphabetical-ascending"
        variant="outlined"
      >
      </v-autocomplete>

   </div>


</template>


<script>

import numeral from 'numeral';
//import Input from '../../../../vendor/laravel/breeze/stubs/inertia-vue/resources/js/Components/Input.vue';


/* 
dovstrano bindovanje https://stackoverflow.com/questions/48979636/vue-two-way-prop-binding?rq=1
                
*/

/* stari export default {
  //components: { Input },
  props: {
          'optionList':null,
          'naziv':null, 
          'size':null,
          'default':null,
          publicSifraRoba:String,
          publicEdit:false

      },
  props: {
        publicSifraRS: { //javni prop koji vraća vrijednost sa roditelja
          type: String,
          default: ''
        },
        publicEdit: Boolean,
      },
  data(){
      return{
        
       model:this.publicSifraRoba,
        Pretraga:'',
        PretragaSifra:'',
        svaroba:[],
        svarobaarr:[],
        opcijers:[],
        

        roba:{
            Sifra: '',
            Naziv: '',
            Slug:'', 
            Cijena:0
        },
        currentItem: null,
        sifraCombo:this.publicSifraRoba,
        edit:this.publicEdit
      }
    },
    computed:{

    },
    created() {
    
      this.vratiPodatke();
        
    },


    methods:{
    
      dsetujCombo(siftb){
        
        this.sifraCombo=siftb;
        console.log('tblookup setujcombo publicSifraRoba je : ' + this.publicSifraRoba)
      },

    vratiPodatke(page_url){
            

            this.svaroba = [];
            
            page_url = page_url || '/api/apiproduktisvilookup';
            fetch(page_url)
                .then(res => res.json())
                .then(res => {
                    this.svaroba = res.data; 
                    //console.log('tblookup matrica ušo tarife ' + res.data[0].Naziv);

                    this.svarobaarr = 
                      this.svaroba.map(roba=>({naziv:roba.NazivRoba, sifra:roba.Sifra, slug:roba.Slug, cijena:roba.Cijena}));
                    
                    this.opcijers=this.svarobaarr;

                    //console.log('matrica ' + this.svarobaarr[0].naziv + '-' + this.svarobaarr[0].sifra);

                })
                .catch(err => console.log(err));
            },


    selectedOption: function(v, a){
          
          let robanaziv = "";
          let robaslug="";
          let robacijena=0;
          for (var i in this.opcijers) 
          {			 	 
            if (this.opcijers[i].sifra == v) 
            {
	  			    //console.log("šifra robe na lookup : " + v + " naziv robe : "+ this.opcijers[i].naziv );
              robanaziv=	this.opcijers[i].naziv;
              robaslug=this.opcijers[i].slug;
              robacijena=this.opcijers[i].cijena;

            }
          }

          let robasifra = v;
          
          this.$emit('updateRoba',robasifra ,robanaziv, robaslug, robacijena);
          //this.$emit('update:publicSifraRoba', value); isključio na transdialogu
          
         
	  		}    

    }


} */


export default {
  props: {
    publicSifraRS: { //javni prop koji vraća vrijednost sa roditelja
      type: String,
      default: ''
    },
    publicEdit: Boolean,
  },
  data() {
    return {
      internalValue: this.publicSifraRS, // Interna vrednost koja prati prop vrednost
      opcijeRS: [], // Lista opcija za autocomplete
      /*opcijeRoba: [
        { naziv: 'TROŠKOVI BRZE POŠTE DO 10KG - (BHPOSTA01)', sifra: 'BHPOSTA01' },
        { naziv: 'TROŠKOVI BRZE POŠTE OD 10 - 20KG - (BHPOSTA02)', sifra: 'BHPOSTA02' }
      ]*/
    };
  },
  watch: {
    // Prati promenu prop vrednosti sa roditeljskih formi i sinhronizuj sa lokalnom vrednošću
    publicSifraRS(newVal) {
      this.internalValue = newVal;
    },
    // Kada se interna vrednost promeni, emituje nazad roditelju
    internalValue(newVal) {
      this.$emit('input', newVal); // Emituje promenjenu vrednost za v-model
      this.$emit('update:updateRoba', newVal); // Emituje za updateRoba događaj
      //console.log('Emitovanje vrednosti:', newVal);
    }
  },
  created() {
    this.vratiPodatke(); // Inicijalno punjenje podataka
  },
  methods: {
    vratiPodatke(page_url) {
        page_url = page_url || '/api/apiproduktisvilookup';
        fetch(page_url)
          .then(res => res.json())
          .then(res => {
            this.opcijeRS = res.data.map(roba => ({
              naziv: roba.NazivRoba,
              sifra: roba.Sifra,
              slug:roba.Slug, 
              cijena:roba.Cijena
            }));

            //console.log('sKonvertovani podaci:', this.opcijeRS);
          })
          .catch(err => console.log('Greška pri dohvaćanju podataka:', err));
      
    },
    selectedOption(v) {
      let selectedRS = this.opcijeRS.find(item => item.sifra === v);
      if (selectedRS) {
        //console.log('Izabrana opcija:', selectedRoba);
        this.$emit('updateRoba', selectedRS.sifra, selectedRS.naziv, selectedRS.slug, selectedRS.cijena); // Emitovanje šifre i naziva
      }
    }
  }
};
</script>