<!-- ovaj fajl cemo zadrzati samo za probu vue -->

<template>

  <div>
    <v-treeview :items="items"></v-treeview>
  </div>

    <div>
        

        <div v-html="content"> </div>
        <div v-html="content"> </div>
        <div v-html="content"> </div>
        <div v-html="content"> </div>
        <div v-html="content"> </div>
        vue test
    </div>
</template>

<script>

import axios from 'axios';
export default {

components: {
   //VueEditor
},
props: ['data', 'lang', 'dataItem'],
data() {
    return {
      items: [
        {
          id: 1,
          name: 'Applications :',
          children: [
            { id: 2, name: 'Calendar : app' },
            { id: 3, name: 'Chrome : app' },
            { id: 4, name: 'Webstorm : app' }
          ]
        },
        {
          id: 5,
          name: 'Documents :',
          children: [
            {
              id: 6,
              name: 'vuetify :',
              children: [
                {
                  id: 7,
                  name: 'src :',
                  children: [
                    { id: 8, name: 'index : ts' },
                    { id: 9, name: 'bootstrap : ts' }
                  ]
                }
              ]
            },
            {
              id: 10,
              name: 'material2 :',
              children: [
                {
                  id: 11,
                  name: 'src :',
                  children: [
                    { id: 12, name: 'v-btn : ts' },
                    { id: 13, name: 'v-card : ts' },
                    { id: 14, name: 'v-window : ts' }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 15,
          name: 'Downloads :',
          children: [
            { id: 16, name: 'October : pdf' },
            { id: 17, name: 'November : pdf' },
            { id: 18, name: 'Tutorial : html' }
          ]
        },
        {
          id: 19,
          name: 'Videos :',
          children: [
            {
              id: 20,
              name: 'Tutorials :',
              children: [
                { id: 21, name: 'Basic layouts : mp4' },
                { id: 22, name: 'Advanced techniques : mp4' },
                { id: 23, name: 'All about app : dir' }
              ]
            },
            { id: 24, name: 'Intro : mov' },
            { id: 25, name: 'Conference introduction : avi' }
          ]
        }
      ],


      robaContent: [],
      content: "<h1>Some initial content</h1>" ,
      htmlForEditor: "",

      editorSettings: {
       modules: {
           imageDrop: true,
           imageResize: {}
         }
     }
    }
  },
 beforeMount(){
     this.loadRobaContent();
 },
 methods: {

     loadRobaContent() {
            
             var param = "KB00010392923";
             console.log("route parametar test "  + param);
             
             axios.get('/api/apiroba/'+param, {
                     //params 

                 })
                 .then((response) => {
                     this.robaContent = response.data.data;
                     this.content = this.robaContent["Kratkiopis"];
                     console.log("Kratki opis: " + this.content  );
                     
                 })
                 .catch(function (error) {
                     console.log(error);
                 });
         },

     saveContent: function() {
     // You have the content to save
     console.log(this.content);
     },


     /* ovaj pretvara Base64 u img link i uploada na server */
     /*
     handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
       var formData = new FormData();
       formData.append("file", file);
       var vuex = window.localStorage.getItem('vuex')
       var token = JSON.parse(vuex);

       axios({
         url: this.publicPath + "/images",
         method: "POST",
         data: formData,
         headers: {'Authorization': 'Bearer ' + token.auth.userToken}
       }).then(result => {
         let url = result.data.filePath; // Get url from response
         Editor.insertEmbed(cursorLocation, "image", url);
         resetUploader();
       }).catch(err => {
         alert('yukleme basarisiz! ' + err)
       });
     }
   */

 }


}
</script>
