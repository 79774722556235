


//import VueRouter  from 'vue-router' //vue2
import { createRouter, createWebHistory } from 'vue-router';
import Home from './vue/WebShop/HomeWShop.vue' 
//import WSFront from './vue/testvue.vue'

import WSFront from './vue/WebShop/FrontPageShop.vue'
import WSKontakt from './vue/WebShop/Kontakt.vue'
import WShop from './vue/WebShop/WebShopWShop.vue'
import WebShopKontejner from './vue/WebShop/WebShopKontejner'
import WShopEditProdukt from './vue/WebShop/EditProduktWShop'
import WShopEditProdukt_URL from './vue/WebShop/EditProduktWShop_URL.vue'
import WShopCheckOut from './vue/WebShop/Checkout'
import WShopKomparacija from './vue/WebShop/KomparacijaArtikala'
import WShopSummary from './vue/WebShop/ShopSummary'
import Carousels from './vue/WebShop/ProductCarousel/CarouselsKontrola'
//import Vue2Quill from './vue/WebShop/VueQuillEditor/Vue2Quill'
import Vue2Quill from './vue/testvue.vue'
//import CarouselZoomer from './vue/WebShop/CarouselProductZoomer/ProductZoomer'
//import LighBox from './vue/WebShop/LightBoxGallery/LightGalery'


/* DASHBOARD ROUTE KOJE SAM POSTAVIO DA ZAMJENIM PHP BLADE-OVE */
import WSKreiranje from './vue/WebShopKreiranje/WebShopKreiranjeKontejner'
import WSNarudzbe from './vue/WebShopNarudzbe/WebShopNarudzbeTabela'
import WSRoba from './vue/Registarroba/RobaTabela'

// Your router configuration
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'WSFront',
      component: WSFront
    },
    {
      path: '/wshop',
      name: 'WebShop',
      component: WebShopKontejner,
      //component: () => import('./vue/WebShop/WebShopWShop')
    
    },



    {
      /* OVO JE PUTANJA SA VANA PREKO BROWSERA 
      neće se otvoriti u dialogu nego kao svoja komponenta 
      problem je jer neće da otvori dialog sa ovim linkom
      za razliku od regularnog linka koji otvara dialog ovaj će imati dodatak URL u routu*/     
      
      /* prvo primam adresu u web.php pa je on šalje ovde  */
      path: '/url_wsprodukteditfront/:editProduktSlug',
      name: 'wsprodukteditfront_url',
      component: WShopEditProdukt_URL,
      props: true, // Ovo omogućava prenošenje parametra kao props komponenti

      
      },


  {
            
    path: '/wsprodukteditfront/:editProduktSlug',
    name: 'wsprodukteditfront',
    component: WShopEditProdukt,
    props: true, // Ovo omogućava prenošenje parametra kao props komponenti
    /* docekaj na komponenti sa 
      created() {  
      
        this.publicSlug = this.$route.params.editProduktSlug;
      
        this.loadProduktiPics();
        this.loadProdukti();
        this.loadDetalji();
      },
    */
    //params: { editProduktSlug:slug }
    //props: { editProduktSlug: "54VIKINGMA339C" }
    //component: () => import('./vue/WebShop/WebShopWShop')
    
    },
    {
        path: '/apiwscheckout',
        name: 'apiwscheckout',
        component: WShopCheckOut
        //component: () => import('./vue/WebShop/WebShopWShop')
    },
    {
        path: '/apiwskomparacija',
        name: 'apiwskomparacija',
        component: WShopKomparacija
        //component: () => import('./vue/WebShop/WebShopWShop')
    },    
    {
        path: '/apiwssummary',
        name: 'apiwssummary',
        component: WShopSummary
        //component: () => import('./vue/WebShop/WebShopWShop')
    },
    {
      path: '/kontakt',
      name: 'Kontakt',
      component: WSKontakt,
      //component: () => import('./vue/WebShop/WebShopWShop')
    
    },
    {
      path: '/carousels',
      name: 'carousels',
      component: Carousels
      //component: () => import('./vue/WebShop/WebShopWShop')
    },

    {
      path: '/vue2quill',
      name: 'vue2quill',
      component: Vue2Quill
      
    },


    {
      path: '/wskreiranje_back',
      name: 'wskreiranje',
      component: WSKreiranje
      
    },
    {
      path: '/wsnarudzbe_back',
      name: 'wsnarudzbe',
      component: WSNarudzbe
      
    },
    {
      path: '/wsroba_back',
      name: 'wsroba',
      component: WSRoba
      
    },    

  ], 
});


export default router;







