<template>
    <vueper-slides class=""  
          bullets-outside style="width: 100%;" 
          :dragging-distance="50" 
          lazy 
          lazy-load-on-drag   
          autoplay
          :pause-on-hover="pauseOnHover"
          @autoplay-pause="internalAutoPlaying = false"
          @autoplay-resume="internalAutoPlaying = true"
          fractions progress
          fade 
          >

            <vueper-slide
              v-for="(slide, i) in slides"
              :key="i"
              :image="slide.image"
              :title="slide.title"
              :content="slide.content"
              :video="slide.video" />
              <template v-slot:loader>
                <span>Loading...</span>
              </template>
        
        </vueper-slides>
    <v-card 
        elevation="0"
        class="no-outline mx-auto " 
        max-width="98%">




    
        <!--v-row>
            <v-card  class="no-outline" elevation="0"-->
                <v-row class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
                    Top artikli
                </v-row>
                <v-row class="frontpodnaslov_white1 pa-3  text-center" align="center" justify="center">
                    Top artikli - Klikni na karticu za prikaz detalja
                </v-row>

            <v-card-text>
            

                <v-sheet
                    class="mx-auto"
                    max-width="100%"
                    >

                    <v-slide-group
                        v-model="topproduktindex"
                        class="pa-4"
                        center-active
                        show-arrows
                    >

                            <v-slide-group-item 
                                v-for="proizvod in topprodukti"
                                :key="proizvod.Slug"
                                v-slot="{ active, toggle }"
                            >
                        

                                                    
                                    <v-hover
                                        v-slot="{ isHovering, props }"
                                        open-delay="200"
                                    >
                                        <!--v-card 
                                        
                                            :elevation="hover ? 10 : 2"
                                            :class=" { 'wskartica': true, 'on-hover': isHovering, 'no-outline': true} "
                                            v-bind="props"
                                        -->

                                            <v-card 
                                                :color="active ? '#0c0c0c00' : 'white'"
                                                @click="toggle"
                                                class=" ma-2 no-outline  wskartica-izgled" 
                                                v-bind="props"
                                                
                                            >
                                                <v-img 
                                                    height="200px" 
                                                    width="100%"
                                                    min-width="250px"
                                                    
                                                    class="align-end"
                                                    cover
                                                    :src="isHovering?proizvod.Frontpagepicalt:proizvod.Frontpagepic"
                                                    lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"
                                                >
                                                    <!--v-card-title class="transparentna_traka">{{ proizvod.NazivRoba }}</v-card-title-->
                                                    <div class="wskartica-transparentna_traka">
                                                        <v-card-text class="wskartica-naslov-transparentna_traka">{{ proizvod.NazivRoba.length > 28 ? proizvod.NazivRoba.slice(0, 28) + "..." : proizvod.NazivRoba }} </v-card-text>

                                                        <v-card-title v-if="proizvod.Cijenanaupit == 0" class="wskartica-cijena-desno">Cijena: {{ proizvod.Cijena }} {{ proizvod.Valuta }}</v-card-title>
                                                        
                                                        <v-card-title v-else class="wskartica-cijena-desno" > Cijena na upit </v-card-title>
                                                    </div>
                                                        
                                                    <v-expand-transition>
                                                        <div
                                                            v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out bg-grey-darken-2 v-card--reveal text-h4"
                                                            style="height: 100%;"
                                                        >
                                                        Klikni za prikaz
                                                        </div>
                                                    </v-expand-transition>                                
                                                </v-img>
                                            </v-card>

                                        <!--/v-card-->
                                    </v-hover>
        
                        
                            </v-slide-group-item>

                    </v-slide-group>

                        <v-expand-transition>
                            <v-sheet
                                v-if="topproduktindex != null"
                                tile
                                
                            >
                                <v-container fluid class="pa-12">
                                    <v-row>
                                    <v-card class="no-outline " elevation="0">

                                        <v-card-actions class="justify-start" >

                                                <v-snackbar
                                                :icon="ikonasnackbar"
                                                v-model="alertsnackbar"
                                                :multi-line="multiLine"
                                                :color="colorsnackbar"
                                                relative
                                                centered
                                                middle
                                                >
                                                <v-layout align-center pr-4>
                                                    <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
                                                    <v-layout column>
                                                    <div>
                                                        <strong>{{ snackbartitle }}</strong>
                                                    </div>
                                                    <div>{{ snackbartekst }}</div>
                                                    </v-layout>
                                                </v-layout>
                                                

                                                <template v-slot:action="{ attrs }">
                                                    <v-btn
                                                    color="white"
                                                    text
                                                    v-bind="attrs"
                                                    @click="alertsnackbar = false"
                                                    >
                                                    <v-icon>clear</v-icon>
                                                    </v-btn>
                                                </template>
                                                </v-snackbar> 

                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">                                    
                                                        <v-btn
                                                            class="ma-2" 
                                                            variant="outlined"
                                                                                                                
                                                            @click="$store.commit('dodajUKomparaciju', topprodukti[topproduktindex]); obavijestHeading('Produkt je dodat u komparaciju!', 'info')"
                                                            v-bind="props"
                                                        >
                                                            
                                                            <v-icon
                                                                                                        
                                                            >
                                                            mdi-compare-horizontal
                                                            </v-icon>
                                                        </v-btn> 
                                                    </template>
                                                    <span>Dodaj u komparaciju</span>
                                                </v-tooltip>
                                                
                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">                                             
                                                        <v-btn
                                                            class="ma-2"                                                                                                
                                                            variant="outlined"
                                                            v-bind="props"
                                                            @click="$store.commit('dodajUCart', topprodukti[topproduktindex]); obavijestHeading('Produkt je dodat u korpu!', 'success')"
                                                        >
                                                            
                                                            <v-icon>
                                                                mdi-cart
                                                            </v-icon>
                                                        </v-btn>  
                                                    </template>
                                                    <span>Dodaj u kart</span>
                                                </v-tooltip>

                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">     
                                                        <v-btn
                                                            class="ma-2"                                                                                               
                                                            variant="outlined"
                                                            v-bind="props"
                                                            @click="$router.push('/url_wsprodukteditfront/'+topprodukti[topproduktindex].Slug+'')" >
                                                            <v-icon>
                                                                mdi-information-variant
                                                            </v-icon>
                                                        </v-btn>    
                                                    </template>
                                                    <span>Otvori artikal</span>
                                                </v-tooltip>   

                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">     
                                                            <v-btn
                                                                class="ma-2"                                                                                               
                                                                variant="outlined"
                                                                v-bind="props"
                                                                @click="topproduktindex = null" >
                                                                <v-icon>
                                                                    mdi-window-close
                                                                </v-icon>
                                                            </v-btn>    
                                                        </template>
                                                        <span>Zatvori prikaz</span>
                                                </v-tooltip>                                              
                                        </v-card-actions>
                                    </v-card>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-card class="wseditartikla-kratki-opis" elevation="0">
                                                
                                                <v-card-title>
                                                    {{ topprodukti[topproduktindex].NazivRoba }}
                                                </v-card-title>

                                                <v-card-text>
                                                    <div v-if="topprodukti[topproduktindex].Kratkiopisprikazi == 1"  v-html=topprodukti[topproduktindex].KratkiOpisRoba > </div> 
                                                
                                                    <v-card-title class="text-end">
                                                        <div v-if="topprodukti[topproduktindex].Cijenanaupit==0">Cijena : {{ topprodukti[topproduktindex].Cijena }} {{ topprodukti[topproduktindex].Valuta }}</div>
                                                        <div v-else>Cijena na upit</div>
                                                    </v-card-title>

                                                </v-card-text>

                                                <!--div v-html=topprodukti[topproduktindex].KratkiOpisRoba > </div-->   
                                                <!--p class="text-justify mt-4">
                                                
                                                    {{  topprodukti[topproduktindex].OpisRoba }}
                                                </p-->
                                            </v-card>
                                        </v-col>
                                            
                                        <v-col cols="12" sm="6" class="d-flex align-center justify-center">
                                            <div class="thumbnail">
                                                <img :src="topprodukti[topproduktindex].Frontpagepic"  style="width: 100%;" />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-sheet>
                        </v-expand-transition>
                </v-sheet> 
            </v-card-text>    
            <!--/v-card>
        </v-row-->


        <!--v-row>
            <v-card  class="no-outline" elevation="0"-->
            <v-row class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
                Artikli u akciji 
            </v-row>
            <v-row class="frontpodnaslov_white1 pa-3 text-center" align="center" justify="center">
                Artikli u akciji - Klikni na karticu za prikaz detalja
            </v-row>

            <v-card-text>
            

                <v-sheet
                    class="mx-auto"
                    max-width="100%"
                    >

                    <v-slide-group
                        v-model="akcijaproduktindex"
                        class="pa-4"
                        center-active
                        show-arrows
                    >

                            <v-slide-group-item 
                                v-for="akcijaproizvod in akcijaprodukti"
                                :key="akcijaproizvod.Slug"
                                v-slot="{ active, toggle }"
                            >
                        

                                                    
                                    <v-hover
                                        v-slot="{ isHovering, props }"
                                        open-delay="200"
                                    >
                                        <!--v-card 
                                        
                                            :elevation="hover ? 10 : 2"
                                            :class=" { 'wskartica': true, 'on-hover': isHovering, 'no-outline': true} "
                                            v-bind="props"
                                        -->

                                            <v-card 
                                                :color="active ? '#0c0c0c00' : 'white'"
                                                @click="toggle"
                                                class=" ma-2 no-outline  wskartica-izgled" 
                                                v-bind="props"
                                                
                                            >
                                                <v-img 
                                                    height="200px" 
                                                    width="100%"
                                                    min-width="250px"
                                                    
                                                    class="align-end"
                                                    cover
                                                    :src="isHovering?akcijaproizvod.Frontpagepicalt:akcijaproizvod.Frontpagepic"
                                                    lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"
                                                >
                                                    <!--v-card-title class="transparentna_traka">{{ akcijaproizvod.NazivRoba }}</v-card-title-->
                                                    <div class="wskartica-transparentna_traka">
                                                        <v-card-text class="wskartica-naslov-transparentna_traka">{{ akcijaproizvod.NazivRoba.length > 28 ? akcijaproizvod.NazivRoba.slice(0, 28) + "..." : akcijaproizvod.NazivRoba }} </v-card-text>

                                                        <v-card-title v-if="akcijaproizvod.Cijenanaupit == 0" class="wskartica-cijena-desno">Cijena: {{ akcijaproizvod.Cijena }} {{ akcijaproizvod.Valuta }}</v-card-title>
                                                        
                                                        <v-card-title v-else class="wskartica-cijena-desno" > Cijena na upit </v-card-title>
                                                    </div>
                                                        
                                                    <v-expand-transition>
                                                        <div
                                                            v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out bg-grey-darken-2 v-card--reveal text-h4"
                                                            style="height: 100%;"
                                                        >
                                                        Klikni za prikaz
                                                        </div>
                                                    </v-expand-transition>                                
                                                </v-img>
                                            </v-card>

                                        <!--/v-card-->
                                    </v-hover>
        
                        
                            </v-slide-group-item>

                    </v-slide-group>

                        <v-expand-transition>
                            <v-sheet
                                v-if="akcijaproduktindex != null"
                                tile
                                
                            >
                                <v-container fluid class="pa-12">
                                    <v-row>
                                    <v-card class="no-outline " elevation="0">

                                        <v-card-actions class="justify-start" >

                                                <v-snackbar
                                                :icon="ikonasnackbar"
                                                v-model="alertsnackbar"
                                                :multi-line="multiLine"
                                                :color="colorsnackbar"
                                                relative
                                                centered
                                                middle
                                                >
                                                <v-layout align-center pr-4>
                                                    <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
                                                    <v-layout column>
                                                    <div>
                                                        <strong>{{ snackbartitle }}</strong>
                                                    </div>
                                                    <div>{{ snackbartekst }}</div>
                                                    </v-layout>
                                                </v-layout>
                                                

                                                <template v-slot:action="{ attrs }">
                                                    <v-btn
                                                    color="white"
                                                    text
                                                    v-bind="attrs"
                                                    @click="alertsnackbar = false"
                                                    >
                                                    <v-icon>clear</v-icon>
                                                    </v-btn>
                                                </template>
                                                </v-snackbar> 

                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">                                    
                                                        <v-btn
                                                            class="ma-2" 
                                                            variant="outlined"
                                                                                                                
                                                            @click="$store.commit('dodajUKomparaciju', akcijaprodukti[akcijaproduktindex]); obavijestHeading('Produkt je dodat u komparaciju!', 'info')"
                                                            v-bind="props"
                                                        >
                                                            
                                                            <v-icon
                                                                                                        
                                                            >
                                                            mdi-compare-horizontal
                                                            </v-icon>
                                                        </v-btn> 
                                                    </template>
                                                    <span>Dodaj u komparaciju</span>
                                                </v-tooltip>
                                                
                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">                                             
                                                        <v-btn
                                                            class="ma-2"                                                                                                
                                                            variant="outlined"
                                                            v-bind="props"
                                                            @click="$store.commit('dodajUCart', akcijaprodukti[akcijaproduktindex]); obavijestHeading('Produkt je dodat u korpu!', 'success')"
                                                        >
                                                            
                                                            <v-icon>
                                                                mdi-cart
                                                            </v-icon>
                                                        </v-btn>  
                                                    </template>
                                                    <span>Dodaj u kart</span>
                                                </v-tooltip>

                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">     
                                                        <v-btn
                                                            class="ma-2"                                                                                               
                                                            variant="outlined"
                                                            v-bind="props"
                                                            @click="$router.push('/url_wsprodukteditfront/'+akcijaprodukti[akcijaproduktindex].Slug+'')" >
                                                            <v-icon>
                                                                mdi-information-variant
                                                            </v-icon>
                                                        </v-btn>    
                                                    </template>
                                                    <span>Otvori artikal</span>
                                                </v-tooltip>   

                                                <v-tooltip text="Tooltip">
                                                    <template v-slot:activator="{ props }">     
                                                            <v-btn
                                                                class="ma-2"                                                                                               
                                                                variant="outlined"
                                                                v-bind="props"
                                                                @click="akcijaproduktindex = null" >
                                                                <v-icon>
                                                                    mdi-window-close
                                                                </v-icon>
                                                            </v-btn>    
                                                        </template>
                                                        <span>Zatvori prikaz</span>
                                                </v-tooltip>                                              
                                        </v-card-actions>
                                    </v-card>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-card class="wseditartikla-kratki-opis" elevation="0">
                                                
                                                <v-card-title>
                                                    {{ akcijaprodukti[akcijaproduktindex].NazivRoba }}
                                                </v-card-title>

                                                <v-card-text>
                                                    <div v-if="akcijaprodukti[akcijaproduktindex].Kratkiopisprikazi == 1"  v-html=akcijaprodukti[akcijaproduktindex].KratkiOpisRoba > </div> 
                                                
                                                    <v-card-title class="text-end">
                                                        <div v-if="akcijaprodukti[akcijaproduktindex].Cijenanaupit==0">Cijena : {{ akcijaprodukti[akcijaproduktindex].Cijena }} {{ akcijaprodukti[akcijaproduktindex].Valuta }}</div>
                                                        <div v-else>Cijena na upit</div>
                                                    </v-card-title>

                                                </v-card-text>

                                                <!--div v-html=akcijaprodukti[akcijaproduktindex].KratkiOpisRoba > </div-->   
                                                <!--p class="text-justify mt-4">
                                                
                                                    {{  akcijaprodukti[akcijaproduktindex].OpisRoba }}
                                                </p-->
                                            </v-card>
                                        </v-col>
                                            
                                        <v-col cols="12" sm="6" class="d-flex align-center justify-center">
                                            <div class="thumbnail">
                                                <img :src="akcijaprodukti[akcijaproduktindex].Frontpagepic"  style="width: 100%;" />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-sheet>
                        </v-expand-transition>
                </v-sheet> 
            </v-card-text>    
            <!--/v-card>
        </v-row-->

        <v-card-actions>
            <v-snackbar
            :icon="ikonasnackbar"
            v-model="alertsnackbar"
            :multi-line="multiLine"
            :color="colorsnackbar"
            relative
            centered
            middle
            >
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
                <v-layout column>
                <div>
                    <strong>{{ snackbartitle }}</strong>
                </div>
                <div>{{ snackbartekst }}</div>
                </v-layout>
            </v-layout>
            

            <template v-slot:action="{ attrs }">
                <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="alertsnackbar = false"
                >
                <v-icon>clear</v-icon>
                </v-btn>
            </template>
            </v-snackbar>


        
        </v-card-actions>

    
    </v-card>





</template>

<script>
import axios from "axios";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


  export default {


    data () {
      return {


        slides: [ //embed generator https://www.classynemesis.com/projects/ytembed//
      {           
        title: '<span style="font-size: 8em; color: white; text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);">BOTANICA</span>',
        content: '<span style="font-size: 2em;color: white; text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);">plant shop</span>',
        image:'https://www.plantea.com.hr/wp-content/uploads/2015/09/monarda-0001.jpg' 
      },

      {           
        title: '',
        content: '',
        image:'https://www.westonnurseries.com/wp-content/uploads/2022/04/weston-nurseries-pink-phlox-flowers-in-summertime.jpeg' 
      },
      
      {           
        title: '',
        content: '',
        image:'https://alternativa.hr/wp-content/uploads/2013/11/vrijesak-1280x720.jpg' 
      },  
      /* cimmer slide
      {           
        title: '',
        content: '',
        image:'https://fes-bh.ba/slider-photos/Slide_4.jpg' 
      },*/            
  
      /*{
            video: {
        
              
              mp4: `video/Elvir_FES.mp4`,
              props: { autoplay: true, loop: true, controls: false, muted: true }
            }
          },

      {
            title: '',
            content: '',
            image: 'https://www.protiq.com//out/marketplace/merchants/b3d444a46e728f34323ba39c0a836cca/logo/thumbs/420x420e/murtfeld.png',
            video: {
              /*url: 'https://www.youtube.com/embed/ehJg_OlcjpE?rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',*/
  /*            url: 'https://www.youtube.com/embed/lQyyZSJ2LJw??rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',
              props: {
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;'
              }
            }
          },

          
          {
            title: '',
            content: '',
            image: 'https://www.protiq.com//out/marketplace/merchants/b3d444a46e728f34323ba39c0a836cca/logo/thumbs/420x420e/murtfeld.png',
            video: {
              url: 'https://www.youtube.com/embed/ob-3C8unXtQ?rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',
              props: {
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              },
              pointerEvents: false //This video can\'t be interacted with.
            }
          }*/
        ],   

        groupOpen: true,  // Dodato kako bismo kontrolisali otvorenost liste
        isHovered: false,  // Dodato stanje za hover
        isHovering: false,

          
        topproduktindex: null,
        topprodukti:[],
        prikaznaziva:'',
        akcijaproduktindex: null,
        akcijaprodukti:[],


        //snackbar
        alert:false,
        alerttext:'',
        alertsnackbar:false,
        snackbartekst:'',
        snackbartitle:'',
        multiLine: true,
        ikonasnackbar:'',
        colorsnackbar:'',           

      }
      
    },
    components: { 
        VueperSlides, VueperSlide,
     },

    created() {
      this.loadTopProdukti();
      this.loadAkcijaProdukti();
    },

    methods:{
      loadTopProdukti(){

          var params = new URLSearchParams();
              /*console.log('srodni produkti kategorija ' 
              + this.produkti.Kategorija + ' cijena ' + this.produkti.Cijena)*/

              //params.append("kategorija", this.produkti.Kategorija);
              //params.append("cijena", this.produkti.Cijena);

          axios.get('/api/apiwstopprodukti', {
                  //params 
                  params: {
                    "status":1

                  }

              })
              .then((response) => {
                  this.topprodukti = response.data.data;
                  
                  this.prikaznaziva=this.topprodukti[0].KategorijaNaziv;
                  


                  this.loading = false;
              })
              .catch(function (error) {
                  console.log(error);
              });                
      }, 
      loadAkcijaProdukti(){

          var params = new URLSearchParams();
              /*console.log('srodni produkti kategorija ' 
              + this.produkti.Kategorija + ' cijena ' + this.produkti.Cijena)*/

              //params.append("kategorija", this.produkti.Kategorija);
              //params.append("cijena", this.produkti.Cijena);

          axios.get('/api/apiwsakcijaprodukti', {
                  //params 
                  params: {

                  }

              })
              .then((response) => {
                  this.akcijaprodukti = response.data.data;
                  
                  this.loading = false;
              })
              .catch(function (error) {
                  console.log(error);
              });                
      },  
      
      async obavijestHeading(strTekst, tip){

        switch(tip) {
        case 'info':
            this.ikonasnackbar="info";
            this.colorsnackbar="info";
            this.snackbartitle="Info"
            break;
        case 'success':
            this.ikonasnackbar="check_circle";
            this.colorsnackbar="success";
            this.snackbartitle="Obavijest"
            break;
        case 'error':
            this.ikonasnackbar="error";
            this.colorsnackbar="error";
            this.snackbartitle="Greška"
            break; 
        case 'warning':
            this.ikonasnackbar="warning";
            this.colorsnackbar="warning";
            this.snackbartitle="Upozorenje"
            break;                                        
        default:

        }

        this.snackbartekst=strTekst;
        this.alertsnackbar=true;


      },
      
    }
  }
</script>

<style>


</style>
