<template>
  <v-card color="white" flat tile>
    <!-- App Bar -->
    <v-app-bar class="frontmenubtn">
      <!-- Nav Icon for toggling drawer -->
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>Application Bar</v-app-bar-title>

      <v-spacer></v-spacer>

      <!-- Desktop Menu -->
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="item in menuItems"
          :key="item.icon"
          :to="item.path"
          class="text-center"
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <!-- Mobile Drawer -->
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      class="hidden-md-and-up"
    >
    <v-list>
      <v-list-item 
        v-for="(item, i) in menuItems"
        :key="i"
        @click="$router.push({ path: item.path })"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
    </v-navigation-drawer>

    <!-- Main Content -->
    <v-main>
      <router-view></router-view>
    </v-main>

    <!-- Footer -->
    <v-footer  padless>
      <v-card flat tile class="footersite white--text text-center">
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text icon-hover"
            icon
          >
            <v-icon size="24px">{{ icon }}</v-icon>
          </v-btn>
        </v-card-text>

      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      drawer: false, // Controls mobile menu drawer
      menuItems: [
        { title: "Home", path: "/", icon: "mdi-home" },
        { title: "Shop", path: "/wshop", icon: "mdi-store" },
        { title: "Cart", path: "/apiwscheckout", icon: "mdi-cart" },
        { title: "Kontakt", path: "/kontakt", icon: "mdi-information-outline" },
        { title: "vue2quill", path: "/vue2quill", icon: "mdi-pencil" },
      ],
      icons: ["mdi-facebook", "mdi-twitter", "mdi-instagram"],
    };
  },
};
</script>

<style>

</style>
