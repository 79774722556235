<template>
    
 <div>


      <v-autocomplete        
        :value="publicSifraVrstaRobe"
        :items="opcije2"
        label="Vrsta robe"
        @change="selectedOption"        
        item-text="naziv"
        item-value="sifra"  
        >              
     </v-autocomplete>
 </div>
</template>


<script>

export default {
  props: {
          'naziv':null, 
          publicNazivVrstaRobe:String, 
          publicSifraVrstaRobe:Number
      },

  data(){
      return{
        model:this.publicSifraVrstaRobe,
        search_data:[],
        svevrste:[],
        svevrstearr:[],
        opcije2:[],
        vrstarobe:{
            Sifra: '',
            Naziv: '',
            Status:0,
            Prioritet:0
        }
        
      }
    },

  created() {
    
          this.vratiPodatke();
       

        },

        
    methods:{



       vratiPodatke(page_url){
          
                this.svevrste = [];
               
                page_url = page_url || '/api/apivrstarobesva';
                fetch(page_url)
                    .then(res => res.json())
                    .then(res => {
                        this.svevrste = res.data; 

         

                        this.svevrstearr=
                        this.svevrste.map
                        (vrsta=>({naziv:vrsta.Naziv, sifra:vrsta.Sifra}));
                        
                        this.opcije2=this.svevrstearr;    
                        
                        //console.log('matrica ' + this.svevrstearr[0].naziv + '-' + this.svevrstearr[0].sifra);                   
                    })
                    .catch(err => console.log(err));
                },
        selectedOption: function(value){
                //console.log("šifra : " + value.sifra + ' porez : '+ value.porezp + ' samo value: ' + value);
                let myNumber = value;
                this.$emit('updateVrsta', myNumber);
                this.$emit('update:publicSifraVrstaRobe', value);

         
	  		}  
 
    }

}

</script>