<template>


    <row id="mapa">
        <v-card elevation="0" class="fullpage white1" min-width="100%">
            
            <div id="map" class=" " style="width: 100%; height: 400px"></div>

        </v-card>
    </row>

  <v-card 
      elevation="0"
      class="no-outline mx-auto" style="margin-top: 20px;"
      max-width="98%">
      

      <v-row class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
                Kontakt
      </v-row>

      
      
      
        <v-row
          justify="center"
          align="center"
        >          
            <v-col  cols="12" sm="8" class="text-center" >
              <v-card class="wseditartikla-kratki-opis" elevation="0">
                                                
                  <v-card-title>
                    BOTANICA plant shop TR <br>                    
                  </v-card-title>
                  <v-card-text>
                    Adresa: Maršala Tita 135, Tuzla<br>
                    Mobitel: 387 61 657 010<br>
                    E-mail: omarhukic@gmail.com
                  </v-card-text>

              </v-card>
            </v-col>
        </v-row>
      






 
  </v-card>


  <v-card-actions>
        <v-snackbar
        :icon="ikonasnackbar"
        v-model="alertsnackbar"
        :multi-line="multiLine"
        :color="colorsnackbar"
        relative
        centered
        middle
        >
        <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
            <v-layout column>
            <div>
                <strong>{{ snackbartitle }}</strong>
            </div>
            <div>{{ snackbartekst }}</div>
            </v-layout>
        </v-layout>
        

        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="alertsnackbar = false"
            >
            <v-icon>clear</v-icon>
            </v-btn>
        </template>
        </v-snackbar>


    
      </v-card-actions>



</template>

<script>
import axios from "axios";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

  export default {


    data () {
      return {


      slides: [ //embed generator https://www.classynemesis.com/projects/ytembed//
      {           
        title: '<span style="font-size: 8em; color: white; text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);">BOTANICA</span>',
        content: '<span style="font-size: 2em;color: white; text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);">plant shop</span>',
        image:'https://www.plantea.com.hr/wp-content/uploads/2015/09/monarda-0001.jpg' 
      },

      {           
        title: '',
        content: '',
        image:'https://www.westonnurseries.com/wp-content/uploads/2022/04/weston-nurseries-pink-phlox-flowers-in-summertime.jpeg' 
      },
      
      {           
        title: '',
        content: '',
        image:'https://alternativa.hr/wp-content/uploads/2013/11/vrijesak-1280x720.jpg' 
      },  
      /* cimmer slide
      {           
        title: '',
        content: '',
        image:'https://fes-bh.ba/slider-photos/Slide_4.jpg' 
      },*/            
  
      /*{
            video: {
        
              
              mp4: `video/Elvir_FES.mp4`,
              props: { autoplay: true, loop: true, controls: false, muted: true }
            }
          },

      {
            title: '',
            content: '',
            image: 'https://www.protiq.com//out/marketplace/merchants/b3d444a46e728f34323ba39c0a836cca/logo/thumbs/420x420e/murtfeld.png',
            video: {
              /*url: 'https://www.youtube.com/embed/ehJg_OlcjpE?rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',*/
  /*            url: 'https://www.youtube.com/embed/lQyyZSJ2LJw??rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',
              props: {
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;'
              }
            }
          },

          
          {
            title: '',
            content: '',
            image: 'https://www.protiq.com//out/marketplace/merchants/b3d444a46e728f34323ba39c0a836cca/logo/thumbs/420x420e/murtfeld.png',
            video: {
              url: 'https://www.youtube.com/embed/ob-3C8unXtQ?rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',
              props: {
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              },
              pointerEvents: false //This video can\'t be interacted with.
            }
          }*/
        ],   

        groupOpen: true,  // Dodato kako bismo kontrolisali otvorenost liste
        isHovered: false,  // Dodato stanje za hover
        isHovering: false,

          
        topproduktindex: null,
        topprodukti:[],
        prikaznaziva:'',
        akcijaproduktindex: null,
        akcijaprodukti:[],


        //snackbar
        alert:false,
        alerttext:'',
        alertsnackbar:false,
        snackbartekst:'',
        snackbartitle:'',
        multiLine: true,
        ikonasnackbar:'',
        colorsnackbar:'',           

      }
      
    },
    components: { 
        VueperSlides, VueperSlide,
     },

    mounted() {
        this.initializeMap();
    },

    methods:{
      initializeMap() {

        const map = L.map('map').setView([44.53437115258372, 18.68434449719525], 50); // Centar i nivo zumiranja

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        var greenIcon = new L.Icon({
            iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });
        // Dodavanje markera
        const marker = L.marker([44.53437115258372, 18.68434449719525], {icon: greenIcon}).addTo(map);
        marker.bindPopup(`
            <div style="
                font-family: Arial, sans-serif; 
                font-size: 14px; 
                font-weight: bold; 
                color: #2E7D32; 
                text-align: center;
                padding: 5px;
                border-radius: 8px;
                box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
                background-color: rgba(255, 255, 255, 0.7);
                ">
                🌹 🌿 <span style="color: #1B5E20;">BOTANICA</span> plant shop 🌱🌷<br>
                <small style="color: #388E3C;"> Fresh plants & flowers </small>
            </div>
            `).openPopup();
      },
      async obavijestHeading(strTekst, tip){

        switch(tip) {
        case 'info':
            this.ikonasnackbar="info";
            this.colorsnackbar="info";
            this.snackbartitle="Info"
            break;
        case 'success':
            this.ikonasnackbar="check_circle";
            this.colorsnackbar="success";
            this.snackbartitle="Obavijest"
            break;
        case 'error':
            this.ikonasnackbar="error";
            this.colorsnackbar="error";
            this.snackbartitle="Greška"
            break; 
        case 'warning':
            this.ikonasnackbar="warning";
            this.colorsnackbar="warning";
            this.snackbartitle="Upozorenje"
            break;                                        
        default:

        }

        this.snackbartekst=strTekst;
        this.alertsnackbar=true;


      },
      
    }
  }
</script>

<style>


</style>
