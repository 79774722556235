<template>

    <!--v-dialog
        v-model="stavkedialog"
        persistent
        min-width="65%"
        max-width="65%"
        
    -->
            
        <v-card
            outlined
            class="v-card--pozadina-formi"
            >

            <v-card-title     
                class="headline">
                Editovanje stavke : 
                {{ tabprodukt.Slug }} -
                {{ tabprodukt.NazivRoba }} 
            </v-card-title>
                <v-spacer></v-spacer>
                <v-row>
                    <v-col
                    cols="12"
                    sm="3"
                    class="text-center"
                    >
                    <v-btn 
                        class="same-width-btn text-none"
                        color="red-darken-2"
                        rounded="0"
                        variant="outlined"
                        prepend-icon="mdi-exit-to-app"   
                        @click="zatvoriFormu (true, 'odustao')" >
                        <!--v-icon>mdi-close</v-icon-->
                    POVRATAK NA TABELU
                    </v-btn>
                </v-col>


                </v-row>


                <v-tabs
                    v-model="tabs"
                    bg-color="#F4F6F9"
                    grow=""
                   
                >
                    <v-tab value="editstavka">Osnovni podaci</v-tab>
                    <v-tab value="editdetalji">Detalji</v-tab>
                    <v-tab value="editmediji">Slike i videa</v-tab>
                </v-tabs>

                <v-card-text class="v-card--pozadina-formi">
                        <v-window v-model="tabs">
                            <v-window-item value="editstavka">
                                <v-card flat elevation="14" class="v-card--pozadina-formi">
                                    <v-card-text>
                                        <KreiranjeTabela-Edit
                                            :produkt="tabprodukt"
                                            :edit="edit"
                                            @template-loadovan="onTemplateLoaded" 
                                        >
                                        </KreiranjeTabela-Edit>
                                    </v-card-text>
                                </v-card>
                            </v-window-item>

                            <v-window-item value="editdetalji">
                                    <Kreiranje-Detalji-Edit
                                   
                                    :produkt="tabprodukt"
                                    :edit="edit"

                                    >
                                    </Kreiranje-Detalji-Edit>                       
                            </v-window-item>

                            <v-window-item value="editmediji">
                                <Kreiranje-Medija-Edit
                                ref="MedijEditKomponenta"
                                :produkt="tabprodukt"
                                :edit="edit"

                                >
                                </Kreiranje-Medija-Edit>                          
                            </v-window-item>
                        </v-window>
                        </v-card-text>


            <!--v-card elevation="0">
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn

                    class="same-width-btn text-none"
                    color="red-darken-2"
                    rounded="0"
                    variant="outlined"
                    prepend-icon="mdi-exit-to-app" 
                    @click="zatvoriFormu (true, 'odustao')"
                    
                    >
                    ZATVORI FORMU
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>

            </v-card-->

        </v-card>
    <!--/v-dialog-->
</template>

<script>
import KreiranjeTabelaEdit from '../WebShopKreiranje/WebShopKreiranjeTabelaEdit.vue'
import KreiranjeDetaljiEdit from '../WebShopKreiranje/WebShopKreiranjeDetalji.vue'
import KreiranjeMedijaEdit from '../WebShopKreiranje/WebShopKreiranjeMedija.vue'
//import { bus } from '../../app.js'//--- busproba

import { useStore } from 'vuex';

  export default {

    inject:["eventBus"],//bus mitt vue3
    
    setup (){

    },

    data () {
      return {

        //edit: false,
        tabs: null,
        text: '',
        //transtabelanarudzba:[],
        transdialogedit:Boolean,
        lokoprodukt:'',
        sviprodukti: [],
        
        tabprodukt: {

            Sifra:'',
            Slug:'',
            NazivRoba:'',
            Datum:'',
            Staracijena:0, 
            Cijena:0,
            Valuta:'',
            Akcija:0, 
            Akcijadatumstart:'', 
            Akcijadatumkraj:'', 
            Popust:'', 
            Akcijatekst:'',
            Freeshiping:0, 
            Ribbon:0, 
            Ribbontext:'', 
            Prioritet:0,
            Topprodukt:0,
            Cijenanaupit:0, 
            Menukategorija:0,
            Kategorija:0,
            Subkategorija:0,
            SubSubkategorija:0,
            SlikaString:0,
            OpisRoba:0,
            KratkiOpisRoba:0,
            KategorijaNaziv:0,            
            Brand:'',
            Status:0

        },

        produktDefault: {         
            Sifra:'',
            Slug:'',
            NazivRoba:'',
            Datum:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            Staracijena:0, 
            Cijena:0,
            Valuta:'KM',
            Akcija:0, 
            Akcijadatumstart:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
            Akcijadatumkraj:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
            Popust:'', 
            Akcijatekst:'',
            Freeshiping:0, 
            Ribbon:0, 
            Ribbontext:'', 
            Prioritet:0,
            Topprodukt:0,
            Cijenanaupit:0, 
            Menukategorija:0,
            Kategorija:0,
            Subkategorija:0,
            SlikaString:0,
            OpisRoba:0,
            KratkiOpisRoba:0,
            KategorijaNaziv:0,            
            Brand:'',
            Status:0
        },

        bZg:0,
        RefreshTabela:false,

        /*dugme*/
        direction: 'right',
        fab: false,
        fling: false,
        hover: false,
        tabs: false,
        top: true,
        right: false,
        bottom: false,
        left: true,
        transition: 'slide-y-reverse-transition',


      }
    },

    props: {
        edit:false,
        produkt: {
            type: Object,
            required: true,
        },
        transprodukt:'',
        selektovanTabccc:'',
        selected: {
            cijene: [],
            kategorije: [],
            brandovi: [],
            branditems:[],
            kategorijeitems: []
        },        
    },  
    created() {


    },  
    mounted() {
        
    },

    computed:{

       
    },
    components:{
        'KreiranjeTabela-Edit':KreiranjeTabelaEdit,
        'Kreiranje-Detalji-Edit':KreiranjeDetaljiEdit,
        'Kreiranje-Medija-Edit':KreiranjeMedijaEdit,

    },
    
    watch:{
        /*docekuje promjenu sa NaloziTabela.vue 

          <Nalozi-Stavke-Tab
           :brojnarudzbe="transbrojnarudzbe"
          ></Nalozi-Stavke-Tab>
        */


        transprodukt:  function(newVal, oldVal) {
            //console.log("brojnarudzbe je  tab " + newVal);
            this.lokoprodukt=newVal;
            
        },
        produkt: function (newVal, oldVal) {

            Object.assign(this.tabprodukt, newVal);
            
        },

    },


    methods: {
        onTemplateLoaded(updatedProdukt) {
            // Ažurirajte stanje ili prosledite podatke dalje
            this.tabprodukt = { ...updatedProdukt };

            // Emitujte događaj ka `webshopkreiranjedetalji , ako je potrebno
            // ali nije potrebno, ima na watch produkt:
            //this.$refs.KreiranjeDetaljiKomponenta.refreshData(); // Primer ako koristite `ref`
        },

        zatvoriFormu (dialogprikaz, poruka) {
            
            //this.$root.$emit('komponentatabelaprodukata',{refreshgrid:true}); //refreshaj produkte vue2
            this.$store.commit('setRefreshGrida', true);
            ;
            /*bus.$emit('kontejnerinstanca',{transprodukt:this.produktDefault, 
                	                        transedit:true, 
                                            transtabshow:false, transtabelashow:true}); //modrada je true za edit, false za add*/

            this.eventBus.emit('kontejnerinstanca',{transprodukt:this.produktDefault, 
                	                        transedit:true, 
                                            transtabshow:false, transtabelashow:true}); //modrada je true za edit, false za add                                            

        }        
   }

  }
</script>