<template>

  <v-app>

    <v-main>
    
      <v-card
        outlined
        color="blue darken-2"
        class="white--text"
      >
      
        <v-card-title

          class="headline">
          Registar artikala
          <v-spacer></v-spacer>
          
        </v-card-title>

          <v-data-table
          :headers="headers"
          :items="svaroba"
          :search="search"
          class="elevation-1"
        >

            <template v-slot:top>
              <v-card>
                <v-card-text>

                    <v-row >
                      <!-- polja -->
         
                      <v-col
                      cols="12"
                      sm="2"
                      >
                      <v-btn
                  
                  
                          color="blue-darken-2"
                          rounded="0"
                          variant="outlined"
                          prepend-icon="mdi-plus"
                          @click="adialog = true, edit = false, ispraznikontrole(), narednibrojFiskalneSifre()"
                          class="same-width-btn-250  text-none"
                          
                          >
                          DODAVANJE ARTIKLA
                          </v-btn>
                      </v-col>                      
                      <v-col
                      cols="12"
                      sm="2"
                      >
                      <v-btn
                  
                  
                          color="blue-darken-2"
                          rounded="0"
                          variant="outlined"
                          prepend-icon="mdi-database-import-outline"
                          @click="kontrolaImportDialog(true);"
                          class=" text-none"
                          
                          >
                          IMPORT ARTIKALA
                          </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      
                      <v-text-field
                        v-model="search"
                        append-icon="fa-search"
                        label="Pretraga"
                        single-line
                        hide-details
                        variant="outlined"
                        prepend-inner-icon="mdi-order-alphabetical-ascending"
                        append-inner-icon="mdi-magnify"

                      ></v-text-field>

                      <v-snackbar
                      v-model="alertsnackbar2"
                      :multi-line="multiLine"
                      absolute
                      top
                      shaped
                      middle
                      >
                      {{ snackbartekst2 }}

                        <template v-slot:action="{ attrs }">
                            <v-btn
                            color="red"
                            text
                            v-bind="attrs"
                            @click="alertsnackbar2 = false"
                            >
                            Zatvori
                            </v-btn>
                        </template>
                      </v-snackbar>

                      <v-snackbar
                            v-model="alertsnackbar"
                            :icon="ikonasnackbar"
                            :color="colorsnackbar"
                            vertical
                            transition="slide-y-transition"
                            >
                            <v-row justify="space-around">
                                <v-icon  size="large" :icon="ikonasnackbar"></v-icon>
                            </v-row>

                            <div >
                                <strong>{{ snackbartitle }}</strong>
                            </div>
                            <p>{{ snackbartekst }}</p>
                                
                            
                            <template v-slot:actions>
                                <v-btn
                                color="white"
                                variant="text"
                                
                                @click="alertsnackbar = false"
                                >
                                Zatvori
                                </v-btn>
                            </template>
                            </v-snackbar>

                    </v-row >
                    
                    <v-row>


                          <v-dialog
                            v-model="adialog"
                            persistent
                            scrollable
                            max-width="600px"
                          >
                             



                              <v-card                          
                              outlined
                              color="blue darken-2"
                              class="white--text">
                                
                                <v-card-title >
                                 Unos i izmjena registra artikala
                                </v-card-title>

                                <v-card-text class="v-card-text-bg_bijelo-fr_crno">

                                  <v-card-text>
                              
            
                                      <v-row>
                                      <!-- ================================================ start ========================================================================== -->  

                                        <v-col
                                          cols="12"
                                          sm="4"
                                          
                                        >
                                          <v-text-field
                                            v-model="roba.Sifra"
                                            label="Šifra robe*"
                                            id="Sifra-input"
                                            name="Sifra-input"
                                            required
                                            counter="50"
                                            hint="Šifra može sadržavati i slova i brojeve, npr. A0000123"               
                                            flat
                                            clearable
                                            :rules="sifrarules" 
                                            :disabled="!edit?false:true"
                                            :blur="lostFocusProvjera()"

                                            variant="outlined"
                                            prepend-inner-icon="mdi-order-alphabetical-ascending"        
                                          ></v-text-field>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          sm="8"
                                          
                                        >
                                          <v-text-field
                                            v-model="roba.Naziv"
                                            label="Naziv robe*"
                                            id="Naziv-input"
                                            name="Naziv-input"
                                            required
                                            counter="200"
                                            hint="Naziv može sadržavati i slova i brojeve"               
                                            flat
                                            clearable
                                            :rules="nazivrules" 
                                            variant="outlined"
                                            prepend-inner-icon="mdi-order-alphabetical-ascending"        
                                            
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          sm="4"
                                          
                                        >
                                          <v-text-field
                                            v-model="roba.JM"
                                            label="JM*"
                                            id="JM-input"
                                            name="JM-input"
                                            required
                                            counter="3"
                                            hint="Jedinica mjere LIT, KOM, M"               
                                            flat
                                            clearable
                                            :rules="trirules" 
                                            variant="outlined"
                                            prepend-inner-icon="mdi-order-alphabetical-ascending"        
                                            
                                          ></v-text-field>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          sm="8"
                                          
                                        >                       
                                                <LookUp-Tarifni-Broj 
                                                  v-model="roba.TB"                                              
                                                  :publicSifraTarifniBroj.sync="parentsifratarifnibroj"  
                                                  @updateTb="updateTB">                                              >
                                                </LookUp-Tarifni-Broj>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          sm="8">
                                                <LookUp-Vrste-Robe 
                                                  v-model="roba.Vrsta"
                                                  :publicSifraVrstaRobe.sync="parentsifravrstarobe" 
                                                  @updateVrsta="updateVRSTA">
                                                </LookUp-Vrste-Robe>                    
                          
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          sm="4"
                                          
                                        >
                                          <v-text-field
                                            v-model="roba.Cijena"
                                            label="Cijena*"
                                            id="Cijena-input"
                                            name="Cijena-input"
                                            required
                                            hint="Cijena"               
                                            flat
                                            clearable
                                            type="number"
                                            variant="outlined"
                                            prepend-inner-icon="mdi-order-numeric-ascending"
                                          
                                            
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>

                                        <v-col
                                          cols="12"
                                                        
                                        >    

                                        <v-combobox multiple
                                                  v-model="Barkodovi" 
                                                  label="Barkodovi" 
                                                  append-icon
                                                  chips
                                                  deletable-chips
                                                  class="tag-input"
                                                  :search-input.sync="cmbStrSearch" 
                                                  @keyup.enter="updateTags"
                                                  @paste="updateTags" 
                                                  hint="Kada unesete barkod pritisite tipku Enter da sačvate unos.Pritiskom na X brišete barkod. Roba može imati više barkodova."                                                                                     
                                                  variant="outlined"
                                                  prepend-inner-icon="mdi-order-alphabetical-ascending"

                                                  >
                                                  <template v-slot:selection="data">
                                                        <v-chip
                                                          :key="JSON.stringify(data.item)"
                                                          v-bind="data.attrs"
                                                          :input-value="data.selected"
                                                          :disabled="data.disabled"
                                                          close
                                                          color="primary"
                                                          text-color="white"
                                                          @click:close="data.parent.selectItem(data.item)"
                                                        >
                                                          <v-icon left>
                                                            fa-barcode
                                                          </v-icon>
                                                          <!--v-avatar
                                                            class="accent white--text"
                                                            left
                                                            v-text="data.item.slice(0, 1).toUpperCase()"
                                                          ></v-avatar-->
                                                          {{ data.item }}
                                                        </v-chip>
                                                      </template>
                                        </v-combobox>
                                        


                                        </v-col>
                                      </v-row>


                                      <v-row>
                                        <v-col
                                          cols="12"
                                          sm="12"
                                          
                                        >
                                          <v-text-field
                                            v-model="roba.SlikaString"
                                            label="Web lokacija slike artikla (slika na glavnoj stranici)"
                                            id="SlikaString-input"
                                            name="SlikaString-input"
                                            required
                                            counter="500"
                                            hint="Putanja mora sadržavati tačnu lokaciju slike (jpg, png ..)"               
                                            flat
                                            clearable
                                            :rules="nazivrules" 
                                            variant="outlined"
                                            prepend-inner-icon="mdi-order-alphabetical-ascending"                                                
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>


                                      <v-row>
                                        <v-col
                                          cols="12"
                                          sm="8">
                                                <!--LookUp-Vrste-Robe 
                                                  v-model="roba.Vrsta"
                                                  :publicSifraVrstaRobe.sync="parentsifravrstarobe" 
                                                  @updateVrsta="updateVRSTA">
                                                </LookUp-Vrste-Robe-->     

                                                <Look-Up-Brandovi
                                                  v-model="roba.Brand"
                                                  :publicSifraBrand.sync="parentsifrabrand" 
                                                  @updatebrand="updateBRAND">
                                                </Look-Up-Brandovi>                                                               
                          
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                                        
                                        >                  
                                          <v-checkbox
                                            v-model="chkenabled"
                                            hide-details
                                            
                                            :label="!chkenabled?'Artikal je neaktivan':'Artikal je aktivan'"
                                          >
                                          </v-checkbox>
                                        </v-col>

                                      </v-row>


                                      <!-- ================================================ kraj ========================================================================== -->  


                                    <small>*indikacija za obavezno polje</small>
                                  </v-card-text>

                                </v-card-text>
                                <v-card-action variant="tonal">
                                  <v-card class="my-auto" >
                                    <v-spacer></v-spacer>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        class="text-center"
                                        > 
                                      <v-btn
                                        class="same-width-btn text-none"
                                        color="red-darken-2"
                                        rounded="0"
                                        variant="outlined"
                                        prepend-icon="mdi-exit-to-app" 

                                        @click="adialog = false, edit = false, ispraznikontrole()"
                                        
                                      >
                                        Odustani
                                      </v-btn>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        class="text-center"
                                        > 

                                        <v-btn
                                          class="same-width-btn text-none"
                                          color="green-darken-2"
                                          rounded="0"
                                          variant="outlined"
                                          prepend-icon="mdi-content-save-all" 

                                          @click="adialog = false, addRoba(), ispraznikontrole()"
                                        
                                        >
                                          Upiši
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                    </v-card>
                                </v-card-action>
                              
                              </v-card>

                            </v-dialog>

                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="headline">Jeste li sigurni u brisanje artikla {{deleteartikalsifra}} - {{ deleteartikalnaziv }} ?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialogImportRoba" max-width="500px">
                              <v-card>
                                <v-card-title class="headline">Forma za importovanje šifarnika robe.</v-card-title>
                                <v-card-text>
                                  <div>
                                    <v-text-field
                                      label="Odaberi .csv fajl"
                                      variant="outlined"
                                      prepend-inner-icon="mdi-file-import-outline"  
                                      type="file" 
                                      @change="handleFileUploadCSV" 
                                      accept=".csv" >
                                    </v-text-field>

                                    
                                  </div>
                                </v-card-text>
                                <v-card-actions>

                                  <v-spacer></v-spacer>
                                  <v-btn
                                      class="same-width-btn text-none"
                                      color="blue-darken-2"
                                      rounded="0"
                                      variant="outlined"
                                      prepend-icon="mdi-database-import-outline"
                                      @click="submitFileCSV"
                                      
                                      :disabled="!file"                                    
                                      >
                                      UPLOAD CSV FAJL
                                    </v-btn>
                                  <v-btn  
                                    class="same-width-btn text-none"
                                    variant="outlined"
                                    prepend-icon="mdi-exit-to-app"  
                                    color="red-darken-2"
                                    @click="kontrolaImportDialog(false)">ZATVORI FORMU</v-btn>
                                  
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                      <!-- polja kraj -->
                    </v-row>

                </v-card-text>
              </v-card>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                  mdi-delete
                  </v-icon>
            </template>
          </v-data-table>


      </v-card>

  </v-main>
  </v-app>
</template>




<script>
import LookUpBrandovi from '../Registarbrandova/BrandoviLookUp.vue';
import LookupVrsteRobe from './VrstaRobeLookUp.vue';
import LookupTarifniBroj from './TarifnibrojLookUp.vue';
import axios from 'axios';
import javnefunkcije from '../../javnefunkcije'

var numeral = require('numeral');

export default {

  data() {
    return {
      file: null,
      submitted: false,
      svevrsterobe: [],
      svaroba: [],
      roba: {
        Sifra: '',
        Naziv: '',
        JM: '',
        TB:'',
        Porez:0,
        Cijena:0, 
        Vrsta:0,
        Status:0,
        Fiskalnasifra:0, 
        SlikaString:'',
        Brand:'',
        transbarkod:[]
      },
      Barkodovi:[],
      cmbStrSearch:"",
      Pretraga:'',
      PretragaSifra:'',
      Sifra: '',
      pagination: {},
      edit: false,
      isChecked:false, 
      parentsifravrstarobe:0,
      parentnazivvrstarobe:'',
      parentsifratarifnibroj:'',
      parentpptarifnibroj:0,
      parentsifrabrand:'',

      dialogImportRoba:false,

      alert:false,
      alerttext:'',
      alertsnackbar:false,
      snackbartekst:'',
      multiLine: true,
      ikonasnackbar:'',
      colorsnackbar:'',   
      snackbartitle:'', 

      alertsnackbar2:false,
      snackbartekst2:'',


      //====== za vuetify kontrole
      search:'',
      cmbSearch:'',
      adialog:false,
      dialogDelete: false,
      deleteartikalsifra:'',
      deleteartikalnaziv:'',
      chkenabled: false,
      sifrarules: [
        v => !!v || 'Šifra je obavezna!',
        v => (v && v.length <= 50) || 'Šifra mora biti kraća od 50 karaktera.',
      ],
      nazivrules: [
        v => !!v || 'Naziv je obavezan!',
        v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
      ],
      trirules: [
        v => !!v || 'Vrijednost je obavezna!',
        v => (v && v.length <= 3) || 'Unesena vrijednost mora biti kraći od 3 karaktera.',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      
      //wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],


     
    //====== za vuetify kontrole kraj


      headers: [
        {
          title: 'Šifra',
          align: 'start',
          filterable: false,
          key: 'Sifra',
        },
        { title: 'Naziv', key: 'Naziv' },
        { title: 'JM', key: 'JM' },
        { title: 'TB', key: 'TB' },
        { title: 'Porez', key: 'Porez' },
        { title: 'Cijena', key: 'Cijena' },
        { title: 'Actions', key: 'actions', sortable: false }
      ]

    };
  },


  created() {
    this.popuniGrid();

  },
 
  components:{
    'LookUp-Vrste-Robe':LookupVrsteRobe,
    'LookUp-Tarifni-Broj':LookupTarifniBroj,
    'Look-Up-Brandovi':LookUpBrandovi
    
  },
 
  computed:{
    /*referenca https://www.youtube.com/watch?v=kECp7rv-noU*/


  },


  methods: {

    handleFileUploadCSV(event) {
      this.file = event.target.files[0];
    },
    async submitFileCSV() {
      if (!this.file) return;

      let formData = new FormData();
      formData.append('file', this.file);


      try {
        const response = await axios.post('/api/upload-csv-roba', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response); // Proverite kako izgleda `response` ovde
        this.obavijest('Fajl je uspješno uploadovan! Originalna poruka: ' + response.data, 'success');
        alert( ' prošo ');

      } catch (error) {
        this.popuniGrid();
        this.obavijest('Došlo je do greške uploada fajla! Originalna poruka: ' || 'Nepoznata greška', 'error');
        alert( 'Nepoznata greška ' +error + ' php greška ' + response.greskamsg);
      }
          
        



        


    },



    formatBroj(val){
        var numBr = numeral(val);
        var strBr = numBr.format('0.00');
        return strBr;

    },      
    popuniGrid(page_url) {
      //let vm = this;
      page_url = page_url || '/api/apirobasva';
      fetch(page_url)
        .then(res => res.json())
        .then(res => {
          this.svaroba = res.data;
          //vm.makePagination(res.meta, res.links);
        })
        .catch(err => console.log(err));
    },
    deleteRoba(sifra) {
      //if (confirm('Da li ste sigurni u brisanje?')) {
        fetch(`api/apiroba/${sifra}`, {
          method: 'delete'
        })
          .then(res => res.json())
          .then(data => {
            //alert('Roba je obrisana!');
            this.obavijest('Roba je obrisana!','success');
            this.popuniGrid();
          })
          .catch(err => console.log(err.toString()));
          //console.error(this.props.url, status, err.toString()));
      //}
    },

    async provjeriSifru(){

         try {
                let sifra = this.roba.Sifra;
                let putanja =`/api/apifunkcijeRetFieldValue/wshop_roba/Naziv/Sifra/${sifra}`;

                
                const res = await fetch(putanja, {
                    method: 'get'
                    });

                const resdata = await res.json();
  	            //console.log ("vratio je " + resdata.data[0].Naziv);              
                const nazivkontrole= resdata.data[0].Naziv;
                return nazivkontrole;

            }catch(e) 
            {
                //console.log('u asyncu sam i nije vraćen broj stavke naloga');
                return "slobodanbroj";

            }
      
    },

    async lostFocusProvjera(){
        if (this.adialog != false) {
          if(this.edit===false) {

            let strprovjeriSifru= await this.provjeriSifru();
            
            if (strprovjeriSifru!="slobodanbroj") {                
                this.snackbartekst="Šifra postoji, pod nazivom : "+strprovjeriSifru+", unesite drugu vrijednost Šifre!"
                this.alertsnackbar=true;
                //this.ispraznikontrole();
                this.roba.Sifra="";
                return;
            }
          }
       }

    },

    /*async obavijestHeading(strTekst){
        this.snackbartekst2=strTekst;
        this.alertsnackbar2=true;
    },*/


    async obavijest(strTekst, cTip){
            
            this.alertsnackbar=true;
            await javnefunkcije.obavijest(strTekst, cTip, this);
            
        },


    kontrolaImportDialog(bDialog){

      this.dialogImportRoba=false;
      if(bDialog==true){
        this.dialogImportRoba=true;
      } 


    },

    async addRoba() {
      this.submitted=true;

      if (!this.roba.Sifra) {
        await this.obavijest("Šifra je prazna i ništa nije upisano u bazu!",'error');

        return;
      }

      this.roba.Status=0;
      if (this.chkenabled==true) 
      {
        this.roba.Status=1;
      }
      
      this.roba.Vrsta=this.parentsifravrstarobe;
      this.roba.TB=this.parentsifratarifnibroj;
      this.roba.Brand=this.parentsifrabrand;

      this.roba.transbarkod=this.Barkodovi;
      
      if (this.edit === false) {
        // Add
        fetch('api/apiroba', {
          method: 'post',
          body: JSON.stringify(this.roba),
          headers: {
            'content-type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(data => {
            
            //alert('Roba dodata!');
            this.obavijest("Roba je dodata!",'success');
            this.popuniGrid();
            this.ispraznikontrole();
          })
          .catch(err => console.log(err));
      } else {
        // Update
        //alert("update " + this.roba.Sifra + ' ' + this.roba.Naziv);
        fetch('api/apiroba', {
          method: 'put',
          body: JSON.stringify(this.roba),
          headers: {
            'content-type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(data => {
           
            //alert('Roba je izmjenjena!');
            this.obavijest("Roba je izmjenjena!",'success');
            this.popuniGrid();
            this.edit = false;
            this.ispraznikontrole();
          })
          .catch(err => console.log(err.toString()));
      }
    },

      editItem (item) {
        this.edit = true;
        this.roba = this.svaroba.indexOf(item)
        this.roba = Object.assign({}, item)

        this.parentsifravrstarobe=this.roba.Vrsta;
        this.parentsifratarifnibroj=this.roba.TB;
        this.parentsifrabrand=this.roba.Brand;

        var stat= this.roba.Status;
        this.chkenabled=false;//$('#Status').prop("checked", false);
        if (stat==1){
          this.chkenabled=true;//$('#Status').prop("checked", true);
        }
        this.popuniBarkod(this.roba.Sifra);
        this.adialog = true
      },
      deleteItem (item) {
        this.roba = this.svaroba.indexOf(item)
        this.roba = Object.assign({}, item)
        this.deleteartikalsifra= this.roba.Sifra
        this.deleteartikalnaziv= this.roba.Naziv
        this.dialogDelete = true
      },
      closeDelete () {
        this.dialogDelete = false
        this.ispraznikontrole()
      },
      deleteItemConfirm () {
        //this.desserts.splice(this.editedIndex, 1)
        this.deleteRoba( this.deleteartikalsifra);
        this.closeDelete()
      },
      ispraznikontrole(){
        try {
            this.$nextTick(() => {
            this.roba = Object.assign({}, this.defaultItem)
            //this.roba = -1
        })
          
        } catch (error) {
           this.roba.transbarkod=[];
           
        }


        this.Barkodovi=[];
        this.roba.transbarkod=[];
      },

    async updateTB (newData){
      this.roba.TB=newData;
      this.roba.Porez= await this.vratiPorez(newData);
      console.log ('porez vraćen : '+ this.roba.Porez)
    },


    async vratiPorez(val){

         try {
                //let sifra = this.roba.Sifra;
                let putanja =`/api/apifunkcijeRetFieldValue/wshop_tarifnibroj/PP/Sifra/${val}`;

                
                const res = await fetch(putanja, {
                    method: 'get'
                    });

                const resdata = await res.json();
  	            //console.log ("vratio je " + resdata.data[0].Naziv);              
                const porez= resdata.data[0].PP;
                return porez;

            }catch(e) 
            {

                return "greška";

            }
      
    },

    async narednibrojFiskalneSifre(){

        
         try {

                let putanja = "/api/apifunkcijeGetMaxBroj/wshop_roba/fiskalnasifra";
                
                const res = await fetch(putanja, {
                    method: 'get'
                    });

                const resdata = await res.json();
  	            //console.log ("vratio je " + resdata.retmaxsifra);              
                //const retmaxbroj = resdata.retmaxsifra;
                this.roba.fiskalnasifra=resdata.retmaxsifra;
                
                return ;

            }catch(e) 
            {
                alert (e.toString());
                //console.log('u asyncu sam i nije vraćen broj stavke naloga');
               return "slobodanbroj";

            }
      
    },

    updateVRSTA(newData){
      this.roba.Vrsta=newData;
      
    },

    updateBRAND(newData){
      this.roba.Brand=newData;
      
    },

    moramovorazraditizanumberinputeformat2decimale ($event) {
    // pozovi sa kontrole @keypress="format2decimale"

     // console.log($event.keyCode); //keyCodes value
     let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

     // only allow number and one dot
     if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) { // 46 is dot
      $event.preventDefault();
     }

     // restrict to 2 decimal places
     if(this.price!=null && this.price.indexOf(".")>-1 && (this.price.split('.')[1].length > 1)){
     $event.preventDefault();
     }
   },

    openMyDialog () {
      bus.$emit('dialog', true) // emit the event to the bus
    }, 

  //https://codepen.io/virtualadrian/pen/pOeVPX?editors=1010
   popuniBarkod(sifrarobe) {
      
        fetch('api/apibarkod/'+sifrarobe)
        .then(res => res.json())
        .then(res => {
          
          for (let rd of  res.data){
            //console.log('robatables ušo je u popuniBarkod  i sifra je : '+rd.Sifra + ' bc ' + rd.BarKod); 
            this.Barkodovi.push(rd.BarKod)
          }
          
        })
        .catch(err => console.log(err));
    },

    updateTags() {
      //console.log ('bc izvor ' + this.Barkodovi);
      this.roba.transbarkod=this.Barkodovi;
 
      
 
      /*
      this.$nextTick(() => {
           
        this.roba.transbarkod=this.Barkodovi;
        //this.transbarkod.push(this.Barkodovi.split(","));
        console.log('robatables ušo je u updatetags samostalan transbarkod : '+this.transbarkod);  

        this.$nextTick(() => {
          this.cmbSearch = "";
        });
      });
      */
    }
  

  
  }
};
</script>