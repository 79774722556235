<template>

    <div id="galerija" class="v-galerija">



        <div class="aktivnaSlika">
            
            
            <!--div v-show="isImageVisible" class="fullscreen-image">
                <span class="close-btn" @click="hideImage">&times;</span>
                <v-img @click="showImg()" :src="activePic"></v-img>
                
            </div-->
            <!--img @click="showImage" ref="imageRef" v-show="bslika"-->

            <img  ref="imageRef"  @click="showImg()" v-show="bslika">
            

            <video
                class="video"
                v-show="bvideo"
                ref="videoRef"
                controls
                autoplay="autoplay"
                    >
                <source
                    src=""                
                    >
            </video>
            
            <iframe 
                class="iframe"
                v-show="biframe"
                ref="iframeRef"
                src=""
            ></iframe>
        </div>

        <!--div class="skroler">
            <div v-for="(slika, index) in images">
                <img :src="slika.thumb" @mouseup="changeActivePic(index)" :class="{active: index==indexOfActive ? true : false}">

                
            </div>
        </div-->  
        <div class="skroler">
            <v-slide-group
                    v-model="srproduktindex"
                    class="pa-4"
                    center-active
                    show-arrows
                    >
                    <v-slide-group-item 
                        v-for="(slika, index) in  images "
                        :key="images[index].src"

                        >
                       
                            <img :src="slika.thumb" @mouseup="changeActivePic(index)" :class="{aktivna: index==indexOfActive ? true : false}">
                       
                    </v-slide-group-item>
            </v-slide-group>
        </div>
    </div>


    <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index1"
        @hide="handleHide"
    ></vue-easy-lightbox>

</template>
<script>


import VueEasyLightbox from 'vue-easy-lightbox' /**https://github.com/XiongAmao/vue-easy-lightbox */
export default {

    data: function () {

        return {
            visible: false,
            index1: 0, // default: 0

            /*imgs: [
                
                '/images/radnafirma/artikli/ChamecyparisSungold.jpg',
                '/images/radnafirma/artikli/ChamecyparisSungold2.jpg',
                '/images/radnafirma/artikli/ChamecyparisSungold3.jpg'

            ],*/
            imgs: [],

            srproduktindex: null,
            //zoom div
            isImageVisible: false,
             //putanja:"http://127.0.0.1:8000/",
            //galerija
            indexOfActive:0,
            activePic:'',
            //activeVideo:'',
            videoblok:false,
            bslika:true,
            bvideo:false,
            biframe:false,
            strVideo:'',
            /* pictures:[
                'https://www.planetware.com/wpimages/2020/02/france-in-pictures-beautiful-places-to-photograph-eiffel-tower.jpg',
                'https://ichef.bbci.co.uk/news/999/cpsprodpb/15951/production/_117310488_16.jpg',
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQEr1AiVE3QtBxw5y7e8r6UKkbqfdzT0MJUA&usqp=CAU',
                'https://www.planetware.com/wpimages/2019/11/canada-in-pictures-beautiful-places-to-photograph-morraine-lake.jpg',
                'https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg',
                'https://images.pexels.com/photos/1133957/pexels-photo-1133957.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'


            ],*/
        }
    },
    components: {
        
            VueEasyLightbox
     },
    props:{
        images:  Array 
            
        },
    watch:{

        images:  function(newVal, oldVal) {  
            
            console.log(' matrica images: ' + JSON.stringify(this.images)); 
            // Napunite imgs niz sa URL-ovima slika iz images prop
            this.imgs = this.images.map(image => image.image);
            //console.log(' matrica imgs: ' + JSON.stringify(this.imgs)); 

            this.setujPrvusliku();
            this.hideImage();
        }   
    },     
    mounted() {
        
        this.setujPrvusliku();
        this.hideImage();
       
        },
    methods:{
        showImage() {
          this.isImageVisible = true;
          document.body.style.overflow = "hidden"; // Disable scrolling
        },
        hideImage() {
          this.isImageVisible = false;
          document.body.style.overflow = "auto"; // Enable scrolling
        },        
        setujPrvusliku(){
            this.$refs.imageRef.src ="";

            if (this.images && this.images.length > 0) {
                this.changeActivePic(0); // Postavite prvu sliku
            } else {
                // Zamjenska slika ako nema dostupnih slika
                this.activePic = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'; // Zamijenite ovu URL sa URL-om vaše zamjenske slike
                this.bslika = true; // Prikazujemo zamjensku sliku
            }

             //this.changeActivePic(0);
             //this.$refs.imageRef.src ="https://res.cloudinary.com/dxn1bjdta/image/upload/c_scale,w_3000,f_auto/v1606990043/vue-product-carousel/camera-on-tripod.jpg"
        },
        changeActivePic(index){
            
            
            this.index1=index;
            this.bslika=true;
            this.bvideo=false;
            this.biframe=false;
            
            if (this.images[index].tip=="video"){
                    if (this.images[index]['format']=="mp4")
                    {           
                        this.biframe=false;
                        this.bvideo=true;
                        this.bslika=false;
                        this.strVideo=this.images[index]['video'];
                        this.$refs.videoRef.src = this.strVideo;
                        this.$refs.videoRef.play();
                        return;
                    }
                    else
                    {                        
                        this.bvideo=false;
                        this.biframe=true;
                        this.bslika=false;
                        this.strVideo=this.images[index]['video'];
                        this.$refs.iframeRef.src = this.strVideo;
                        //this.$refs.videoRef.play();
                        return;

                    }
                }
            else{ 
                 this.bslika=true;
                 this.activePic=this.images[index].image;
                 this.$refs.imageRef.src = this.activePic;

                 console.log("putanja je : " + this.activePic);}

            this.indexOfActive=index;
        },  
        
        

        /* od galerije zoom */
        showImg() {
          
            console.log('index1 : ' + this.index1 )
            this.visible = true
             /* this.index1 = this.index
            this.visible = true*/
        },

        handleHide() {
            this.visible = false
        }

    }

}
</script>
<style>


    .aktivnaSlika{
        width: 100%;
        height: 350px;

    }

    .aktivnaSlika img{

        width: 100%;
        height: 100%;
        object-fit: contain;
         
    }

    .video{
        width: 100%    !important;
        height: 100%    !important;        
    }

    .iframe{
  
        top: 0;
        left: 0;
        width: 100%    !important;
        height: 100%    !important;


    }

    .skroler{
        width: 100%;
        display: flex;    
        /*overflow-x: scroll;  */
    }

    .skroler img{
        /*width: 150%;
       */
        /*float: left;*/
        width:  100px;
        height: 100px;
        object-fit: cover;  
        margin: 15px;        
    }

    .aktivna {
        border: 2px solid greenyellow;
    }
    .v-galerija{
       
       /* position:absolute;
        left: 0;
        right: 0; */ /*
        height: 700px;
      
        height: 100%;
        min-height: 760px;*/
        overflow: hidden;
        transform: translate3d(0px, 0px, 0px);
        background-color: #ffffff;
    }
/*
     @media screen and (max-width: 1279px) {
        .v-galerija  {
            right: 100%; 
        }
        .v-galerija  .iframe{
            width: 100%;
            height: auto;
        } 
    }

    @media screen and (max-width: 1023px) {
        .v-galerija {
        position: static;
        left: 0;
        right: 0;
        height: auto;
        transform: none; }
    }*/
    @media screen and (max-width: 640px) {
    .aktivnaSlika {
        width: 100%;
        height: auto;
        max-height: 70vh; /* Ograničava visinu na 70% visine ekrana */
        margin: 0;
    }
    
    .aktivnaSlika img,
    .aktivnaSlika .video,
    .aktivnaSlika .iframe {
        width: 100%;
        height: 100%;
        max-height: 70vh;
        object-fit: contain;
    }

    .skroler img {
        width: 70px; /* Manje sličice za pregled */
        height: 70px;
        margin: 5px;
    }
}
   

/*
  @media screen and (max-width: 1023px) {
    .v-galerija {
      position: static;
      left: 0;
      right: 0;
      height: auto;
      transform: none; }
    .v-galerija .block-image {
      position: static;
      right: 0;
      height: 650px;
      background: #fff; }
    .v-galerija .block-image .main-image{
      display: none; }
    .v-galerija .block-image .block-image-carousel {
      overflow: hidden;
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      height: 583px; }
    .v-galerija .block-image .block-image-carousel .swipe-wrap {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      height: 100%;
      width: 100%; }
    .v-galerija .block-image .block-image-carousel .swipe-wrap > div {
      height: 100%; }
    .v-galerija .block-image .block-image-carousel a {
      width: 100%;
      height: 100%;
      opacity: 1; }
    .v-galerija .block-image .swipe-dots {
      display: block;
      padding: 25px 0;
      margin: 0 80px;
      width: auto;
      z-index: 10;
      text-align: center;
      border-bottom: 1px solid #E1E1E1; }
    .v-galerija .block-image .swipe-dots .btn-swipe {
      display: inline-block;
      text-indent: -5000px;
      overflow: hidden;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      border: 1px solid #1e2226;
      margin: 0 2px; }
    .v-galerija .block-image .swipe-dots .btn-swipe.selected,
    .v-galerija .block-image .swipe-dots .btn-swipe:hover {
      background: #1e2226; }
    .v-galerija .block-details {
      position: static;
      left: 0;
      top: 0;
      width: 100%; }

  }
  @media screen and (max-width: 640px) {
    .v-galerija .block-image .swipe-dots {
      margin: 0 25px; }
  }*/



  /*zoom div*/
  img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(253, 253, 253, 0.1);
      cursor: pointer;
    }

    .fullscreen-image {
      position: fixed;
      top: 0;
      left: 0;
      /*width: 100%;
      height: 100%;*/
      width: 100vw; /* Koristi celu širinu ekrana */
      height: 100vh; /* Koristi celu visinu ekrana */
      background-color: rgba(0, 0, 0, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
      overflow: hidden;
    }

    .fullscreen-image img {
      width: 300%;
      height: 300%;
      /*object-fit: contain;*/

    }

    .close-btn {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      font-size: 3rem;
      color: white;
      cursor: pointer;
    }

    .close-btn:hover {
      color: red;
    }
</style>