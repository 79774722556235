<template>
    
 <div>

    <div></div>
      <v-autocomplete        
        :value="publicSifraTarifniBroj"
        :items="opcije2"
        label="Tarifni broj"
        item-text="naziv"
        item-value="sifra"
        v-on:change="selectedOption"
        >        
      </v-autocomplete>
     <!--input v-model="stopaPDV" type="number"  id="tarifaPP" name="tarifaPP" -->

   </div>


</template>


<script>

import numeral from 'numeral';
//import Input from '../../../../vendor/laravel/breeze/stubs/inertia-vue/resources/js/Components/Input.vue';


/* 
dovstrano bindovanje https://stackoverflow.com/questions/48979636/vue-two-way-prop-binding?rq=1
                
*/

export default {
  //components: { Input },
  props: {
          'optionList':null,
          'naziv':null, 
          'size':null,
          'default':null,
          publicSifraTarifniBroj:String,
          publicPPTarifniBroj:Number

      },

  data(){
      return{
        
       model:this.publicSifraTarifniBroj,
        Pretraga:'',
        PretragaSifra:'',
        svetarife:[],
        svetarifearr:[],
        opcije2:[],
        tarifa:{
            Sifra: '',
            Naziv: '',
            PP:0,
            Duguje:'',
            Potrazuje:'',
            Oznaka:'',
            fiskalnasifra:0
        },
        users_options: [

		    ],
        sifraCombo:this.publicSifraTarifniBroj,
        txtPP:0,
        stopaPDV:0
      }
    },
    computed:{

    },
    created() {
    
      this.vratiPodatke();
        
    },


    methods:{
    
      setujCombo(siftb){
        
        this.sifraCombo=siftb;
        console.log('tblookup setujcombo publicSifraTarifniBroj je : ' + this.publicSifraTarifniBroj)
      },

    vratiPodatke(page_url){
            

            this.svetarife = [];
            
            page_url = page_url || '/api/apitarifnibrojevisvi';
            fetch(page_url)
                .then(res => res.json())
                .then(res => {
                    this.svetarife = res.data; 
                    //console.log('tblookup matrica ušo tarife ' + res.data[0].Naziv);

                    this.svetarifearr = 
                    this.svetarife.map
                    (tarifa=>({naziv:tarifa.Naziv, sifra:tarifa.Sifra, porezp:tarifa.PP}));
                    
                    this.opcije2=this.svetarifearr;

                    //console.log('matrica ' + this.svetarifearr[0].naziv + '-' + this.svetarifearr[0].sifra);

                })
                .catch(err => console.log(err));
            },


    selectedOption: function(value){
	  			//console.log("šifra : " + value.sifra + ' porez : '+ value.porezp + ' samo value: ' + value);
          let myNumber = value;
          this.$emit('updateTb', myNumber);
          this.$emit('update:publicSifraTarifniBroj', value);
          
         
	  		}    

    }


}

</script>