 <template>
        <v-card>

        <v-card-text class="v-card-scrollable-text">

            <v-container>

            <v-row>
            <!-- ================================================ start ========================================================================== -->  
               <v-col
                cols="12"
                sm="6"
                >

                    <LookUp-Roba
                        ref="lookUpRoba"
                        v-model="produkt.Sifra" 
                        :publicSifraRS="produkt.Sifra" 
                        :publicEdit="disableRoba"
                        @updateRS="updateRoba"
                        
                    ></LookUp-Roba>

                </v-col>
                
                <v-col
                cols="12"
                sm="2"
                class=" my-3"
                >
                <v-btn
            
                    :disabled="DisabledImportBtn"
                    color="blue-darken-2"
                    rounded="0"
                    variant="outlined"
                    prepend-icon="mdi-refresh"
                    @click="kontrolaImportDialog(true);"
                    class=" text-none"
                    
                    >
                    REFRESH ARTIKLE
                    </v-btn>
                </v-col>
                
                <v-col
                cols="12"
                sm="4"
                
                >
                <v-text-field
                    v-model="produkt.Slug"
                    label="Slug artikla*"
                    id="Slug-input"
                    name="Slug-input"
                    required
                    counter="50"                                    
                    flat
                    :disabled="true"
                    variant="outlined"
                    prepend-inner-icon="mdi-order-alphabetical-ascending"

                ></v-text-field>



                
                </v-col>                                              
            </v-row>
            <v-row>
               <v-col
                cols="12"
                sm="4"
                
                >
                    <LookUp-Kategorije
                        v-model="produkt.Kategorija" 
                        :publicSifraRS="produkt.Kategorija" 
                        :publicEdit="disableKategorija"
                        @updateRS="updateKategorija"                        
                    ></LookUp-Kategorije>
                <!--div>Odabrana sifra parent: {{ produkt.Kategorija }}</div-->
                </v-col> 
                <v-col
                cols="12"
                sm="4"
                
                >
                    <LookUp-Subkategorije
                        v-model="produkt.Subkategorija" 
                        :publicSifraRS="produkt.Subkategorija" 
                        @updateRS="updateSubkategorija"

                    ></LookUp-Subkategorije>
                    <!--div>Odabrana sifra parent: {{ produkt.Subkategorija }}</div-->
                </v-col> 
                <v-col
                cols="12"
                sm="4"
                
                >
                    <LookUp-SubSubkategorije
                        v-model="produkt.SubSubkategorija" 
                        :publicSifraRS="produkt.SubSubkategorija" 
                        @updateRS="updateSubSubkategorija"

                    ></LookUp-SubSubkategorije>
                    <!--div>Odabrana sifra parent: {{ produkt.Subkategorija }}</div-->
                </v-col> 

            </v-row>
            <v-row>
                <v-col
                cols="12"
                sm="2"
                
                >
                <v-text-field
                    v-model="produkt.Stanje"
                    label="Stanje artikla*"
                    id="Stanjeartikla-input"
                    name="Stanjeartikla-input"
                    required
                    hint="Stanje artikla"               
                    flat
                    clearable
                    type="number"
                    variant="outlined"
                    prepend-inner-icon="mdi-order-numeric-ascending"
                    
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                sm="2"
                
                >
                <v-text-field
                    v-model="produkt.Staracijena"
                    label="Stara cijena*"
                    id="StaraCijena-input"
                    name="StaraCijena-input"
                    required
                    hint="Stara cijena"               
                    flat
                    clearable
                    type="number"
                    prepend-inner-icon="mdi-order-numeric-ascending"
                    variant="outlined"
                ></v-text-field>
                </v-col>

                <v-col
                cols="12"
                sm="2"
                
                >
                <v-text-field
                    v-model="produkt.Cijena"
                    label="Cijena*"
                    id="Cijena-input"
                    name="Cijena-input"
                    required
                    hint="cijena"               
                    flat
                    clearable
                    type="number"
                    prepend-inner-icon="mdi-order-numeric-ascending"
                    variant="outlined"
                ></v-text-field>
                </v-col>

                <v-col
                cols="12"
                sm="2"
                
                >
                <v-text-field
                    v-model="produkt.Valuta"
                    label="Valuta*"
                    id="Valuta-input"
                    name="Valuta-input"
                    required
                    hint="Valuta"               
                    flat
                    clearable
                    counter="2"
                    variant="outlined"
                    prepend-inner-icon="mdi-order-alphabetical-ascending"
                ></v-text-field>
                </v-col>  

                <v-col
                cols="12"
                sm="2"
                                
                >                  
                <v-checkbox
                    v-model="chkenabled"
                    hide-details
                    
                    label="Cijena je na upit"
                >
                </v-checkbox>
                </v-col>                                              

            </v-row>


           <v-row>
            <v-col
                cols="12"
                sm="6">

                    <v-menu
                        v-model="menu1"
                   
                        offset-y
                        :close-on-content-click="false"
                        transition="scale-transition"

                        max-width="290px"
                   
                    >
                            <template v-slot:activator="{ props }">
                            <v-text-field
                                clearable
                                v-model="formattedDate1"
                                label="Datum "
                                prepend-inner-icon="mdi-calendar"
                                variant="outlined" 
                                v-bind="props"
                                
                                
                            ></v-text-field>
                            </template>
                        
                            <v-date-picker

                                v-model="odabraniDatum1"
                                @input="menu1 = false"
                                
                            
                            >

                            </v-date-picker>
                    </v-menu>

            </v-col>

                             
     
            </v-row>
            <v-row>
                <v-col
                cols="12"
                sm="6">
                                        
                    <v-textarea
                        v-model="produkt.Akcijatekst"

                        clearable
                        clear-icon="mdi-close"
                        label="Akcija tekst"
                        prepend-inner-icon="mdi-comment-text-outline"
                        variant="outlined"
                        hint=""
                        counter="500"
                        rows="3"
                    ></v-textarea>
                </v-col>
                <v-col
                cols="12"
                sm="2"                                        
                >                       

                <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                            <template v-slot:activator="{ props }">
                            <v-text-field
                                v-model="formattedDate2"
                                label="Datum "
                                prepend-inner-icon="mdi-calendar"
                                variant="outlined" 
                                v-bind="props"
                                
                                @click="menu2 = true" 
                            ></v-text-field>
                            </template>
                        
                            <v-date-picker

                                v-model="odabraniDatum2"
                                @input="menu2 = false"
                                
                            
                            >

                            </v-date-picker>
                    </v-menu>                                    
                </v-col>   

                <v-col
                cols="12"
                sm="2"                                        
                >                       

                    <v-menu
          
                    v-model="menu3"
                    :close-on-content-click="false"
          
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    min-heigth="290px"
                    >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                        v-model="formattedDate3"
                        label="Datum završetka akcije "                                            
                        v-bind="props"
                        prepend-inner-icon="mdi-calendar"
                        variant="outlined" 
                        @click="menu3 = true" 
                        ></v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="odabraniDatum3" 
                        no-title 
                        scrollable
                         @input="menu3 = false"
                    >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                    </v-date-picker>
                    </v-menu>                                       
                </v-col>  



                <v-col
                cols="12"
                sm="2"
                                
                >                  
                <v-checkbox
                    v-model="chkenabled1"
                    hide-details
                    
                    label="Akcija"
                >
                </v-checkbox>
                </v-col>                                                                                                                                                                                    
            </v-row>
            <v-row>


                <v-col
                cols="12"
                sm="6">
                                        
                    <v-textarea
                        v-model="produkt.Ribbontext"
                        prepend-inner-icon="mdi-comment-text-outline"
                        variant="outlined"
                        clearable
                        clear-icon="mdi-close"
                        label="Ribbon tekst"
                        hint=""
                        counter="500"
                        rows="3"
                    ></v-textarea>
                </v-col>  

                <v-col
                cols="12"
                sm="2"
                                
                >                  
                <v-checkbox
                    v-model="chkenabled2"
                    hide-details
                    
                    label="Prikaži ribbon"
                >
                </v-checkbox>
                </v-col>                                                                                               
            </v-row>
            <v-row>
                <v-col
                cols="12"
                sm="4"
                
                >
                <v-text-field
                    v-model="produkt.Prioritet"
                    label="Prioritet*"
                    id="Prioritet-input"
                    name="Prioritet-input"
                    required
                    hint="Prioritet unutar grupe srodnih artikala"               
                    flat
                    clearable
                    type="number"
                    prepend-inner-icon="mdi-order-numeric-ascending"
                    variant="outlined"
                ></v-text-field>
                </v-col>   
                <v-col
                cols="12"
                sm="4"
                                
                >                  
                <v-checkbox
                    v-model="chkenabled3"
                    hide-details
                    
                    label="Freeshiping"
                >
                </v-checkbox>
                </v-col>
                <v-col
                cols="12"
                sm="4"
                                
                >                  
                <v-checkbox
                    v-model="chkenabled4"
                    hide-details
                    
                    label="Top artikl"
                >
                </v-checkbox>
                </v-col> 
            
        

            </v-row>
            <v-row>

                <v-col
                cols="12"
                sm="2"                                        
                >                       

                    <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"

                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                        v-model="formattedDate4"
                        label="Datum početka za novi artikl "
                        prepend-inner-icon="mdi-calendar"                                                
                        v-bind="props"
                        variant="outlined"
                        @click="menu4 = true" 
                        ></v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="odabraniDatum4" 
                        no-title 
                        scrollable
                         @input="menu4 = false"
                    >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                    </v-date-picker>
                    </v-menu>                                       
                </v-col>   

                <v-col
                cols="12"
                sm="2"                                        
                >                       

                    <v-menu
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
  
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                        v-model="formattedDate5"
                        label="Datum završetka za novi artikl "
                        prepend-inner-icon="mdi-calendar"                                                
                        v-bind="props"
                        variant="outlined"
                        @click="menu5 = true" 
                        ></v-text-field>


                    </template>
                    <v-date-picker 
                        v-model="odabraniDatum5" 
                        no-title 
                        scrollable
                         @input="menu5 = false"
                    >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                    </v-date-picker>
                    </v-menu>                                       
                </v-col>  



                <v-col
                cols="12"
                sm="2"
                                
                >                  
                <v-checkbox
                    v-model="chkenabled5"
                    hide-details
                    
                    label="Novi artikl"
                >
                </v-checkbox>
                </v-col>                                                                                                                                                                                    
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    sm="6"
                                    
                    >  
                        <v-card
                            class="d-flex flex-column" min-height="500px"
                            title=""
                            subtitle="Slika na frontpage "
                            text="Ova će slika biti na kartici na shopu"
                              variant="outlined"
                        >
                            <v-card-text >

                                <v-img
                                prepend-icon="mdi-calendar"
                                    class="image-fit"
                                    contain
                                    lazy-src="https://picsum.photos/id/11/10/6"
                                    max-height="100"
                                    max-width="200"
                                    :src="produkt.Frontpagepic"
                                    ></v-img>
                                    {{ produkt.Frontpagepic }}
                                </v-card-text>
                                
                            <v-card-actions>
                                <input type="file"  @change="onPicSrcChanged">   
                            </v-card-actions>
                            
                        </v-card>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="6"
                                    
                    >  
                        <v-card
                            class="d-flex flex-column" min-height="500px"
                            title=""
                            subtitle="Alt slika na frontpage "
                            text="Ova će slika biti na kartici na shopu, kao sekundarna slika koja će se prikazivita prilikom prelaska miša preko kartice."
                            variant="outlined"
                        >
                            <v-card-text >

                                <v-img
                                prepend-icon="mdi-calendar"
                                    class="image-fit"
                                    contain
                                    lazy-src="https://picsum.photos/id/11/10/6"
                                    max-height="100"
                                    max-width="200"
                                    :src="produkt.Frontpagepicalt"
                                    ></v-img>
                                    {{ produkt.Frontpagepicalt }}
                                </v-card-text>
                                
                            <v-card-actions>
                                <input type="file"  @change="onAltPicSrcChanged">   
                            </v-card-actions>
                            
                        </v-card>
                    </v-col>                    
                </v-row>
                    <!-- ================================================ kraj ========================================================================== -->  
            


            </v-container>
            
            <small>*indikacija za obavezno polje</small>
            <Web-Kartica-Zona></Web-Kartica-Zona>
            
        </v-card-text>

        <v-divider inset></v-divider>



        <v-card-actions>
            <v-dialog v-model="dialogImportRoba" max-width="500px">
                              <v-card>
                                <v-card-title class="headline">Forma za importovanje šifarnika robe.</v-card-title>
                                <v-card-text>
                                  <div>
                                    <v-text-field
                                      label="Odaberi .csv fajl"
                                      variant="outlined"
                                      prepend-inner-icon="mdi-file-import-outline"  
                                      type="file" 
                                      @change="handleFileUploadCSV" 
                                      accept=".csv" >
                                    </v-text-field>

                                    
                                  </div>
                                </v-card-text>
                                <v-card-actions>

                                  <v-spacer></v-spacer>
                                  <v-btn
                                      class="same-width-btn text-none"
                                      color="blue-darken-2"
                                      rounded="0"
                                      variant="outlined"
                                      prepend-icon="mdi-database-import-outline"
                                      @click="submitFileCSV"
                                      
                                      :disabled="!file"                                    
                                      >
                                      UPLOAD CSV FAJL
                                    </v-btn>
                                  <v-btn  
                                    class="same-width-btn text-none"
                                    variant="outlined"
                                    prepend-icon="mdi-exit-to-app"  
                                    color="red-darken-2"
                                    @click="kontrolaImportDialog(false)">ZATVORI FORMU</v-btn>
                                  
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
            </v-dialog>

            <v-dialog
                    v-model="dialogTemplateEdit" max-width="800px"
                    >



                        <v-card                          
                        outlined
                        color="blue darken-2"
                        class="white--text">
                        
                            <v-card-title >
                            Unos i odabir registra template-a
                            </v-card-title>

                            <v-card-text class="v-card-text-bg_bijelo-fr_crno">

                                <v-card-text>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        sm="3"
                                                        
                                        >                  
                                        <v-checkbox
                                            v-model="chkNoviTemplate"
                                            hide-details
                                            
                                            label="Novi template"
                                        >
                                        </v-checkbox>
                                        </v-col>             

                                    </v-row>
                                     <v-row>
                                        <v-col
                                                cols="12"
                                                sm="8"
                                                class="text-center"
                                            > 
                                            <v-text-field
                                                    v-model="produktTemplate.NazivTemplejta"
                                                    label="Naziv template-a"
                                                    id="Nazivtemplate-input"
                                                    name="Nazivtemplate-input"
                                                    required
                                                    counter="200"
                                                    hint="Naziv može sadržavati i slova i brojeve"               
                                                    flat
                                                    clearable
                                                    :rules="nazivrules" 
                                                    v-if="chkNoviTemplate"
                                                    variant="outlined"
                                                    prepend-inner-icon="mdi-order-alphabetical-ascending"        
                                                    
                                                ></v-text-field>

                                                <LookUp-Templejti
                                                @updateRST="updateTemplejt"
                                                v-if="!chkNoviTemplate"
                                                >

                                                </LookUp-Templejti>

                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            class="text-center"
                                            > 
                                            <v-text-field
                                                    v-model="produktTemplate.SlugArtiklaTemplejta"
                                                    label="Slug template-a"
                                                    id="Slugtemplate-input"
                                                    name="Slugtemplate-input"
                                                    required
                                                    counter="200"
                                                    hint="Slug može sadržavati i slova i brojeve"               
                                                    flat
                                                    clearable
                                                    :rules="nazivrules" 
                                                    variant="outlined"
                                                    prepend-inner-icon="mdi-order-alphabetical-ascending"        
                                                    
                                                    :disabled="true"
                                                ></v-text-field>

                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="text-center"
                                            > 
                                            <v-textarea
                                                v-model="produktTemplate.OpisTemplejta"
                                                prepend-inner-icon="mdi-comment-text-outline"
                                                variant="outlined"
                                                clearable
                                                clear-icon="mdi-close"
                                                label="Opis templejta"
                                                hint=""
                                                counter="500"
                                                rows="2"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row class="mx-auto">
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            class="text-center"
                                            >
                                            <v-btn
                                            class="same-width-btn text-none"
                                            color="green-darken-2"
                                            rounded="0"
                                            variant="outlined"
                                            prepend-icon="mdi-content-save-all" 
                                            :disabled="!chkNoviTemplate"
                                            @click="sacuvajTemplate()"
                                            
                                            >
                                            SAČUVAJ TEMPLATE
                                            </v-btn>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            class="text-center"
                                            >
                                            <v-btn
                                            class="same-width-btn text-none"
                                            color="blue-darken-2"
                                            rounded="0"
                                            variant="outlined"
                                            prepend-icon="mdi-content-save-all" 

                                            @click="showConfirmDialog()"
                                            
                                            >
                                            LOAD TEMPLATE
                                            </v-btn>
                                        </v-col>                                        
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            class="text-center"
                                            > 
                                            <v-btn  
                                                    class="same-width-btn text-none"
                                                    variant="outlined"
                                                    prepend-icon="mdi-exit-to-app"  
                                                    color="red-darken-2"
                                                    @click="kontrolaTemplateDialog(false)">ZATVORI FORMU</v-btn>
                                        </v-col>
                                    </v-row>
                                        
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                            </v-card-text>

                        </v-card>
            </v-dialog>


            <v-dialog v-model="dialogConfirm" max-width="500px">
                <v-card class="delete-dialog"> 
                    <v-card-title class="headline">Potvrda loada template-a</v-card-title>
      
                    <v-card-text class="text-center">
                        <p>
                        <strong>Da li želite da loadate templejt i time nepovratno izmijenite podatke o proizvodu?</strong> <br />
                        </p>
      
                    </v-card-text>
                    <v-card-actions class="justify-center">
                    <v-spacer></v-spacer>
                    <v-btn class="same-width-btn text-none" color="red-darken-2" variant="outlined" @click="closeConfirm">Cancel</v-btn>
                    <v-btn class="same-width-btn text-none" color="green-darken-2" variant="outlined" @click="loadProceduru">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-snackbar
                v-model="alertsnackbar"
                :icon="ikonasnackbar"
                :color="colorsnackbar"
                vertical
                transition="slide-y-transition"
                >
                <v-row justify="space-around">
                    <v-icon  size="large" :icon="ikonasnackbar"></v-icon>
                </v-row>

                <div >
                    <strong>{{ snackbartitle }}</strong>
                </div>
                <p>{{ snackbartekst }}</p>
                    
                
                <template v-slot:actions>
                    <v-btn
                    color="white"
                    variant="text"
                    
                    @click="alertsnackbar = false"
                    >
                    Zatvori
                    </v-btn>
                </template>
            </v-snackbar>



            <v-spacer></v-spacer>
        
            <!--v-btn
            color="red darken-1"
            text
            @click="zatvoriFormu(false,'odustao')"
            
            >
            Odustani
            </v-btn-->
            <v-row>
                <v-col
                        cols="12"
                        sm="6"
                        class="text-center"
                        > 
                        <v-btn 
                                :disabled="bDisabledOpcijeTemplejta"
                                class="same-width-btn text-none"
                                color="red-darken-2"
                                rounded="0"
                                variant="outlined"
                                prepend-icon="mdi-bottle-tonic-plus-outline"   
                                @click="kontrolaTemplateDialog (true)" >
                                <!--v-icon>mdi-close</v-icon-->
                            OPCIJE ZA TEMPLEJT
                            </v-btn>
                </v-col>
                <v-col
                        cols="12"
                        sm="6"
                        class="text-center"
                        > 
                    <v-btn
                    
                    
                    color="green-darken-2"
                    rounded="0"
                    variant="outlined"
                    prepend-icon="mdi-content-save-all"
                    @click="addprodukt();"
                    class="same-width-btn text-none"
                    
                    >
                    UPIŠI
                    </v-btn>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
        </v-card-actions>
        
        </v-card>


 
   
</template>
 
<script>
import { useDate } from 'vuetify'
import LookupRoba from '../Registarroba/RobaLookUp.vue';
import LookupKategorija from '../RegistarKategorija/KategorijeLookUp.vue'
import LookupSubkategorija from '../RegistarSubkategorija/SubkategorijeLookUp.vue'
import LookupSubSubkategorija from '../RegistarSubSubkategorija/SubSubkategorijeLookUp.vue'
import LookUpTemplejti from './WebShopTemplejtiLookUp.vue'
import WebKarticaZona from './WebKarticaZona.vue'
import javnefunkcije from '../../javnefunkcije'
import axios from 'axios';

export default {

    data() {
        return { 
            dialogConfirm:false,
            bDisabledOpcijeTemplejta:false,
            cSlugTemplejta:'',
            chkNoviTemplate:false,
            dialogTemplateEdit:false,
            DisabledImportBtn:false,
            file: null,
            dialogImportRoba:false,
            selectedPicSrcFile: null,
            selectedPicSrcSlika:'',

            selectedAltPicSrcFile: null,
            selectedAltPicSrcSlika:'',

            chkenabled: false,
            chkenabled1: false,
            chkenabled2: false,
            chkenabled3: false,
            chkenabled4: false, 
            chkenabled5: false,            

            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            
            //date: new Date().toISOString().substr(0, 10),
            date: new Date(),

            odabraniDatum1: null,
            odabraniDatum2: null,
            odabraniDatum3: null,
            odabraniDatum4: null,
            odabraniDatum5: null,

            alert:false,
            alerttext:'',

            alertsnackbar:false,
            snackbartekst:'',      
            multiLine: true,
            snackbarStyle: {
                top: '0px',
                left: '0px',
            },

            alertsnackbar2:false,
            snackbartekst2:'',

            vracenasifrarobesaemita:'',

            disableRoba:false,
            disableKategorija:false,

            produktTemplate: {
                NazivTemplejta:'',
                OpisTemplejta:'',
                SlugArtiklaTemplejta:''

            },

            defaultProdukt:{ 
                Sifra:'',
                Slug:'',
                NazivRoba:'',
                Datum:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                Stanje:0,
                Staracijena:0, 
                Cijena:0,
                Valuta:'',
                Akcija:0, 
                Akcijadatumstart:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
                Akcijadatumkraj:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
                Popust:'', 
                Akcijatekst:'',
                Freeshiping:0, 
                Ribbon:0, 
                Ribbontext:'', 
                Prioritet:0,
                Topprodukt:0,
                Cijenanaupit:0, 
                Menukategorija:0,
                Kategorija:0,
                Subkategorija:0,
                SubSubkategorija:0,
                SlikaString:0,
                OpisRoba:0,
                KratkiOpisRoba:0,
                KategorijaNaziv:0,           
                Brand:'',
                Status:0,
                Noviartikl:0,
                Noviartikldatumstart:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
                Noviartikldatumstop:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
                Frontpagepic:'',
                Frontpagepicalt:''
            },

            produktdetalji:{
                Slug:'',
                Kratkiopis:'',
                Kratkiopisprikazi: 0,
                Opis:'',
                Opisprikazi: 0,
                Brand:'',
                Brandprikazi:0,
                MadeIn:'',	
                MadeInprikazi:0,
                Materijal: 0,
                Materijalprikazi:0,
                Tezina:'',
                Tezinaprikazi:0,
                Velicina:'',
                Velicinaprikazi:0,
                Kubikaza:0,
                Kubikazaprikazi:0,
                Snaga:'',
                Snagaprikazi:0,
                Dimenzije:'',
                Dimenzijeprikazi:0,
                Visina:'',
                Visinaprikazi:0,
                Duzina:'',
                Duzinaprikazi:0,
                Sirina:'',
                Sirinaprikazi:0,
                Boja:'',
                Bojaprikazi:0,
                Promjer:'',
                Promjerprikazi:0,
                Novopolovno:'',
                Novopolovnoprikazi:0
            }, 

            produktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1
            },                           
        }
    },

    props:{
        edit: false,
        
        produkt: {           
            Sifra:'',
            Slug:'',
            NazivRoba:'',
            Datum:null,
            Stanje:0,
            Staracijena:0, 
            Cijena:0,
            Valuta:'',
            Akcija:0, 
            Akcijadatumstart:'', 
            Akcijadatumkraj:'', 
            Popust:'', 
            Akcijatekst:'',
            Freeshiping:0, 
            Ribbon:0, 
            Ribbontext:'', 
            Prioritet:0,
            Topprodukt:0,
            Cijenanaupit:0, 
            Menukategorija:0,
            Kategorija:0,
            Subkategorija:0,
            SubSubkategorija:0,
            SlikaString:0,
            OpisRoba:0,
            KratkiOpisRoba:0,
            KategorijaNaziv:0,           
            Brand:'',
            Status:0,
            Noviartikl:0,
            Noviartikldatumstart:'', 
            Noviartikldatumstop:'',
            Frontpagepic:'',
            Frontpagepicalt:'' 

        }

    },

    components:{
        'LookUp-Roba':LookupRoba,
        'LookUp-Kategorije':LookupKategorija,
        'LookUp-Subkategorije':LookupSubkategorija,
        'LookUp-SubSubkategorije':LookupSubSubkategorija,
        'LookUp-Templejti':LookUpTemplejti,
        'Web-Kartica-Zona':WebKarticaZona
   
    },

    computed:{

       // Compute the formatted date based on odabraniDatum1
       formattedDate1() {
            return this.odabraniDatum1 ? this.formatDate(new Date(this.odabraniDatum1)) : this.computedDateFormatted1();
        },
        formattedDate2() {
                    return this.odabraniDatum2 ? this.formatDate(this.odabraniDatum2) : this.computedDateFormatted2();
        },

        formattedDate3() {
                    return this.odabraniDatum3 ? this.formatDate(this.odabraniDatum3) : this.computedDateFormatted3();
        },         

        formattedDate4() {
                    return this.odabraniDatum4 ? this.formatDate(this.odabraniDatum4) : this.computedDateFormatted4();
        }, 
        formattedDate5() {
                    return this.odabraniDatum5 ? this.formatDate(this.odabraniDatum5) : this.computedDateFormatted5();
        }, 

    },

    created() { 
           //alert("created");
        //this.checkAddStatus();
        this.aktivirajcheckove();
 
    },

    watch:{

        odabraniDatum1(newDate) {
            // Update produkt.Datum whenever odabraniDatum1 changes
            this.produkt.Datum = this.formatDateForMySQL(new Date(newDate));
        },

        odabraniDatum2(newDate) {
            // Update produkt.Datum whenever  1 changes
            this.produkt.Akcijadatumstart = this.formatDateForMySQL(new Date(newDate));
        },
        odabraniDatum3(newDate) {
            // Update produkt.Datum whenever odabraniDatum1 changes
            this.produkt.Akcijadatumkraj = this.formatDateForMySQL(new Date(newDate));
        },
        odabraniDatum4(newDate) {
            // Update produkt.Datum whenever odabraniDatum1 changes
            this.produkt.Noviartikldatumstart = this.formatDateForMySQL(new Date(newDate));
        },
        odabraniDatum5(newDate) {
            // Update produkt.Datum whenever odabraniDatum1 changes
            this.produkt.Noviartikldatumstop = this.formatDateForMySQL(new Date(newDate));
        },

        edit: function(newVal, oldVal) {
            this.DisabledImportBtn=true;
            
            this.disableRoba=true;
            if (newVal==false){ //ako je add
                
                this.DisabledImportBtn=false;
                
                this.disableRoba=false;
                //this.isprazniKontroleAdd();

                
            } 
            //console.log('Disable roba je : ' + this.disableRoba );
        },

        produkt: {
            handler(newVal, oldVal) {

                //console.log('produkft je : ' + newVal.Slug );
                this.produktTemplate.SlugArtiklaTemplejta=newVal.Slug;
                this.chkenabled=false;
                if(newVal.Cijenanaupit==1)
                {this.chkenabled=true;}

                this.chkenabled1=false;
                if(newVal.Akcija==1)
                {this.chkenabled1=true;}

                this.chkenabled2=false;
                if(newVal.Ribbon==1)
                {this.chkenabled2=true;}


                this.chkenabled3=false;
                if(newVal.Freeshiping==1)
                {this.chkenabled3=true;}

                this.chkenabled4=false;
                if(newVal.Topprodukt==1)
                {this.chkenabled4=true;}

                this.chkenabled5=false;
                if(newVal.Noviartikl==1)
                {this.chkenabled5=true;}
            },
            deep: true, // Praćenje dubinskih promena unutar objekta
        },
        



      
  
    },

    methods: {
        showConfirmDialog(){
            this.dialogConfirm = true;
        },

        closeConfirm() {
            this.dialogConfirm = false;
            //this.ispraznikontrole()
        },

        loadProceduru() {
            //this.desserts.splice(this.editedIndex, 1)
            this.loadajTemplate();
            this.closeConfirm();
        },

        updateTemplejt(newSlug, newNaziv,newOpis){
            this.cSlugTemplejta=newSlug;
            //console.log('slug templejta ' + newSlug);
            this.produktTemplate.OpisTemplejta=newOpis;
        },

        async loadajTemplate(){

            if (!this.cSlugTemplejta) {
                await this.obavijest("Šifra templejta je prazna, morate odabrati templejt koji će biti loadovan!","error");
            
                return;
            }      
            /*      
            fetch('/api/apiwsproduktloadovanjetemplate', {
                    method: 'post',
                    body: JSON.stringify({ cslugtemplejta: this.cSlugTemplejta, cslugartikla:this.produktTemplate.Slug }),
                    headers: {
                    'content-type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(data => {

                                   
                        this.obavijest( "Novi template produkta je loadovan iz baze!","success");//iskljuci add mod sa edit=true
                    })
                    .catch(err => this.obavijest( "Došlo je do greške pri loadovanja template iz baze! Originalna greška: " + err,"error"));
                */
                axios.post('/api/apiwsproduktloadovanjetemplate', {
                    cslugtemplejta: this.cSlugTemplejta,
                    cslugartikla: this.produkt.Slug
                })
                .then(response => {
                    // Ako je zahtev uspešan

                    if(!response.data.greska){
                        /*promijeni trenutne vrijednosti u komponentama*/                        
                        const podaci = response.data.podaci[0]; 
                       
                        this.produkt.Kategorija = podaci.Kategorija || this.produkt.Kategorija;
                        this.produkt.Subkategorija = podaci.Subkategorija || this.produkt.Subkategorija;
                        this.produkt.SubSubkategorija = podaci.SubSubkategorija || this.produkt.SubSubkategorija;
                        this.produkt.Menukategorija=podaci.Menukategorija||this.produkt.Menukategorija;
                        this.produkt.Frontpagepic=podaci.Frontpagepic||this.produkt.Frontpagepic;
                        this.produkt.FrontpagepicAlt=podaci.FrontpagepicAlt||this.produkt.FrontpagepicAlt;
                        this.produkt.Valuta=podaci.Valuta||this.produkt.Valuta;

                        this.$emit("template-loadovan", this.produkt);//ovo šaljem na webshopkreiranjetab.vue
                        
                        this.obavijest("Novi template produkta je loadovan iz baze!", "success");                        
                    }
                    else{
                        this.obavijest("Došlo je do greške pri loadovanju template iz baze! Originalna greška: " + response.data.data + " eror je " + response.data.greska, "error");
                    }
                })
                .catch(error => {
                    // Ako je došlo do greške
                    this.obavijest(
                        "Došlo je do greške pri loadovanju template iz baze! Originalna greška: " + error.message,
                        "error"
                    );
                    console.error(error);
                });

        },

        async sacuvajTemplate(){
            if (!this.produktTemplate.SlugArtiklaTemplejta) {
                await this.obavijest("Slug je prazan i ništa neće biti upisano u bazu!","error");
            
                return;
            }
            if (!this.produktTemplate.NazivTemplejta || this.produktTemplate.NazivTemplejta.trim() === "") {
                this.obavijest("Naziv templejta je prazan i ništa neće biti upisano u bazu!", "error");
                return;
            }
            
            fetch('/api/apiwsproduktkreiranjetemplate', {
                    method: 'post',
                    body: JSON.stringify(this.produktTemplate),
                    headers: {
                    'content-type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(data => {

                        if (!data.greska){
                            this.obavijest( "Novi template produkta je dodat u bazu!","success");
                        }      
                        else {
                            this.obavijest("Došlo je do greške pri upisu template u bazu! Originalna greška: " + data.data, "error");
                        }
                        
                    })
                    .catch(err => this.obavijest( "Došlo je do greške pri upisu template u bazu! Originalna greška: " + err,"error"));

        },

        kontrolaTemplateDialog(bDialog){
            
            this.dialogTemplateEdit=false;
            if(bDialog==true){
                this.dialogTemplateEdit=true;
            } 


        },
        kontrolaImportDialog(bDialog){

            this.dialogImportRoba=false;
            if(bDialog==true){
            this.dialogImportRoba=true;
            } 


        },
        handleFileUploadCSV(event) {
            this.file = event.target.files[0];
        },

        async submitFileCSV() {
            if (!this.file) return;

            let formData = new FormData();
            formData.append('file', this.file);


            try {
                const response = await axios.post('/api/upload-csv-roba', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                });
                //console.log(response); // Proverite kako izgleda `response` ovde
                
                if (this.$refs.lookUpRoba) {
                    this.$refs.lookUpRoba.refreshData(); // Poziva metodu 'refreshData' iz child komponente
                }
                this.kontrolaImportDialog(false);
                this.obavijest('Fajl je uspješno uploadovan! Originalna poruka: ' + response.data, 'success');

            } catch (error) {
                if (this.$refs.lookUpRoba) {
                    this.$refs.lookUpRoba.refreshData(); // Poziva metodu 'refreshData' iz child komponente
                }
                
                //alert( 'Nepoznata greška ' +error + ' php greška ' + response.greskamsg);
                this.kontrolaImportDialog(false);
                this.obavijest('Došlo je do greške uploada fajla! Originalna poruka: ' || 'Nepoznata greška', 'error');
            }
                
                



                


        },

        /*
        computedDateFormatted1() {
            if (this.edit === true) {
                // Make sure the date is converted to ISO string first
                return this.formatDate(new Date(this.produkt.Datum).toISOString().substr(0, 10));
            } else {
                // Get the current date in ISO format (YYYY-MM-DD)
                return this.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
            }
        },*/
        computedDateFormatted1() {
                if (this.edit === true) {
                return this.formatDate(new Date(this.produkt.Datum));
                } else {
                return this.formatDate(new Date());
                }
        },
        
        computedDateFormatted2() {
            if (this.edit===true){ 
                return this.formatDate(new Date(this.produkt.Akcijadatumstart).toISOString().substr(0, 10));
            } else 
            {return this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),);}
        },
        computedDateFormatted3() {
            if (this.edit==true){ 
                return this.formatDate(new Date(this.produkt.Akcijadatumkraj).toISOString().substr(0, 10));
            } else 
            {return this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),);}
         },                
        
         computedDateFormatted4() {
            if (this.edit==true){ 
                
                return this.formatDate(new Date(this.produkt.Noviartikldatumstart).toISOString().substr(0, 10));
            } else 
            {return this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),);}
        },
        computedDateFormatted5() {
            if (this.edit==true){ 
                
                return this.formatDate(new Date(this.produkt.Noviartikldatumstop).toISOString().substr(0, 10));
            } else 
            {return this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),);}
         },  
        isprazniKontroleAdd(){
 /*           
            this.produkt.Sifra="";
            this.produkt.Slug="";
            this.produkt.Staracijena=0;
            this.produkt.Cijena=0;
            this.produkt.Valuta="KMa";
            this.produkt.Prioritet=2;
            this.chkenabled=false;
            this.chkenabled1=false;
            this.chkenabled2=false;
            this.chkenabled3=false;
            this.chkenabled4=false;
            this.produkt.Datum=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.produkt.Akcijadatumstart=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.produkt.Akcijadatumkraj=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

            this.produkt.Akcijatekst="akšn";
            this.produkt.Ribbontext="ribn";

   */


        },
        



        formatDate(date) {



            // If it's a Date object, convert it to the correct format (YYYY-MM-DD)
            if (date instanceof Date) {
                // Convert Date object to 'YYYY-MM-DD' string
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
                const day = date.getDate().toString().padStart(2, '0');
                return `${day}/${month}/${year}`;
            }

            // If it's a string, proceed with splitting
            if (typeof date === 'string') {
                const [year, month, day] = date.split("-");
                return `${day}/${month}/${year}`;
            }

            return null;

        },

        // Format date to yyyy-mm-dd for MySQL
        formatDateForMySQL(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        /*
        formatDate(date) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Date(date).toLocaleDateString(undefined, options);
        },*/
        aktivirajcheckove(){
               
                var stat= this.produkt.Cijenanaupit;
                this.chkenabled=false;
                if (stat==1){
                this.chkenabled=true;
                }

                var stat1= this.produkt.Akcija;
                this.chkenabled1=false;
                if (stat1==1){
                this.chkenabled1=true;
                }

                var stat2= this.produkt.Ribbon;
                this.chkenabled2=false;
                if (stat2==1){
                this.chkenabled2=true;
                }        

                var stat3= this.produkt.Freeshiping;
                this.chkenabled3=false;
                if (stat3==1){
                this.chkenabled3=true;
                }  

                var stat4= this.produkt.Topprodukt;
                this.chkenabled4=false;
                if (stat4==1){
                this.chkenabled4=true;
                } 

                var stat5= this.produkt.Noviartikl;
                this.chkenabled5=false;
                if (stat5==1){
                this.chkenabled5=true;
                }                 
            },




        async addprodukt() {
         this.submitted=true;
         
            
            if (!this.produkt.Sifra) {
                await this.obavijest("Šifra je prazna i ništa neće biti upisano u bazu!","error");
            
                return;
            }
 
            this.produkt.Cijenanaupit=0;
            if (this.chkenabled==true) 
            {
                this.produkt.Cijenanaupit=1;
            }


            this.produkt.Akcija=0;
            if (this.chkenabled1==true) 
            {
                this.produkt.Akcija=1;
            }

            this.produkt.Ribbon=0;
            if (this.chkenabled2==true) 
            {
                this.produkt.Ribbon=1;
            }

            this.produkt.Freeshiping=0;
            if (this.chkenabled3==true) 
            {
                this.produkt.Freeshiping=1;
            }

            this.produkt.Topprodukt=0;
            if (this.chkenabled4==true) 
            {
                this.produkt.Topprodukt=1;
            }

            this.produkt.Noviartikl=0;
            if (this.chkenabled5==true) 
            {
                this.produkt.Noviartikl=1;
            }

            if (this.edit === false) {
                // AKO JE ADD
                var kontrolanalogabrzag= await this.kontrolasluga(this.produkt.slug);
                
                if (kontrolanalogabrzag!="slobodanbroj") {
                    await this.obavijest("Ovaj slug već postoji, radi zaštite integriteta podataka proces će biti obustavljen!","error");
                    return;
                }else
                {
                    //console.log('nije našao broj i vraćeno je ' + this.kontrolanalogabrzag );
                
                }


            }
  /**/
           
            if (this.edit === false) {
                //add
                
                fetch('/api/apiwsproduktkreiranje', {
                    method: 'post',
                    body: JSON.stringify(this.produkt),
                    headers: {
                    'content-type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(data => {

                    this.upisSlogaDetalja(this.produkt.Slug);
                    this.upisSlogaMedija(this.produkt.Slug);
                    this.posaljikomponentamaslug(this.produkt.Slug);
                    
                    this.zavrsiUpis(true, "Novi produkt je dodat u webshop!","success")//iskljuci add mod sa edit=true
                    })
                    .catch(err => console.log(err));
 

            } else {
                // Update
                
                fetch('/api/apiwsproduktkreiranje', {
                method: 'put',
                body: JSON.stringify(this.produkt),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(data => {
                
                    if (!data.greska){
                            //this.obavijest( "Novi template produkta je dodat u bazu!","success");
                            this.zavrsiUpis(true, "Produkt je izmjenjen!","success")//edit je true
                        }      
                        else {
                            this.obavijest("Došlo je do greške pri izmjeni produkta u bazi! Originalna greška: " + data.data, "error");
                        }
                    //console.log("došo do upis prije završi upis");
                    //console.log(this.produkt);
                    
                })
                .catch(err => console.log( err.toString()));
            }

            
        },


        upisSlogaDetalja(Slug){

                this.produktdetalji.Slug=Slug;

                fetch('/api/apiwsproduktdetaljikreiranje', {
                    method: 'post',
                    body: JSON.stringify(this.produktdetalji),
                    headers: {
                        'content-type': 'application/json'
                    }
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (!data.greska)
                        {                               
                            this.obavijest("Detalji produkta su dodati!","success");
                        }      
                        else 
                        {
                            this.obavijest("Došlo je do greške pri dodavanju detalja produkta u bazi! Originalna greška: " + data.data, "error");
                            //console.log('greška detalja : ' +data.data);
                        }
                    
                    

                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijest("Došlo je do greške upisa! " + err.toString(),"error")
                    
                    );

        },

        upisSlogaMedija(Slug){

                this.produktimediji.Slug=Slug;
                this.produktimediji.Src="-";
                this.produktimediji.Thumb="-";
                this.produktimediji.Tip="image";
                this.produktimediji.Format="jpg";
                this.produktimediji.Aktivan=1;

                fetch('/api/apiwsproduktmediakreiranje', {
                    method: 'post',
                    body: JSON.stringify(this.produktimediji),
                    headers: {
                        'content-type': 'application/json'
                    }
                    })
                    .then(res => res.json())
                    .then(data => {
                    
                    
                    this.obavijest("Mediji produkta su dodati!","success");
                    

                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijest("Došlo je do greške upisa! " + err.toString(),"error")
                    
                    );
        },

        async kontrolasluga(slug) {

                try {
                    const res = await fetch(`api/apiwsproduktkreiranje/${slug}`, {
                        method: 'get'
                        });
                    const resdata = await res.json();
                    const vraceniniz=resdata.data;                   
                    const retslug= vraceniniz.slug;
                   
                    return retslug;

                }catch(e) 
                {
                       
                    //console.log('u asyncu sam i nije vraćen broj stavke naloga');
                    return "slobodanbroj";
                }
            },

        updateRoba (newSifra, newNaziv, newStanje, newCijena ){
            
            var sifra= newSifra;

            this.produkt.Sifra=sifra;
            this.produkt.Stanje=newStanje;
            this.produkt.Cijena=newCijena;
            this.vratiMaxRBrojprodukta(sifra);
        },



        updateKategorija (newData){
            var sifra= newData;
            this.produkt.Kategorija=sifra;
            
        },
        updateSubkategorija (newData){
            var sifra= newData;
            this.produkt.Subkategorija=sifra;
            
        },
        updateSubSubkategorija (newData){
            var sifra= newData;
            this.produkt.SubSubkategorija=sifra;
            
        },
        async vratiMaxRBrojprodukta(slug){
            try {

                    let putanja = "/api/apifunkcijeGetMaxBroj/wshop_produkti/Rb";
                    
                    const res = await fetch(putanja, {
                        method: 'get'
                        });

                    const resdata = await res.json();
                    //console.log ("vratio je " + resdata.retmaxsifra);              
                    this.produkt.Slug=resdata.retmaxsifra + slug;
                    this.produktTemplate.SlugArtiklaTemplejta=this.produkt.Slug;
                    
                    return ;

                }catch(e) 
                {
                    alert ('Greška kod metode vratiMaxRBrojprodukta, poruka: ' + e.toString());
                    //console.log('u asyncu sam i nije vraćen broj stavke naloga');
                return "slobodanbroj";

                }                    
        },

        zavrsiUpis (editmodusrada, poruka,ctip) {
        
            //this.$emit('ws_KreiranjeEditClick',false,editmodusrada,true, poruka ) 
            this.editmodusrada=editmodusrada;//uvijek se završava sa edit modom

            this.obavijest(poruka,ctip);


            

        },

        posaljikomponentamaslug(slug){
            /*vue 2
            this.$root.$emit('komponentadetalji',{Slug:slug}); 
            this.$root.$emit('komponentamediji',{Slug:slug}); 
            */
            /*vue 3*/
            this.$emit('komponentadetalji',{Slug:slug}); 
            this.$emit('komponentamediji',{Slug:slug}); 

        },

        /*async obavijestHeading(strTekst){


            this.snackbartekst=strTekst;
            this.alertsnackbar=true;
        },*/
        async obavijest(strTekst, cTip){
            
            this.alertsnackbar=true;
            await javnefunkcije.obavijest(strTekst, cTip, this);
            
        },
        onPicSrcChanged (event) {
            this.selectedPicSrcFile = event.target.files[0]
         
            if (this.selectedPicSrcFile!=""){
                //this.item.Thumb =app_public_medija_path + this.selectedSrcFile["name"]
                //this.item.Src=app_public_medija_path + this.selectedSrcFile["name"]
                this.createImgPc( this.selectedPicSrcFile, true);
                //console.log("Putanja slike : " + this.selectedSrcFile["name"])
                
            }
        },
        onAltPicSrcChanged (event) {
            this.selectedAltPicSrcFile = event.target.files[0]
         
            if (this.selectedAltPicSrcFile!=""){
                //this.item.Thumb =app_public_medija_path + this.selectedSrcFile["name"]
                //this.item.Src=app_public_medija_path + this.selectedSrcFile["name"]
                this.createAltImgPc( this.selectedAltPicSrcFile, true);
                //console.log("Putanja slike : " + this.selectedSrcFile["name"])
                
            }
        },
        createImgPc(file, edit){
            // we will preview our image before upload
            var image = new Image;
            var reader = new FileReader;

            reader.onload = (e) =>{
                if(edit==false){//ako je dodavanje medija
                    this.selectedPicSrcSlika = e.target.result;
                    this.produkt.Frontpagepic=pp_public_medija_path + this.selectedPicSrcFile["name"];
                    //console.log("Add slika ima: " + this.produkt.Frontpagepic);
                }else
                {//ako je izmjena
                    this.selectedPicSrcSlika = e.target.result;
                    this.produkt.Frontpagepic=app_public_medija_path + this.selectedPicSrcFile["name"];
                    //console.log("Edit slika ima: " + this.produkt.Frontpagepic);
                }
            };
            reader.readAsDataURL(file);
        },

        createAltImgPc(file, edit){
            // we will preview our image before upload
            var image = new Image;
            var reader = new FileReader;

            reader.onload = (e) =>{
                if(edit==false){//ako je dodavanje medija
                    this.selectedAltPicSrcSlika = e.target.result;
                    this.produkt.FrontpagepicAlt=pp_public_medija_path + this.selectedAltPicSrcFile["name"];
                    //console.log("Add slika ima: " + this.produkt.Frontpagepicalt);
                }else
                {//ako je izmjena
                    this.selectedAltPicSrcSlika = e.target.result;
                    this.produkt.Frontpagepicalt=app_public_medija_path + this.selectedAltPicSrcFile["name"];
                    //console.log("Edit slika ima: " + this.produkt.Frontpagepicalt);
                }
            };
            reader.readAsDataURL(file);
        },



    }
}
</script>

