<template>


        <div class="container">
            <v-card elevation="0" >
                <v-card-actions class="justify-end">
                            <v-btn 
                                class="ma-2 dugmad"
                                variant="outlined"
                                
                                    
                                @click="prkaziBrziCart()" >
                                    <v-icon                               
                                        dark
                                    >
                                    mdi-cart
                                    </v-icon>
                                
                            </v-btn>                            


                            <v-btn 
                                class="ma-2 dugmad"
                                variant="outlined"
                                

                                @click="prikaziKomparaciju()" >
                                    <v-icon
                                    
                                    dark
                                    >
                                    mdi-compare-horizontal

                                    </v-icon>
                                
                            </v-btn>


                </v-card-actions>
            </v-card>


            <Web-Shop v-show="tabelashow" ></Web-Shop> 
            <Edit-Produkt-WS  v-show="!tabelashow" :editProduktSlug=transSlug ></Edit-Produkt-WS>
            <Komparacija :prikazidialog="komparacijadialog"></Komparacija>
            <BrziCart :prikazidialog="cartdialog"></BrziCart>


        </div>
</template>
  
<script>  

//import LookupRoba from '../Registarroba/RobaLookUp.vue';

import WebShop from './WebShopWShop.vue'
import EditProduktWS from './EditProduktWShop.vue'
import Komparacija from './KomparacijaArtikala.vue'
import BrziCart from './BrziCart.vue'
//import { bus } from '../../app.js'//--- busproba




export default {
    inject:["eventBus"],//bus mitt vue3
    data() {
        return {
            //currentComp: 'Kreiranje-Tabela',
            tabelashow:true,
            tabshow:false,
            transSlug:'',
            edit:false,
            produkt: {
                
                    Sifra:'',
                    Slug:'',
                    NazivRoba:'',
                    Datum:'',
                    Staracijena:0, 
                    Cijena:0,
                    Valuta:'',
                    Akcija:0, 
                    Akcijadatumstart:'', 
                    Akcijadatumkraj:'', 
                    Popust:'', 
                    Akcijatekst:'',
                    Freeshiping:0, 
                    Ribbon:0, 
                    Ribbontext:'', 
                    Prioritet:0,
                    Topprodukt:0,
                    Cijenanaupit:0, 
                    Menukategorija:0,
                    Kategorija:0,
                    Subkategorija:0,
                    SubSubkategorija:0,
                    SlikaString:0,
                    OpisRoba:0,
                    KratkiOpisRoba:0,
                    KategorijaNaziv:0,            
                    Brand:'',
                    Status:0

            },
            memorisanselected: {
                cijene: [],
                kategorije: [],
                brandovi: [],
                branditems:[],
                kategorijeitems: []
            }, 
            komparacijadialog:false,
            cartdialog:false,
        };
    },
    
    components:{
        'Web-Shop':WebShop,
        'Edit-Produkt-WS':EditProduktWS, 
        'Komparacija':Komparacija,
        'BrziCart':BrziCart  
        
    },

    created() {

        // ==== bus aktiviraj instancu
        //bus.$on('kontejnerinstanca',(data)=>{this.posaljidata(data)}); vue2
        //bus.$on('kontejnerinstancadialozi',(data)=>{this.upravljajdialozima(data)}) vue2
        this.eventBus.on('kontejnerinstanca',(data)=>{this.posaljidata(data)});//bus mitt vue3
        this.eventBus.on('kontejnerinstancadialozi',(data)=>{this.upravljajdialozima(data)});//bus mitt vue3

    },   

    methods: { 
   
        posaljidata(data){
            //console.log(data.poruka);
            this.transSlug=data.transproduktslug;
            this.tabelashow=data.transtabelashow;
            this.tabshow=data.transtabshow;

            //bus.$emit('tabtransinstanca', {kontejnerprodukt:this.produkt, kontejneredit: this.edit, kontejnerporuka:"došlo sa kontejnera"}); //modrada je true za edit, false za add
        }, 

        upravljajdialozima(data){
            this.komparacijadialog=data.komparacijadialogbool;
            this.cartdialog=data.cartdialogbool;
        },

        prikaziKomparaciju(prikazi){
            if (this.komparacijadialog==true){
                this.komparacijadialog=false;
                return;
            }
            this.komparacijadialog=true;
           
        },

        prkaziBrziCart() {
            
            if (this.cartdialog==true){
                this.cartdialog=false;
                return;
            }
            this.cartdialog=true;            
        }

    }
}
  
</script> 



<style scoped>
/*
.container {
  width: 450px;
  height: 270px;
  margin: 5px;
  padding: 10px;
  border: 1px solid blue;
}

.label {
  font-size: 10px;
  color: blue;
  margin-right: 20px;
}
.dugmad {
    color: #344955 60% !important; 
}

.textbox{
    color: #344955 60% !important; 
}
    */
</style>
