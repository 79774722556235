<template>

                
                
        <v-card>
            <v-table>

                <tbody>
                  <tr v-if="produktdetalji.Brandprikazi===1">
                    <td  class="elevation-1 table_td_left">Brand: </td>
                    <td>{{ produktdetalji.Brand }}</td>
                  </tr>
                  <tr v-if="produktdetalji.MadeInprikazi===1">
                    <td  class="elevation-1 table_td_left">Zemlja porijekla: </td>
                    <td >{{ produktdetalji.MadeIn }}</td>
                  </tr>     
                  <tr v-if="produktdetalji.Materijalprikazi===1" >
                    <td class="elevation-1 table_td_left">Materijal: </td>
                    <td>{{ produktdetalji.Materijal }}</td>
                  </tr> 
                  <tr v-if="produktdetalji.Tezinaprikazi===1" >
                    <td class="elevation-1 table_td_left">Težina: </td>
                    <td>{{ produktdetalji.Tezina }}</td>
                  </tr>  
                  <tr v-if="produktdetalji.Velicinaprikazi===1" >
                    <td class="elevation-1 table_td_left">Veličina: </td>
                    <td>{{ produktdetalji.Velicina }}</td>
                  </tr>                                                                        
                  <tr v-if="produktdetalji.Kubikazaprikazi===1" >
                    <td class="elevation-1 table_td_left">Kubikaža: </td>
                    <td>{{ produktdetalji.Kubikaza }}</td>
                  </tr>                                                                        
                  <tr v-if="produktdetalji.Snagaprikazi===1" >
                    <td class="elevation-1 table_td_left">Snaga: </td>
                    <td>{{ produktdetalji.Snaga }}</td>
                  </tr> 
                  <tr v-if="produktdetalji.Dimenzijeprikazi===1" >
                    <td class="elevation-1 table_td_left">Dimenzije: </td>
                    <td>{{ produktdetalji.Dimenzije }}</td>
                  </tr>     
                  <tr v-if="produktdetalji.Visinaprikazi===1" >
                    <td class="elevation-1 table_td_left">Visina: </td>
                    <td>{{ produktdetalji.Visina }}</td>
                  </tr>     
                  <tr v-if="produktdetalji.Duzinaprikazi===1" >
                    <td class="elevation-1 table_td_left">Dužina: </td>
                    <td>{{ produktdetalji.Duzina }}</td>
                  </tr>    
                  <tr v-if="produktdetalji.Sirinaprikazi===1" >
                    <td class="elevation-1 table_td_left">Širina: </td>
                    <td>{{ produktdetalji.Sirina }}</td>
                  </tr>                       
                  <tr v-if="produktdetalji.Bojaprikazi===1" >
                    <td class="elevation-1 table_td_left">Boja: </td>
                    <td>{{ produktdetalji.Boja }}</td>
                  </tr>                                         
                  <tr v-if="produktdetalji.Promjerprikazi===1" >
                    <td class="elevation-1 table_td_left">Promjer: </td>
                    <td>{{ produktdetalji.Promjer }}</td>
                  </tr>                          
                  <tr v-if="produktdetalji.Novopolovnoprikazi===1" >
                    <td class="elevation-1 table_td_left">Stanje: </td>
                    <td>{{ produktdetalji.Novopolovno }}</td>
                  </tr>                                                        
                </tbody>

            </v-table>

                   
        </v-card>



</template>

<script>
export default {


  data() {
    return {
      submitted: false,
      svinalozikontrola: [],
      svinalozi: [],
     
      Barkodovi:[],
      cmbStrSearch:"",
      Pretraga:'',
      PretragaSifra:'',
      Sifra: '',
      pagination: {},
      
      isChecked:false, 
      parentsifrarobe:'',
      chkenabled:false,
      produktdetalji:{
                    Slug:'',
                    Kratkiopis:'',
                    Kratkiopisprikazi: 0,
                    Opis:'',
                    Opisprikazi: 0,
                    Brand:'',
                    Brandprikazi:0,
                    MadeIn:'sss',	
                    MadeInprikazi:0,
                    Materijal: 0,
                    Materijalprikazi:0,
                    Tezina:'',
                    Tezinaprikazi:0,
                    Velicina:'',
                    Velicinaprikazi:0,
                    Kubikaza:0,
                    Kubikazaprikazi:0,
                    Snaga:'',
                    Snagaprikazi:0,
                    Dimenzije:'',
                    Dimenzijeprikazi:0,
                    Visina:'',
                    Visinaprikazi:0,
                    Duzina:'',
                    Duzinaprikazi:0,
                    Sirina:'',
                    Sirinaprikazi:0,
                    Boja:'',
                    Bojaprikazi:0,
                    Promjer:'',
                    Promjerprikazi:0,
                    Novopolovno:'',
                    Novopolovnoprikazi:0,
                    AktivneZone_zb: '', // Zone koje trebaju biti aktivne
                    SuhoVlazno_zb:'',
                    SunceSjena_zb:'',
                    Visinabiljke_zb:'',
                    Sirinabiljke_zb:'',
                    Bojalista_zb:'',
                    Bojacvijeta_zb:'',
                    Zivotinjetoksicna_zb:'',
                    Vrijemecvijetanja_zb:'',
                    Orezivanje_zb:'',
                    Prikazi_zb:0                      
                    },
     

    };
  },
    props: {
        //value: Boolean,
        dialogedit: Boolean,
        /*
        produktdetalji:{
                Slug:'',
                Opis:'',
                Opisprikazi:0,
                Brand:'',
                Brandprikazi:0,
                MadeIn:'',	
                MadeInprikazi:0,
                Materijal:'',
                Materijalprikazi:0,
                Tezina:0,
                Tezinaprikazi:0,
                Velicina:'',
                Velicinaprikazi:0,
                Kubikaza:0,
                Kubikazaprikazi:0,
                Snaga:0,
                Snagaprikazi:0,
                Dimenzije:'',
                Dimenzijeprikazi:0,
                Visina:0,
                Visinaprikazi:0,
                Duzina:0,
                Duzinaprikazi:0,
                Sirina:0,
                Sirinaprikazi:0,
                Boja:'',
                Bojaprikazi:0,
                Promjer:0,
                Promjerprikazi:0,
                Novopolovno:'',
                Novopolovnoprikazi:0
        },*/
        modelValue: Object, // Podržava `v-model` (ovde dolazi `produktdetalji`)
 



    },
    watch: {


      // Praćenje promene `modelValue` i ažuriranje `mapaZona`
      modelValue: {
        immediate: true, // Pokreće se i prilikom mount-a
        deep: true,
        handler(newValue) {
          //this.produktdetalji = { ...newValue }; // Ažuriranje `mapaZona` novim podacima
          Object.assign(this.produktdetalji, newValue); // Reaktivno ažuriranje
          //console.log('napunio detalje u kontroli ' +  JSON.stringify(this.produktdetalji, null, 2));
          this.chkenabled=false;
          if(this.produktdetalji.Prikazi_zb==1){
            this.chkenabled=true;
          }
        },
      },


    },  
    components:{
      
    },

    computed:{
      
               
       
    },
    
   created(){

    },

    methods: {

 
   

    }
}


</script>

<style >
.table_td_left{
  background-color:#F2F3F4;
  font-weight: bold;
  width: 30%;
}
.table_td_right{
  /*background-color:#F2F3F4;
  font-weight: bold;*/
}
</style>