
export default {


    
    async obavijest(strTekst, tip, instance) {
      switch (tip) {
        case 'info':
          instance.ikonasnackbar = "info";
          instance.colorsnackbar = "info";
          instance.snackbartitle = "Info";
          break;
        case 'success':
          instance.ikonasnackbar = "check_circle";
          instance.colorsnackbar = "success";
          instance.snackbartitle = "Obavijest";
          break;
        case 'error':
          instance.ikonasnackbar = "alert-circle";
          instance.colorsnackbar = "error";
          instance.snackbartitle = "Greška";
          break;
        case 'warning':
          instance.ikonasnackbar = "mdi-alert";
          instance.colorsnackbar = "warning";
          instance.snackbartitle = "Upozorenje";
          break;
        default:
      }
  
      instance.snackbartekst = strTekst;
      instance.alertsnackbar = true;
    }
  };