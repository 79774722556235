<template>

                <!--v-card
                    outlined
                    color="blue darken-2"
                    class="white--text"
                >
                
                <v-card-title class="headline1">
                   Detalji o kupcu 
                </v-card-title-->




                <v-card elevation="0">
                    <v-card-text>
                        Ovde možete izmijeniti i dopuniti neke podatke o kupcu kao što su email, broj telefona, adresa, grad, država, ID ili PDV broj i da li je lice pravno ili fizičko. 
                        Podatke poput UserID, Password koji su kardinalni ne možete mijenjati.
                    </v-card-text>
                    <v-card-text>
                        <v-container>
                        <v-row   >

                            <v-col
                                cols="12"
                                sm="6"                                                
                            >                  
                                <v-checkbox
                                v-model="chkNoviKupac"
                                hide-details
                                
                                label="Novi kupac"
                                >
                                 
                                </v-checkbox>
                            </v-col>                            
                        </v-row   >
                        <v-row   >

                            <v-col
                            cols="12"
                            sm="6"
   
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.UserID"
                                label="User ID*"
                                id="Id-input"
                                name="Id-input"
                                required
                                hint="Id broj, autoinkrement."               
                                flat
                                clearable
                                disabled
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="4"
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.Sifra"
                                label="Sifra"
                                id="Sifra-input"
                                name="Sifra-input"
                                required
                                type="number"
                                hint=""               
                                flat
                                clearable
                                disabled
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"

                            ></v-text-field>
                            </v-col>                            
                        </v-row>

                        <v-row   >

                            <v-col
                            cols="12"
                            sm="3"
   
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.Ime"
                                label="Ime/Naziv firme"
                                id="Ime-input"
                                name="Ime-input"
                                required
                                hint="Ime"               
                                flat
                                clearable
                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"

                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.Prezime"
                                label="Prezime"
                                id="Prezime-input"
                                name="Prezime-input"
                                required

                                hint="Prezime"               
                                flat
                                clearable
                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                                
                            ></v-text-field>
                            </v-col>        

                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.Email"
                                label="Email"
                                id="Email-input"
                                name="Email-input"
                                required

                                hint="Email"               
                                flat
                                clearable

                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                                
                            ></v-text-field>
                            </v-col>    


                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.Telefon"
                                label="Telefon"
                                id="Telefon-input"
                                name="Telefon-input"
                                required

                                hint="Telefon"               
                                flat
                                clearable

                                :rules="nazivrules"   
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                              
                            ></v-text-field>
                            </v-col>                                                                              
                        </v-row>    

                        <v-row   >

                            <v-col
                            cols="12"
                            sm="3"
   
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.Adresa"
                                label="Adresa"
                                id="Adresa-input"
                                name="Adresa-input"
                                required
                                hint="Adresa"               
                                flat
                                clearable

                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"

                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.Grad"
                                label="Grad"
                                id="Grad-input"
                                name="Grad-input"
                                required

                                hint="Grad"               
                                flat
                                clearable

                                :rules="nazivrules"  
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                               
                            ></v-text-field>
                            </v-col>        

                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.ZipCode"
                                label="ZipCode"
                                id="ZipCode-input"
                                name="ZipCode-input"
                                required

                                hint="ZipCode"               
                                flat
                                clearable

                                :rules="nazivrules"  
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"
                               
                            ></v-text-field>
                            </v-col>    


                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.Drzava"
                                label="Drzava"
                                id="Drzava-input"
                                name="Drzava-input"
                                required

                                hint="Drzava"               
                                flat
                                clearable

                                :rules="nazivrules"  
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                               
                            ></v-text-field>
                            </v-col>                                                                              
                        </v-row>      


                        <v-row   >

                            <v-col
                            cols="12"
                            sm="3"
   
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.IDbroj"
                                label="IDbroj"
                                id="IDbroj-input"
                                name="IDbroj-input"
                                required
                                hint="IDbroj"               
                                flat
                                clearable

                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"

                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="lococlon_kupac.PDVbroj"
                                label="PDVbroj"
                                id="PDVbroj-input"
                                name="PDVbroj-input"
                                required

                                hint="PDVbroj"               
                                flat
                                clearable

                                :rules="nazivrules"  
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"
                               
                            ></v-text-field>
                            </v-col>        

                            <v-col
                            cols="12"
                            sm="6"
                            
                            >
                                                    
                                <v-textarea
                                v-model="lococlon_kupac.Komentar"
                                    
                                    clearable
                                    clear-icon="fa-close"
                                    label="Komentar"
                                    value=""
                                    hint=""
                                    counter="500"
                                    rows="3"
                                    variant="outlined"
                                    prepend-inner-icon="mdi-comment-text-outline"

                                ></v-textarea>  

                            </v-col>
                                                                          
                        </v-row>    



                        <v-row   >

                                <v-col
                                    cols="12"
                                    sm="6"                                                
                                >                  
                                    <v-checkbox
                                    v-model="chkenabled"
                                    hide-details
                                    
                                    :label="!chkenabled?'Fizičko lice':'Pravno lice'"
                                    >
                                    </v-checkbox>
                                </v-col>                            
                        </v-row   >

                        </v-container>

                    </v-card-text>


                    <v-card-actions class="justify-end">

                        <v-snackbar
                        v-model="alertsnackbar"
                        :icon="ikonasnackbar"
                        :color="colorsnackbar"
                        vertical
                        transition="slide-y-transition"
                        >
                        <v-row justify="space-around">
                            <v-icon  size="large" :icon="ikonasnackbar"></v-icon>
                        </v-row>

                        <div >
                            <strong>{{ snackbartitle }}</strong>
                        </div>
                        <p>{{ snackbartekst }}</p>
                            
                        
                        <template v-slot:actions>
                            <v-btn
                            color="white"
                            variant="text"
                            
                            @click="alertsnackbar = false"
                            >
                            Zatvori
                            </v-btn>
                        </template>
                        </v-snackbar>



                       

                    </v-card-actions>   
                    <v-row>
                        <v-col>
                            <small>*indikacija za obavezno polje</small>
                        </v-col>
                        <v-col></v-col>
                        <v-col>
                            <v-btn
                                class="same-width-btn text-none"
                                color="green-darken-2"
                                rounded="0"
                                variant="outlined"
                                prepend-icon="mdi-content-save-all" 
                                @click="izmjenipodatkekupca()"
                                
                                >
                                
                                UPIŠI IZMJENE KUPCA
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            <!--/v-card-->


</template>


<script>

import { th } from 'vuetify/locale';
import javnefunkcije from '../../javnefunkcije'

export default {


  data() {
    return {
      submitted: false,
      svenarudzbekontrola: [],
      //svinalozi: [],

      cmbStrSearch:"",
      Pretraga:'',
      PretragaSifra:'',
      Sifra: '',
      pagination: {},
      
      isChecked:false, 
      parentsifrarobe:'',


      //====== za vuetify kontrole

      date: new Date().toISOString().substr(0, 10),

      alert:false,
      alerttext:'',
      alertsnackbar:false,
      snackbartekst:'',
      multiLine: true,
      ikonasnackbar:'',
      colorsnackbar:'',   
      snackbartitle:'',

      disableRoba:true,

      menu1: false,
      menu2: false,
      menu3: false,

      cmbSearch:'',
      //adialog:false,
      dialogDelete: false,
      deletenarudzba:'',
      chkenabled: false,
      chkenabled1: false,
      chkenabled2:false,
      chkNoviKupac:false,
      apiMetod:false,
      
      lococlon_kupac:{ ...this.kupac},   

      memory_kupac: {
            Sifra:0,
            Naziv:'',
            Ime:'',
            Prezime:'',
            Email:'',
            Telefon:'',
            Adresa:'',
            Grad:'',
            Drzava:'',
            ZipCode:'',
            UserID:''
        }, 
        prazan_kupac: {
            Sifra:0,
            Naziv:'',
            Ime:'',
            Prezime:'',
            Email:'',
            Telefon:'',
            Adresa:'',
            Grad:'',
            Drzava:'',
            ZipCode:'',
            UserID:''
        }, 

      sifrarules: [
        v => !!v || 'Šifra je obavezna!',
        v => (v && v.length <= 50) || 'Šifra mora biti kraća od 50 karaktera.',
      ],
      nazivrules: [
        v => !!v || 'Tekstualna vrijednost je obavezna!',
        v => (v && v.length <= 200) || 'Tekst mora biti kraći od 200 karaktera.',
      ],
      trirules: [
        v => !!v || 'Vrijednost je obavezna!',
        v => (v && v.length <= 3) || 'Unesena vrijednost mora biti kraći od 3 karaktera.',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      numberRules:[
            v => !!v || 'Vrijednost je obavezna!',
        ],
     
    //====== za vuetify kontrole kraj

    };
  },
    props: {
        //value: Boolean,
        //dialogedit: Boolean,
        //publicTransDialogKupacID:'',



        kupac: {
            Sifra:0,
            Naziv:'',
            Ime:'',
            Prezime:'',
            Email:'',
            Telefon:'',
            Adresa:'',
            Grad:'',
            Drzava:'',
            ZipCode:'',
            UserID:''
        }, 

        

    },

    components:{
      

    },

    computed:{
      
               
       
    },
    
   created(){

    },

    watch:{

        chkNoviKupac(newVal) {
            if (newVal === true) {
                this.memory_kupac = JSON.parse(JSON.stringify(this.lococlon_kupac)); // Duboka kopija
                this.lococlon_kupac = JSON.parse(JSON.stringify(this.prazan_kupac));
            } else {
                this.lococlon_kupac = JSON.parse(JSON.stringify(this.memory_kupac));
            }
        },
                  
        kupac: function(newVal, oldVal) {

            this.chkenabled=false;
            if (newVal.Pravno==1){
                this.chkenabled=true;
            }
            
            
            this.lococlon_kupac={ ...newVal};
            
            
        }  




    },
    methods: {
        
        kupac_setovanje(novi){
            if(novi===true){
                this.memory_kupac=Object.assign({}, this.lococlon_kupac);//this.lococlon_kupac;
                this.lococlon_kupac=Object.assign({}, this.prazan_kupac);//this.prazan_kupac;
            }else
            {
                this.lococlon_kupac=Object.assign({}, this.memory_kupac);//this.memory_kupac;
            }
        },

        izmjenipodatkekupca(){
                
                this.lococlon_kupac.Pravno=0;
                if(this.chkenabled==true){
                    this.lococlon_kupac.Pravno=1;
                }
                //this.lococlon_kupac.Naziv= this.lococlon_kupac.Ime + ' ' + this.lococlon_kupac.Prezime;
               
                this.apiMetod="put";
                if (this.chkNoviKupac==true){
                    
                    this.apiMetod="post";
                    
                }

                //console.log('Upis kupca! ' + JSON.stringify(this.lococlon_kupac, null, 2)); 

                fetch('api/apikupac', {
                method: this.apiMetod,
                body: JSON.stringify(this.lococlon_kupac),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(res => {
                    //

                    //this.zatvoriFormu(false, "Zaglavlje narudžbe je izmijenjeno!")
                    if (this.apiMetod=="put"){
                        this.obavijest("Podaci o kupcu su izmijenjeni!",'success');
                    }else{
                        this.obavijest("Novi kupac je dodat!",'success');
                        this.$emit('novi-kupac-dodan', res);
                    }
                    //this.ispraznikontrole();        
  
                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijest("Došlo je do greške upisa! " + err.toString(),'error')
                    );
        },

        async obavijest(strTekst, cTip){
            //this.snackbartekst=strTekst;
            this.alertsnackbar=true;
            await javnefunkcije.obavijest(strTekst, cTip, this);
        },        
 

    }
}
</script>        