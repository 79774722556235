<template>

    <v-dialog
        v-model="localDialog"
        persistent
        min-width="65%"
        max-width="65%"
        
    >
            
        <v-card
        outlined

        class="white--text">

        <v-card-title >
           Pregled stavki narudžbe
            <v-spacer></v-spacer>
            <v-btn icon @click="zatvoriFormu (true, 'odustao')" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

            <v-tabs
                v-model="tabs"
               
                fixed-tabs
            >


                <v-tab
                    value="tabelastavke"
                    class="primary--text"
                >
                Pregled stavki narudžbe
                </v-tab>

                <v-tab
                    value="editstavke"
                    class="primary--text"
                >
                
                Editovanje stavke narudžbe
                </v-tab>

            </v-tabs>


                
                <v-window v-model="tabs">
                            <v-window-item value="tabelastavke">

                                <v-card flat>
                                    
                                    <v-toolbar
                                    border 
                                    title="Stavke narudžbe"
                                    >

                                     

                                            <v-speed-dial
                                                location="bottom "
                                                transition="fade-transition"
                                                
                                                >
                                                <template v-slot:activator="{ props: activatorProps }">
    
                                                    <v-fab
                                                    v-bind="activatorProps"
                                                    color="grey"
                                                    icon=" mdi-menu"
                                                    ></v-fab>
                                                    
                                                </template>

                                                <v-btn
                                                
                                                    icon="mdi-plus"
                                                    color="green"
                                                    @click="DodajStavkunarudzba()"
                                                >
                                                    
                                                </v-btn>
                                                <v-btn
                                                    icon="mdi-close"
                                                    color="red"
                                                    @click="zatvoriFormu(false, 'odustao')"
                                                >

                                                </v-btn>

                                            </v-speed-dial>
                            
                                    </v-toolbar>
                                    <Narudzbe-Stavke-Tabela-Form
                                        @tblstavkeclick="TblStavkeClick"
                                        @selektovantab ="SelektovanTab"
                                        :narudzbestavkebrojnarudzbe="lokobrojnarudzbe"
                                        :transrefreshtabele="RefreshTabela"
                                        >
                                        </Narudzbe-Stavke-Tabela-Form>

                                    <v-card-actions>


                                    </v-card-actions>

                                    <v-card-text>


                                    </v-card-text>
                                    </v-card>
                            </v-window-item>

                            <v-window-item value="editstavke">
                                <v-card flat>
                                    <Narudzbe-Stavke-Edit-Form
                                        :stavkenarudzba="transtabelanarudzba"
                                        :dialogedit="transdialogedit"
                                        @zavrsenupis="RefreshajTabeluNaTabelaForm"
                                    ></Narudzbe-Stavke-Edit-Form>
                                </v-card>
                            </v-window-item>
                        </v-window>


                <!--v-tabs-items :value="tabs">
              
                    <v-tab-item     
                        :value="'tabelastavke'"
                    >
    
                        <v-card flat elevation="14">

                            <v-toolbar
                            color="blue darken-2"
                            light
                            extended
                            >
                            
                            <v-toolbar-title class="white--text">
                            Pregled stavki narudzbe
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
    
                            <template v-slot:extension>

                                <v-speed-dial

                                    v-model="fab"
                                    :top="top"
                                    :bottom="bottom"
                                    :right="right"
                                    :left="left"
                                    :direction="direction"
                                    :open-on-hover="hover"
                                    :transition="transition"
                                                            
                                
                                
                                    >
                                    <template v-slot:activator>
                                        <v-btn
                                        v-model="fab"
                                        color="blue darken-2"
                                        dark
                                        fab
                                        

                                        >
                                        <v-icon v-if="fab"  size="15">
                                            fa-close
                                        </v-icon>
                                        <v-icon v-else size="15">
                                            fa-list
                                        </v-icon>
                                        </v-btn>

                                        
                                    </template>

                                    <v-btn
                                        fab
                                        dark
                                        small
                                        color="green"
                                        @click="DodajStavkunarudzba()"
                                    >
                                        <v-icon size="15">fa-plus</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        small
                                        color="red"
                                        @click="zatvoriFormu(false, 'odustao')"
                                    >
                                        <v-icon size="15">fa-times-circle</v-icon>
                                    </v-btn>

                                </v-speed-dial>

                            </template>
                            </v-toolbar>

                        
                            <v-card-actions>

                            </v-card-actions>

                            <v-card-text>
                                <Narudzbe-Stavke-Tabela-Form
                                @tblstavkeclick="TblStavkeClick"
                                @selektovantab ="SelektovanTab"
                                :narudzbestavkebrojnarudzbe="lokobrojnarudzbe"
                                :transrefreshtabele="RefreshTabela"
                                >
                                </Narudzbe-Stavke-Tabela-Form>

                            </v-card-text>
                        </v-card>
                    
                    </v-tab-item>

                    <v-tab-item
                        :value="'editstavke'"
                    >
                        <v-card flat>
                            <Narudzbe-Stavke-Edit-Form
                                :stavkenarudzba="transtabelanarudzba"
                                :dialogedit="transdialogedit"
                                @zavrsenupis="RefreshajTabeluNaTabelaForm"
                            ></Narudzbe-Stavke-Edit-Form>
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>

            </v-tabs-->

            <v-card elevation="0">
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="red darken-1"
                    text
                    @click="zatvoriFormu (true, 'odustao')"
                    
                    >
                    Zatvori formu
                    </v-btn>
                </v-card-actions>

            </v-card>

        </v-card>
    </v-dialog>
</template>

<script>
import NarudzbeStavkeEditForm from './WebShopNarudzbeStavkeEditForm.vue';
import NarudzbeStavkeTabelaForm from './WebShopNarudzbeStavkeTabelaForm.vue';

  export default {
    data () {
      return {
        localDialog: this.stavkedialog,
        
        tabs: null,
        text: '',
        transtabelanarudzba:[],
        transdialogedit:Boolean,
        lokobrojnarudzbe:'',
        novinarudzba: {
                Id:0,
                Sifra: '',
                Slug:'', 
                NazivRoba:'', 
                Kolicina:0, 
                Cijena:0, 
                Iznos:0,
                Narudzba:'',
        },

        bZg:0,
        RefreshTabela:false,

        /*dugme*/
        direction: 'right',
        fab: false,
        fling: false,
        hover: false,
        tabs: false,
        top: true,
        right: false,
        bottom: false,
        left: true,
        transition: 'slide-y-reverse-transition',


      }
    },

    props: {
            stavkedialog:false,
            brojnarudzba:'',
            selektovanTabccc:''
    },  


    components:{
        'Narudzbe-Stavke-Edit-Form':NarudzbeStavkeEditForm,
        'Narudzbe-Stavke-Tabela-Form':NarudzbeStavkeTabelaForm

    },
    mounted() {

        this.lokobrojnarudzbe=this.brojnarudzba; //ovde je popunjavam jer prvi put je ne vidi watch: jer nije newVal 
                                                
    },
    watch:{
        /*docekuje promjenu sa NaloziTabela.vue 

          <Nalozi-Stavke-Tab
           :brojnarudzbe="transbrojnarudzbe"
          ></Nalozi-Stavke-Tab>
        */
        stavkedialog(newVal) {
                this.localDialog = newVal;
                },

            localDialog(newVal) {
                this.$emit('update:stavkedialog', newVal);
                },

        brojnarudzba:  function(newVal, oldVal) {
            //console.log("brojnarudzbe je tab " + newVal);
            this.lokobrojnarudzbe=newVal;
            
        },

    },


    methods: {

        /*AktivirajDialogTab (narudzba, modrada){
            this.brojnarudzbe=narudzba;
            this.stavkedialog=modrada;
        },*/

        RefreshajTabeluNaTabelaForm(){
            console.log('okino refresh tabelu na tabovima');  
        
            this.RefreshTabela=true;
            this.tabs='tabelastavke';//promijeni aktivni tab
            
        },

        TblStavkeClick (narudzba, modrada){ //true je za edit, false za add
            this.transtabelanarudzba=narudzba;
            this.transdialogedit=modrada;
            this.RefreshTabela=false;
            this.SelektovanTab();
            //console.log ('narudzba je transporotovan : '+ this.transtabelanarudzba.brojzag + ', modusrada edit : ' + this.transdialogedit)
            },
        SelektovanTab (){
            this.tabs='editstavke';//promijeni aktivni tab
            //console.log ('selektovan je edit tab : ' + this.tabs)
        },

        DodajStavkunarudzba(page_url){
            this.novinarudzba.Kolicina=1;
            this.novinarudzba.Cijena=1;
            this.novinarudzba.Iznos=1;
            this.novinarudzba.Narudzba=this.lokobrojnarudzbe;
            this.TblStavkeClick (this.novinarudzba, false);

            /*
            page_url = page_url || '/api/apiradninalozistavkenovi/'+this.lokobrojnarudzbe;

            fetch(page_url)
                .then(res => res.json())
                .then(res => {

                
                    this.bZg=(res.rs[0].Maks==null? 1 :parseInt(res.rs[0].Maks)+1);

                    //console.log ('broj narudzbaa ' + res.narudzba[0] + ' broj stavke ' +this.bZg)
                    this.novinarudzba.brojzag = res.narudzba[0];
                    this.novinarudzba.brojstav= this.bZg;
                    this.novinarudzba.kolicina=1;
                    this.TblStavkeClick (this.novinarudzba, false);
                })

                .catch(err => console.log(err));

           // this.transtabelanarudzba= this.novinarudzba;
           */
        },


        zatvoriFormu (dialogprikaz, poruka) {

            this.$emit('ws_NarudzbaStavkeTabClick',false, poruka ) 

        }        
   }

  }
</script>