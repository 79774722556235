<template>
    
 <div>


      <v-autocomplete        
        :value="publicSifraBrand"
        :items="opcije2"
        label="Brand"
        @change="selectedOption"        
        item-text="naziv"
        item-value="sifra"  
        >              
     </v-autocomplete>
 </div>
</template>


<script>

export default {
  props: {
          'naziv':null, 
          publicSifraBrand:String, 
          publicNazivBrand:String
      },

  data(){
      return{
        model:this.publicSifraBrand,
        search_data:[],
        svibrandovi:[],
        svibrandoviarr:[],
        opcije2:[],
        brand:{
            Sifra: '',
            Naziv: '',
            brand:0
        }
        
      }
    },

  created() {
    
          this.vratiPodatke();
       

        },

        
    methods:{



       vratiPodatke(page_url){
          
                this.svibrandovi = [];
               
                page_url = page_url || '/api/apibrandovisve';
                fetch(page_url)
                    .then(res => res.json())
                    .then(res => {
                        this.svibrandovi = res.data; 

         

                        this.svibrandoviarr=
                        this.svibrandovi.map
                        (brand=>({naziv:brand.Naziv, sifra:brand.Sifra}));
                        
                        this.opcije2=this.svibrandoviarr;    
                        
                        //console.log('matrica ' + this.svibrandoviarr[0].naziv + '-' + this.svibrandoviarr[0].sifra);                   
                    })
                    .catch(err => console.log(err));
                },
        selectedOption: function(value){
                //console.log("šifra : " + value.sifra + ' porez : '+ value.porezp + ' samo value: ' + value);
                let myNumber = value;
                this.$emit('updatebrand', myNumber);
                this.$emit('update:publicSifraBrand', value);

         
	  		}  
 
    }

}

</script>