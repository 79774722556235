<template>

                <!--v-card
                    outlined
                    color="blue darken-2"
                    class="white--text"
                >
                
                <v-card-title class="headline1">
                   Detalji o kupcu 
                </v-card-title-->




                <v-card elevation="0">
                    <v-card-text>
                        Ovde možete izmijeniti ili dopuniti podatke o alternativnom kupcu i mjestu dostave narudžbe ukoliko kupac nije krajnja destinacija dostave. 
                        Ako je prilikom narudžbe propuštena mogućnost unošenja alternatitvne adrese dostave možete je ovde dodati.
                        Podatke poput UserID, Narudžba koji su kardinalni ne možete mijenjati. Za unos alternativnog kupca i adrese potrebno je minimalno unijeti podatke imena, prezimena i emaila. 
                        Ostale podatke možete dodati u editovanju stavke.
                    </v-card-text>
                    <v-card-text>
                        <v-container>


                        <v-row   >

                            <v-col
                            cols="12"
                            sm="6"
   
                            
                            >
                            <v-text-field
                                v-model="altkupac.UserID"
                                label="User ID*"
                                id="Id-input"
                                name="Id-input"
                                required
                                hint="Id broj."               
                                flat
                                clearable
                                disabled
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"

                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="4"
                            
                            >
                            <v-text-field
                                v-model="altkupac.Narudzba"
                                label="Narudžba"
                                id="Narudzba-input"
                                name="Narudzba-input"
                                required
                                hint=""               
                                flat
                                clearable
                                disabled
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"

                            ></v-text-field>
                            </v-col>                            
                        </v-row>

                        <v-row   >

                            <v-col
                            cols="12"
                            sm="3"
   
                            
                            >
                            <v-text-field
                                v-model="altkupac.Ime"
                                label="Ime/Naziv firme*"
                                id="Ime-input"
                                name="Ime-input"
                                required
                                hint="Ime"               
                                flat
                                clearable
                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"

                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="altkupac.Prezime"
                                label="Prezime*"
                                id="Prezime-input"
                                name="Prezime-input"
                                required

                                hint="Prezime"               
                                flat
                                clearable
                                :rules="nazivrules"     
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                            
                            ></v-text-field>
                            </v-col>        

                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="altkupac.Email"
                                label="Email*"
                                id="Email-input"
                                name="Email-input"
                                required

                                hint="Email"               
                                flat
                                clearable

                                :rules="nazivrules"  
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                               
                            ></v-text-field>
                            </v-col>    


                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="altkupac.Telefon"
                                label="Telefon"
                                id="Telefon-input"
                                name="Telefon-input"
                                required

                                hint="Telefon"               
                                flat
                                clearable

                                :rules="nazivrules"    
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                             
                            ></v-text-field>
                            </v-col>                                                                              
                        </v-row>    

                        <v-row   >

                            <v-col
                            cols="12"
                            sm="3"
   
                            
                            >
                            <v-text-field
                                v-model="altkupac.Adresa"
                                label="Adresa"
                                id="Adresa-input"
                                name="Adresa-input"
                                required
                                hint="Adresa"               
                                flat
                                clearable

                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"

                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="altkupac.Grad"
                                label="Grad"
                                id="Grad-input"
                                name="Grad-input"
                                required

                                hint="Grad"               
                                flat
                                clearable

                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                                
                            ></v-text-field>
                            </v-col>        

                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="altkupac.ZipCode"
                                label="ZipCode"
                                id="ZipCode-input"
                                name="ZipCode-input"
                                required

                                hint="ZipCode"               
                                flat
                                clearable

                                :rules="nazivrules"   
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"
                              
                            ></v-text-field>
                            </v-col>    


                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="altkupac.Drzava"
                                label="Drzava"
                                id="Drzava-input"
                                name="Drzava-input"
                                required

                                hint="Drzava"               
                                flat
                                clearable

                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-alphabetical-ascending"
                                
                            ></v-text-field>
                            </v-col>                                                                              
                        </v-row>      


                        <v-row   >

                            <v-col
                            cols="12"
                            sm="3"
   
                            
                            >
                            <v-text-field
                                v-model="altkupac.IDbroj"
                                label="IDbroj"
                                id="IDbroj-input"
                                name="IDbroj-input"
                                required
                                hint="IDbroj"               
                                flat
                                clearable

                                :rules="nazivrules" 
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"

                            ></v-text-field>
                            </v-col>



                            <v-col
                            cols="12"
                            sm="3"
                            
                            >
                            <v-text-field
                                v-model="altkupac.PDVbroj"
                                label="PDVbroj"
                                id="PDVbroj-input"
                                name="PDVbroj-input"
                                required

                                hint="PDVbroj"               
                                flat
                                clearable

                                :rules="nazivrules"  
                                variant="outlined"
                                prepend-inner-icon="mdi-order-numeric-ascending"
                               
                            ></v-text-field>
                            </v-col>        

                            <v-col
                            cols="12"
                            sm="6"
                            
                            >
                                                    
                                <v-textarea
                                v-model="altkupac.Komentar"

                                    clearable
                                    clear-icon="fa-close"
                                    label="Komentar"
                                    value=""
                                    hint=""
                                    counter="500"
                                    rows="3"
                                    variant="outlined"
                                    prepend-inner-icon="mdi-comment-text-outline"

                                ></v-textarea>  

                            </v-col>
                                                                          
                        </v-row>    



                        <v-row   >

                                <v-col
                                    cols="12"
                                    sm="6"                                                
                                >                  
                                    <v-checkbox
                                    v-model="chkenabled"
                                    hide-details
                                    
                                    :label="!chkenabled?'Fizičko lice':'Pravno lice'"
                                    >
                                    </v-checkbox>
                                </v-col>                            
                        </v-row   >

                        </v-container>
                    </v-card-text>
                        
          


                    <v-card-actions class="justify-end">

                        <v-snackbar
                            v-model="alertsnackbar"
                            :multi-line="multiLine"
                            absolute
                            left
                            shaped
                            middle
                            >
                            {{ snackbartekst }}

                            <template v-slot:action="{ attrs }">
                                <v-btn
                                color="red"
                                text
                                v-bind="attrs"
                                @click="alertsnackbar = false"
                                >
                                Zatvori
                                </v-btn>
                            </template>
                        </v-snackbar>



                       

                    </v-card-actions>   
                    <v-row>
                        <v-col>
                            <small>*indikacija za obavezno polje</small>
                        </v-col>
                        <v-col></v-col>
                        <v-col>
                            <v-btn

                                class="flex-grow-1 text-none"
                                color="green-darken-2"
                                rounded="0"
                                variant="outlined"
                                prepend-icon="mdi-content-save-all" 
                                @click="izmjenipodatkekupca()"

                                >
                                UPIŠI IZMJENE PODATAKA O KUPCU 
                            </v-btn>

                        </v-col>
                    </v-row>
                </v-card>
            <!--/v-card-->


</template>


<script>



export default {


  data() {
    return {
      submitted: false,
      svenarudzbekontrola: [],
      //svinalozi: [],

      cmbStrSearch:"",
      Pretraga:'',
      PretragaSifra:'',
      Sifra: '',
      pagination: {},
      
      isChecked:false, 
      parentsifrarobe:'',


      //====== za vuetify kontrole

      date: new Date().toISOString().substr(0, 10),

      alert:false,
      alerttext:'',
      alertsnackbar:false,
      snackbartekst:'',
      multiLine: true,

      disableRoba:true,

      menu1: false,
      menu2: false,
      menu3: false,

      cmbSearch:'',
      //adialog:false,
      dialogDelete: false,
      deletenarudzba:'',
      chkenabled: false,
      chkenabled1: false,
      chkenabled2:false,

      sifrarules: [
        v => !!v || 'Šifra je obavezna!',
        v => (v && v.length <= 50) || 'Šifra mora biti kraća od 50 karaktera.',
      ],
      nazivrules: [
        v => !!v || 'Tekstualna vrijednost je obavezna!',
        v => (v && v.length <= 200) || 'Tekst mora biti kraći od 200 karaktera.',
      ],
      trirules: [
        v => !!v || 'Vrijednost je obavezna!',
        v => (v && v.length <= 3) || 'Unesena vrijednost mora biti kraći od 3 karaktera.',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      numberRules:[
            v => !!v || 'Vrijednost je obavezna!',
        ],
     
    //====== za vuetify kontrole kraj

    };
  },
    props: {
        //value: Boolean,
        //dialogedit: Boolean,
        //publicTransDialogaltkupacID:'',
        imaaltkupac:Boolean,


        altkupac: {
            Id:0,
            Narudzba:'',
            Naziv:'',
            Ime:'',
            Prezime:'',
            Email:'',
            Telefon:'',
            Adresa:'',
            Grad:'',
            Drzava:'',
            ZipCode:'',
            UserID:'',
            Pravno:0,
            IDbroj:'',
            PDVbroj:'',
            Komentar:''        }
        

    },

    components:{
      

    },

    computed:{
      
               
       
    },
    
   created(){

    },

   watch:{ 

                  
        altkupac: function(newVal, oldVal) {
            if(newVal.UserID!=""){
                //console.log("došo " + newVal.UserID)
            }



            this.chkenabled=false;
            if (newVal.Pravno==1){
                this.chkenabled=true;
            }
            
        }  




    },
    methods: {
        
        izmjenipodatkekupca(){

                if (this.altkupac.Ime=="" || this.altkupac.Ime==null  ) {
                    
                    this.obavijestHeading("Ime je obavezno za unos! Proces je obustavljen.");
                    return;
                }

                if (this.altkupac.Prezime=="" || this.altkupac.Prezime==null  ) {
                    this.obavijestHeading("Prezime je obavezno za unos! Proces je obustavljen.");
                    return;
                }
                if (this.altkupac.Emaii=="" || this.altkupac.Email==null  ) {
                    this.obavijestHeading("Email je obavezan za unos! Proces je obustavljen.");
                    return;
                }

                if (this.nemaltkupca==false){}


                this.altkupac.Pravno=0;
                if(this.chkenabled==true){
                    this.altkupac.Pravno=1;
                }

                if (this.imaaltkupac==true) { 
                        //edit, dopuna postojeceg altkupca
                        fetch('api/apialtkupac', {
                        method: 'put',
                        body: JSON.stringify(this.altkupac),
                        headers: {
                            'content-type': 'application/json'
                        }
                        })
                        .then(res => res.json())
                        .then(res => {
                            //

                            //this.zatvoriFormu(false, "Zaglavlje narudžbe je izmijenjeno!")
                            this.obavijestHeading("Podaci o kupcu su izmijenjeni!");
                            //this.ispraznikontrole();        
        
                        })
                        .catch(
                            //err => console.log(err.toString())
                            err=> this.obavijestHeading("Došlo je do greške upisa! " + err.toString())
                            );
                }
                else
                {
                        //add, dodavanje nevog altkupca
                        fetch('api/apialtkupac', {
                        method: 'post',
                        body: JSON.stringify(this.altkupac),
                        headers: {
                            'content-type': 'application/json'
                        }
                        })
                        .then(res => res.json())
                        .then(res => {
                            //

                            //this.zatvoriFormu(false, "Zaglavlje narudžbe je izmijenjeno!")
                            this.obavijestHeading("Podaci o kupcu su dodati!");
                            //this.ispraznikontrole();        
        
                        })
                        .catch(
                            //err => console.log(err.toString())
                            err=> this.obavijestHeading("Došlo je do greške upisa! " + err.toString())
                            );
                }
        },

        async obavijestHeading(strTekst){
            this.snackbartekst=strTekst;
            this.alertsnackbar=true;
        },        
 

    }
}
</script>        