<template>
    <v-card class="pa-4" elevation="2">
      <v-row>
        
        <v-col cols="4" class="text-center">
          <div>
            <strong>ZONE</strong>
          </div>
          <v-img
            :src="imageUrl"
            :lazy-src="fallbackImage"
            max-height="200"
            max-width="300"
            class="mx-auto"
          ></v-img>
          <div class="text-h6">6-8</div>
        </v-col>
  
        
        <v-col cols="4" class="text-center">
          <div>
            <strong>GROWTH CYCLE</strong>
          </div>
          <v-progress-linear
            value="40"
            height="6"
            color="blue lighten-2"
            class="my-2"
          ></v-progress-linear>
          <div class="text-caption">Cool</div>
        </v-col>
  
        
        <v-col cols="4" class="text-center">
          <div>
            <strong>BLOOM</strong>
          </div>
          <v-progress-linear
            value="70"
            height="6"
            color="green lighten-2"
            class="my-2"
          ></v-progress-linear>
          <div class="text-caption">Early Spring</div>
        </v-col>
      </v-row>
  
      
      <v-divider class="my-4"></v-divider>
      <v-row>
        <v-col cols="6">
          <div><strong>MOISTURE REQ.:</strong> Dry-Moist</div>
          <div><strong>LIGHT EXPOSURE:</strong> Part Sun-Shade</div>
          <div><strong>HEIGHT W/O BLOOM:</strong> 1 ft</div>
        </v-col>
        <v-col cols="6">
          <div><strong>HEIGHT W/BLOOM:</strong> 1 ft</div>
          <div><strong>ORIGIN:</strong> Japan</div>
        </v-col>
      </v-row>
    </v-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        imageUrl: "https://upload.wikimedia.org/wikipedia/commons/7/7e/BlankMap-Eurasia.svg", // Original image URL
        fallbackImage: "https://via.placeholder.com/200x300?text=No+Image", // Fallback image
        // ovde možeš menjati podatke za karticu
        zone: "6-8",
        growthCycle: "Cool",
        bloom: "Early Spring",
        moistureReq: "Dry-Moist",
        lightExposure: "Part Sun-Shade",
        heightWithoutBloom: "1 ft",
        heightWithBloom: "1 ft",
        origin: "Japan",
      };
    },
  };
  </script>