<template>

 <div class="container">


        <div class="row"> 
         
        </div>

        <div class="row">  
            <div class="col-lg-3 mb-4">
                <!--h1 class="mt-4">Filteri</h1>

                <router-link to="/">Home</router-link>
                <router-link to="/wshop">Shop</router-link-->


              
            </div>

            
        </div>
  </div>


</template>


<script>
    

  export default {

    data () {
      return {
        items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ]
      }
    },
  }
</script>