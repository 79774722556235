<template>
  <v-app>
    <v-main>
    
      <v-card
        outlined
        color="blue darken-2"
        class="white--text"
      >
                    <!--v-snackbar
                    :icon="ikonasnackbar"
                    v-model="alertsnackbar"
                    :multi-line="multiLine"
                    :color="colorsnackbar"
                    relative
                    centered
                    middle
                    >
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ ikonasnackbar }}</v-icon>
                        <v-layout column>
                        <div>
                            <strong>{{ snackbartitle }}</strong>
                        </div>
                        <div>{{ snackbartekst }}</div>
                        </v-layout>
                    </v-layout>
                    

                    <template v-slot:action="{ attrs }">
                        <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="alertsnackbar = false"
                        >
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </template>
                    </v-snackbar-->     

                            

        <v-card-title 
  
          class="headline">
          Unos i izmjena informacija o firmi
          <v-spacer></v-spacer>

        </v-card-title>

            <v-card elevation="0">



                <v-card-text>
                    <v-card elevation="0">
                        <v-card-title class="headline">
                            Opšti podaci o firmi

                        </v-card-title>   
                        <v-divider></v-divider>                     
                        <v-card-text>
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        
                                >
                                        <v-text-field
                                        v-model="setupfirma.Naziv"
                                        label="Naziv firme*"
                                        id="Naziv-input"
                                        name="Naziv-input"
                                        required
                                        counter="200"
                                        hint="Naziv je alfanumerička i može sadržavati i slova i brojeve"               
                                        flat
                                        clearable
                                        :rules="nazivrules" 
                                        ></v-text-field>
                                </v-col>     
                                <v-col
                                        cols="12"
                                        sm="6"
                                        
                                >
                                        <v-text-field
                                        v-model="setupfirma.Nazivopis"
                                        label="Opis firme*"
                                        id="Nazivopis-input"
                                        name="Nazivopis-input"
                                        required
                                        counter="200"
                                        hint="Opis je alfanumerička i može sadržavati i slova i brojeve"               
                                        flat
                                        clearable
                                        :rules="nazivrules" 
                                        ></v-text-field>
                                </v-col>                                      
                            </v-row>
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        
                                >
                                        <v-text-field
                                        v-model="setupfirma.Adresa"
                                        label="Adresa firme*"
                                        id="Adresa-input"
                                        name="Adresa-input"
                                        required
                                        counter="200"
                                        hint="Adresa je alfanumerička i može sadržavati i slova i brojeve"               
                                        flat
                                        clearable
                                        :rules="nazivrules" 
                                        ></v-text-field>
                                </v-col>     
                                <v-col
                                        cols="12"
                                        sm="3"
                                        
                                >
                                        <v-text-field
                                        v-model="setupfirma.Grad"
                                        label="Grad*"
                                        id="Grad-input"
                                        name="Grad-input"
                                        required
                                        counter="200"
                                        hint="Grad je alfanumerički podatak i može sadržavati i slova i brojeve"               
                                        flat
                                        clearable
                                        :rules="nazivrules" 
                                        ></v-text-field>
                                </v-col>   
                                <v-col
                                        cols="12"
                                        sm="3"
                                        
                                >
                                        <v-text-field
                                        v-model="setupfirma.Drzava"
                                        label="Država*"
                                        id="Drzava-input"
                                        name="Drzava-input"
                                        required
                                        counter="200"
                                        hint="Država je alfanumerički podatak i može sadržavati i slova i brojeve"               
                                        flat
                                        clearable
                                        :rules="nazivrules" 
                                        ></v-text-field>
                                </v-col>                                                            
                            </v-row>                    
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        
                                >
                                        <v-text-field
                                        v-model="setupfirma.IDbroj"
                                        label="ID broj firme*"
                                        id="IDbroj-input"
                                        name="IDbroj-input"
                                        required
                                        counter="13"
                                        hint="ID broj može sadržavati samo brojeve"               
                                        flat
                                        clearable
                                        :rules="nazivrules" 
                                        ></v-text-field>
                                </v-col>     
                                <v-col
                                        cols="12"
                                        sm="3"
                                        
                                >
                                        <v-text-field
                                        v-model="setupfirma.PDVbroj"
                                        label="PDV broj*"
                                        id="PDVbroj-input"
                                        name="PDVbroj-input"
                                        required
                                        counter="12"
                                        hint="PDV broj može sadržavati brojeve"               
                                        flat
                                        clearable
                                        :rules="nazivrules" 
                                        ></v-text-field>
                                </v-col>   
                                <v-col
                                        cols="12"
                                        sm="3"
                                        
                                >
                                        <v-text-field
                                        v-model="setupfirma.Sudbroj"
                                        label="Sudski broj*"
                                        id="Sudbroj-input"
                                        name="Sudbroj-input"
                                        required
                                        counter="200"
                                        hint="Sudski broj je alfanumerički podatak i može sadržavati i slova i brojeve"               
                                        flat
                                        clearable
                                        :rules="nazivrules" 
                                        ></v-text-field>
                                </v-col>                                                            
                            </v-row>   
                        </v-card-text>
   


      
                            
                            <v-row>


                                <v-snackbar
                                v-model="alertsnackbar"
                                :multi-line="multiLine"
                                relative
                                shaped
                                centered
                                middle
                                >
                                {{ snackbartekst }}

                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        color="red"
                                        text
                                        v-bind="attrs"
                                        @click="alertsnackbar = false"
                                        >
                                        Zatvori
                                        </v-btn>
                                    </template>
                                </v-snackbar>

                                    <v-spacer></v-spacer>

                                    <v-btn
                                    @click="updateinfofirma()"
                                    class="ma-2"
                                    outlined
                                    color="green darken-1"
                                    >
                                    Upiši
                                    </v-btn>    
                                    <v-spacer></v-spacer>                                   
                            </v-row>
                        

                        <v-divider></v-divider>    
                    </v-card>

                </v-card-text>

                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                
                        >                

                            <v-expansion-panels>
                                <v-expansion-panel>                           
                                    <v-expansion-panel-header                                                 
                                        color="green darken-1"
                                        class=" white--text">

                                        Dodavanje email-a +
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content>
                                        <v-card elevation="0">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    
                                                    >
                                                        
                                                        <v-text-field
                                                            v-model="addemail.Email"
                                                            label="Email*"
                                                            id="Email-input"
                                                            name="Email-input"
                                                            required 
                                                            clearable                                                                                    
                                                            flat
                                                        ></v-text-field>
                                                    </v-col>   
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="10"
                                                    
                                                    >
                                                        <v-textarea
                                                        v-model="addemail.Komentar"
                                                            prepend-inner-icon="fa-comments-o"
                                                            clearable
                                                            clear-icon="fa-close"
                                                            label="Komentar"
                                                            value=""
                                                            hint=""
                                                            counter="500"
                                                            rows="2"
                                                        ></v-textarea> 
                                                    </v-col>  
                                                </v-row>
                                                <v-row>   
                                                    <v-col
                                                    cols="12"
                                                    sm="2"
                                                    
                                                    >
                                                        
                                                        <v-autocomplete
                                                        v-model="addemail.Tip"
                                                        :value="addemail.Tip"
                                                        :items="tipoviemailselect"
                                                        
                                                        label="Tip"
                                                        ></v-autocomplete>
                                                    </v-col>   
                                                                                                                                         
                                                </v-row>                                            
                                            </v-card-text>

                                            <v-card-action class="justify-end" >
                                                    <v-row class="justify-end">                                               

                                                        <v-btn
                                                        @click="addEmail()"
                                                        class="ma-2"
                                                        outlined
                                                        color="green darken-1"
                                                        >
                                                        Upiši
                                                        </v-btn>                                       
                                                    </v-row>
                                            
                                            </v-card-action>
                                        </v-card>
                                    </v-expansion-panel-content>
                                
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-card elevation="0">
                                <v-card-text>
                                    <v-simple-table
                                        ref="myTable"
                                        fixed-header
                                        min-width="50%"
                                        height="300px"
                                        class="table_td_visina"
                                        dense
                                    >


                                        <thead>
                                        <tr>
                                            <th class="table_td_sivo" style="text-align:left">Email</th>
                                            <th class="table_td_sivo" style="text-align:center">Komentar</th>
                                            <th class="table_td_sivo" style="text-align:center">Tip</th>
                                            <th class="table_td_sivo" style="text-align:center">Dlt</th>
                                            <th class="table_td_sivo" style="text-align:center">Upd</th>
                                        </tr>

                                        </thead>

                                        <tbody>



                                            <tr v-for="(item,index) in email" :key="item.Id">
                                                <td class="table_td_osnovna" >

                                                    <v-text-field
                                                        v-model="item.Email"
                                                        label="Email*"
                                                        id="Email-input"
                                                        name="Email-input"
                                                        required 
                                                        clearable                                                                                    
                                                        flat
                                                        style="width:250px;"
                                                    ></v-text-field>                                    

                                                </td>
                                                <td  class="table_td_osnovna"  >
                                                    <v-textarea
                                                    v-model="item.Komentar"
                                                        prepend-inner-icon="fa-comments-o"
                                                        clearable
                                                        clear-icon="fa-close"
                                                        label="Komentar"
                                                        value=""
                                                        hint=""
                                                        counter="500"
                                                        rows="2"
                                                        style="width:200px;"
                                                    ></v-textarea>  

                                                </td>                                
                                                <td  class="table_td_osnovna" >

                                                    <v-autocomplete
                                                    v-model="item.Tip"
                                                    :value="item.Tip"
                                                    :items="tipoviemailselect"
                                                    style="width:70px;"
                                                    ></v-autocomplete>

                                                </td>


                                                <td  class="table_td_osnovna"  >
                                                    <v-icon
                                                        small
                                                        @click="deleteEmail(item.Id, item.Email)"
                                                    >
                                                        fa-trash
                                                    </v-icon>
                                                </td>
                                                <td  class="table_td_osnovna" >
                                                    <v-icon
                                                        small
                                                        class="mr-2"
                                                        @click="updateEmail(item.Id, item.Email, item.Komentar, item.Tip)"
                                                    >
                                                        fa-pencil
                                                    </v-icon>

                                                </td>                            
                                            </tr>

                                        </tbody>        
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                
                        >                

                            <v-expansion-panels>
                                <v-expansion-panel>                           
                                    <v-expansion-panel-header                                                 
                                        color="green darken-1"
                                        class=" white--text">

                                        Dodavanje telefona +
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content>
                                        <v-card elevation="0">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    
                                                    >
                                                        
                                                        <v-text-field
                                                            v-model="addtelefoni.Broj"
                                                            label="Broj*"
                                                            id="Broj-input"
                                                            name="Broj-input"
                                                            required 
                                                            clearable                                                                                    
                                                            flat
                                                        ></v-text-field>
                                                    </v-col>   
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="10"
                                                    
                                                    >
                                                        <v-textarea
                                                        v-model="addtelefoni.Komentar"
                                                            prepend-inner-icon="fa-comments-o"
                                                            clearable
                                                            clear-icon="fa-close"
                                                            label="Komentar"
                                                            value=""
                                                            hint=""
                                                            counter="500"
                                                            rows="2"
                                                            
                                                        ></v-textarea> 
                                                    </v-col>  
                                                </v-row>
                                                <v-row>   
                                                    <v-col
                                                    cols="12"
                                                    sm="2"
                                                    
                                                    >
                                                        
                                                        <v-autocomplete
                                                        v-model="addtelefoni.Tip"
                                                        :value="addtelefoni.Tip"
                                                        :items="tipovitelefoniselect"
                                                        
                                                        label="Tip"
                                                        ></v-autocomplete>
                                                    </v-col>   
                                                                                                                                         
                                                </v-row>                                            
                                            </v-card-text>

                                            <v-card-action class="justify-end" >
                                                    <v-row class="justify-end">                                               

                                                        <v-btn
                                                        @click="addTelefone()"
                                                        class="ma-2"
                                                        outlined
                                                        color="green darken-1"
                                                        >
                                                        Upiši
                                                        </v-btn>                                       
                                                    </v-row>
                                            
                                            </v-card-action>
                                        </v-card>
                                    </v-expansion-panel-content>
                                
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-card elevation="0">
                                <v-card-text>
                                    <v-simple-table
                                        ref="myTable"
                                        fixed-header
                                        min-width="50%"
                                        height="300px"
                                        class="table_td_visina"
                                        dense
                                    >


                                        <thead>
                                        <tr>
                                            <th class="table_td_sivo" style="text-align:left">Telefon</th>
                                            <th class="table_td_sivo" style="text-align:center">Komentar</th>
                                            <th class="table_td_sivo" style="text-align:center">Tip</th>
                                            <th class="table_td_sivo" style="text-align:center">Dlt</th>
                                            <th class="table_td_sivo" style="text-align:center">Upd</th>
                                        </tr>

                                        </thead>

                                        <tbody>



                                            <tr v-for="(item,index) in telefoni" :key="item.Id">
                                                <td class="table_td_osnovna" >

                                                    <v-text-field
                                                        v-model="item.Broj"
                                                        label="Broj*"
                                                        id="Broj-input"
                                                        name="Broj-input"
                                                        required 
                                                        clearable                                                                                    
                                                        flat
                                                        style="width:170px;"
                                                    ></v-text-field>                                    

                                                </td>
                                                <td  class="table_td_osnovna"  >
                                                    <v-textarea
                                                    v-model="item.Komentar"
                                                        prepend-inner-icon="fa-comments-o"
                                                        clearable
                                                        clear-icon="fa-close"
                                                        label="Komentar"
                                                        value=""
                                                        hint=""
                                                        counter="500"
                                                        rows="2"
                                                        style="width:200px;"
                                                    ></v-textarea>  

                                                </td>                                
                                                <td  class="table_td_osnovna" >

                                                    <v-autocomplete
                                                    v-model="item.Tip"
                                                    :value="item.Tip"
                                                    :items="tipovitelefoniselect"
                                                    style="width:100px;"
                                                    ></v-autocomplete>

                                                </td>


                                                <td  class="table_td_osnovna"  >
                                                    <v-icon
                                                        small
                                                        @click="deleteTelefon(item.Id, item.Broj)"
                                                    >
                                                        fa-trash
                                                    </v-icon>
                                                </td>
                                                <td  class="table_td_osnovna" >
                                                    <v-icon
                                                        small
                                                        class="mr-2"
                                                        @click="updateTelefone(item.Id, item.Broj, item.Komentar, item.Tip)"
                                                    >
                                                        fa-pencil
                                                    </v-icon>

                                                </td>                            
                                            </tr>

                                        </tbody>        
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-col>                        
                    </v-row>

                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                
                        >                

                            <v-expansion-panels>
                                <v-expansion-panel>                           
                                    <v-expansion-panel-header                                                 
                                        color="green darken-1"
                                        class=" white--text">

                                        Dodavanje žiroračuna +
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content>
                                        <v-card elevation="0">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    
                                                    >
                                                        
                                                        <v-text-field
                                                            v-model="addziroracuni.Banka"
                                                            label="Banka*"
                                                            id="Banka-input"
                                                            name="Banka-input"
                                                            required 
                                                            clearable                                                                                    
                                                            flat
                                                        ></v-text-field>
                                                    </v-col>   
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    
                                                    >
                                                        
                                                        <v-text-field
                                                            v-model="addziroracuni.Ziroracun"
                                                            label="Ziroracun*"
                                                            id="Ziroracun-input"
                                                            name="Ziroracun-input"
                                                            required 
                                                            clearable                                                                                    
                                                            flat
                                                        ></v-text-field>
                                                    </v-col>   
                                                </v-row>                                                
                                                <v-row>
                                                    <v-col
                                                    cols="12"
                                                    sm="10"
                                                    
                                                    >
                                                        <v-textarea
                                                        v-model="addziroracuni.Komentar"
                                                            prepend-inner-icon="fa-comments-o"
                                                            clearable
                                                            clear-icon="fa-close"
                                                            label="Komentar"
                                                            value=""
                                                            hint=""
                                                            counter="500"
                                                            rows="2"
                                                        ></v-textarea> 
                                                    </v-col>  
                                                </v-row>
                                          
                                            </v-card-text>

                                            <v-card-action class="justify-end" >
                                                    <v-row class="justify-end">                                               

                                                        <v-btn
                                                        @click="addZiroracun()"
                                                        class="ma-2"
                                                        outlined
                                                        color="green darken-1"
                                                        >
                                                        Upiši
                                                        </v-btn>                                       
                                                    </v-row>
                                            
                                            </v-card-action>
                                        </v-card>
                                    </v-expansion-panel-content>
                                
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-card elevation="0">
                                <v-card-text>
                                    <v-simple-table
                                        ref="myTable"
                                        fixed-header
                                        min-width="100%"
                                        height="300px"
                                        class="table_td_visina"
                                        dense
                                    >


                                        <thead>
                                        <tr>
                                            <th class="table_td_sivo" style="text-align:left">Banka</th>
                                            <th class="table_td_sivo" style="text-align:center">Žiroračun</th>
                                            <th class="table_td_sivo" style="text-align:center">Komentar</th>
                                            <th class="table_td_sivo" style="text-align:center">Dlt</th>
                                            <th class="table_td_sivo" style="text-align:center">Upd</th>
                                        </tr>

                                        </thead>

                                        <tbody>



                                            <tr v-for="(item,index) in ziroracuni" :key="item.Id">
                                                <td class="table_td_osnovna" >

                                                    <v-text-field
                                                        v-model="item.Banka"
                                                        label="Banka*"
                                                        id="Banka-input"
                                                        name="Banka-input"
                                                        required 
                                                        clearable                                                                                    
                                                        flat
                                                        style="width:150px;"
                                                    ></v-text-field>                                    

                                                </td>
                                                <td class="table_td_osnovna" >

                                                    <v-text-field
                                                        v-model="item.Ziroracun"
                                                        label="Ziroracun*"
                                                        id="Ziroracun-input"
                                                        name="Ziroracun-input"
                                                        required 
                                                        clearable                                                                                    
                                                        flat
                                                        style="width:170px;"
                                                    ></v-text-field>                                    

                                                </td>                                                
                                                <td  class="table_td_osnovna"  >
                                                    <v-textarea
                                                    v-model="item.Komentar"
                                                        prepend-inner-icon="fa-comments-o"
                                                        clearable
                                                        clear-icon="fa-close"
                                                        label="Komentar"
                                                        value=""
                                                        hint=""
                                                        counter="500"
                                                        rows="2"
                                                        style="width:200px;"
                                                    ></v-textarea>  

                                                </td>                                



                                                <td  class="table_td_osnovna"  >
                                                    <v-icon
                                                        small
                                                        @click="deleteZiroracun(item.Id, item.Ziroracun)"
                                                    >
                                                        fa-trash
                                                    </v-icon>
                                                </td>
                                                <td  class="table_td_osnovna" >
                                                    <v-icon
                                                        small
                                                        class="mr-2"
                                                        @click="updateZiroracun(item.Id, item.Banka, item.Ziroracun, item.Komentar)"
                                                    >
                                                        fa-pencil
                                                    </v-icon>

                                                </td>                            
                                            </tr>

                                        </tbody>        
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-col>


                    </v-row>                    


            </v-card>


            <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                    <v-card-title class="headline"> {{deleteupit}}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    </v-card>
            </v-dialog>

      </v-card>

    </v-main>
  </v-app>
</template>

<script>
export default {

    data() {
        return {
            deleteupit:'',
            deleteemail:false,
            deletetelefoni:false,
            deleteziroracuni:false,

            alert:false,
            alerttext:'',
            alertsnackbar:false,
            snackbartekst:'',
            snackbartitle:'',
            multiLine: true,
            ikonasnackbar:'',
            colorsnackbar:'', 

            setupfirma:{
              Id:0,
              Naziv:'',
              Nazivopis:'',
              Adresa:'',
              Grad:'',
              Drzava:'',
              IDbroj:'',
              PDVbroj:'',
              Sudbroj:''
            },

            ziroracuni: {
                Id:0,
                Banka:'',
                Ziroracun:'',                        
                Komentar:''
            },
            addziroracuni: {
                Id:0,
                Banka:'',
                Ziroracun:'',                        
                Komentar:''
            },
            updateziroracuni: {
                Id:0,
                Banka:'',
                Ziroracun:'',                        
                Komentar:''
            },
            deleteziroracuniId:0,
            tipoviziroracuniselect:['', ''],

                                  
            telefoni: {
                Id:0,
                Tip:'',
                Broj:'',                        
                Komentar:''
            },
            addtelefoni: {
                Id:0,
                Tip:'',
                Broj:'',                        
                Komentar:''
            },   
            updatetelefoni: {
                Id:0,
                Tip:'',
                Broj:'',                        
                Komentar:''
            },    
            deletetelefoniId:0,
            tipovitelefoniselect:['mobitel', 'fiksni'],

            email: {
                Id:0,
                Tip:'',
                Email:'',                        
                Komentar:''
            },
            addemail: {
                Id:0,
                Tip:'',
                Email:'',                        
                Komentar:''
            },  
            updateemail: {
                Id:0,
                Tip:'',
                Email:'',                        
                Komentar:''
            },            
            deleteemailId:0,
            tipoviemailselect:['email', 'email'],





            produktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1
            },
            defaultproduktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1
            },
            exportproduktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1
            }, 
            
            addproduktimediji:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1
            },

            tipoviformataselect:['jpg', 'gif', 'png', 'mp4', 'mpeg', 'youtube'],
            alert:false,
            alerttext:'',

            alertsnackbar:false,
            snackbartekst:'',      
            multiLine: true,

            alertsnackbar2:false,
            snackbartekst2:'',

            deletesifra:'',
            deletenaziv:'',
            deleterb:0,

            deleteproduktmedij:{
                Rb:0,
                Slug:'',
                Thumb:'',
                Src:'',
                Tip:'',
                Format:'',
                Aktivan:1
            },
            dialogDelete:false,
            loading: false,        
            sifrarules: [
                v => !!v || 'Šifra je obavezna!',
                v => (v && v.length <= 50) || 'Šifra mora biti kraća od 50 karaktera.',
            ],
            nazivrules: [
                v => !!v || 'Naziv je obavezan!',
                v => (v && v.length <= 200) || 'Naziv mora biti kraći od 200 karaktera.',
            ],
            trirules: [
                v => !!v || 'Vrijednost je obavezna!',
                v => (v && v.length <= 3) || 'Unesena vrijednost mora biti kraći od 3 karaktera.',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
            ],                               
        }
    },

    props:{

    },

    components: {
     
    },   
    
    computed:{
       

        
    },
    created(){
       this.popunisetup();

    },

    mounted() {  

       this.popunisetup();

    },
    
    watch:{ 

    },

    methods:{
        popunisetup(slug){
            this.popunisetupfirma();
            this.popuniemail();
            this.popunitelefone();
            this.popuniziroracune();


        }, 

        popunisetupfirma(){
            axios.get('/api/apiwsfirmaback', {
                })
                .then((response) => {                      
                    this.setupfirma = response.data.data[0];
                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        updateinfofirma(){
            fetch('/api/apiwsfirmabackinfoupdate', {
                method: 'put',
                body: JSON.stringify(this.setupfirma),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(data => {
                
                if(data.greska=!false){ 
                    this.obavijestHeading('Informacije o firmi su izmjenjene!','success');
                }else
                {
                    this.obavijestHeading("Došlo je do greške upisa! " + err.toString(), "error");
                }
                

            })
            .catch(
                //err => console.log(err.toString())
                err=> this.obavijestHeading("Došlo je do greške upisa! " + err.toString(), "error")
                
                );
        },

        //email
        popuniemail(){
            axios.get('/api/apiwsfirmabackemail', {
                })
                .then((response) => {                      
                    this.email = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        updateEmail(Id, Email, Komentar, Tip){

            this.updateemail.Id=Id;
            this.updateemail.Email=Email;
            this.updateemail.Komentar=Komentar;
            this.updateemail.Tip=Tip;

            fetch('/api/apiwsfirmabackemailupdate', {
                method: 'put',
                body: JSON.stringify(this.updateemail),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(data => {
                
                if(data.greska=!false){ 
                    this.obavijestHeading('Podaci o email-u izmjenjeni!','success');
                }else
                {
                    this.obavijestHeading("Došlo je do greške upisa kod izmjene! " + data.data.toString(), "error");
                }
                

            })
            .catch(
                //err => console.log(err.toString())
                err=> this.obavijestHeading("Došlo je do greške upisa kod izmjene! " + err.toString(), "error")
                
                );
        },

        addEmail(){


            fetch('/api/apiwsfirmabackemailadd', {
                method: 'post',
                body: JSON.stringify(this.addemail),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(data => {
                
                if(data.greska=!false){ 
                    this.obavijestHeading('Email je dodat!','success');
                    this.addemail.Email='';
                    this.addemail.Komentar='';
                    this.addemail.Tip='';
                    this.popuniemail();
                }else
                {
                    this.obavijestHeading("Došlo je do greške upisa! Originalna greška : " + data.toString(), "error");
                }
                

            })
            .catch(
                //err => console.log(err.toString())
                err=> this.obavijestHeading("Došlo je do greške upisa! Originalna greška : " + err.toString(), "error")
                
                );
        },

        obrisiEmail(Id) {
        //if (confirm('Da li ste sigurni u brisanje?')) {
            fetch(`api/apiwsfirmabackemaildelete/${Id}`, {
            method: 'delete'
            })
            .then(res => res.json())
            .then(data => {
                //alert('brandova je obrisana!');
                this.obavijestHeading("Email je obrisan!", "success");
                this.popuniemail();
                
            })
            .catch(err => console.log(err.toString()));
            //console.error(this.props.url, status, err.toString()));
        //}
        },

        deleteEmail (Id, Email) {
            this.deleteemail=true,
            this.deleteupit='Da li ste sigurni u brisanje emaila '+ Email + '?';
            this.deleteemailId=Id;           
            this.dialogDelete = true
        },


        //telefoni
        popunitelefone(){
            axios.get('/api/apiwsfirmabacktelefoni', {
                })
                .then((response) => {                      
                    this.telefoni = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        updateTelefone(Id, Broj, Komentar, Tip){

            this.updatetelefoni.Id=Id;
            this.updatetelefoni.Broj=Broj;
            this.updatetelefoni.Komentar=Komentar;
            this.updatetelefoni.Tip=Tip;

            fetch('/api/apiwsfirmabacktelefoniupdate', {
                method: 'put',
                body: JSON.stringify(this.updatetelefoni),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(data => {
                
                if(data.greska=!false){ 
                    this.obavijestHeading('Podaci o telefonima su izmjenjeni!','success');
                }else
                {
                    this.obavijestHeading("Došlo je do greške upisa kod izmjene telefona! " + data.data.toString(), "error");
                }
                

            })
            .catch(
                //err => console.log(err.toString())
                err=> this.obavijestHeading("Došlo je do greške upisa kod izmjene telefona! " + err.toString(), "error")
                
                );
        },

        addTelefone(){


            fetch('/api/apiwsfirmabacktelefoniadd', {
                method: 'post',
                body: JSON.stringify(this.addtelefoni),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(data => {
                
                if(data.greska=!false){ 
                    this.obavijestHeading('Broj je dodat!','success');
                    this.addtelefoni.Broj='';
                    this.addtelefoni.Komentar='';
                    this.addtelefoni.Tip='';
                    this.popunitelefone();
                }else
                {
                    this.obavijestHeading("Došlo je do greške upisa! Originalna greška : " + data.toString(), "error");
                }
                

            })
            .catch(
                //err => console.log(err.toString())
                err=> this.obavijestHeading("Došlo je do greške upisa! Originalna greška : " + err.toString(), "error")
                
                );
        },

        obrisiTelefone(Id) {
        //if (confirm('Da li ste sigurni u brisanje?')) {
            fetch(`api/apiwsfirmabacktelefonidelete/${Id}`, {
            method: 'delete'
            })
            .then(res => res.json())
            .then(data => {
                //alert('brandova je obrisana!');
                this.obavijestHeading("Telefon je obrisan!", "success");
                this.popunitelefone();
                
            })
            .catch(err => console.log(err.toString()));
            //console.error(this.props.url, status, err.toString()));
        //}
        },

        deleteTelefon (Id, Broj) {
            this.deletetelefoni=true,
            this.deleteupit='Da li ste sigurni u brisanje telefona '+ Broj + '?';
            this.deletetelefoniId=Id;           
            this.dialogDelete = true
        },



        //žiroračuni
        popuniziroracune(){
            axios.get('/api/apiwsfirmabackziroracuni', {
                })
                .then((response) => {                      
                    this.ziroracuni = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        updateZiroracun(Id, Banka, Ziroracun, Komentar){

            this.updateziroracuni.Id=Id;
            this.updateziroracuni.Banka=Banka;
            this.updateziroracuni.Ziroracun=Ziroracun;
            this.updateziroracuni.Komentar=Komentar;
            

            fetch('/api/apiwsfirmabackziroracuniupdate', {
                method: 'put',
                body: JSON.stringify(this.updateziroracuni),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(data => {
                
                if(data.greska=!false){ 
                    this.obavijestHeading('Podaci o žiroračunu su izmjenjeni!','success');
                }else
                {
                    this.obavijestHeading("Došlo je do greške upisa kod izmjene žiroračuna! " + data.data.toString(), "error");
                }
                

            })
            .catch(
                //err => console.log(err.toString())
                err=> this.obavijestHeading("Došlo je do greške upisa kod izmjene žiroračuna! " + err.toString(), "error")
                
                );
        },

        addZiroracun(){


            fetch('/api/apiwsfirmabackziroracuniadd', {
                method: 'post',
                body: JSON.stringify(this.addziroracuni),
                headers: {
                    'content-type': 'application/json'
                }
                })
                .then(res => res.json())
                .then(data => {
                
                if(data.greska=!false){ 
                    this.obavijestHeading('Broj je dodat!','success');
                    this.addziroracuni.Banka='';
                    this.addziroracuni.Ziroracun='';
                    this.addziroracuni.Komentar='';

                    this.popuniziroracune();
                }else
                {
                    this.obavijestHeading("Došlo je do greške upisa! Originalna greška : " + data.toString(), "error");
                }
                

            })
            .catch(
                //err => console.log(err.toString())
                err=> this.obavijestHeading("Došlo je do greške upisa! Originalna greška : " + err.toString(), "error")
                
                );
        },

        obrisiZiroracun(Id) {
        //if (confirm('Da li ste sigurni u brisanje?')) {
            fetch(`api/apiwsfirmabackziroracunidelete/${Id}`, {
            method: 'delete'
            })
            .then(res => res.json())
            .then(data => {
                //alert('brandova je obrisana!');
                this.obavijestHeading("Žiroračun je obrisan!", "success");
                this.popuniziroracune();
                
            })
            .catch(err => console.log(err.toString()));
            //console.error(this.props.url, status, err.toString()));
        //}
        },

        deleteZiroracun (Id, Ziroracun) {
            this.deleteziroracuni=true,
            this.deleteupit='Da li ste sigurni u brisanje žiroračuna '+ Ziroracun + '?';
            this.deleteziroracuniId=Id;           
            this.dialogDelete = true
        },


        async closeDelete () {
            this.deleteemail=false,
            this.deletetelefoni=false,
            this.deleteziroracunu=false,
            this.deleteupit='';
            this.dialogDelete = false;

           
        },
        deleteItemConfirm () {

            if( this.deleteemail==true){
                this.obrisiEmail( this.deleteemailId);
                this.deleteEmail=false;
            }
            if( this.deletetelefoni==true){
                this.obrisiTelefone(this.deletetelefoniId);
                this.deletetelefoni=false;
            }

            if( this.deleteziroracuni==true){
                this.obrisiZiroracun( this.deleteziroracuniId);
                this.deleteziroracuni=false;
            }

            this.closeDelete()
        },


        async obavijestHeading(strTekst, tip){

            switch(tip) {
            case 'info':
                this.ikonasnackbar="info";
                this.colorsnackbar="info";
                this.snackbartitle="Info"
                break;
            case 'success':
                this.ikonasnackbar="check_circle";
                this.colorsnackbar="success";
                this.snackbartitle="Obavijest"
                break;
            case 'error':
                this.ikonasnackbar="error";
                this.colorsnackbar="error";
                this.snackbartitle="Greška"
                break; 
            case 'warning':
                this.ikonasnackbar="warning";
                this.colorsnackbar="warning";
                this.snackbartitle="Upozorenje"
                break;                                        
            default:

            }

            this.snackbartekst=strTekst;
            this.alertsnackbar=true;


        },    


        pokrenutosaroot(data){
            if(data.SLug==""){
                this.produktimediji = Object.assign({}, this.defaultproduktimediji);
                return;
            };
            this.popunikontrole(data.SLug);
        },


        
        zatvoriFormu (editmodusrada, poruka) {
        
            this.$emit('ws_KreiranjeEditClick',false,editmodusrada,true, poruka ) 

        },
        updatemedia(rb,Slug, Thumb, Src, Tip, Format){
                this.exportproduktimediji.Rb=rb;
                this.exportproduktimediji.Slug=Slug;
                this.exportproduktimediji.Thumb=Thumb;
                this.exportproduktimediji.Src=Src;
                this.exportproduktimediji.Tip=Tip;
                this.exportproduktimediji.Format=Format;
                this.exportproduktimediji.Aktivan=1;

                fetch('/api/apiwsproduktmediakreiranje', {
                    method: 'put',
                    body: JSON.stringify(this.exportproduktimediji),
                    headers: {
                        'content-type': 'application/json'
                    }
                    })
                    .then(res => res.json())
                    .then(data => {
                    
                    
                    this.obavijestHeading("Mediji produkta su izmjenjeni!");
                    this.popunikontrole(Slug);

                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijestHeading("Došlo je do greške upisa! " + err.toString())
                    
                    );


        },

        addmedia(){
                
                this.addproduktimediji.Slug=this.produkt.Slug;

                fetch('/api/apiwsproduktmediakreiranje', {
                    method: 'post',
                    body: JSON.stringify(this.addproduktimediji),
                    headers: {
                        'content-type': 'application/json'
                    }
                    })
                    .then(res => res.json())
                    .then(data => {
                    
                    
                    this.obavijestHeading("Mediji produkta su dodati!");
                    this.popunikontrole(this.produkt.Slug);
                    this.addproduktimediji.Thumb='';
                    this.addproduktimediji.Src='';
                    this.addproduktimediji.Format='';
                    this.addproduktimediji.Tip='';

                })
                .catch(
                    //err => console.log(err.toString())
                    err=> this.obavijestHeading("Došlo je do greške upisa! " + err.toString())
                    
                    );


        },

        popunikontrole(slug){
            if (slug==""){
                
                this.produktimediji = Object.assign({}, this.defaultproduktimediji)
                return;
            }           
               axios.get('/api/apiwsproduktmediakreiranje/'+ slug, {
                    })
                    .then((response) => {                      
                        this.produktimediji = response.data;
                        
                        
                      
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

        }, 





        async loadingslova () {
            this.loading = true
            await new Promise(resolve => setTimeout(resolve, 3000))
            this.loading = false
        },


    },


    
}
</script>

<style >


.glavni_naslov{
  font-size: 12px !important;  
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.sekcija_naslov{
  font-size: 12px !important;  
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.table_td_visina{
     line-height: 5px !important; 
}

.table_td_osnovna{
  font-size: 16px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 5px  !important; 
    
    padding: 0px;
    margin: 0px;
}

.table_td_osnovna_prosiri{
  font-size: 16px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 5px  !important; 
  width: 200px !important;
}
.table_td_left{
  background-color:#F2F3F4;
  font-weight: bold;
  font-size: 10px !important;
  width: 30%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;  
}
.table_td_nastavak{
  font-weight: bold;
  font-size: 10px !important;
  width: 30%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.table_td_sivo{

  background-color:#F2F3F4;
  font-weight: bold;
  font-size: 16px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;  
}

.table_stavke{
  
    min-width: 200px !important;
}
.table_td_right{
  /*background-color:#F2F3F4;
  font-weight: bold;*/
}

.v-progress-circular {
  margin: 1rem;
}
</style>