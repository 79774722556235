<template>
    <v-app>
        <v-main>

        <div class="container">

            <Kreiranje-Tabela v-show="tabelashow" :produkt="produkt" :edit="edit" ></Kreiranje-Tabela> 
            <Kreiranje-Tabela-Tab  v-show="!tabelashow"  :produkt="produkt" :edit="edit" ></Kreiranje-Tabela-Tab>
            <!--component :is="currentComp" :produkt="produkt" :edit="edit" :selected="memorisanselected"></component-->
        </div>
        </v-main>
    </v-app>
</template>
  
<script>  

//import LookupRoba from '../Registarroba/RobaLookUp.vue';
import KreiranjeTabela from '../WebShopKreiranje/WebShopKreiranjeTabela.vue'
import KreiranjeTabelaTab from '../WebShopKreiranje/WebShopKreiranjeTab.vue'
//import { bus } from '../../app.js'//--- busproba

import { ref } from 'vue'

var numeral = require('numeral');


export default {
    inject:["eventBus"],//bus mitt vue3
    data() {
        return {
            //currentComp: 'Kreiranje-Tabela',
            tabelashow:true,
            tabshow:false,
            edit:false,
            produkt: {
                
                    Sifra:'',
                    Slug:'',
                    NazivRoba:'',
                    Datum:'',
                    Staracijena:0, 
                    Cijena:0,
                    Valuta:'',
                    Akcija:0, 
                    Akcijadatumstart:'', 
                    Akcijadatumkraj:'', 
                    Popust:'', 
                    Akcijatekst:'',
                    Freeshiping:0, 
                    Ribbon:0, 
                    Ribbontext:'', 
                    Prioritet:0,
                    Topprodukt:0,
                    Cijenanaupit:0, 
                    Menukategorija:0,
                    Kategorija:0,
                    Subkategorija:0,
                    SubSubkategorija:0,
                    SlikaString:0,
                    OpisRoba:0,
                    KratkiOpisRoba:0,
                    KategorijaNaziv:0,            
                    Brand:'',
                    Status:0

            },
            memorisanselected: {
                cijene: [],
                kategorije: [],
                brandovi: [],
                branditems:[],
                kategorijeitems: []
            }, 
        };
    },
    
    components:{
        'Kreiranje-Tabela':KreiranjeTabela,
        'Kreiranje-Tabela-Tab':KreiranjeTabelaTab,       
    },

    created() {

        // ==== bus aktiviraj instancu
        //bus.$on('kontejnerinstanca',(data)=>{this.posaljidatatabu(data)})
        this.eventBus.on('kontejnerinstanca',(data)=>{this.posaljidatatabu(data)});//bus mitt vue3

    },   

    methods: { 
        posaljidatatabu(data){
            //console.log(data.poruka);
            this.produkt=data.transprodukt;
            this.edit=data.transedit;
            this.tabelashow=data.transtabelashow;
            this.tabshow=data.transtabshow;

            //bus.$emit('tabtransinstanca', {kontejnerprodukt:this.produkt, kontejneredit: this.edit, kontejnerporuka:"došlo sa kontejnera"}); //modrada je true za edit, false za add
        }
    }
}
  
</script> 



<style scoped>
/*
.container {
  width: 450px;
  height: 270px;
  margin: 5px;
  padding: 10px;
  border: 1px solid blue;
}

.label {
  font-size: 10px;
  color: blue;
  margin-right: 20px;
}*/
</style>
